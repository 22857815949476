import {Checkbox, Col, Form, Input, InputNumber, message, Row} from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ActionButton from '../../components/action-button';
import InputEmail from '../../components/input-email';
import InputName from '../../components/input-name';
import InputPassword from '../../components/input-password';
import MiniFormDrawer from '../../components/mini-form-drawer';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';

const defaultValue = {
    host: '',
    porta: null,
    usuario: '',
    alterarSenha: '',
    senha: '',
    deEmail: '',
    deNome: '',
    email: '',
};

export default function SmtpDrawer({visible, onClose}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (visible) {
            setLoading(true);
            form.setFieldsValue(defaultValue);
            unwrapAxiosPromise(axios.get('/smtp'))
                .then(data => {
                    delete data.senha;
                    form.setFieldsValue(data);
                })
                .finally(() => setLoading(false));
        }
    }, [visible]);

    const handleDrawerAfterOpen = () => {
        document.querySelector('#host').focus();
    };

    const handleDrawerAfterClose = () => {
        form.resetFields();
    };

    const handleFinish = values => {
        setSaving(true);
        unwrapAxiosPromise(axios.put('/smtp', values), form)
            .then(() => {
                message.success(t('SMTP alterado com sucesso'));
                onClose();
            })
            .finally(() => setSaving(false));
    };

    const handleAlterarSenhaClick = e => {
        if (e.target.checked) {
            setImmediate(() => document.querySelector('#senha')?.focus());
        }
    }

    const footer = (
        <>
            <ActionButton.Save
                onClick={() => form.submit()}
                loading={saving}
                disabled={loading}
            />
            <ActionButton.Close
                onClick={onClose}
                disabled={saving}
            />
        </>
    );

    return (
        <MiniFormDrawer
            drawerTitle={t('SMTP')}
            drawerFooter={footer}
            drawerWidth={400}
            drawerVisible={visible}
            form={form}
            loading={loading}
            onDrawerClose={onClose}
            onDrawerAfterOpen={handleDrawerAfterOpen}
            onDrawerAfterClose={handleDrawerAfterClose}
            onFinish={handleFinish}
        >
            <Row gutter={[16, 0]}>
                <Col flex="auto">
                    <Form.Item name="host" label={t('Host')}>
                        <Input disabled={saving} maxLength={100}/>
                    </Form.Item>
                </Col>
                <Col flex="100px">
                    <Form.Item name="porta" label={t('Porta')}>
                        <InputNumber disabled={saving} maxLength={5} min={1} max={99999}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="usuario" label={t('Usuário')}>
                <Input disabled={saving} maxLength={100}/>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(pv, cv) => pv.alterarSenha !== cv.alterarSenha}>
                {({getFieldValue}) =>
                    <Form.Item
                        name="senha"
                        label={
                            <Form.Item name="alterarSenha" valuePropName="checked" noStyle>
                                <Checkbox disabled={loading} onClick={handleAlterarSenhaClick}>
                                    {t('Alterar Senha')}
                                </Checkbox>
                            </Form.Item>
                        }
                    >
                        <InputPassword
                            disabled={saving || !getFieldValue('alterarSenha')}
                            maxLength={100}
                        />
                    </Form.Item>
                }
            </Form.Item>
            <Form.Item name="deEmail" label={t('E-mail do Remetente')}>
                <InputEmail disabled={saving}/>
            </Form.Item>
            <Form.Item name="deNome" label={t('Nome do Remetente')}>
                <InputName disabled={saving} />
            </Form.Item>
        </MiniFormDrawer>
    );
}

SmtpDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};
