import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import useFormatter from '../../../hooks/useFormatter';
import { formatCpf } from '../../../util/cpf';
import { compareStrings } from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

export default function EmpresaRepresentantesTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatDate} = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Sócio'),
      dataIndex: ['socio', 'nome'],
      key: 'socio',
      sorter: (a, b) => compareStrings(a.socio.nome, b.socio.nome),
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
    },
    {
      title: t('CPF'),
      dataIndex: 'cpf',
      key: 'cpf',
      sorter: (a, b) => compareStrings(a.cpf, b.cpf),
      render: (text, record) => formatCpf(text),
    },
    {
      title: t('Data Inicial'),
      dataIndex: 'dataInicial',
      key: 'dataInicial',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataInicial, b.dataInicial),
      render: (text, record) => formatDate(record.dataInicial),
    },
    {
      title: t('Data Final'),
      dataIndex: 'dataFinal',
      key: 'dataFinal',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataFinal, b.dataFinal),
      render: (text, record) => record.indeterminado ? t('Indeterminado') : formatDate(record.dataFinal),
    },
    {
      title: t('Poderes'),
      dataIndex: 'poderes',
      key: 'poderes',
      sorter: (a, b) => compareStrings(a.poderes, b.poderes),
      render: (text) => <TableCellEllipsis text={text}/>
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaRepresentantesTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
