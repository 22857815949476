// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Nu\\+U3gFGfv-2qeYQNsrfAQ\\=\\= {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/consultivo/quadro/_ConsultaCollection.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wBAAA;EACA,QAAA;AACF","sourcesContent":[".ConsultaCollection {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConsultaCollection": "Nu+U3gFGfv-2qeYQNsrfAQ=="
};
export default ___CSS_LOADER_EXPORT___;
