import { message, Space } from 'antd';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import MiniTable from '../../../../components/mini-table';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import { openPopup } from '../../../../util/window';
import styles from '../_styles.less';
import { isGestorSelector } from "../../../../store/autenticacao";
import { fetchAssuntos, assuntosSelector } from "../../../../store/consultivoArea";
import ConsultivoAreaAssuntosTable from "./_table";

export default function ConsultivoAreaAssuntosDetailPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isEditable = useSelector(isGestorSelector);
  const assuntos = useSelector(assuntosSelector);
  const loading = useLoading(fetchAssuntos.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCreate = () => {
    history.replace(`/consultivo/areas/${areaId}/assuntos/create`);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete(`/consultivo/areas/${areaId}/assuntos`, { params: { ids: selectedIds } }))
      .then(() => {
        message.success(t('Registro(s) removido(s) com sucesso'));
        setSelectedIds([]);
        dispatch(fetchAssuntos({ areaId }));
      })
      .finally(() => setLoadingRemove(false));
  };

  const handleDetail = assunto => {
    history.replace(`/consultivo/areas/${areaId}/assuntos/${assunto.id}`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/consultivo/areas/${areaId}/assuntos/`, `logs_/consultivo/areas/${areaId}/assuntos/`);
  };

  const actions = [];
  if (isEditable) {
    actions.push(<ActionButton.Create onClick={handleCreate}/>);
    actions.push(<ActionButton.Remove
      disabled={selectedIds.length === 0}
      onClick={handleRemove}
      loading={loadingRemove}
    />);
  }
  actions.push(<ActionButton.Logs noTitle onClick={handleLogs}/>);
  actions.push(<MiniTable.DownloadButton disabled={loading || assuntos.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar>{actions}</ActionBar>;
  return (
    <Space direction="vertical" className={styles['space']}>
      <ConsultivoAreaAssuntosTable
        dataSource={assuntos}
        loading={loading}
        miniTableRef={miniTableRef}
        onClick={handleDetail}
        onSelectedIdsChange={setSelectedIds}
        selectedIds={selectedIds}
        title={title}
      />
    </Space>);
}