import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextItem from '../../../../components/text-item';
import { EmpresaUnidade, Endereco, findByIdAndMap } from '../../../../constants';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { fetchUnidade, isEditableSelector, unidadeSelector } from '../../../../store/empresa';
import { formatCep } from '../../../../util/cep';
import { formatCnpj } from '../../../../util/cnpj';
import { openPopup } from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

const { Tipo } = EmpresaUnidade;

export default function EmpresaUnidadeDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const unidade = useSelector(unidadeSelector);
  const { formatDate, formatDateTime, formatBoolean } = useFormatter();
  const loading = useLoading(fetchUnidade.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/unidades/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/unidades`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/unidades/${id}/`, `logs_/empresas/${empresaId}/unidades/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="250px"><TextItem label={t('Unidade')}>{unidade?.apelido}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('CNPJ')}>{formatCnpj(unidade?.cnpj)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Tipo')}>{findByIdAndMap(Tipo, unidade?.tipo, tipo => t(tipo.label))}</TextItem></Col>
        <Col flex="150px"><TextItem label={t('Ativa')}>{formatBoolean(unidade?.ativa)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="250px"><TextItem label={t('Estado')}>{findByIdAndMap(Endereco.Estado, unidade?.estado, estado => t(estado.label))}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Cidade')}>{unidade?.cidade}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('CEP')}>{formatCep(unidade?.cep)}</TextItem></Col>
        <Col flex="150px">
          {unidade?.ativa === false &&
            <TextItem label={t('Baixa em')}>{formatDate(unidade?.dataBaixa)}</TextItem>
          }
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Endereço')}>{unidade?.endereco}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('CNAE Primário')}>{unidade?.cnaePrimario ? unidade.cnaePrimario.codigo + ' - ' + unidade.cnaePrimario.descricao : ''}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('CNAEs Secundários')}>{unidade?.cnaesSecundarios?.map(it => <div>{it.codigo} - {it.descricao}</div>)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Objeto Social')}>{unidade?.objetoSocial}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Observação')}>{unidade?.obs}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(unidade?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(unidade?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{unidade?.id}</TextItem></Col>
      </Row>
    </Spin>
  );
}
