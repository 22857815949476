import { Col, Row, Spin } from "antd";
import React from 'react';
import { openPopup } from "../../../../util/window";
import ActionBar from "../../../../components/action-bar";
import ActionButton from "../../../../components/action-button";
import { isGestorSelector } from "../../../../store/autenticacao";
import { useSelector } from "react-redux";
import { cadastroSelector, fetchCadastro } from "../../../../store/consultivoArea";
import useLoading from "../../../../hooks/useLoading";
import useFormatter from "../../../../hooks/useFormatter";
import { useHistory, useRouteMatch } from "react-router";
import TextItem from "../../../../components/text-item";
import { useTranslation } from "react-i18next";
import TextColor from "../../../../components/text-color";

export default function ConsultivoAreaCadastroDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isGestorSelector);
  const cadastro = useSelector(cadastroSelector);
  const loading = useLoading(fetchCadastro.typePrefix);
  const { formatDateTime } = useFormatter();
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;

  const handleEdit = () => {
    history.replace(`/consultivo/areas/${areaId}/cadastro/edit`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/consultivo/areas/${areaId}/cadastro/`, `logs_/consultivo/areas/${areaId}/cadastro/`);
  };

  return (
    <Spin spinning={loading}>
      {isEditable &&
        <ActionBar>
          <ActionButton.Edit onClick={handleEdit}/>
          <ActionButton.Logs noTitle onClick={handleLogs}/>
        </ActionBar>
      }
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Nome')}>{cadastro?.nome}</TextItem></Col>
        <Col flex="200px"><TextColor label={t('Cor')}>{cadastro?.cor}</TextColor></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextItem label={t('Área de Atuação Pai')}>{cadastro?.areaPai?.caminho}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Responsável')}>{cadastro?.responsavel?.nome}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Id Externo')}>{cadastro?.idExterno}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(cadastro?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(cadastro?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{cadastro?.id}</TextItem></Col>
      </Row>
    </Spin>
  )
}