import styles from "../_styles.less";
import { Consulta, findById } from "../../../../constants";
import ActionLink from "../../../../components/action-link";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextItem from "../../../../components/text-item";
import SelectConsultivoConsultaStatus from "../../../../components/select-consultivo-consulta-status";
import { CloseOutlined } from "@ant-design/icons";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import axios from "axios";
import { message } from "antd";
import { cabecalhoSelector, fetchCabecalho, fetchCadastro, fetchFases, fetchLogs, fetchPermissoes, hasPermissaoSelector } from "../../../../store/consultivoConsulta";
import useDispatchWithResult from "../../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";

export default function Status() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.STATUS_ALTERAR.id));
  const cabecalho = useSelector(cabecalhoSelector);
  const [loadingSave, setLoadingSave] = useState(false);
  const [editing, setEditing] = useState(false);
  const [status, setStatus] = useState(cabecalho?.status);

  const consultaId = cabecalho?.id;

  const handleClick = () => {
    setEditing(true);
    setStatus(cabecalho?.status);
  };

  const handleSave = () => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/consultivo/consultas/${consultaId}/cabecalho/status`, { status }))
      .then(() => {
        message.success(t('Status alterado com sucesso'));
        dispatch(fetchPermissoes({ consultaId }));
        dispatch(fetchCabecalho({ consultaId }));
        dispatch(fetchCadastro({ consultaId }));
        dispatch(fetchFases({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
        setEditing(false);
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    setEditing(false);
  }

  if (editing) {
    return (
      <TextItem label={t('Status')}>
        <div className={styles['text-item-editable']}>
          <SelectConsultivoConsultaStatus
            disabled={loadingSave}
            value={status}
            onChange={setStatus}
            style={{ minWidth: '200px' }}
          />
          {isEditable &&
            <>
              <ActionLink.Save
                className={styles['button']}
                loading={loadingSave}
                noTitle
                onClick={handleSave}/>
              <ActionLink.Cancel
                className={styles['button']}
                icon={<CloseOutlined/>}
                noTitle
                onClick={handleCancel}/>
            </>
          }
        </div>
      </TextItem>
    );

  }

  return (
    <TextItem label={t('Status')}>
      <div className={styles['text-item-editable']}>
        <div>{t(findById(Consulta.Status, cabecalho?.status)?.label)}</div>
        {isEditable &&
          <ActionLink.Edit className={styles['button']} noTitle onClick={handleClick}/>
        }
      </div>
    </TextItem>
  );
}
