import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import {formatDocumento} from '../../../../util/documento';
import {compareStrings} from '../../../../util/string';
import TableCellEllipsis from "../../../../components/table-cell-ellipsis";

export default function EmpresaSociosTable3({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();

  const fireClick = socio => typeof onClick === 'function' ? onClick(socio) : undefined;

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('CPF/CNPJ'),
      dataIndex: 'documento',
      key: 'documento',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
      render: (text, record) => formatDocumento(text),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaSociosTable3.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
