import {Col, Form, Input, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import MiniForm from '../../../../components/mini-form';
import SelectBoolean from '../../../../components/select-boolean';
import SelectEmpresaProcuradorTipo from '../../../../components/select-empresa-procurador-tipo';
import InputNames from "../../../../components/input-names";

export default function EmpresaProcuradorForm({disabled, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="tipo" label={t('Tipo')}>
              <SelectEmpresaProcuradorTipo disabled={disabled}/>
            </Form.Item>
          </Col>
          <Col flex="400px">
            <Form.Item name="nomes" label={t('Nomes')}>
              <InputNames name="nomes" disabled={disabled}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="dataInicial" label={t('Data Inicial')}>
              <InputDate disabled={disabled}/>
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="indeterminado" label={t('Prazo Indeterminado')}>
              <SelectBoolean disabled={disabled}/>
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.indeterminado !== nextValues.indeterminado}
            >
              {({getFieldValue, setFieldsValue}) => {
                const indeterminado = getFieldValue('indeterminado');
                if (indeterminado === false)
                  return (
                      <Form.Item name="dataFinal" label={t('Data Final')}>
                        <InputDate disabled={disabled}/>
                      </Form.Item>);
                setFieldsValue({'dataFinal': null});
                return null;
              }}
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="aviso" label={t('Controle de Prazo')}>
              <SelectBoolean disabled={disabled}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="poderes" label={t('Poderes')}>
              <Input.TextArea autoSize disabled={disabled}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="anexo" label={t('Anexo')}>
              <InputAnexo disabled={loading}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="obs" label={t('Observação')}>
              <Input.TextArea autoSize disabled={disabled}/>
            </Form.Item>
          </Col>
        </Row>
      </MiniForm>);
}

EmpresaProcuradorForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
