import { useSelector } from "react-redux";
import { nivelAcessoSelector, usuarioIdSelector } from "../../store/autenticacao";
import React, { useEffect } from "react";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { consultivoFiltroAplicar, consultivoFiltroResetar } from "../../store/consultivoFiltro";
import { Redirect } from "react-router";
import { Usuario } from "../../constants";
import { acessoSelector } from "../../store/acessos";

export default function ConsultivoPage() {
  const dispatch = useDispatchWithResult();
  const acesso = useSelector(acessoSelector);
  const acessoId = acesso?.id;
  const usuarioId = useSelector(usuarioIdSelector);
  const nivelAcesso = useSelector(nivelAcessoSelector);

  useEffect(() => {
    dispatch(consultivoFiltroResetar());
    if (nivelAcesso <= Usuario.NivelAcesso.ClienteGestor)
      dispatch(consultivoFiltroAplicar({ acessoId, usuarioId }));
    else if (nivelAcesso <= Usuario.NivelAcesso.Gestor)
      dispatch(consultivoFiltroAplicar({ usuarioId }));
  }, []);

  return <Redirect to="/consultivo/dashboard"/>;
}
