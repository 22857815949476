import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import {errosSelector, totalSelector} from '../../../store/complianceDashboard';
import {fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas, filtrarEmpresas} from '../../../store/empresas';
import NivelCard from './_NivelCard';

export default function NivelErroCard() {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const erros = useSelector(errosSelector);
  const total = useSelector(totalSelector);

  const handleClick = () => {
    dispatch(filtrarEmpresas({sucessos: false, avisos: false, erros: true, inativas: false, tabs: []}));
    history.push('/compliance/detalhado');
  };

  return (
      <NivelCard
          className="background-error"
          count={erros}
          loading={loading}
          title={t('Pendências')}
          total={total}
          onClick={handleClick}
      />
  );
}
