import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import ConsultivoConsultaFasesDetailPage from "./_detail";

export default function ConsultivoConsultaFasesPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/consultas/:consultaId" component={ConsultivoConsultaFasesDetailPage}/>
      </Switch>
    </Card>);
}
