import PropTypes from 'prop-types';
import React from 'react';
import {Empresa} from '../../../../../constants';
import EmpresaContratoForm1 from './_form_1';
import EmpresaContratoForm2 from './_form_2';

export default function EmpresaContratoForm({cabecalho, ...props}) {
  switch (cabecalho?.tipo) {
    default:
    case Empresa.Tipo.SociedadeLimitada.id:
    case Empresa.Tipo.SociedadeSimples.id:
    case Empresa.Tipo.Cooperativa.id:
    case Empresa.Tipo.SociedadePorAcoes.id:
    case Empresa.Tipo.Eireli.id:
    case Empresa.Tipo.EmpresarioIndividual.id:
    case Empresa.Tipo.Associacao.id:
    case Empresa.Tipo.Fundacao.id:
    case Empresa.Tipo.SociedadeEmContaDeParticipacao.id:
    case Empresa.Tipo.FundoDeInvestimento.id:
      return <EmpresaContratoForm1 {...props} />;
    case Empresa.Tipo.EntidadeEstrangeira.id:
      return <EmpresaContratoForm2 {...props} />;
  }
}

EmpresaContratoForm.propTypes = {
  disabled: PropTypes.bool,
  cabecalho: PropTypes.object,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
