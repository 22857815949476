// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bLYazGNAX5t0VVJTtk4moA\\=\\= {\n  line-height: 32px;\n  font-weight: bold;\n  padding: 0 8px;\n}\n.bLYazGNAX5t0VVJTtk4moA\\=\\=:not(.gmSD7VGLW6RznDuoM0Jr4Q\\=\\=) {\n  cursor: pointer;\n}\n.bLYazGNAX5t0VVJTtk4moA\\=\\=:not(.gmSD7VGLW6RznDuoM0Jr4Q\\=\\=):hover {\n  background-color: #f0f2f5;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_WorkflowTarefaNome.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,cAAA;AACF;AAEA;EACE,eAAA;AAAF;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[".workflow-tarefa-nome {\n  line-height: 32px;\n  font-weight: bold;\n  padding: 0 8px;\n}\n\n.workflow-tarefa-nome:not(.read-only) {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-nome": "bLYazGNAX5t0VVJTtk4moA==",
	"read-only": "gmSD7VGLW6RznDuoM0Jr4Q=="
};
export default ___CSS_LOADER_EXPORT___;
