// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o4gFwxtKXmlI-6pmQXptWg\\=\\= {\n  line-height: 32px;\n  padding: 0 8px;\n}\n.o4gFwxtKXmlI-6pmQXptWg\\=\\=:not(._36vz5BqBZaygOTJkf9p9fw\\=\\=) {\n  cursor: pointer;\n}\n.o4gFwxtKXmlI-6pmQXptWg\\=\\=:not(._36vz5BqBZaygOTJkf9p9fw\\=\\=):hover {\n  background-color: #f0f2f5;\n}\n._8FAvuP6yEb\\+DLwAQKwuKvw\\=\\= {\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_WorkflowTarefaItemDescricao.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;AACF;AAEA;EACE,eAAA;AAAF;AAEE;EACE,yBAAA;AAAJ;AAIA;EACE,cAAA;AAFF","sourcesContent":[".workflow-tarefa-item-descricao {\n  line-height: 32px;\n  padding: 0 8px;\n}\n\n.workflow-tarefa-item-descricao:not(.read-only) {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.nao-preenchido {\n  color: #999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-item-descricao": "o4gFwxtKXmlI-6pmQXptWg==",
	"read-only": "_36vz5BqBZaygOTJkf9p9fw==",
	"nao-preenchido": "_8FAvuP6yEb+DLwAQKwuKvw=="
};
export default ___CSS_LOADER_EXPORT___;
