import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Brand from '../components/brand';
import SplashLayout from '../layouts/splash';
import {nomeSelector} from '../store/autenticacao';
import styles from './_styles.less';

export default function SplashPage() {
  const {t} = useTranslation();
  const nome = useSelector(nomeSelector);
  const history = useHistory();
  useEffect(() => {
    const timeout = setTimeout(() => history.push('/'), 5000);
    return () => clearTimeout(timeout);
  }, []);
  return (
      <SplashLayout centered>
        <p className={styles['splash-title']}>{t('Olá')} <strong>{nome}</strong>!</p>
        <p className={styles['splash-description']}>{t('O controle de todas as suas obrigações societárias em uma única tela.')}</p>
        <div className={styles['splash-loading']}>
          <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin />} />
        </div>
        <Brand height="32px" theme="light" />
      </SplashLayout>
  );
}
