import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  workflows: [],
}

export const workflowsSelector = state => state.workflows.workflows;

export const fetchWorkflows = createAsyncThunk(
  'workflows/fetchWorkflows',
  async ({ acessoId }, thunkAPI) => {
    try {
      return (await axios.get('/workflows', { params: { acesso: acessoId } })).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const slice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWorkflows.fulfilled]: (state, action) => {
      state.workflows = action.payload;
    },
  },
});

export default slice.reducer;

export const {} = slice.actions;