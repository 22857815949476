import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import ConsultivoAreaCadastroDetailPage from './_detail';
import ConsultivoAreaCadastroEditPage from './_edit';

export default function ConsultivoAreaCadastroPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/areas/:areaId/cadastro/edit" component={ConsultivoAreaCadastroEditPage}/>
        <Route path="/consultivo/areas/:areaId" component={ConsultivoAreaCadastroDetailPage}/>
      </Switch>
    </Card>);
}
