import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaCertificadosDetailPage from './_detail';
import EmpresaCertificadoPage from './certificado';

export default function EmpresaCertificadosPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/certificados/:id" component={EmpresaCertificadoPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaCertificadosDetailPage} />
        </Switch>
      </Card>);
}
