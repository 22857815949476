import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import useFormatter from '../../../hooks/useFormatter';
import {compareStrings} from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

export default function EmpresaCBEsTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatDate} = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Apresentação'),
      dataIndex: 'dataApresentacao',
      key: 'dataApresentacao',
      sorter: (a, b) => compareStrings(a.dataApresentacao, b.dataApresentacao),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {formatDate(record.dataApresentacao) ?? t('(não preenchido)')}
          </Button>),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaCBEsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
