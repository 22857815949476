import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaLicencasDetailPage from './_detail';
import EmpresaLicencaPage from './licenca';

export default function EmpresaLicencasPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/licencas/:id" component={EmpresaLicencaPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaLicencasDetailPage} />
        </Switch>
      </Card>);
}
