import {Col, Form, Input, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputAnexo from '../../../../../components/input-anexo';
import InputDate from '../../../../../components/input-date';
import InputRazaoSocial from '../../../../../components/input-razao-social';
import MiniForm from '../../../../../components/mini-form';
import SelectEmpresaLivroDigital from "../../../../../components/select-empresa-livro-digital";

export default function EmpresaContratoForm2({disabled, empresaId, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="400px">
            <Form.Item name="nomeAto" label={t('Nome do Ato')}>
              <InputRazaoSocial disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="dataAto" label={t('Data do Ato')}>
              <InputDate disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="dataRegistro" label={t('Data de Registro')}>
              <InputDate disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="600px">
            <Form.Item name="anexo" label={t('Anexo')}>
              <InputAnexo disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="600px">
            <Form.Item name="livroDigitalId" label={t('Livro Digital')}>
              <SelectEmpresaLivroDigital allowClear empresaId={empresaId} disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="obs" label={t('Observação')}>
              <Input.TextArea autoSize disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </MiniForm>);
}

EmpresaContratoForm2.propTypes = {
  disabled: PropTypes.bool,
  empresaId: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
