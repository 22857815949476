import styles from "./_WorkflowTarefa.less";
import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { createWorkflowTarefa, selectedTipoTarefaSelector, selectWorkflowTipoTarefaId, workflowSelector } from "../../store/workflow";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import ActionLink from "../../components/action-link";
import InputName from "../../components/input-name";
import ActionBar from "../../components/action-bar";
import ActionButton from "../../components/action-button";
import PropTypes from "prop-types";
import SelectWorkflowTipoTarefa from "./tipoTarefa/_SelectWorkflowTipoTarefa";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";

export default function WorkflowTarefaCriar(props) {
  const { etapaId, edicao, onEdicao } = props;
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const workflow = useSelector(workflowSelector);
  const tipoTarefa = useSelector(selectedTipoTarefaSelector);

  const workflowId = workflow.id;

  const fireEdicao = (edicao) => {
    if (typeof onEdicao === 'function')
      onEdicao(edicao);
  }

  const handleCreateTarefa = () => {
    fireEdicao(true);
  };

  const handleBlur = e => {
    if (e.currentTarget === e.relatedTarget)
      return;
    if (e.currentTarget.contains(e.relatedTarget))
      return;
    if (!tipoTarefa.id)
      handleCloseTarefa();
  }

  const handleCloseTarefa = () => {
    form.resetFields();
    fireEdicao(false);
  }

  const handleFinish = values => {
    unwrapAxiosPromise(dispatch(createWorkflowTarefa({ workflowId, etapaId, ...values }), form))
      .then(() => handleCloseTarefa());
  };

  if (edicao)
    return (
      <div className={styles['workflow-tarefa-criar']}>
        <div className={styles['conteudo']} onBlur={handleBlur} tabIndex={1}>
          <Form
            form={form}
            onFinish={handleFinish}
            size="middle"
          >
            <Form.Item name="tipoTarefaId">
              <SelectWorkflowTipoTarefa workflowId={workflowId} autoFocus placeholder={t('Tipo de Tarefa')}/>
            </Form.Item>
            <Form.Item name="nome">
              <InputName placeholder={t('Nome')}/>
            </Form.Item>
          </Form>
          <ActionBar>
            <ActionButton.Save onClick={form.submit}/>
            <ActionButton.Cancel onClick={handleCloseTarefa}/>
          </ActionBar>
        </div>
      </div>
    );

  return (
    <ActionLink.Create title={t('Criar Tarefa')} onClick={handleCreateTarefa}/>
  );
}

WorkflowTarefaCriar.propTypes = {
  etapaId: PropTypes.number,
  edicao: PropTypes.bool,
  onEdicao: PropTypes.func
}