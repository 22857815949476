import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function SelectBoolean({max, ...props}) {
  const {t} = useTranslation();

  const createOption = (id, label) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(true, 'Sim')}
        {createOption(false, 'Não')}
      </Select>
  );
}

SelectBoolean.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
