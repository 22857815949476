// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J7NRs7o9InsDwCDCZ2Jkug\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n}\n.J7NRs7o9InsDwCDCZ2Jkug\\=\\= > .Ga6FWW083GvkTb70WQ-sNw\\=\\= {\n  flex-grow: 1;\n  line-height: 32px;\n  padding: 0 8px;\n}\n.J7NRs7o9InsDwCDCZ2Jkug\\=\\=:not(.USDI0p46hCa9dKm5sf4MBw\\=\\=) > .Ga6FWW083GvkTb70WQ-sNw\\=\\= {\n  cursor: pointer;\n}\n.J7NRs7o9InsDwCDCZ2Jkug\\=\\=:not(.USDI0p46hCa9dKm5sf4MBw\\=\\=) > .Ga6FWW083GvkTb70WQ-sNw\\=\\=:hover {\n  background-color: #f0f2f5;\n}\n.KTObfohl0n3MtJXMDKKc-A\\=\\= {\n  width: 100%;\n}\n.HeDRRfLZhTEYtdfLACz1lw\\=\\= {\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_SelectWorkflowEmpresa.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,UAAA;EACA,mBAAA;AACF;AAEA;EACE,YAAA;EACA,iBAAA;EACA,cAAA;AAAF;AAGA;EACE,eAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAKA;EACE,WAAA;AAHF;AAMA;EACE,cAAA;AAJF","sourcesContent":[".workflow-tarefa-empresa {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n}\n\n.workflow-tarefa-empresa > .descricao {\n  flex-grow: 1;\n  line-height: 32px;\n  padding: 0 8px;\n}\n\n.workflow-tarefa-empresa:not(.read-only) > .descricao {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.select {\n  width: 100%;\n}\n\n.nao-preenchido {\n  color: #999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-empresa": "J7NRs7o9InsDwCDCZ2Jkug==",
	"descricao": "Ga6FWW083GvkTb70WQ-sNw==",
	"read-only": "USDI0p46hCa9dKm5sf4MBw==",
	"select": "KTObfohl0n3MtJXMDKKc-A==",
	"nao-preenchido": "HeDRRfLZhTEYtdfLACz1lw=="
};
export default ___CSS_LOADER_EXPORT___;
