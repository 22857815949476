import { LoginOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputEmail from '../components/input-email';
import InputPassword from '../components/input-password';
import Link from '../components/link';
import useDispatchWithResult from '../hooks/useDispatchWithResult';
import useLoading from '../hooks/useLoading';
import CardLayout from '../layouts/card';
import { unwrapResult } from '../store';
import { autenticar, emailMemorizadoSelector, lembrarSelector } from '../store/autenticacao';
import styles from './_styles.less';

const formLayout = {
  layout: 'horizontal',
  labelCol: { flex: '80px' },
  wrapperCol: { flex: 'auto' },
};

const buttonStyle = { paddingLeft: '80px' };

function ButtonSubmit(props) {
  const { t } = useTranslation();
  return (
    <Button
      size="middle"
      icon={<LoginOutlined/>}
      type="primary"
      htmlType="submit"
      {...props}
    >
      {t('Login')}
    </Button>
  );
}

export default function AutenticarPage() {
  const [error, setError] = useState(false);
  const dispatch = useDispatchWithResult();
  const history = useHistory();
  const loading = useLoading(autenticar.typePrefix);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const email = useSelector(emailMemorizadoSelector);
  const lembrar = useSelector(lembrarSelector);

  const handleFinish = values => {
    dispatch(autenticar(values), form).then(unwrapResult)
      .then(() => history.replace('/splash'))
      .catch(err => setError(err.mensagem));
  };

  const alert = error ?
    <Alert showIcon message={error} type="error" className={styles['alert']}/> :
    null;

  return (
    <CardLayout width={480}>
      <Form
        form={form}
        onFinish={handleFinish}
        size="middle"
        {...formLayout}
      >
        {alert}
        <Form.Item name="email" label={t('E-mail')} initialValue={email}>
          <InputEmail autoFocus={!lembrar} disabled={loading}/>
        </Form.Item>
        <Form.Item name="senha" label={t('Senha')}>
          <InputPassword autoFocus={lembrar} disabled={loading}/>
        </Form.Item>
        <Form.Item name="lembrar" style={buttonStyle} valuePropName="checked" initialValue={lembrar}>
          <Checkbox>{t('Lembrar meu e-mail')}</Checkbox>
        </Form.Item>
        <Form.Item style={buttonStyle}>
          <ButtonSubmit loading={loading}/>
          <span className={styles['link']}>
        <Link to="/recuperar-acesso" disabled={loading}>{t('redefinir senha')}</Link>
        </span>
        </Form.Item>
      </Form>
    </CardLayout>
  );
};
