import {Card, Layout, Menu} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Brand from '../../components/brand';
import Flag from '../../components/flag';
import styles from './_styles.less';

export default function CardLayout({children, width}) {
  const {i18n} = useTranslation();

  const handleLanguageClick = ({key}) => {
    i18n.changeLanguage(key);
  };

  return (
      <Layout className={styles['card-layout']}>
        <Card
            cover={(
                <Layout.Header className={styles['cover']}>
                  <div><Brand width="100%" theme="light" /></div>
                </Layout.Header>
            )}
            className={styles['card']}
            bordered={false}
            style={{maxWidth: width}}
        >
          {children}
          <Menu mode="vertical-right" className={styles['menu']}>
            <Menu.SubMenu title={<Flag countryCode={i18n.language.substr(-2)} />} popupClassName={styles['language-popup']}>
              <Menu.Item key="pt-BR" onClick={handleLanguageClick}><Flag countryCode="BR" /> BR</Menu.Item>
              <Menu.Item key="en-US" onClick={handleLanguageClick}><Flag countryCode="US" /> US</Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Card>
      </Layout>
  );
}

CardLayout.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
};
