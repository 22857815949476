import React, { useEffect, useState } from 'react';
import { Modal, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styles from './_WorkflowTipoTarefaModal.less';
import {
  fetchWorkflowTipoTarefa,
  logsByTipoTarefaIdSelector,
  selectedTipoTarefaSelector,
  selectWorkflowTipoTarefaId,
  tiposTarefasItensSelector,
  updateWorkflowTipoTarefaCor,
  updateWorkflowTipoTarefaNome,
  workflowSelector
} from "../../../store/workflow";
import { useSelector } from "react-redux";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import WorkflowTipoTarefaCor from "./_WorkflowTipoTarefaCor";
import WorkflowTipoTarefaNome from "./_WorkflowTipoTarefaNome";
import useFormatter from "../../../hooks/useFormatter";
import moment from "moment";
import WorkflowTipoTarefaItemCriar from "./_WorkflowTipoTarefaItemCriar";
import WorkflowTipoTarefaItem from "./_WorkflowTipoTarefaItem";
import useLoading from "../../../hooks/useLoading";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTipoTarefaModal() {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchWorkflowTipoTarefa.typePrefix);
  const { id, nome, cor } = useSelector(selectedTipoTarefaSelector);
  const workflow = useSelector(workflowSelector);
  const itens = useSelector(tiposTarefasItensSelector(id));
  const logs = useSelector(logsByTipoTarefaIdSelector(id));
  const [now, setNow] = useState(Date.now());

  const workflowId = workflow.id;

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 60000);
    return () => clearInterval(interval);
  }, []);

  const handleNomeSubmit = nome => unwrapAxiosPromise(dispatch(updateWorkflowTipoTarefaNome({ workflowId, id, nome })));
  const handleCorSubmit = cor => unwrapAxiosPromise(dispatch(updateWorkflowTipoTarefaCor({ workflowId, id, cor })));
  const handleCancel = () => dispatch(selectWorkflowTipoTarefaId(null));

  return (
    <Modal
      title={
        <WorkflowTipoTarefaNome nome={nome} onSubmit={handleNomeSubmit}/>
      }
      open={id > 0}
      destroyOnClose
      footer={false}
      onCancel={handleCancel}
    >
      <Spin spinning={loading}>
        <div className={styles['workflow-tipo-tarefa-modal']}>
          <div className={styles['cabecalho']}>{t('Cor')}</div>
          <div>
            <WorkflowTipoTarefaCor cor={cor} onSubmit={handleCorSubmit}/>
          </div>
          <div className={styles['cabecalho']}>{t('Itens')}</div>
          <div className={styles['conteudo']}>
            {itens?.map(item => (
              <WorkflowTipoTarefaItem key={item.id} {...item} />
            ))}
            <div>
              <WorkflowTipoTarefaItemCriar tipoTarefaId={id}/>
            </div>
          </div>
          <div className={styles['cabecalho']}>{t('Log')}</div>
          <div className={styles['logs']}>
            {logs?.map(log => (
              <div key={log.id}>
                <div>{log.texto}</div>
                <div className={styles['log-autor']}>
                  <div>
                    <Tooltip title={formatDateTime(log.createdAt)}>
                      {moment(log.createdAt).fromNow()}
                    </Tooltip>
                  </div>
                  <div>{t('por')} <strong>{log.usuario.nome}</strong></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Spin>
    </Modal>
  )
}