// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".krSLgUf47XAvIJfgPKJLIQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n.krSLgUf47XAvIJfgPKJLIQ\\=\\= > .ant-space-item:last-child {\n  flex-grow: 1;\n}\n.cSiylIzvGALiVvOL74t0Vg\\=\\= {\n  flex-grow: 1;\n}\n.cSiylIzvGALiVvOL74t0Vg\\=\\=.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,\n.cSiylIzvGALiVvOL74t0Vg\\=\\=.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {\n  background-color: inherit;\n}\n.l5cOEV06epdhlylEiKXqng\\=\\= {\n  margin-right: 12px;\n}\n.l5cOEV06epdhlylEiKXqng\\=\\=.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,\n.l5cOEV06epdhlylEiKXqng\\=\\=.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {\n  background-color: inherit;\n  padding: 0;\n}\n.l5cOEV06epdhlylEiKXqng\\=\\= button {\n  color: rgba(255, 255, 255, 0.65);\n}\n.l5cOEV06epdhlylEiKXqng\\=\\= button:hover {\n  color: #ffffff;\n}\n", "",{"version":3,"sources":["webpack://src/pages/empresa/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AAJA;EAMI,YAAA;AACJ;AAGA;EACE,YAAA;AADF;AAGE;;EAEE,yBAAA;AADJ;AAKA;EACE,kBAAA;AAHF;AAKE;;EAEE,yBAAA;EACA,UAAA;AAHJ;AAHA;EAUI,gCAAA;AAJJ;AAMI;EACE,cAAA;AAJN","sourcesContent":[".space {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n\n  > :global(.ant-space-item):last-child {\n    flex-grow: 1;\n  }\n}\n\n.empresa-menu {\n  flex-grow: 1;\n\n  &:global(.ant-menu-dark.ant-menu-horizontal) > :global(.ant-menu-item),\n  &:global(.ant-menu-dark.ant-menu-horizontal) > :global(.ant-menu-submenu) {\n    background-color: inherit;\n  }\n}\n\n.empresa-menu-buttons {\n  margin-right: 12px;\n\n  &:global(.ant-menu-dark.ant-menu-horizontal) > :global(.ant-menu-item),\n  &:global(.ant-menu-dark.ant-menu-horizontal) > :global(.ant-menu-submenu) {\n    background-color: inherit;\n    padding: 0;\n  }\n\n  button {\n    color: rgba(255, 255, 255, 0.65);\n\n    &:hover {\n      color: rgba(255, 255, 255, 1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "krSLgUf47XAvIJfgPKJLIQ==",
	"empresa-menu": "cSiylIzvGALiVvOL74t0Vg==",
	"empresa-menu-buttons": "l5cOEV06epdhlylEiKXqng=="
};
export default ___CSS_LOADER_EXPORT___;
