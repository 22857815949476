import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchRDEIED} from '../../../../store/empresa';
import EmpresaRDEIEDCreatePage from './_create';
import EmpresaRDEIEDDetailPage from './_detail';
import EmpresaRDEIEDEditPage from './_edit';

export default function EmpresaRDEIEDPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchRDEIED({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/rde-ieds/:id/edit" component={EmpresaRDEIEDEditPage} />
        <Route path="/*/empresas/:empresaId/rde-ieds/create" component={EmpresaRDEIEDCreatePage} />
        <Route path="/*/empresas/:empresaId/rde-ieds/:id" component={EmpresaRDEIEDDetailPage} />
      </Switch>);
}
