import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import {changeLanguage} from '../store/i18n';

export function createI18n(isDev, store) {
  const dispatch = store.dispatch;
  i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .on('languageChanged', lng => dispatch(changeLanguage(lng)))
      .init({
        debug: false,
        keySeparator: false,
        fallbackLng: 'pt',
        preload: ['pt', 'en'],
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: '/api/locales/{{lng}}/{{ns}}.json',
          // addPath: '/api/locales/{{lng}}/{{ns}}.json',
        },
        // saveMissing: true,
      });
  return i18n;
}
