import {CheckOutlined, CloseOutlined, LineOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import React, {useMemo, useState} from 'react';
import {formatCep, isValidCep, parseCep} from '../../util/cep';

export default function InputCep({onChange, value, ...props}) {
  const [editing, setEditing] = useState(false);
  const formattedValue = useMemo(() => formatCep(value), [value]);
  const isValid = useMemo(() => isValidCep(value), [value]);

  const fireChange = newValue => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(newValue);
  };
  const handleBlur = e => setEditing(false);
  const handleChange = e => fireChange(parseCep(e.target.value));
  const handleFocus = e => {
    setEditing(true);
    const target = e.target;
    setImmediate(() => target.setSelectionRange(0, target.value.length));
  };
  const handlePaste = e => {
    e.preventDefault();
    fireChange(parseCep(e.clipboardData.getData('text')));
  };

  const suffix = !value ?
      <LineOutlined /> :
      isValid ?
          <CheckOutlined className="success" /> :
          <CloseOutlined className="error" />;

  return (
      <Input
          maxLength={editing ? 8 : undefined}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onPaste={handlePaste}
          suffix={suffix}
          value={editing ? value : formattedValue}
          {...props}
      />
  );
}
