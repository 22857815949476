import { Form, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import useDispatchWithResult from '../../../../../hooks/useDispatchWithResult';
import { fetchTiposTrabalho } from '../../../../../store/consultivoArea';
import unwrapAxiosPromise from '../../../../../util/unwrapAxiosPromise';
import ConsultivoAreaTipoTrabalhoForm from './_form';

export default function ConsultivoAreaTipoTrabalhoCreatePage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;

  useEffect(() => {
    form.resetFields();
    setImmediate(() => document.querySelector('#descricao').focus());
  }, []);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.post(`/consultivo/areas/${areaId}/tipos-trabalho`, values), form)
      .then(data => {
        message.success(t('Registro incluído com sucesso'));
        history.replace(`/consultivo/areas/${areaId}/tipos-trabalho`);
        dispatch(fetchTiposTrabalho({ areaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/areas/${areaId}/tipos-trabalho`);
  };

  const title = (
    <ActionBar>
      <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
      <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
    </ActionBar>);

  return (
    <ConsultivoAreaTipoTrabalhoForm
      autoFillByCnpj="overwrite"
      disabled={loadingSave}
      form={form}
      onFinish={handleFinish}
      title={title}
    />);
}
