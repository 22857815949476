// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HV6RGIBRcPzbCmFe40uWAg\\=\\= {\n  white-space: nowrap;\n}\n.HV6RGIBRcPzbCmFe40uWAg\\=\\= > label > .S\\+lsAGqM0vc-OdtZwxHZvA\\=\\= {\n  font-weight: bold;\n  white-space: pre-wrap;\n  min-height: 24px;\n  padding-bottom: 4px;\n}\n.HV6RGIBRcPzbCmFe40uWAg\\=\\= .k3p0S\\+lw1FwEgsFqJ5wQew\\=\\= {\n  writing-mode: horizontal-tb;\n  margin-inline-start: 4px;\n  color: rgba(0, 0, 0, 0.45);\n}\n", "",{"version":3,"sources":["webpack://src/components/text-anexo/_styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAFA;EAII,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AARA;EAWI,2BAAA;EACA,wBAAA;EACA,0BAAA;AAAJ","sourcesContent":[".text-anexo {\n  white-space: nowrap;\n\n  > label > .value {\n    font-weight: bold;\n    white-space: pre-wrap;\n    min-height: 24px;\n    padding-bottom: 4px;\n  }\n\n  .icon {\n    writing-mode: horizontal-tb;\n    margin-inline-start: 4px;\n    color: rgba(0, 0, 0, 0.45);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-anexo": "HV6RGIBRcPzbCmFe40uWAg==",
	"value": "S+lsAGqM0vc-OdtZwxHZvA==",
	"icon": "k3p0S+lw1FwEgsFqJ5wQew=="
};
export default ___CSS_LOADER_EXPORT___;
