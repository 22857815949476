import React, { useState } from "react";
import styles from "./_WorkflowTarefaItem.less";
import { Checkbox } from "antd";
import WorkflowTarefaItemDescricao from "./_WorkflowTarefaItemDescricao";
import ActionBar from "../../../components/action-bar";
import ActionLink from "../../../components/action-link";
import {
  dragEndWorkflowTarefaItemId,
  draggingTarefaItemSelector,
  dragStartWorkflowTarefaItemId,
  removeWorkflowTarefaItem,
  updateWorkflowTarefaItemDescricao,
  updateWorkflowTarefaItemMarcado,
  updateWorkflowTarefaItemPosicao,
  workflowSelector
} from "../../../store/workflow";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { isClienteOperadorSelector } from "../../../store/autenticacao";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTarefaItem(props) {
  const { id, tarefa: tarefaId, descricao, marcado, posicao } = props;
  const dispatch = useDispatchWithResult();
  const isClienteOperador = useSelector(isClienteOperadorSelector);
  const workflow = useSelector(workflowSelector);
  const draggingTarefaItem = useSelector(draggingTarefaItemSelector)
  const [descricaoEdicao, setDescricaoEdicao] = useState(false);

  const workflowId = workflow.id;

  const handleDragStart = e => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('application/json', JSON.stringify({ event: 'WorkflowTarefaItem', data: { id } }));
    dispatch(dragStartWorkflowTarefaItemId(id));
    setDescricaoEdicao(false);
  }

  const handleDragEnd = e => {
    dispatch(dragEndWorkflowTarefaItemId());
  }

  const handleDragOver = e => {
    const target = e.currentTarget;
    if (!draggingTarefaItem)
      return;
    if (draggingTarefaItem.id === id)
      return;
    if (posicao < draggingTarefaItem.posicao)
      target.classList.add(styles['dropzone-top'])
    else
      target.classList.add(styles['dropzone-bottom'])
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  const handleDragLeave = e => {
    const target = e.currentTarget;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
  }

  const handleDrop = e => {
    const target = e.currentTarget;
    if (!target)
      return;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
    const { event, data } = JSON.parse(e.dataTransfer.getData('application/json'));
    if (event !== 'WorkflowTarefaItem')
      return;
    e.preventDefault();
    unwrapAxiosPromise(dispatch(updateWorkflowTarefaItemPosicao({ workflowId, tarefaId, id: data.id, posicao })));
  }

  const handleItemMarcadoSubmit = (e) => unwrapAxiosPromise(dispatch(updateWorkflowTarefaItemMarcado({ workflowId, tarefaId, id, marcado: e.target.checked })));
  const handleItemDescricaoSubmit = (descricao) => unwrapAxiosPromise(dispatch(updateWorkflowTarefaItemDescricao({ workflowId, tarefaId, id, descricao })));
  const handleItemRemoveClick = () => unwrapAxiosPromise(dispatch(removeWorkflowTarefaItem({ workflowId, tarefaId, id })));

  return (
    <div
      className={styles['workflow-tarefa-item']}

      draggable={isClienteOperador}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}

      onDragEnter={handleDragOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div><Checkbox id={id} checked={marcado} disabled={!isClienteOperador} onChange={handleItemMarcadoSubmit}/></div>
      <div><WorkflowTarefaItemDescricao descricao={descricao} edicao={isClienteOperador && descricaoEdicao} onEdicao={setDescricaoEdicao} onSubmit={handleItemDescricaoSubmit} readOnly={!isClienteOperador}/></div>
      {isClienteOperador &&
        <div>
          <ActionBar>
            <ActionLink.Remove noTitle onClick={handleItemRemoveClick}/>
          </ActionBar>
        </div>
      }
    </div>
  )
}