import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { valuesToFormData } from "../util/formData";

const initialState = {
  workflow: {},
  membroById: {},
  tipoTarefaById: {},
  tipoTarefaItemById: {},
  etapaById: {},
  tarefaById: {},
  tarefaAnexoById: {},
  tarefaItemById: {},

  logsByTipoTarefaId: {},
  logsByTarefaId: {},

  filtro: {
    membroIds: [],
    empresaIds: [],
    unidadeIds: [],
  },

  selectedTipoTarefaId: null,
  selectedTarefaId: null,

  draggingTipoTarefaItemId: null,
  draggingEtapaId: null,
  draggingTarefaId: null,
  draggingTarefaAnexoId: null,
  draggingTarefaItemId: null
}

export const workflowSelector = state => state.workflow.workflow;
export const membroByIdMapSelector = state => state.workflow.membroById;
export const membroByIdSelector = id => state => state.workflow.membroById[id] ?? {};
export const tipoTarefaByIdMapSelector = state => state.workflow.tipoTarefaById;
export const tipoTarefaByIdSelector = id => state => state.workflow.tipoTarefaById[id] ?? {};
export const tipoTarefaItemByIdSelector = id => state => state.workflow.tipoTarefaItemById[id] ?? {};
export const etapaByIdSelector = id => state => state.workflow.etapaById[id] ?? {};
export const tarefaByIdSelector = id => state => state.workflow.tarefaById[id] ?? {};
export const tarefaAnexoByIdSelector = id => state => state.workflow.tarefaAnexoById[id] ?? {};
export const tarefaItemByIdSelector = id => state => state.workflow.tarefaItemById[id] ?? {};
export const logsByTipoTarefaIdSelector = tarefaId => state => state.workflow.logsByTipoTarefaId[tarefaId] ?? [];
export const logsByTarefaIdSelector = tarefaId => state => state.workflow.logsByTarefaId[tarefaId] ?? [];

export const membrosSelector = state => state.workflow.workflow?.membros?.map(it => state.workflow.membroById[it])?.sort((a, b) => a.id - b.id) ?? [];
export const tiposTarefasSelector = state => state.workflow.workflow?.tiposTarefas?.map(it => state.workflow.tipoTarefaById[it])?.sort((a, b) => a.id - b.id) ?? [];
export const tiposTarefasItensSelector = tipoTarefaId => state => state.workflow.tipoTarefaById[tipoTarefaId]?.itens?.map(it => state.workflow.tipoTarefaItemById[it])?.sort((a, b) => a.posicao - b.posicao) ?? [];
export const etapasSelector = state => state.workflow.workflow?.etapas?.map(it => state.workflow.etapaById[it])?.sort((a, b) => a.posicao - b.posicao) ?? [];
export const tarefasSelector = etapaId => state => state.workflow.etapaById[etapaId]?.tarefas?.map(it => state.workflow.tarefaById[it])?.sort((a, b) => a.posicao - b.posicao) ?? [];
export const tarefasMembrosSelector = tarefaId => state => state.workflow.tarefaById[tarefaId]?.membros?.map(it => state.workflow.membroById[it])?.sort((a, b) => a.id - b.id) ?? [];
export const tarefasAnexosSelector = tarefaId => state => state.workflow.tarefaById[tarefaId]?.anexos?.map(it => state.workflow.tarefaAnexoById[it])?.sort((a, b) => a.posicao - b.posicao) ?? [];
export const tarefasItensSelector = tarefaId => state => state.workflow.tarefaById[tarefaId]?.itens?.map(it => state.workflow.tarefaItemById[it])?.sort((a, b) => a.posicao - b.posicao) ?? [];

export const filtroSelector = state => state.workflow.filtro;
export const filtradoSelector = createSelector(filtroSelector,
  filtro => filtro.membroIds.length > 0 || filtro.empresaIds.length > 0 || filtro.unidadeIds.length > 0);
export const tarefasFiltradasSelector = etapaId => createSelector(tarefasSelector(etapaId), filtroSelector, filtradoSelector,
  (tarefas, filtro, filtrado) =>
    filtrado ?
      tarefas.filter(tarefa => {
        if (filtro.membroIds.length > 0)
          if (tarefa.membros.findIndex(membro => filtro.membroIds.includes(membro)) < 0)
            return false;
        if (filtro.empresaIds.length > 0)
          if (!filtro.empresaIds.includes(tarefa.empresa?.id))
            return false;
        if (filtro.unidadeIds.length > 0)
          if (!filtro.unidadeIds.includes(tarefa.unidade?.id))
            return false;
        return true;
      }) :
      tarefas);

export const selectedTipoTarefaSelector = state => state.workflow.tipoTarefaById[state.workflow.selectedTipoTarefaId] ?? {};
export const selectedTarefaSelector = state => state.workflow.tarefaById[state.workflow.selectedTarefaId] ?? {};

export const draggingTipoTarefaItemSelector = state => state.workflow.tipoTarefaItemById[state.workflow.draggingTipoTarefaItemId];
export const draggingEtapaSelector = state => state.workflow.etapaById[state.workflow.draggingEtapaId];
export const draggingTarefaSelector = state => state.workflow.tarefaById[state.workflow.draggingTarefaId];
export const draggingTarefaAnexoSelector = state => state.workflow.tarefaAnexoById[state.workflow.draggingTarefaAnexoId];
export const draggingTarefaItemSelector = state => state.workflow.tarefaItemById[state.workflow.draggingTarefaItemId];

// WORKFLOW

export const fetchWorkflow = createAsyncThunk(
  'workflow/fetchWorkflow',
  async ({ workflowId }, thunkAPI) => {
    try {
      const { data } = await axios.get(`/workflows/${workflowId}`);
      data.membros = (await axios.get(`/workflows/${workflowId}/membros`)).data;
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// TIPOTAREFA

export const fetchWorkflowTipoTarefa = createAsyncThunk(
  'workflow/fetchWorkflowTipoTarefa',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetchWorkflowTipoTarefaLogs({ workflowId, id }));
      return (await axios.get(`/workflows/${workflowId}/tipos-tarefas/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchWorkflowTipoTarefaLogs = createAsyncThunk(
  'workflow/fetchWorkflowTipoTarefaLogs',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      return (await axios.get(`/workflows/${workflowId}/tipos-tarefas/${id}/logs`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const createWorkflowTipoTarefa = createAsyncThunk(
  'workflow/createWorkflowTipoTarefa',
  async ({ workflowId, nome }, thunkAPI) => {
    try {
      const { id } = (await axios.post(`/workflows/${workflowId}/tipos-tarefas`, { workflowId, nome })).data;
      await thunkAPI.dispatch(fetchWorkflowTipoTarefa({ workflowId, id }));
      return { id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateWorkflowTipoTarefaNome = createAsyncThunk(
  'workflow/updateWorkflowTipoTarefaNome',
  async ({ workflowId, id, nome }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tipos-tarefas/${id}/nome`, { nome });
      thunkAPI.dispatch(fetchWorkflowTipoTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTipoTarefaCor = createAsyncThunk(
  'workflow/updateWorkflowTipoTarefaCor',
  async ({ workflowId, id, cor }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tipos-tarefas/${id}/cor`, { cor });
      thunkAPI.dispatch(fetchWorkflowTipoTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const removeWorkflowTipoTarefa = createAsyncThunk(
  'workflow/removeWorkflowTipoTarefa',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/workflows/${workflowId}/tipos-tarefas/${id}`);
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTipoTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

// TIPOTAREFAITEM

export const fetchWorkflowTipoTarefaItem = createAsyncThunk(
  'workflow/fetchWorkflowTipoTarefaItem',
  async ({ workflowId, tipoTarefaId, id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetchWorkflowTipoTarefaLogs({ workflowId, id: tipoTarefaId }));
      return (await axios.get(`/workflows/${workflowId}/tipos-tarefas/${tipoTarefaId}/itens/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const createWorkflowTipoTarefaItem = createAsyncThunk(
  'workflow/createWorkflowTipoTarefaItem',
  async ({ workflowId, tipoTarefaId, descricao }, thunkAPI) => {
    try {
      const { id } = (await axios.post(`/workflows/${workflowId}/tipos-tarefas/${tipoTarefaId}/itens`, { descricao })).data;
      await thunkAPI.dispatch(fetchWorkflowTipoTarefaItem({ workflowId, tipoTarefaId, id }));
      return { id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateWorkflowTipoTarefaItemDescricao = createAsyncThunk(
  'workflow/updateWorkflowTipoTarefaItemDescricao',
  async ({ workflowId, tipoTarefaId, id, descricao }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tipos-tarefas/${tipoTarefaId}/itens/${id}/descricao`, { descricao });
      thunkAPI.dispatch(fetchWorkflowTipoTarefaItem({ workflowId, tipoTarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTipoTarefaItemPosicao = createAsyncThunk(
  'workflow/updateWorkflowTipoTarefaItemPosicao',
  async ({ workflowId, tipoTarefaId, id, posicao }, thunkAPI) => {
    try {
      const { data } = await axios.put(`/workflows/${workflowId}/tipos-tarefas/${tipoTarefaId}/itens/${id}/posicao`, { posicao });
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTipoTarefaItem({ workflowId, tipoTarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const removeWorkflowTipoTarefaItem = createAsyncThunk(
  'workflow/removeWorkflowTipoTarefaItem',
  async ({ workflowId, tipoTarefaId, id }, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/workflows/${workflowId}/tipos-tarefas/${tipoTarefaId}/itens/${id}`);
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTipoTarefaItem({ workflowId, tipoTarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

// ETAPA

export const fetchWorkflowEtapa = createAsyncThunk(
  'workflow/fetchWorkflowEtapa',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      return (await axios.get(`/workflows/${workflowId}/etapas/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const createWorkflowEtapa = createAsyncThunk(
  'workflow/createWorkflowEtapa',
  async ({ workflowId, nome }, thunkAPI) => {
    try {
      const { id } = (await axios.post(`/workflows/${workflowId}/etapas`, { workflowId, nome })).data;
      await thunkAPI.dispatch(fetchWorkflowEtapa({ workflowId, id }));
      return { id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateWorkflowEtapaNome = createAsyncThunk(
  'workflow/updateWorkflowEtapaNome',
  async ({ workflowId, id, nome }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/etapas/${id}/nome`, { nome });
      thunkAPI.dispatch(fetchWorkflowEtapa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowEtapaPosicao = createAsyncThunk(
  'workflow/updateWorkflowEtapaPosicao',
  async ({ workflowId, id, posicao }, thunkAPI) => {
    try {
      const { data } = await axios.put(`/workflows/${workflowId}/etapas/${id}/posicao`, { posicao });
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowEtapa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const removeWorkflowEtapa = createAsyncThunk(
  'workflow/removeWorkflowEtapa',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/workflows/${workflowId}/etapas/${id}`);
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowEtapa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

// TAREFA

export const fetchWorkflowTarefa = createAsyncThunk(
  'workflow/fetchWorkflowTarefa',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetchWorkflowTarefaLogs({ workflowId, id }));
      return (await axios.get(`/workflows/${workflowId}/tarefas/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchWorkflowTarefaLogs = createAsyncThunk(
  'workflow/fetchWorkflowTarefaLogs',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      return (await axios.get(`/workflows/${workflowId}/tarefas/${id}/logs`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const createWorkflowTarefa = createAsyncThunk(
  'workflow/createWorkflowTarefa',
  async ({ workflowId, etapaId, nome, tipoTarefaId }, thunkAPI) => {
    try {
      const { id } = (await axios.post(`/workflows/${workflowId}/tarefas`, { workflowId, etapaId, nome, tipoTarefaId })).data;
      await thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
      return { id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateWorkflowTarefaNome = createAsyncThunk(
  'workflow/updateWorkflowTarefaNome',
  async ({ workflowId, id, nome }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${id}/nome`, { nome });
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTarefaDescricao = createAsyncThunk(
  'workflow/updateWorkflowTarefaDescricao',
  async ({ workflowId, id, descricao }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${id}/descricao`, { descricao });
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)
export const updateWorkflowTarefaDataPrazo = createAsyncThunk(
  'workflow/updateWorkflowTarefaDataPrazo',
  async ({ workflowId, id, dataPrazo }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${id}/data-prazo`, { dataPrazo });
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)
export const updateWorkflowTarefaDataEntrega = createAsyncThunk(
  'workflow/updateWorkflowTarefaDataEntrega',
  async ({ workflowId, id, dataEntrega }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${id}/data-entrega`, { dataEntrega });
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTarefaEmpresa = createAsyncThunk(
  'workflow/updateWorkflowTarefaEmpresa',
  async ({ workflowId, id, empresaId }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${id}/empresa`, { empresaId });
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTarefaUnidade = createAsyncThunk(
  'workflow/updateWorkflowTarefaUnidade',
  async ({ workflowId, id, unidadeId }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${id}/unidade`, { unidadeId });
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const addWorkflowTarefaMembro = createAsyncThunk(
  'workflow/addWorkflowTarefaMembro',
  async ({ workflowId, id, membroId }, thunkAPI) => {
    try {
      await axios.post(`/workflows/${workflowId}/tarefas/${id}/membros/${membroId}`);
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const removeWorkflowTarefaMembro = createAsyncThunk(
  'workflow/removeWorkflowTarefaMembro',
  async ({ workflowId, id, membroId }, thunkAPI) => {
    try {
      await axios.delete(`/workflows/${workflowId}/tarefas/${id}/membros/${membroId}`);
      thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTarefaPosicao = createAsyncThunk(
  'workflow/updateWorkflowTarefaPosicao',
  async ({ workflowId, id, posicao }, thunkAPI) => {
    try {
      const { data } = await axios.put(`/workflows/${workflowId}/tarefas/${id}/posicao`, { posicao });
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTarefaEtapa = createAsyncThunk(
  'workflow/updateWorkflowTarefaEtapa',
  async ({ workflowId, etapaId, id, posicao }, thunkAPI) => {
    try {
      const { data } = await axios.put(`/workflows/${workflowId}/tarefas/${id}/etapa`, { etapaId, posicao });
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const removeWorkflowTarefa = createAsyncThunk(
  'workflow/removeWorkflowTarefa',
  async ({ workflowId, id }, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/workflows/${workflowId}/tarefas/${id}`);
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTarefa({ workflowId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

// TAREFAANEXO

export const fetchWorkflowTarefaAnexo = createAsyncThunk(
  'workflow/fetchWorkflowTarefaAnexo',
  async ({ workflowId, tarefaId, id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetchWorkflowTarefaLogs({ workflowId, id: tarefaId }));
      return (await axios.get(`/workflows/${workflowId}/tarefas/${tarefaId}/anexos/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const createWorkflowTarefaAnexo = createAsyncThunk(
  'workflow/createWorkflowTarefaAnexo',
  async ({ workflowId, tarefaId, anexo }, thunkAPI) => {
    try {
      const { id } = (await axios.post(`/workflows/${workflowId}/tarefas/${tarefaId}/anexos`, valuesToFormData({ anexo }))).data;
      await thunkAPI.dispatch(fetchWorkflowTarefaAnexo({ workflowId, tarefaId, id }));
      return { id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateWorkflowTarefaAnexoNome = createAsyncThunk(
  'workflow/updateWorkflowTarefaAnexoNome',
  async ({ workflowId, tarefaId, id, nome }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${tarefaId}/anexos/${id}/nome`, { nome });
      thunkAPI.dispatch(fetchWorkflowTarefaAnexo({ workflowId, tarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTarefaAnexoPosicao = createAsyncThunk(
  'workflow/updateWorkflowTarefaAnexoPosicao',
  async ({ workflowId, tarefaId, id, posicao }, thunkAPI) => {
    try {
      const { data } = await axios.put(`/workflows/${workflowId}/tarefas/${tarefaId}/anexos/${id}/posicao`, { posicao });
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTarefaAnexo({ workflowId, tarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const removeWorkflowTarefaAnexo = createAsyncThunk(
  'workflow/removeWorkflowTarefaAnexo',
  async ({ workflowId, tarefaId, id }, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/workflows/${workflowId}/tarefas/${tarefaId}/anexos/${id}`)
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTarefaAnexo({ workflowId, tarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

// TAREFAITEM

export const fetchWorkflowTarefaItem = createAsyncThunk(
  'workflow/fetchWorkflowTarefaItem',
  async ({ workflowId, tarefaId, id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetchWorkflowTarefaLogs({ workflowId, id: tarefaId }));
      return (await axios.get(`/workflows/${workflowId}/tarefas/${tarefaId}/itens/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const createWorkflowTarefaItem = createAsyncThunk(
  'workflow/createWorkflowTarefaItem',
  async ({ workflowId, tarefaId, descricao }, thunkAPI) => {
    try {
      const { id } = (await axios.post(`/workflows/${workflowId}/tarefas/${tarefaId}/itens`, { descricao })).data;
      await thunkAPI.dispatch(fetchWorkflowTarefaItem({ workflowId, tarefaId, id }));
      return { id };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateWorkflowTarefaItemDescricao = createAsyncThunk(
  'workflow/updateWorkflowTarefaItemDescricao',
  async ({ workflowId, tarefaId, id, descricao }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${tarefaId}/itens/${id}/descricao`, { descricao });
      thunkAPI.dispatch(fetchWorkflowTarefaItem({ workflowId, tarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)
export const updateWorkflowTarefaItemMarcado = createAsyncThunk(
  'workflow/updateWorkflowTarefaItemMarcado',
  async ({ workflowId, tarefaId, id, marcado }, thunkAPI) => {
    try {
      await axios.put(`/workflows/${workflowId}/tarefas/${tarefaId}/itens/${id}/marcado`, { marcado });
      thunkAPI.dispatch(fetchWorkflowTarefaItem({ workflowId, tarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const updateWorkflowTarefaItemPosicao = createAsyncThunk(
  'workflow/updateWorkflowTarefaItemPosicao',
  async ({ workflowId, tarefaId, id, posicao }, thunkAPI) => {
    try {
      const { data } = await axios.put(`/workflows/${workflowId}/tarefas/${tarefaId}/itens/${id}/posicao`, { posicao });
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTarefaItem({ workflowId, tarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const removeWorkflowTarefaItem = createAsyncThunk(
  'workflow/removeWorkflowTarefaItem',
  async ({ workflowId, tarefaId, id }, thunkAPI) => {
    try {
      const { data } = await axios.delete(`/workflows/${workflowId}/tarefas/${tarefaId}/itens/${id}`)
      for (const { id } of data)
        thunkAPI.dispatch(fetchWorkflowTarefaItem({ workflowId, tarefaId, id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

//

export const slice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    filtrarWorkflow: (state, action) => {
      if (action.payload.membroIds)
        state.filtro.membroIds = action.payload.membroIds;
      if (action.payload.empresaIds)
        state.filtro.empresaIds = action.payload.empresaIds;
      if (action.payload.unidadeIds)
        state.filtro.unidadeIds = action.payload.unidadeIds;
      if (state.filtro.empresaIds.length === 0)
        state.filtro.unidadeIds = [];
    },
    selectWorkflowTipoTarefaId: (state, action) => {
      state.selectedTipoTarefaId = action.payload;
    },
    selectWorkflowTarefaId: (state, action) => {
      state.selectedTarefaId = action.payload;
    },
    dragStartWorkflowTipoTarefaItemId: (state, action) => {
      state.draggingTipoTarefaItemId = action.payload;
    },
    dragEndWorkflowTipoTarefaItemId: (state) => {
      state.draggingTipoTarefaItemId = null;
    },
    dragStartWorkflowEtapaId: (state, action) => {
      state.draggingEtapaId = action.payload;
    },
    dragEndWorkflowEtapaId: (state) => {
      state.draggingEtapaId = null;
    },
    dragStartWorkflowTarefaId: (state, action) => {
      state.draggingTarefaId = action.payload;
    },
    dragEndWorkflowTarefaId: (state) => {
      state.draggingTarefaId = null;
    },
    dragStartWorkflowTarefaAnexoId: (state, action) => {
      state.draggingTarefaAnexoId = action.payload;
    },
    dragEndWorkflowTarefaAnexoId: (state) => {
      state.draggingTarefaAnexoId = null;
    },
    dragStartWorkflowTarefaItemId: (state, action) => {
      state.draggingTarefaItemId = action.payload;
    },
    dragEndWorkflowTarefaItemId: (state) => {
      state.draggingTarefaItemId = null;
    }
  },
  extraReducers: {
    [fetchWorkflow.fulfilled]: (state, action) => {
      state.workflow = {};
      state.membroById = {};
      state.tipoTarefaById = {};
      state.logsByTipoTarefaId = {};
      state.etapaById = {};
      state.tarefaById = {};
      state.tarefaAnexoById = {};
      state.tarefaItemById = {};
      state.logsByTipoTarefaId = {};
      state.logsByTarefaId = {};
      state.selectedTipoTarefaId = null;
      state.selectedTarefaId = null;
      state.draggingTipoTarefaItemId = null;
      state.draggingEtapaId = null;
      state.draggingTarefaId = null;
      state.draggingTarefaAnexoId = null;
      state.draggingTarefaItemId = null;
      reduceWorkflow(state, action.payload);
    },
    // TIPOTAREFA
    [fetchWorkflowTipoTarefa.fulfilled]: (state, action) => {
      reduceTipoTarefa(state, action.payload);
    },
    [fetchWorkflowTipoTarefaLogs.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      state.logsByTipoTarefaId[id] = action.payload;
    },
    [createWorkflowTipoTarefa.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state.workflow.tiposTarefas.push(id);
    },
    [removeWorkflowTipoTarefa.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      const tipoTarefa = state.tipoTarefaById[id];
      if (tipoTarefa)
        state.workflow.tiposTarefas = state.workflow.tiposTarefas.filter(tipoTarefaId => id !== tipoTarefaId);
      delete state.tipoTarefaById[id];
    },
    // TIPOTAREFAITEM
    [fetchWorkflowTipoTarefaItem.fulfilled]: (state, action) => {
      reduceTipoTarefaItem(state, action.payload);
    },
    [fetchWorkflowTipoTarefaLogs.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      state.logsByTipoTarefaId[id] = action.payload;
    },
    [createWorkflowTipoTarefaItem.fulfilled]: (state, action) => {
      const { tipoTarefaId } = action.meta.arg;
      const { id } = action.payload;
      state.tipoTarefaById[tipoTarefaId].itens.push(id);
    },
    [removeWorkflowTipoTarefaItem.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      const item = state.tipoTarefaItemById[id];
      if (item)
        state.tipoTarefaById[item.tipoTarefa].itens = state.tipoTarefaById[item.tipoTarefa].itens.filter(itemId => id !== itemId);
      delete state.tipoTarefaItemById[id];
    },
    // ETAPA
    [fetchWorkflowEtapa.fulfilled]: (state, action) => {
      reduceEtapa(state, action.payload);
    },
    [createWorkflowEtapa.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state.workflow.etapas.push(id);
    },
    [removeWorkflowEtapa.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      const etapa = state.etapaById[id];
      delete state.etapaById[id];
      for (const tarefaId of etapa.tarefas) {
        delete state.tarefaById[tarefaId];
      }
      state.workflow.etapas = state.workflow.etapas.filter(etapaId => id !== etapaId);
    },
    // TAREFA
    [fetchWorkflowTarefa.fulfilled]: (state, action) => {
      reduceTarefa(state, action.payload);
    },
    [fetchWorkflowTarefaLogs.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      state.logsByTarefaId[id] = action.payload;
    },
    [createWorkflowTarefa.fulfilled]: (state, action) => {
      const { etapaId } = action.meta.arg;
      const { id } = action.payload;
      state.etapaById[etapaId].tarefas.push(id);
    },
    [removeWorkflowTarefa.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      const tarefa = state.tarefaById[id];
      if (tarefa)
        state.etapaById[tarefa.etapa].tarefas = state.etapaById[tarefa.etapa].tarefas.filter(tarefaId => id !== tarefaId);
      delete state.tarefaById[id];
    },
    // TAREFAANEXO
    [fetchWorkflowTarefaAnexo.fulfilled]: (state, action) => {
      reduceTarefaAnexo(state, action.payload);
    },
    [createWorkflowTarefaAnexo.fulfilled]: (state, action) => {
      const { tarefaId } = action.meta.arg;
      const { id } = action.payload;
      state.tarefaById[tarefaId].anexos.push(id);
    },
    [removeWorkflowTarefaAnexo.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      const anexo = state.tarefaAnexoById[id];
      if (anexo)
        state.tarefaById[anexo.tarefa].anexos = state.tarefaById[anexo.tarefa].anexos.filter(anexoId => id !== anexoId);
      delete state.tarefaAnexoById[id];
    },
    // TAREFAITEM
    [fetchWorkflowTarefaItem.fulfilled]: (state, action) => {
      reduceTarefaItem(state, action.payload);
    },
    [createWorkflowTarefaItem.fulfilled]: (state, action) => {
      const { tarefaId } = action.meta.arg;
      const { id } = action.payload;
      state.tarefaById[tarefaId].itens.push(id);
    },
    [removeWorkflowTarefaItem.fulfilled]: (state, action) => {
      const { id } = action.meta.arg;
      const item = state.tarefaItemById[id];
      if (item)
        state.tarefaById[item.tarefa].itens = state.tarefaById[item.tarefa].itens.filter(itemId => id !== itemId);
      delete state.tarefaItemById[id];
    }
    //
  },
});

export default slice.reducer;

export const {
  filtrarWorkflow,
  selectWorkflowTarefaId,
  selectWorkflowTipoTarefaId,
  dragStartWorkflowTipoTarefaItemId,
  dragEndWorkflowTipoTarefaItemId,
  dragStartWorkflowEtapaId,
  dragEndWorkflowEtapaId,
  dragStartWorkflowTarefaId,
  dragEndWorkflowTarefaId,
  dragStartWorkflowTarefaAnexoId,
  dragEndWorkflowTarefaAnexoId,
  dragStartWorkflowTarefaItemId,
  dragEndWorkflowTarefaItemId
} = slice.actions;

function reduceWorkflow(state, workflow) {
  if (typeof workflow === 'number')
    return workflow;
  const { membros, tiposTarefas, etapas, ...reduced } = workflow;
  reduced.membros = membros.map(it => reduceMembro(state, it));
  reduced.tiposTarefas = tiposTarefas.map(it => reduceTipoTarefa(state, it));
  reduced.etapas = etapas.map(it => reduceEtapa(state, it));
  state.workflow = reduced;
  return workflow.id;
}

function reduceTipoTarefa(state, tipoTarefa) {
  if (typeof tipoTarefa === 'number')
    return tipoTarefa;
  const { itens, ...reduced } = tipoTarefa;
  reduced.itens = itens.map(it => reduceTipoTarefaItem(state, it));
  state.tipoTarefaById[reduced.id] = reduced;
  return tipoTarefa.id;
}

function reduceTipoTarefaItem(state, item) {
  if (typeof item === 'number')
    return item;
  state.tipoTarefaItemById[item.id] = item;
  return item.id;
}

function reduceEtapa(state, etapa) {
  if (typeof etapa === 'number')
    return etapa;
  const { tarefas, ...reduced } = etapa;
  reduced.tarefas = tarefas.map(it => reduceTarefa(state, it));
  state.etapaById[reduced.id] = reduced;
  return etapa.id;
}

function reduceTarefa(state, tarefa) {
  if (typeof tarefa === 'number')
    return tarefa;
  const prevTarefa = state.tarefaById[tarefa.id];
  if (prevTarefa && prevTarefa.etapa !== tarefa.etapa) {
    state.etapaById[prevTarefa.etapa].tarefas = state.etapaById[prevTarefa.etapa].tarefas.filter(it => it !== tarefa.id);
    state.etapaById[tarefa.etapa].tarefas.push(tarefa.id);
  }
  const { membros, anexos, itens, ...reduced } = tarefa;
  reduced.membros = membros.map(it => reduceMembro(state, it));
  reduced.anexos = anexos.map(it => reduceTarefaAnexo(state, it));
  reduced.itens = itens.map(it => reduceTarefaItem(state, it));
  state.tarefaById[reduced.id] = reduced;
  return tarefa.id;
}

function reduceMembro(state, membro) {
  if (typeof membro === 'number')
    return membro;
  state.membroById[membro.id] = membro;
  return membro.id;
}

function reduceTarefaAnexo(state, anexo) {
  if (typeof anexo === 'number')
    return anexo;
  state.tarefaAnexoById[anexo.id] = anexo;
  return anexo.id;

}

function reduceTarefaItem(state, item) {
  if (typeof item === 'number')
    return item;
  state.tarefaItemById[item.id] = item;
  return item.id;
}