import {formatCnpj, isValidCnpj} from './cnpj';
import {formatCpf, isValidCpf} from './cpf';

export function formatDocumento(documento) {
  documento = documento || '';
  switch (documento.length) {
    case 11:
      return formatCpf(documento);
    case 14:
      return formatCnpj(documento);
  }
  return documento;
}

export function parseDocumento(documento) {
  return documento.replace(/\D+/g, '');
}

export function isValidDocumento(documento) {
  if (!documento)
    return false;
  switch (documento.length) {
    case 11:
      return isValidCpf(documento);
    case 14:
      return isValidCnpj(documento);
  }
}
