import { Breadcrumb } from 'antd';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionBar from '../../components/action-bar';
import ActionButton from '../../components/action-button';
import FlexCard from '../../components/flex-card';
import MiniTable from '../../components/mini-table';
import AuthenticatedLayout from '../../layouts/authenticated';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import AmbienteDrawer from './_AmbienteDrawer';
import AmbientesTable from './_AmbientesTable';

export default function AmbientesPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [activeSearch, setActiveSearch] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [ambiente, setAmbiente] = useState();
  const [ambienteDrawerVisible, setAmbienteDrawerVisible] = useState(false);

  const handleSearch = search => {
    setLoadingSearch(true);
    unwrapAxiosPromise(axios.get('/ambientes', { params: { search } }))
      .then(data => {
        setActiveSearch(search);
        setDataSource(data);
      })
      .finally(() => setLoadingSearch(false));
  };

  const handleCreateClick = () => {
    setAmbiente(undefined);
    setAmbienteDrawerVisible(true);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete('/ambientes', { params: { ids: selectedIds } }))
      .then(() => {
        setSelectedIds([]);
        handleSearch(activeSearch);
      })
      .finally(() => setLoadingRemove(false));
  };

  const handleClick = ambiente => {
    setLoadingDrawer(true);
    setAmbienteDrawerVisible(true);
    unwrapAxiosPromise(axios.get(`/ambientes/${ambiente.id}`))
      .then(data => {
        setAmbiente(data);
      })
      .finally(() => setLoadingDrawer(false));
  };

  const handleAmbienteChange = () => {
    handleSearch(activeSearch);
  };

  const handleAmbienteDrawerClose = () => {
    setAmbienteDrawerVisible(false);
  };

  const handleAmbienteDrawerAfterClose = () => {
    setAmbiente(undefined);
  };

  const title = () => {
    return (
      <ActionBar onSearch={handleSearch}>
        <ActionButton.Create onClick={handleCreateClick}/>
        <ActionButton.Remove
          disabled={selectedIds.length === 0}
          onClick={handleRemove}
          loading={loadingRemove}
        />
        <MiniTable.DownloadButton disabled={loadingSearch || loadingRemove || dataSource.length === 0} miniTableRef={miniTableRef}/>
      </ActionBar>);
  };

  return (
    <AuthenticatedLayout breadcrumb={[t('Ambientes')]}>
      <Breadcrumb>
        <Breadcrumb.Item>{t('Ambientes')}</Breadcrumb.Item>
      </Breadcrumb>
      <FlexCard>
        <AmbientesTable
          dataSource={dataSource}
          loading={loadingSearch || loadingRemove}
          miniTableRef={miniTableRef}
          onClick={handleClick}
          onSelectedIdsChange={setSelectedIds}
          selectedIds={selectedIds}
          title={title}
        />
      </FlexCard>
      <AmbienteDrawer
        loading={loadingDrawer}
        onAfterClose={handleAmbienteDrawerAfterClose}
        onChange={handleAmbienteChange}
        onClose={handleAmbienteDrawerClose}
        value={ambiente}
        visible={ambienteDrawerVisible}
      />
    </AuthenticatedLayout>
  );
}
