import {useTranslation} from 'react-i18next';

export default function useFormatter() {
  const {t, i18n} = useTranslation();
  const formatter = {
    /**
     * @param boolean {boolean | string}
     * @return {string}
     */
    formatBoolean(boolean) {
      if (typeof boolean === 'string')
        boolean = boolean === 'true';
      if (typeof boolean === 'boolean')
        return boolean ? t('Sim') : t('Não');
      return '-';
    },

    /**
     * @param date    {Date | string}
     * @param options {DateTimeFormatOptions}
     * @return {string}
     */
    formatDate(date, options = undefined) {
      if (typeof date === 'string')
        date = new Date(date);
      if (date instanceof Date)
        return new Intl.DateTimeFormat(
            i18n.language,
            {
              timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit', ...options,
            })
            .format(date);
      return '-';
    },

    /**
     * @param dateTime {Date | string}
     * @param options  {DateTimeFormatOptions}
     * @return {string}
     */
    formatDateTime(dateTime, options = undefined) {
      if (typeof dateTime === 'string')
        dateTime = new Date(dateTime);
      if (dateTime instanceof Date)
        return new Intl.DateTimeFormat(
            i18n.language,
            {
              year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
              ...options,
            })
            .format(dateTime);
      return '-';
    },

    /**
     * @param month {number | string}
     * @return {string}
     */
    formatMonth(month) {
      if (typeof month === 'string')
        month = parseInt(month);
      if (typeof month === 'number')
        switch (month) {
          case 1:
            return t('Janeiro');
          case 2:
            return t('Fevereiro');
          case 3:
            return t('Março');
          case 4:
            return t('Abril');
          case 5:
            return t('Maio');
          case 6:
            return t('Junho');
          case 7:
            return t('Julho');
          case 8:
            return t('Agosto');
          case 9:
            return t('Setembro');
          case 10:
            return t('Outubro');
          case 11:
            return t('Novembro');
          case 12:
            return t('Dezembro');
        }
      return '-';
    },

    /**
     * @param number  {number | string}
     * @param options {NumberFormatOptions}
     * @return {string}
     */
    formatNumber(number, options = undefined) {
      if (typeof number === 'string')
        number = parseFloat(number);
      if (typeof number === 'number' && isFinite(number))
        return new Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 8,
          minimumFractionDigits: 0,
          useGrouping: true,
          ...options,
        }).format(number);
      return '-';
    },

    /**
     * @param number  {number | string}
     * @param options {NumberFormatOptions}
     * @return {string}
     */
    formatCurrency(number, options = undefined) {
      if (typeof number === 'string')
        number = parseFloat(number);
      if (typeof number === 'number' && isFinite(number))
        return new Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
          ...options,
        }).format(number);
      return '-';
    },

    /**
     * @param number  {number | string}
     * @param options {NumberFormatOptions}
     * @return {string}
     */
    formatPercentage(number, options = undefined) {
      if (typeof number === 'string')
        number = parseFloat(number);
      if (typeof number === 'number' && isFinite(number))
        return new Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 8,
          minimumFractionDigits: 0,
          // style: 'percent',
          useGrouping: true,
          ...options,
        }).format(100 * number);
      return '-';
    },

    /**
     * @param number  {number | string}
     * @return {string}
     */
    formatBytes(number) {
      if (typeof number === 'string')
        number = parseFloat(number);
      if (isFinite(number) && number) {
        let unit = 0;
        while (number >= 1000 && unit < 4) {
          number /= 1000;
          unit++;
        }
        switch (unit) {
          default:
            return formatter.formatNumber(number, {maximumFractionDigits: 0}) + ' B';
          case 1:
            return formatter.formatNumber(number, {maximumFractionDigits: 0}) + ' KB';
          case 2:
            return formatter.formatNumber(number, {maximumFractionDigits: 1}) + ' MB';
          case 3:
            return formatter.formatNumber(number, {maximumFractionDigits: 2}) + ' GB';
          case 4:
            return formatter.formatNumber(number, {maximumFractionDigits: 2}) + ' TB';
        }
      }
      return '-';
    },

    /**
     * @param number  {number | string}
     * @param options {NumberFormatOptions}
     * @return {number}
     */
    parseNumber(number, options = undefined) {
      if (typeof number === 'string') {
        const nf = new Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 8,
          minimumFractionDigits: 0,
          useGrouping: true,
          ...options,
        });
        const gs = nf.format(1111).replace(/\p{Number}/gu, '');
        const ds = nf.format(1.1).replace(/\p{Number}/gu, '');
        number = parseFloat(number
            .replaceAll(gs, '')
            .replaceAll(ds, '.'));
      }
      return number;
    },

    /**
     * @param date    {Date | string}
     * @return {Date}
     */
    parseDate(date) {
      if (typeof date === 'string') {
        let year;
        let month;
        let day;
        if (date.match(/^\d{6,8}$/g)) {
          if (i18n.language === 'en-US') {
            month = date.substring(0, 2);
            day = date.substring(2, 4);
            year = date.substring(4);
          } else {
            day = date.substring(0, 2);
            month = date.substring(2, 4);
            year = date.substring(4);
          }
        } else {
          const split = date.split('/', 3);
          if (i18n.language === 'en-US') {
            month = split[0];
            day = split[1];
            year = split[2];
          } else {
            day = split[0];
            month = split[1];
            year = split[2];
          }
        }
        year = parseInt(year);
        month = parseInt(month);
        day = parseInt(day);
        if (isFinite(year) && isFinite(month) && isFinite(day)) {
          if (year < 50) year += 2000;
          else if (year < 100) year += 1900;
          else if (year < 500) year += 2000;
          else if (year < 1000) year += 1000;
          date = new Date(year, month - 1, day);
        }
      }
      return date;
    },
  };
  return formatter;
};
