import styles from "./_WorkflowTipoTarefaTag.less";
import React from "react";
import PropTypes from "prop-types";

export default function WorkflowTipoTarefaTag(props) {
  const { nome, cor } = props;
  return (
    <span className={styles['workflow-tipoTarefa-tag']} style={{ backgroundColor: '#' + cor }}>
      {nome}
    </span>
  )
}

WorkflowTipoTarefaTag.propTypes = {
  cor: PropTypes.string,
  nome: PropTypes.string,
}