import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import { EmpresaBeneficiarioSocio, findByIdAndMap } from '../../../../constants';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { beneficiariosSocioelector, fetchBeneficiarioSocio, isEditableSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

const {Apresentacao, Aceite} = EmpresaBeneficiarioSocio;

export default function EmpresaBeneficiarioSocioDetailPage() {
  const {t} = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const beneficiarioSocio = useSelector(beneficiariosSocioelector);
  const {formatDateTime, formatDate, formatBoolean} = useFormatter();
  const loading = useLoading(fetchBeneficiarioSocio.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/beneficiarios-socio/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/beneficiarios-socio`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/beneficiarios-socio/${id}/`, `logs_/empresas/${empresaId}/beneficiarios-socio/${id}/`);
  };

  return (
      <Spin spinning={loading}>
        <ActionBar>
          {isEditable && <ActionButton.Edit onClick={handleEdit} />}
          <ActionButton.Back onClick={handleBack} />
          <ActionButton.Logs noTitle onClick={handleLogs} />
        </ActionBar>
        <Row gutter={[16, 8]}>
          <Col flex="600px"><TextItem label={t('Sócio')}>{beneficiarioSocio?.socio ? beneficiarioSocio.socio.id + ' - ' + beneficiarioSocio.socio.nome : ''}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Aplicável')}>{formatBoolean(beneficiarioSocio?.aplicavel)}</TextItem></Col>
          <Col flex="200px">
            {beneficiarioSocio?.aplicavel === true &&
            <TextItem label={t('Informado em')}>{formatDate(beneficiarioSocio?.dataAplicavel)}</TextItem>
            }
          </Col>
          <Col flex="200px">
            {beneficiarioSocio?.aplicavel === true &&
            <TextItem label={t('Data Limite da Apresentação')}>{formatDate(beneficiarioSocio?.dataLimiteApresentacao)}</TextItem>
            }
          </Col>
        </Row>
        {beneficiarioSocio?.aplicavel === true &&
        <>
          <Row gutter={[16, 8]}>
            <Col flex="200px">
              <TextItem label={t('Apresentação')}>{findByIdAndMap(Apresentacao, beneficiarioSocio?.apresentacao, apresentacao => t(apresentacao.label))}</TextItem>
            </Col>
            <Col flex="200px">
              {beneficiarioSocio?.apresentacao === Apresentacao.Sim.id &&
              <TextItem label={t('Data de Apresentação')}>{formatDate(beneficiarioSocio?.dataApresentacao)}</TextItem>
              }
            </Col>
            <Col flex="200px">
              {beneficiarioSocio?.apresentacao === Apresentacao.Sim.id &&
              <TextItem label={t('Aceite')}>{findByIdAndMap(Aceite, beneficiarioSocio?.aceite, aceite => t(aceite.label))}</TextItem>
              }
            </Col>
            <Col flex="200px">
              {beneficiarioSocio?.aceite === Aceite.Sim.id &&
              <TextItem label={t('Data de Aceite')}>{formatDate(beneficiarioSocio?.dataAceite)}</TextItem>
              }
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col flex="800px"><TextAnexo label={t('Anexo')} value={beneficiarioSocio?.anexo} /></Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col flex="800px"><TextItem label={t('Observação')}>{beneficiarioSocio?.obs}</TextItem></Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col flex="200px"><TextItem label="Criado em">{formatDateTime(beneficiarioSocio?.createdAt)}</TextItem></Col>
            <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(beneficiarioSocio?.updatedAt)}</TextItem></Col>
            <Col flex="200px"><TextItem label="Id">{beneficiarioSocio?.id}</TextItem></Col>
          </Row>
        </>
        }
      </Spin>
  );
}
