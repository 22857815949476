// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rx3cFmmGqSPSBZx8d1WR4A\\=\\= {\n  display: flex;\n  flex-flow: column nowrap;\n  flex-grow: 1;\n  justify-content: center;\n  align-items: center;\n}\n.SClcx0kCcjkO2wGj3N3g0w\\=\\= {\n  display: flex;\n  flex-flow: column nowrap;\n  flex-grow: 1;\n}\n.sTCuTWBNp89e7pXe09wHKg\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-grow: 1;\n  overflow-x: scroll;\n  padding: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/_Workflow.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,wBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEA;EACE,aAAA;EACA,wBAAA;EACA,YAAA;AAAF;AAGA;EACE,aAAA;EACA,qBAAA;EACA,2BAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;AADF","sourcesContent":[".loading {\n  display: flex;\n  flex-flow: column nowrap;\n  flex-grow: 1;\n  justify-content: center;\n  align-items: center;\n}\n\n.workflow {\n  display: flex;\n  flex-flow: column nowrap;\n  flex-grow: 1;\n}\n\n.etapas {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-grow: 1;\n  overflow-x: scroll;\n  padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "rx3cFmmGqSPSBZx8d1WR4A==",
	"workflow": "SClcx0kCcjkO2wGj3N3g0w==",
	"etapas": "sTCuTWBNp89e7pXe09wHKg=="
};
export default ___CSS_LOADER_EXPORT___;
