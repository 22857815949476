import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaDocumentosDetailPage from './_detail';
import EmpresaDocumentoPage from './documento';

export default function EmpresaDocumentosPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/documentos/:id" component={EmpresaDocumentoPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaDocumentosDetailPage} />
        </Switch>
      </Card>);
}
