// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OAS0oUm34sUWMsQ-Qf50Fg\\=\\= {\n  font-size: 11px;\n  font-weight: bold;\n  padding: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/_WorkflowEtapaNome.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".workflow-etapa-nome {\n  font-size: 11px;\n  font-weight: bold;\n  padding: 0 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-etapa-nome": "OAS0oUm34sUWMsQ-Qf50Fg=="
};
export default ___CSS_LOADER_EXPORT___;
