import { Select } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import { formatCnpj } from "../../util/cnpj";

export default function SelectConsultivoUnidade({ acessoId, empresaId, filter, onChange, params, value, ...props }) {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchSearch = useCallback(search => {
    if (acessoId > 0 && empresaId > 0) {
      setLoadingSearch(true);
      unwrapAxiosPromise(axios.get(`/consultivo/empresas/${empresaId}/unidades`, { params: { search, acessoId } }))
        .then(data => {
          if (typeof filter === 'function')
            data = data.filter(filter);
          setOptions(data);
        })
        .finally(() => setLoadingSearch(false));
    } else
      setOptions([]);
  }, [acessoId, empresaId]);

  const handleChange = id => {
    onChange(id);
  };

  const handleSearch = useDebouncedCallback(500, search => {
    fetchSearch(search);
  }, [fetchSearch]);

  useEffect(() => {
    fetchSearch();
  }, [fetchSearch]);

  return (
    <Select
      filterOption={false}
      loading={loadingSearch}
      onChange={handleChange}
      onSearch={handleSearch}
      showSearch
      value={value}
      {...props}
    >
      {options.map(option => (
        <Select.Option key={option.id} value={option.id}>
          {formatCnpj(option.cnpj)} - {option.apelido}
        </Select.Option>
      ))}
    </Select>
  );
}

SelectConsultivoUnidade.propTypes = {
  empresaId: PropTypes.number,
  filter: PropTypes.func,
  onChange: PropTypes.func,
  params: PropTypes.any,
  value: PropTypes.number,
};
