import { Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import styles from './_styles.less';

function Search({ search, onSearch, loading }, ref) {
    const [searchText, setSearchText] = useState(search ?? '');

    useDebouncedEffect(500, () => {
        if (typeof onSearch === 'function')
            onSearch(searchText);
    }, [searchText]);

    return (
        <Input.Search
          ref={ref}
          className={styles['search']}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          loading={loading}
          autoFocus
        />
    );
}

Search.propTypes = {
    search: PropTypes.string,
    onSearch: PropTypes.func,
    loading: PropTypes.bool,
};

export default React.forwardRef(Search);
