// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O5y4XygAIp8ajrQ3uwS2-A\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: center;\n  align-items: center;\n}\n._13xfFuneS7RYqX-VtfL6HA\\=\\= {\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://src/pages/ambientes/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;AAAF","sourcesContent":[".logo {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: center;\n  align-items: center;\n}\n\n.checkbox-vertical {\n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "O5y4XygAIp8ajrQ3uwS2-A==",
	"checkbox-vertical": "_13xfFuneS7RYqX-VtfL6HA=="
};
export default ___CSS_LOADER_EXPORT___;
