import {Card} from 'antd';
import React from 'react';
import styles from './_styles.less';

export default function FlexCard({className, ...props}) {
  let cn = styles['card'];
  if (className)
    cn += ' ' + className;
  return (
      <Card className={cn} {...props} />
  );
}
