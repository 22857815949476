import {Progress, Spin, Tooltip} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import FlexCard from '../../../components/flex-card';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useFormatter from '../../../hooks/useFormatter';
import useLoading from '../../../hooks/useLoading';
import {acessoSelector} from '../../../store/acessos';
import {comparativoEmpresasSelector} from '../../../store/complianceDashboard';
import {fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas} from '../../../store/empresas';
import {openPopupEmpresa} from '../../../util/window';
import styles from './_styles.less';

function Empresa({empresa, count, status}) {
  const {formatNumber} = useFormatter();
  const dispatch = useDispatchWithResult();
  const acesso = useSelector(acessoSelector);

  const onUnload = () => {
    dispatch(fetchEmpresas({acessoId: acesso?.id}));
  };

  const handleClick = () => {
    openPopupEmpresa(empresa, 'cadastro', {onUnload});
  };

  return (
      <div className={styles['empresa']} onClick={handleClick}>
        <small>{empresa.apelido || empresa.razaoSocial}</small>
        <Tooltip title={formatNumber(count)}>
          <Progress percent={count / 0.15} showInfo={false} status={status} />
        </Tooltip>
      </div>);
}

export default function RankingEmpresasCard() {
  const {t} = useTranslation();
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const comparativoEmpresas = useSelector(comparativoEmpresasSelector);

  const top = [...comparativoEmpresas].sort((a, b) => b.sucessos - a.sucessos).slice(0, 5);
  const bottom = [...comparativoEmpresas].sort((a, b) => b.erros - a.erros).slice(0, 5);

  return (
      <Spin spinning={loading}>
        <FlexCard bordered={false}>
          <div className={styles['ranking-empresas']}>
            <div>
              <h1>{t('Menos pendências')}</h1>
              {top.map(empresa => <Empresa key={empresa.id} empresa={empresa} count={empresa.sucessos} />)}
            </div>
            <div>
              <h1>{t('Mais pendências')}</h1>
              {bottom.map(empresa => <Empresa key={empresa.id} empresa={empresa} count={empresa.erros} status="exception" />)}
            </div>
          </div>
        </FlexCard>
      </Spin>
  );
}
