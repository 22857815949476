import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchSocio} from '../../../../store/empresa';
import EmpresaSocioCreatePage from './_create';
import EmpresaSocioDetailPage from './_detail';
import EmpresaSocioEditPage from './_edit';

export default function EmpresaSocioPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchSocio({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/socios/:id/edit" component={EmpresaSocioEditPage} />
        <Route path="/*/empresas/:empresaId/socios/create" component={EmpresaSocioCreatePage} />
        <Route path="/*/empresas/:empresaId/socios/:id" component={EmpresaSocioDetailPage} />
      </Switch>);
}
