import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import { acessoSelector } from '../../store/acessos';
import { fetchEmpresas } from '../../store/empresas';
import ActionLink from '../action-link';
import EmpresaDrawer from './_EmpresaDrawer';
import styles from './_styles.less';
import { funcoesSelector } from "../../store/autenticacao";

export default function WorkflowMenu({ showCreate, extraMenu }) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const funcoes = useSelector(funcoesSelector);
  const acesso = useSelector(acessoSelector);

  const [empresa, setEmpresa] = useState();
  const [empresaDrawerVisible, setEmpresaDrawerVisible] = useState(false);

  const hasCadastro = funcoes?.includes('cadastro') && acesso?.funcoes?.includes('cadastro');

  const handleCreateClick = () => {
    setEmpresa({ acessoId: acesso?.id });
    setEmpresaDrawerVisible(true);
  };

  const handleEmpresaChange = () => {
    dispatch(fetchEmpresas({ acessoId: acesso?.id }));
  };

  const handleEmpresaDrawerClose = () => {
    setEmpresaDrawerVisible(false);
  };

  const handleEmpresaDrawerAfterClose = () => {
    setEmpresa(undefined);
  };

  const buttons = [];
  if (showCreate && hasCadastro) {
    buttons.push(
      <ActionLink
        key="create"
        title={t('Criar Empresa')}
        noTitle
        icon={<PlusOutlined/>}
        onClick={handleCreateClick}
      />);
  }
  if (extraMenu && extraMenu.length > 0)
    buttons.push(extraMenu);

  return (
    <div className={styles['workflow-menu']}>
      {buttons}
      {hasCadastro &&
        <EmpresaDrawer
          value={empresa}
          onChange={handleEmpresaChange}
          visible={empresaDrawerVisible}
          onClose={handleEmpresaDrawerClose}
          onAfterClose={handleEmpresaDrawerAfterClose}
        />
      }
    </div>
  );
}

WorkflowMenu.propTypes = {
  showCreate: PropTypes.bool,
  showFilter: PropTypes.bool,
  extraMenu: PropTypes.array,
};
