import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AcessoMenu from '../../../components/acesso-menu';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import AuthenticatedLayout from '../../../layouts/authenticated';
import { acessoSelector } from '../../../store/acessos';
import { isClienteGestorSelector } from '../../../store/autenticacao';
import { fetchDashboard } from '../../../store/regulatorioDashboard';
import { alertasByEmpresaIdSelector, empresasFiltradasSelector, fetchEmpresas } from '../../../store/empresas';
import ComparativoEmpresasCard from './_ComparativoEmpresasCard';
import NivelAvisoCard from './_NivelAvisoCard';
import NivelErroCard from './_NivelErroCard';
import NivelSucessoCard from './_NivelSucessoCard';
import ProximoAlertaAvisoCard from './_ProximoAlertaAvisoCard';
import ProximoAlertaErroCard from './_ProximoAlertaErroCard';
import RankingEmpresasCard from './_RankingEmpresasCard';
import PesquisaMenu from "../../../components/pesquisa-menu";
import RegulatorioMenu from "../../../components/regulatorio-menu";

export default function RegulatorioDashboardPage() {
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const acesso = useSelector(acessoSelector);
  const empresas = useSelector(empresasFiltradasSelector);
  const alertasByEmpresaId = useSelector(alertasByEmpresaIdSelector);
  const dispatch = useDispatchWithResult();
  const acessoId = acesso?.id;

  useEffect(() => {
    dispatch(fetchEmpresas({ acessoId }));
  }, [acessoId]);

  useEffect(() => {
    dispatch(fetchDashboard({ empresas, alertasByEmpresaId }));
  }, [empresas, alertasByEmpresaId]);

  return (
    <AuthenticatedLayout
      modulo="regulatorio"
      menu={
        <>
          <AcessoMenu defaultRoute="/regulatorio/dashboard" fetchEmpresas/>
          {!!acesso && <PesquisaMenu/>}
          <RegulatorioMenu showCreate={isClienteGestor}/>
        </>
      }
    >
      {acesso &&
        <Row gutter={[16, 16]}>
          <Col span={24} md={8}>
            <NivelSucessoCard/>
          </Col>
          <Col span={24} md={8}>
            <NivelAvisoCard/>
          </Col>
          <Col span={24} md={8}>
            <NivelErroCard/>
          </Col>
          <Col span={24} md={12}>
            <ComparativoEmpresasCard/>
          </Col>
          <Col span={24} md={12}>
            <RankingEmpresasCard/>
          </Col>
          <Col span={24} md={12}>
            <ProximoAlertaAvisoCard/>
          </Col>
          <Col span={24} md={12}>
            <ProximoAlertaErroCard/>
          </Col>
        </Row>
      }
      <br/>
    </AuthenticatedLayout>
  );
}
