import {Col, Form, Input, InputNumber, Radio, Row} from 'antd';
import moment from 'moment';
import pretty from 'pretty';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ActionButton from '../../components/action-button';
import InputDate from '../../components/input-date';
import InputEmail from '../../components/input-email';
import InputName from '../../components/input-name';
import MiniFormDrawer from '../../components/mini-form-drawer';
import SelectEmailEstado from '../../components/select-email-estado';

const defaultValue = {};

export default function EmailDrawer({value = defaultValue, visible, loading, onClose, onAfterClose}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [corpoTab, setCorpoTab] = useState('visualizacao');

    const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

    useEffect(() => {
        setCorpoTab("visualizacao");
        value.corpo = value.corpo ? pretty(value.corpo) : null;
        value.enviadoEm = value.enviadoEm ? moment(value.enviadoEm) : null;
        value.createdAt = value.createdAt ? moment(value.createdAt) : null;
        value.erro = value.erro ? JSON.stringify(JSON.parse(value.erro), null, 2) : null;
        form.setFieldsValue(value);
    }, [value]);

    const handleDrawerAfterOpen = () => {
        document.querySelector('#para').focus();
    };

    const footer = (
        <ActionButton.Close
            onClick={fireClose}
        />
    );

    return (
        <MiniFormDrawer
            drawerTitle={t('E-mail')}
            drawerFooter={footer}
            drawerWidth={500}
            drawerVisible={visible}
            form={form}
            onDrawerClose={fireClose}
            onDrawerAfterOpen={handleDrawerAfterOpen}
            onDrawerAfterClose={onAfterClose}
        >
            <Form.Item name="para" label={t('Para')}>
                <InputEmail disabled/>
            </Form.Item>
            <Form.Item name="assunto" label={t('Assunto')}>
                <InputName disabled />
            </Form.Item>
            <Form.Item
                label={
                    <Row gutter={[16, 0]}>
                        <Col>
                            {t('Corpo')}
                        </Col>
                        <Col>
                            <Radio.Group value={corpoTab} onChange={e => setCorpoTab(e.target.value)}>
                                <Radio.Button value="visualizacao">{t('Visualização')}</Radio.Button>
                                <Radio.Button value="fonte">{t('Fonte')}</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                }
            >
                {corpoTab === 'visualizacao' &&
                <Form.Item
                    name="corpo" label={t('Corpo')} noStyle
                    getValueProps={__html => ({dangerouslySetInnerHTML: {__html}})}
                >
                    <div className="ant-input ant-input-disabled"/>
                </Form.Item>
                }
                {corpoTab === 'fonte' &&
                <Form.Item name="corpo" label={t('Corpo')} noStyle>
                    <Input.TextArea disabled autoSize className="monospace ws-pre" />
                </Form.Item>
                }
            </Form.Item>
            <Row gutter={[16, 0]}>
                <Col flex="auto">
                    <Form.Item name="estado" label={t('Estado')}>
                        <SelectEmailEstado disabled/>
                    </Form.Item>
                </Col>
                <Col flex="100px">
                    <Form.Item name="erros" label={t('Erros')}>
                        <InputNumber disabled/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col xs={24} md={12}>
                    <Form.Item name="enviadoEm" label={t('Enviado em')}>
                        <InputDate disabled showTime/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="criadoEm" label={t('Criado Em')}>
                        <InputDate disabled showTime/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="erro" label={t('Erro')}>
                <Input.TextArea disabled autoSize className="monospace ws-pre"/>
            </Form.Item>
        </MiniFormDrawer>
    );
}

EmailDrawer.propTypes = {
    value: PropTypes.object,
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    onAfterClose: PropTypes.func,
};
