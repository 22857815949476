import styles from "./_WorkflowEtapa.less";
import React, { useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { createWorkflowEtapa, workflowSelector } from "../../store/workflow";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import ActionLink from "../../components/action-link";
import WorkflowEtapaNome from "./_WorkflowEtapaNome";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";

export default function WorkflowEtapaCriar() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector);
  const [exibeBotao, setExibeBotao] = useState(true);

  const workflowId = workflow.id;

  const handleCreateEtapa = () => {
    setExibeBotao(false);
  };

  const handleCloseEtapa = () => {
    setExibeBotao(true);
  }

  const handleEdicao = edicao => {
    setExibeBotao(!edicao);
  }

  const handleSubmit = nome => {
    unwrapAxiosPromise(dispatch(createWorkflowEtapa({ workflowId, nome })))
      .then(() => handleCloseEtapa());
  };

  if (exibeBotao)
    return (
      <div className={styles['workflow-etapa-criar-botao']}>
        <ActionLink.Create title={t('Criar Etapa')} onClick={handleCreateEtapa}/>
      </div>
    );

  return (
    <div className={styles['workflow-etapa-criar']}>
      <div className={styles['cabecalho']}>
        <div className={styles['titulo']}>
          <WorkflowEtapaNome nome='' edicao onEdicao={handleEdicao} onSubmit={handleSubmit}/>
        </div>
      </div>
    </div>
  );
}