import {LoginOutlined} from '@ant-design/icons';
import {Alert, Button, Form} from 'antd';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import InputEmail from '../components/input-email';
import Link from '../components/link';
import useDispatchWithResult from '../hooks/useDispatchWithResult';
import useLoading from '../hooks/useLoading';
import CardLayout from '../layouts/card';
import {unwrapResult} from '../store';
import {emailMemorizadoSelector, recuperarAcesso} from '../store/autenticacao';
import styles from './_styles.less';

const formLayout = {
  layout: 'horizontal',
  labelCol: {flex: '80px'},
  wrapperCol: {flex: 'auto'},
};

const buttonStyle = {paddingLeft: '80px'};

function ButtonSubmit(props) {
  const {t} = useTranslation();
  return (
      <Button
          size="middle"
          icon={<LoginOutlined />}
          type="primary"
          htmlType="submit"
          {...props}
      >
        {t('Redefinir Senha')}
      </Button>
  );
}

export default function RecuperarAcessoPage() {
  const [enviado, setEnviado] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatchWithResult();
  const loading = useLoading(recuperarAcesso.typePrefix);
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const email = useSelector(emailMemorizadoSelector);

  const handleFinish = values => {
    dispatch(recuperarAcesso(values), form)
        .then(unwrapResult)
        .then(() => setEnviado(true))
        .catch(err => setError(err.mensagem));
  };

  const alert = enviado ?
      <Alert showIcon message={t('Verifique seu e-mail para criar uma nova senha')} type="success" className={styles['alert']} /> :
      error ?
          <Alert showIcon message={error} type="error" className={styles['alert']} /> :
          <Alert showIcon message={t('Redefinir Senha')} type='info' className={styles['alert']} />;

  return (
      <CardLayout width={480}>
        <Form
            form={form}
            onFinish={handleFinish}
            size="middle"
            {...formLayout}
        >
          {alert}
          <Form.Item name="email" label={t('E-mail')} initialValue={email}>
            <InputEmail autoFocus disabled={loading || enviado} />
          </Form.Item>
          <Form.Item style={buttonStyle}>
            <ButtonSubmit loading={loading} disabled={enviado} />
            <span className={styles['link']}>
        <Link to="/autenticar" disabled={loading}>{t('voltar')}</Link>
        </span>
          </Form.Item>
        </Form>
      </CardLayout>
  );
};
