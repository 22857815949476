import { Checkbox, Form, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../components/action-button';
import InputName from '../../components/input-name';
import MiniFormDrawer from '../../components/mini-form-drawer';
import TextItem from '../../components/text-item';
import useFormatter from '../../hooks/useFormatter';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import InputAnexo from "../../components/input-anexo";
import { valuesToFormData } from "../../util/formData";
import InputNumber from "../../components/input-number";
import styles from "./_styles.less";
import { Modulos } from "../../constants";

const defaultValue = {
  nome: '',
  logo: null,
  modulos: []
};

export default function AmbienteDrawer({ loading, onAfterClose, onChange, onClose, value = defaultValue, visible }) {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);

  const fireChange = () => typeof onChange === 'function' ? onChange() : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  const modulosOptions = Modulos.map(tab => ({ label: t(tab.label), value: tab.id }))

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#nome').focus();
  };

  const handleFinish = values => {
    setLoadingSave(true);
    const formData = valuesToFormData(values);
    const promise = value.id ?
      axios.put(`/ambientes/${value.id}`, formData) :
      axios.post('/ambientes', formData);

    unwrapAxiosPromise(promise, form)
      .then(() => {
        message.success(t(value.id ?
          'Ambiente alterado com sucesso' :
          'Ambiente incluído com sucesso'));
        fireChange();
        fireClose();
      })
      .finally(() => setLoadingSave(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        onClick={() => form.submit()}
        loading={loadingSave}
      />
      <ActionButton.Close
        onClick={fireClose}
        disabled={loadingSave}
      />
    </>
  );

  const drawerTitle = value.id ? (value.id + ' - ' + value.nome) : t('Criar Ambiente');

  return (
    <MiniFormDrawer
      drawerTitle={drawerTitle}
      drawerFooter={footer}
      drawerWidth={440}
      drawerVisible={visible}
      form={form}
      loading={loading}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onDrawerAfterClose={onAfterClose}
      onFinish={handleFinish}
    >
      <Form.Item name="nome" label={t('Nome')}>
        <InputName disabled={loadingSave}/>
      </Form.Item>
      <Form.Item name="logo" label={t('Logo')}>
        <InputAnexo accept="image/*" disabled={loadingSave}/>
      </Form.Item>
      <Form.Item name="numeroUsuarios" label={t('Número de Usuários (Nível de Acesso de 1 até 5)')}>
        <InputNumber disabled={loadingSave} maximumFractionDigits={0}/>
      </Form.Item>
      <Form.Item name="numeroEmpresas" label={t('Número de Empresas')}>
        <InputNumber disabled={loadingSave} maximumFractionDigits={0}/>
      </Form.Item>
      <Form.Item name="modulos" label={t('Módulos')}>
        <Checkbox.Group className={styles['checkbox-vertical']} disabled={loadingSave} options={modulosOptions}/>
      </Form.Item>
      <TextItem label="Criado em">{formatDateTime(value?.createdAt)}</TextItem>
      <TextItem label="Alterado em">{formatDateTime(value?.updatedAt)}</TextItem>
    </MiniFormDrawer>
  );
}

AmbienteDrawer.propTypes = {
  loading: PropTypes.bool,
  onAfterClose: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.object,
  visible: PropTypes.bool,
};
