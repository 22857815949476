import { useSelector } from 'react-redux';
import { ambienteModulosSelector, modulosSelector } from "../store/autenticacao";
import { acessoSelector } from "../store/acessos";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

export default function useModulo() {
  const history = useHistory();
  const ambienteModulos = useSelector(ambienteModulosSelector)
  const modulos = useSelector(modulosSelector)
  const acesso = useSelector(acessoSelector);
  const acessoModulos = acesso?.modulos ?? [];
  const { location } = history;
  const { pathname } = location;

  const { moduloAtual, moduloBasePath } = useMemo(() => {
    if (pathname.startsWith('/societario/'))
      return { moduloAtual: 'societario', moduloBasePath: '/societario' };
    if (pathname.startsWith('/regulatorio/'))
      return { moduloAtual: 'regulatorio', moduloBasePath: '/regulatorio' };
    if (pathname.startsWith('/workflow/') || pathname.startsWith('/workflows/'))
      return { moduloAtual: 'workflow', moduloBasePath: '/workflow' };
    if (pathname.startsWith('/consultivo/'))
      return { moduloAtual: 'consultivo', moduloBasePath: '/consultivo' };
    if (pathname.startsWith('/compliance/'))
      return { moduloAtual: 'compliance', moduloBasePath: '/compliance' };
    if (pathname.startsWith('/data-analytics/'))
      return { moduloAtual: 'data-analytics', moduloBasePath: '/data-analytics' };
    return { moduloAtual: 'data-analytics', moduloBasePath: '' };
  }, [pathname]);

  const hasModulo = useCallback(modulo => ambienteModulos.includes(modulo) && modulos.includes(modulo), [ambienteModulos, modulos]);
  const aurthorizeModulo = useCallback(modulo => hasModulo(modulo) ? undefined : history.push('/home'), [hasModulo]);

  const hasAcessoModulo = useCallback(modulo => ambienteModulos.includes(modulo) && modulos.includes(modulo) && acessoModulos.includes(modulo), [ambienteModulos, modulos, acessoModulos]);
  const authorizeAcessoModulo = useCallback(modulo => hasAcessoModulo(modulo) ? undefined : history.push('/home'), [hasAcessoModulo]);

  return {
    ambienteModulos, acessoModulos, modulos, moduloAtual, moduloBasePath, hasModulo, aurthorizeModulo, hasAcessoModulo, authorizeAcessoModulo
  };
}
