import {Tabs} from 'antd';
import React from 'react';
import styles from './_styles.less';

export default function CardTabs(props) {
  return (
      <div className={styles['card-tabs']}>
        <Tabs type="card" {...props} />
      </div>
  );
}

