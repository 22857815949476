import {FolderOpenOutlined, FolderOutlined, HomeOutlined} from '@ant-design/icons';
import {Breadcrumb, Tooltip} from 'antd';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import {diretoriosByCaminhoSelector, diretorioSelecionadoSelector, selecionarDiretorioCaminho} from '../../store/drive';
import styles from './_styles.less';

export default function DriveBreadcrumb() {
  const dispatch = useDispatchWithResult();
  const diretoriosByCaminho = useSelector(diretoriosByCaminhoSelector);
  const diretorioSelecionado = useSelector(diretorioSelecionadoSelector);

  const handleClick = (e, caminho) => {
    e.preventDefault();
    dispatch(selecionarDiretorioCaminho(caminho));
  };

  const items = useMemo(() => {
    function loop(diretorio, diretorios = []) {
      diretorios.push(diretorio);
      if (diretorio.caminhoPai)
        loop(diretoriosByCaminho[diretorio.caminhoPai], diretorios);
      return diretorios;
    }

    return loop(diretorioSelecionado)
        .reverse()
        .map((diretorio, index, array) => {
          const isLast = index === array.length - 1;
          const nome = diretorio.caminho !== '/' ? diretorio.nome : null;

          const item = (
              <Breadcrumb.Item
                  key={diretorio.caminho}
                  className={styles['breadcrumb-item']}
                  href=""
                  onClick={e => handleClick(e, diretorio.caminho)}
              >
                {diretorio.caminho === '/' ?
                    <HomeOutlined className={styles['breadcrumb-item-icon']} /> :
                    !diretorio.link ?
                        <FolderOutlined className={styles['breadcrumb-item-icon']} /> :
                        <FolderOpenOutlined className={styles['breadcrumb-item-icon']} />
                }
                {(isLast && nome) &&
                <span className={styles['breadcrumb-item-span']}>{diretorio.nome}</span>
                }
              </Breadcrumb.Item>
          );

          if (!isLast && nome)
            return (
                <Tooltip key={diretorio.caminho + '_tooltip'} title={diretorio.nome}>
                  {item}
                </Tooltip>
            );

          return item;
        });

  }, [diretoriosByCaminho, diretorioSelecionado]);

  return (
      <Breadcrumb className={styles['breadcrumb']}>
        {items}
      </Breadcrumb>
  );
}
