import React, { useCallback, useMemo } from 'react';
import styles from './_styles.less';
import { ArrowRightOutlined, LockOutlined } from "@ant-design/icons";

export default function MenuCard({ disabled, imageSrc, onConheca, onClick, title }) {
  const className = useMemo(() => {
    const classList = [styles['menu-card'], 'background-primary'];
    if (disabled)
      classList.push(styles['disabled']);
    return classList.join(' ');
  }, [disabled])

  const handleConhecaClick = useCallback(e => {
    e.preventDefault();
    if (disabled)
      onConheca()
  }, [disabled]);

  return (
    <div className={className} onClick={handleConhecaClick}>
      <div className={styles['header']}>
        <img className={styles['image']} alt={title} src={imageSrc}/>
      </div>
      <div className={styles['body']}>
        <div className={styles['title']}>
          <span className={styles['text']}>{title}</span>
          {disabled && <span className={styles['icon']}><LockOutlined/></span>}
        </div>
        <div className={styles['horizontal-line']}/>
        <div>
          {!disabled && <button className={styles['button']} onClick={onClick}>Acessar</button>}
          {disabled &&
            <span className={styles['link']}>
              <span>Conheça</span>
              <span className={styles['icon']}><ArrowRightOutlined/></span>
            </span>}
        </div>
      </div>
    </div>
  )
}