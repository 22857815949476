import {Layout} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './_styles.less';

export default function SplashLayout({children, centered}) {
  const align = centered ? 'center' : undefined;
  const className = styles['splash-layout'] + ' ' + 'background-primary';

  return (
      <Layout
          className={className}
          style={{justifyContent: align, alignItems: align}}
      >
        <div className={styles['content']}>
          {children}
        </div>
      </Layout>
  );
}

SplashLayout.propTypes = {
  children: PropTypes.node,
  centered: PropTypes.bool,
};
