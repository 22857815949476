import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { fetchRDEIED, isEditableSelector, rdeiedSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaRDEIEDDetailPage() {
  const {t} = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const rdeied = useSelector(rdeiedSelector);
  const {formatCurrency, formatDate, formatDateTime} = useFormatter();
  const loading = useLoading(fetchRDEIED.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/rde-ieds/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/rde-ieds`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/rde-ieds/${id}/`, `logs_/empresas/${empresaId}/rde-ieds/${id}/`);
  };

  return (
      <Spin spinning={loading}>
        <ActionBar>
          {isEditable && <ActionButton.Edit onClick={handleEdit} />}
          <ActionButton.Back onClick={handleBack} />
          <ActionButton.Logs noTitle onClick={handleLogs} />
        </ActionBar>
        <Row gutter={[16, 8]}>
          <Col flex="600px"><TextItem label={t('Sócio')}>{rdeied?.socio ? rdeied.socio.id + ' - ' + rdeied.socio.nome : ''}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Data da Operação Cambial')}>{formatDate(rdeied?.dataOperacaoCambial)}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Valor da Operação (US$)')}>{formatCurrency(rdeied?.valorOperacaoDolar)}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Data da Última Atualização')}>{formatDate(rdeied?.dataUltimaApresentacao)}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextAnexo label={t('Anexo')} value={rdeied?.anexo} /></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextItem label={t('Observação')}>{rdeied?.obs}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label="Criado em">{formatDateTime(rdeied?.createdAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(rdeied?.updatedAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
        </Row>
      </Spin>
  );
}
