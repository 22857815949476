import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import MiniForm from '../mini-form';
import styles from './_styles.less';

function MiniFormDrawer({ children, drawerFooter, drawerTitle, drawerVisible, drawerWidth, form, loading, onDrawerClose, onDrawerAfterClose, onDrawerAfterOpen, onFinish, ...props }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    function resize() {
      setWindowWidth(window.innerWidth);
    }

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const handleAfterVisibleChange = visible => {
    if (visible) {
      if (typeof onDrawerAfterOpen === 'function')
        onDrawerAfterOpen();
    } else {
      if (typeof onDrawerAfterClose === 'function')
        onDrawerAfterClose();
    }
  };

  const footer = drawerFooter ? (
    <div className={styles['footer']}>
      {drawerFooter}
    </div>) : undefined;

  return (
    <Drawer
      closable={false}
      maskClosable={false}
      width={drawerWidth > windowWidth ? '100%' : drawerWidth}
      title={drawerTitle}
      visible={drawerVisible}
      push={{ distance: drawerWidth }}
      onClose={onDrawerClose}
      afterVisibleChange={handleAfterVisibleChange}
      footer={footer}
      {...props}
    >
      <MiniForm
        children={children}
        form={form}
        loading={loading}
        onFinish={onFinish}
      />
    </Drawer>
  );
}

MiniFormDrawer.defaultProps = {
  drawerWidth: 256,
};

MiniFormDrawer.propTypes = {
  children: PropTypes.node,
  drawerFooter: PropTypes.node,
  drawerTitle: PropTypes.node,
  drawerVisible: PropTypes.bool,
  drawerWidth: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  onDrawerAfterClose: PropTypes.func,
  onDrawerAfterOpen: PropTypes.func,
  onFinish: PropTypes.func,
};

export default MiniFormDrawer;
