import React from 'react';
import styles from './_styles.less';
import { MailOutlined, WhatsAppOutlined } from "@ant-design/icons";

export default function HomeFooter() {
  return (
    <div className={styles['home-footer']}>
      <div className={styles['info']}>
        <span className={styles['icon']}><WhatsAppOutlined/></span>
        <span className={styles['text']}>(19)3234-0543</span>
      </div>
      <div className={styles['info']}>
        <span className={styles['icon']}><MailOutlined/></span>
        <span className={styles['text']}>contato@legalpanel.com.br</span>
      </div>
    </div>
  )
}
