import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './_styles.less';

export default function TextColor({ children, horizontal, label, labelWidth, tooltip }) {
  const { t } = useTranslation();
  let className = styles['text-color'];
  if (horizontal)
    className += ' ' + styles['horizontal'];
  return (
    <div className={className}>
      <label>
        <span className={styles['label']} style={{ width: labelWidth }}>{t(label)}</span>
        {tooltip &&
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined className={styles['icon']}/>
          </Tooltip>
        }
        <div className={styles['value']}>
          <Tag className={styles['tag']} color={'#' + children}>&nbsp;</Tag>
          <span>{children}</span>
        </div>
      </label>
    </div>
  );
}

TextColor.propTypes = {
  children: PropTypes.node,
  horizontal: PropTypes.bool,
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  tooltip: PropTypes.string,
};
