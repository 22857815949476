import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createWorkflowTarefaItem, workflowSelector } from "../../../store/workflow";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import ActionLink from "../../../components/action-link";
import PropTypes from "prop-types";
import WorkflowTarefaItemDescricao from "./_WorkflowTarefaItemDescricao";
import styles from "./_WorkflowTarefaModal.less";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTarefaItemCriar(props) {
  const { tarefaId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector);
  const [exibeBotao, setExibeBotao] = useState(true);

  const workflowId = workflow.id;

  const handleClick = () => {
    setExibeBotao(false);
  };

  const handleClose = () => {
    setExibeBotao(true);
  }

  const handleEdicao = edicao => {
    setExibeBotao(!edicao);
  }

  const handleSubmit = descricao => {
    unwrapAxiosPromise(dispatch(createWorkflowTarefaItem({ workflowId, tarefaId, descricao })))
      .then(() => handleClose());
  };

  if (exibeBotao)
    return (
      <ActionLink.Create title={t('Criar Item')} onClick={handleClick}/>
    );

  return (
    <div className={styles['checkbox-criar']}>
      <WorkflowTarefaItemDescricao descricao='' edicao onEdicao={handleEdicao} onSubmit={handleSubmit}/>
    </div>
  );
}

WorkflowTarefaItemCriar.propTypes = {
  tarefaId: PropTypes.number,
}