import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  areaById: {},
}

export const areaByIdSelector = state => state.consultivo.areaById;

export const fetchConsultivo = createAsyncThunk(
  'consultivo/fetchConsultivo',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(fetchConsultivoAreas())
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchConsultivoAreas = createAsyncThunk(
  'consultivo/fetchConsultivoAreas',
  async (_, thunkAPI) => {
    try {
      return (await axios.get('/consultivo/areas')).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const slice = createSlice({
  name: 'consultivo',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchConsultivoAreas.fulfilled]: (state, action) => {
      state.areaById = {};
      walkAreas(null, action.payload, (areaPai, area) => {
        area.areaPai = areaPai?.id;
        state.areaById[area.id] = area;
      });
    },
  }
})

function walkAreas(areaPai, areas, cb) {
  if (!areas)
    return;
  for (const area of areas) {
    cb(areaPai, area);
    walkAreas(area, area.areasFilhas, cb);
  }
}

export default slice.reducer;
