import { message, Space } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../components/action-bar';
import ActionButton from '../../../components/action-button';
import MiniTable from '../../../components/mini-table';
import { Alerta } from '../../../constants';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import { fetchContratos, fetchLivrosDigitais, isEditableSelector, livrosDigitaisAlertaNivelSelector, livrosDigitaisAlertasSelector, livrosDigitaisSelector } from '../../../store/empresa';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import { openPopup } from '../../../util/window';
import Alertas from '../_Alertas';
import styles from '../_styles.less';
import EmpresaLivrosDigitaisTable from './_table';
import useModulo from "../../../hooks/useModulo";

export default function EmpresaLivrosDigitaisDetailPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const nivel = useSelector(livrosDigitaisAlertaNivelSelector);
  const isEditable = useSelector(isEditableSelector);
  const livrosDigitais = useSelector(livrosDigitaisSelector);
  const alertas = useSelector(livrosDigitaisAlertasSelector);
  const loading = useLoading(fetchLivrosDigitais.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const {moduloBasePath} = useModulo();
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const isNA = nivel === Alerta.Nivel.NA.id;

  const handleCreate = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/livros-digitais/create`);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete(`/empresas/${empresaId}/livros-digitais`, { params: { ids: selectedIds } }))
      .then(() => {
        message.success(t('Registro(s) removido(s) com sucesso'));
        setSelectedIds([]);
        dispatch(fetchLivrosDigitais({ empresaId }));
        dispatch(fetchContratos({ empresaId }));
      })
      .finally(() => setLoadingRemove(false));
  };

  const handleDetail = livroDigital => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/livros-digitais/${livroDigital.id}`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/livros-digitais/`, `logs_/empresas/${empresaId}/livros-digitais/`);
  };

  const actions = [];
  if (isEditable) {
    actions.push(<ActionButton.Create disabled={isNA} onClick={handleCreate}/>);
    actions.push(<ActionButton.Remove
      disabled={selectedIds.length === 0 || isNA}
      onClick={handleRemove}
      loading={loadingRemove}
    />);
  }
  actions.push(<ActionButton.Logs noTitle onClick={handleLogs}/>);
  actions.push(<MiniTable.DownloadButton disabled={loading || livrosDigitais.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar>{actions}</ActionBar>;
  return (
    <Space direction="vertical" className={styles['space']}>
      <EmpresaLivrosDigitaisTable
        dataSource={livrosDigitais}
        loading={loading}
        miniTableRef={miniTableRef}
        onClick={handleDetail}
        onSelectedIdsChange={setSelectedIds}
        selectedIds={selectedIds}
        title={title}
      />
      <Alertas alertas={alertas}/>
    </Space>);
}

EmpresaLivrosDigitaisDetailPage.propTypes = {
  livrosDigitais: PropTypes.array,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
};
