// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DgZtBd9UNkJTgvuG5FyzXA\\=\\= {\n  border-radius: 4px;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/logo/_styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AACF","sourcesContent":[".logo {\n  border-radius: 4px;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "DgZtBd9UNkJTgvuG5FyzXA=="
};
export default ___CSS_LOADER_EXPORT___;
