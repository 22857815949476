import { FolderViewOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as util from "util";
import InputEmail from '../../components/input-email';
import InputName from '../../components/input-name';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useQuery from '../../hooks/useQuery';
import CardLayout from '../../layouts/card';
import { unwrapResult } from '../../store';
import { decodeBase64String } from '../../util/base64';
import { isValidEmail } from '../../util/email';
import { isValidName } from '../../util/name';
import { identificarLink } from "../../store/drive";

const formLayout = {
  layout: 'horizontal',
  labelCol: {flex: '80px'},
  wrapperCol: {flex: 'auto'},
};

const buttonStyle = {paddingLeft: '80px'};

function ButtonSubmit(props) {
  const {t} = useTranslation();
  return (
      <Button
          size="middle"
          icon={<FolderViewOutlined />}
          type="primary"
          htmlType="submit"
          {...props}
      >
        {t('Abrir Drive')}
      </Button>
  );
}

export default function DriveIdPage() {
  const query = useQuery();
  const token = query.has('token') ? decodeBase64String(query.get('token')) : undefined;
  const dispatch = useDispatchWithResult();
  const history = useHistory();
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const handleFinish = values => {
    const {nome, email} = values;
    let erro = false;
    const fields = [];
    if (isValidName(nome)) fields.push({name: 'nome', errors: []});
    else {
      fields.push({name: 'nome', errors: [util.format(t('Deve ter de %d a %d caracteres'), 2, 100)]});
      erro = true;
    }
    if (isValidEmail(email)) fields.push({name: 'email', errors: []});
    else {
      fields.push({name: 'email', errors: [t('Deve ser um e-mail válido')]});
      erro = true;
    }
    form.setFields(fields);
    if (erro)
      return;
    dispatch(identificarLink({ token, nome, email }))
        .then(unwrapResult)
        .then(() => history.push('/drive'));
  };

  return (
      <CardLayout width={480}>
        <Form
            form={form}
            onFinish={handleFinish}
            size="middle"
            {...formLayout}
        >
          <Form.Item name="nome" label={t('Nome')}>
            <InputName autoFocus />
          </Form.Item>
          <Form.Item name="email" label={t('E-mail')}>
            <InputEmail />
          </Form.Item>
          <Form.Item style={buttonStyle}>
            <ButtonSubmit />
          </Form.Item>
        </Form>
      </CardLayout>
  );
};
