import { Col, Form, message, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import { fetchUsuarios, usuariosSelector } from "../../../../store/consultivoArea";
import MiniForm from "../../../../components/mini-form";
import SelectConsultivoUsuario from "../../../../components/select-consultivo-usuario";
import { useSelector } from "react-redux";

export default function ConsultivoAreaUsuarioAddPage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const usuarios = useSelector(usuariosSelector);
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;

  const usuarioIds = useMemo(() => usuarios.map(usuario => usuario.id), [usuarios]);

  useEffect(() => {
    form.resetFields();
    setImmediate(() => document.querySelector('#id').focus());
  }, []);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.post(`/consultivo/areas/${areaId}/usuarios`, values), form)
      .then(data => {
        message.success(t('Registro incluído com sucesso'));
        history.replace(`/consultivo/areas/${areaId}/usuarios`);
        dispatch(fetchUsuarios({ areaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/areas/${areaId}/usuarios`);
  };

  const title = (
    <ActionBar>
      <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
      <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
    </ActionBar>);

  return (
    <MiniForm form={form} loading={loadingSave} onFinish={handleFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="id" label={t('Usuário')}>
            <SelectConsultivoUsuario filter={usuario => !usuarioIds.includes(usuario.id)}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}
