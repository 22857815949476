import {mod} from './number';

export function formatCnpj(cnpj) {
  cnpj = cnpj || '';
  if (cnpj.length === 14) {
    return (
        cnpj.substring(0, 2) + '.' +
        cnpj.substring(2, 5) + '.' +
        cnpj.substring(5, 8) + '/' +
        cnpj.substring(8, 12) + '-' +
        cnpj.substring(12, 14));
  }
  return cnpj;
}

export function parseCnpj(cnpj) {
  return cnpj.replace(/\D+/g, '');
}

export function isValidCnpj(cnpj) {
  if (!cnpj || cnpj.length !== 14)
    return false;
  let sum1 = 0;
  let sum2 = 0;
  for (let i = 0; i < 12; i++) {
    const digit = parseInt(cnpj[i]);
    sum1 += (mod(3 - i, 8) + 2) * digit;
    sum2 += (mod(4 - i, 8) + 2) * digit;
  }
  let dv1 = 11 - (sum1 % 11);
  if (dv1 >= 10) dv1 = 0;
  sum2 += 2 * dv1;
  let dv2 = 11 - (sum2 % 11);
  if (dv2 >= 10) dv2 = 0;
  return parseInt(cnpj[12]) === dv1 && parseInt(cnpj[13]) === dv2;
}
