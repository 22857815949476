import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Alerta, EmpresaTabByName } from '../constants';

const initialState = {
  comparativoEmpresas: [],
  sucessos: 0,
  avisos: 0,
  erros: 0,
  total: 0,
  proximoAviso: null,
  proximoErro: null,
  numerosDestacados: [],
};

export const comparativoEmpresasSelector = state => state.societarioDashboard.comparativoEmpresas;
export const sucessosSelector = state => state.societarioDashboard.sucessos;
export const avisosSelector = state => state.societarioDashboard.avisos;
export const errosSelector = state => state.societarioDashboard.erros;
export const totalSelector = state => state.societarioDashboard.total;
export const proximoAvisoSelector = state => state.societarioDashboard.proximoAviso;
export const proximoErroSelector = state => state.societarioDashboard.proximoErro;
export const numerosDestacadosSelector = state => state.societarioDashboard.numerosDestacados;

export const fetchNumerosDestacados = createAsyncThunk(
  'societarioDashboard/fetchNumerosDestacados',
  async ({ empresas }, thunkAPI) => {
    try {
      return await Promise.all(empresas.map(empresa =>
        Promise.all([
          axios.get(`/empresas/${empresa.id}/cadastro`),
          axios.get(`/empresas/${empresa.id}/pls/last`),
        ])
          .then(([{ data: cadastro }, { data: pl }]) => ({ ...empresa, cadastro, pl }))
          .catch(err => {
          })));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const slice = createSlice({
  name: 'societarioDashboard',
  initialState,
  reducers: {
    fetchDashboard(state, action) {
      state.comparativoEmpresas = [];
      state.sucessos = 0;
      state.avisos = 0;
      state.erros = 0;
      state.proximoAviso = null;
      state.proximoErro = null;
      action.payload.empresas.forEach(empresa => {
        let sucessos = 0;
        let avisos = 0;
        let erros = 0;
        action.payload.alertasByEmpresaId[empresa.id]
          ?.filter(aba => EmpresaTabByName[aba.nome]?.modulos?.includes('societario') === true)
          ?.forEach(aba => {
            switch (aba.nivel) {
              default:
                sucessos++;
                break;
              case Alerta.Nivel.Aviso.id:
                avisos++;
                break;
              case Alerta.Nivel.Erro.id:
                erros++;
                break;
            }
            aba.alertas.forEach(alerta => {
              switch (alerta.nivel) {
                case Alerta.Nivel.Aviso.id:
                  if (!state.proximoAviso || alerta.dataVencimento.localeCompare(state.proximoAviso.dataVencimento) < 0)
                    state.proximoAviso = { ...empresa, aba: aba.nome, dataVencimento: alerta.dataVencimento };
                  break;
                case Alerta.Nivel.Erro.id:
                  if (!state.proximoErro || alerta.dataVencimento.localeCompare(state.proximoErro.dataVencimento) < 0)
                    state.proximoErro = { ...empresa, aba: aba.nome, dataVencimento: alerta.dataVencimento };
                  break;
              }
            });
          });
        state.comparativoEmpresas.push({ ...empresa, sucessos, avisos, erros });
        state.sucessos += sucessos;
        state.avisos += avisos;
        state.erros += erros;
      });
      state.total = state.sucessos + state.avisos + state.erros;
    },
  },
  extraReducers: {
    [fetchNumerosDestacados.fulfilled]: (state, action) => {
      state.numerosDestacados = [...action.payload]
        .sort((a, b) => {
          const comp = [
            (b.cadastro.capitalSocialSubscrito || 0) - (a.cadastro.capitalSocialSubscrito || 0),
            (b.pl.valorReal || 0) - (a.pl.valorReal || 0),
            (b.pl.dataReferencia || '').localeCompare(a.pl.dataReferencia || ''),
            (a.apelido || a.razaoSocial).localeCompare(b.apelido || b.razaoSocial),
          ];
          return comp.find(value => value !== 0) || 0;
        });
    },
  },
});

export default slice.reducer;

export const { fetchDashboard } = slice.actions;
