import PropTypes from 'prop-types';
import React from 'react';
import styles from './_styles.less';

export default function Logo({src, width, height, background}) {
  const w = width + 'px';
  const h = height + 'px';

  return (
      <div
          className={styles['logo']}
          style={{width: w, height: h}}
      >
        {src &&
        <img
            alt="logo"
            src={src}
            style={{maxWidth: w, maxHeight: h, background}}
        />
        }
      </div>
  );
}

Logo.defaultProps = {
  width: 32,
  height: 32,
};

Logo.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
