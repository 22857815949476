import { message, Space } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import MiniTable from '../../../../components/mini-table';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import { fetchUsuario, fetchUsuarios, usuariosSelector } from '../../../../store/consultivoArea';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import { openPopup } from '../../../../util/window';
import styles from '../_styles.less';
import ConsultivoAreaUsuariosTable from './_table';
import { isGestorSelector } from "../../../../store/autenticacao";
import { MinusOutlined } from "@ant-design/icons";

export default function ConsultivoAreaUsuariosDetailPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isEditable = useSelector(isGestorSelector);
  const usuarios = useSelector(usuariosSelector);
  const loading = useLoading(fetchUsuario.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleAdd = () => {
    history.replace(`/consultivo/areas/${areaId}/usuarios/add`);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete(`/consultivo/areas/${areaId}/usuarios`, { params: { ids: selectedIds } }))
      .then(() => {
        message.success(t('Registro(s) removido(s) com sucesso'));
        setSelectedIds([]);
        dispatch(fetchUsuarios({ areaId }));
      })
      .finally(() => setLoadingRemove(false));
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/consultivo/areas/${areaId}/usuarios/`, `logs_/consultivo/areas/${areaId}/usuarios/`);
  };

  const actions = [];
  if (isEditable) {
    actions.push(<ActionButton.Create title={t('Adicionar')} onClick={handleAdd}/>);
    actions.push(<ActionButton.Remove
      title={t('Remover')}
      icon={<MinusOutlined/>}
      disabled={selectedIds.length === 0}
      onClick={handleRemove}
      loading={loadingRemove}
    />);
  }
  actions.push(<ActionButton.Logs noTitle onClick={handleLogs}/>);
  actions.push(<MiniTable.DownloadButton disabled={loading || usuarios.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar>{actions}</ActionBar>;
  return (
    <Space direction="vertical" className={styles['space']}>
      <ConsultivoAreaUsuariosTable
        dataSource={usuarios}
        loading={loading}
        miniTableRef={miniTableRef}
        onSelectedIdsChange={setSelectedIds}
        selectedIds={selectedIds}
        title={title}
      />
    </Space>);
}

ConsultivoAreaUsuariosDetailPage.propTypes = {
  usuarios: PropTypes.array,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
};
