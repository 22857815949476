import AuthenticatedPopupLayout from "../../../layouts/authenticated-popup";
import styles from './_styles.less';
import { Space, Tabs } from "antd";
import { useHistory, useRouteMatch } from "react-router";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { cadastroSelector, fetchAssuntos, fetchCadastro, fetchTiposTrabalho, fetchUsuarios } from "../../../store/consultivoArea";
import CardTabs from "../../../components/card-tabs";
import ConsultivoAreaCadastroPage from "./cadastro";
import { useTranslation } from "react-i18next";
import ConsultivoAreaUsuariosPage from "./usuarios";
import ConsultivoAreaTiposTrabalhoPage from "./tipos-trabalho";
import ConsultivoAreaAssuntosPage from "./assuntos";
import AreaCabecalho from "./cabecalho";

export default function ConsultivoAreaPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const areaId = match.params.id;
  const activeTab = match.params.tab;
  const cadastro = useSelector(cadastroSelector);

  useEffect(() => {
    if (cadastro.caminho)
      document.title = 'Legal Panel - ' + cadastro.caminho;
  }, [cadastro]);

  useEffect(() => {
    dispatch(fetchCadastro({ areaId }));
    dispatch(fetchUsuarios({ areaId }));
    dispatch(fetchTiposTrabalho({ areaId }));
    dispatch(fetchAssuntos({ areaId }));
  }, [areaId]);

  const handleTabChange = activeKey => {
    history.replace(`/consultivo/areas/${areaId}/${activeKey}`);
  };

  return (
    <AuthenticatedPopupLayout menu={<AreaCabecalho/>}>
      <Space direction="vertical" className={styles['space']}>
        <CardTabs activeKey={activeTab} onChange={handleTabChange}>
          <Tabs.TabPane key="cadastro" tab={t('Cadastro')}>
            <ConsultivoAreaCadastroPage/>
          </Tabs.TabPane>
          <Tabs.TabPane key="usuarios" tab={t('Usuários')}>
            <ConsultivoAreaUsuariosPage/>
          </Tabs.TabPane>
          <Tabs.TabPane key="tipos-trabalho" tab={t('Tipos de Trabalho')}>
            <ConsultivoAreaTiposTrabalhoPage/>
          </Tabs.TabPane>
          <Tabs.TabPane key="assuntos" tab={t('Assuntos')}>
            <ConsultivoAreaAssuntosPage/>
          </Tabs.TabPane>
        </CardTabs>
      </Space>
    </AuthenticatedPopupLayout>
  )
}
