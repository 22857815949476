import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchProcurador} from '../../../../store/empresa';
import EmpresaProcuradorCreatePage from './_create';
import EmpresaProcuradorDetailPage from './_detail';
import EmpresaProcuradorEditPage from './_edit';

export default function EmpresaProcuradorPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchProcurador({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/procuradores/:id/edit" component={EmpresaProcuradorEditPage} />
        <Route path="/*/empresas/:empresaId/procuradores/create" component={EmpresaProcuradorCreatePage} />
        <Route path="/*/empresas/:empresaId/procuradores/:id" component={EmpresaProcuradorDetailPage} />
      </Switch>);
}
