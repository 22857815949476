import {CheckOutlined, CloseOutlined, LineOutlined, MailOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import React, {useMemo} from 'react';
import {isValidEmail, parseEmail} from '../../util/email';

export default function InputEmail({onChange, value, ...props}) {
  const isValid = useMemo(() => isValidEmail(value), [value]);

  const fireChange = newValue => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(newValue);
  };

  const handleBlur = e => fireChange(e.target.value);
  const handleChange = e => fireChange(parseEmail(e.target.value));
  const handlePaste = e => {
    e.preventDefault();
    fireChange(parseEmail(e.clipboardData.getData('text')));
  };

  const suffix = !value ?
      <LineOutlined /> :
      isValid ?
          <CheckOutlined className="success" /> :
          <CloseOutlined className="error" />;

  return (
      <Input
          prefix={<MailOutlined />}
          maxLength={100}
          onBlur={handleBlur}
          onChange={handleChange}
          onPaste={handlePaste}
          suffix={suffix}
          value={value}
          {...props}
      />
  );
}
