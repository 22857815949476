import {LoginOutlined} from '@ant-design/icons';
import {Alert, Button, Form} from 'antd';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import InputEmail from '../components/input-email';
import InputPassword from '../components/input-password';
import Link from '../components/link';
import useDispatchWithResult from '../hooks/useDispatchWithResult';
import useLoading from '../hooks/useLoading';
import useQuery from '../hooks/useQuery';
import CardLayout from '../layouts/card';
import {unwrapResult} from '../store';
import {autenticar, redefinirSenha} from '../store/autenticacao';
import {decodeBase64String} from '../util/base64';
import styles from './_styles.less';

const formLayout = {
  layout: 'horizontal',
  labelCol: {flex: '140px'},
  wrapperCol: {flex: 'auto'},
};

const buttonStyle = {paddingLeft: '140px'};

function ButtonSubmit(props) {
  const {t} = useTranslation();
  return (
      <Button
          size="middle"
          icon={<LoginOutlined />}
          type="primary"
          htmlType="submit"
          {...props}
      >
        {t('Alterar Senha')}
      </Button>
  );
}

export default function RedefinirSenhaPage() {
  const [error, setError] = useState(false);
  const dispatch = useDispatchWithResult();
  const history = useHistory();
  const loading = useLoading(autenticar.typePrefix);
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const query = useQuery();
  const passwordToken = decodeBase64String(query.get('password-token'));
  const email = decodeBase64String(query.get('email'));

  const handleFinish = values => {
    const {novaSenha} = values;
    dispatch(redefinirSenha({passwordToken, email, novaSenha}), form)
        .then(unwrapResult)
        .then(() => history.push('/autenticar'))
        .catch(err => setError(err.mensagem));
  };

  const alert = error ?
      <Alert showIcon message={error} type="error" className={styles['alert']} /> :
      <Alert showIcon message={t('Alterar Senha')} type='info' className={styles['alert']} />;

  return (
      <CardLayout width={540}>
        <Form
            form={form}
            onFinish={handleFinish}
            size="middle"
            {...formLayout}
        >
          {alert}
          <Form.Item name="email" label={t('E-mail')} initialValue={email}>
            <InputEmail autoFocus disabled />
          </Form.Item>
          <Form.Item name="novaSenha" label={t('Nova Senha')}>
            <InputPassword autoFocus disabled={loading} />
          </Form.Item>
          <Form.Item name="confirmarSenha" label={t('Confirmar Senha')}>
            <InputPassword disabled={loading} />
          </Form.Item>
          <Form.Item style={buttonStyle}>
            <ButtonSubmit loading={loading} />
            <span className={styles['link']}>
        <Link to="/autenticar" disabled={loading}>{t('voltar')}</Link>
        </span>
          </Form.Item>
        </Form>
      </CardLayout>
  );
};
