import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Consulta} from '../../constants';

const {Prioridade} = Consulta;

export default function SelectConsultivoConsultaPrioridade(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(Prioridade.Alta)}
      {createOption(Prioridade.Media)}
      {createOption(Prioridade.Baixa)}
    </Select>
  );
}

SelectConsultivoConsultaPrioridade.propTypes = {
  value: PropTypes.oneOf([
    Prioridade.Alta.id,
    Prioridade.Media.id,
    Prioridade.Baixa.id,
  ]),
  onChange: PropTypes.func,
};
