import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaDEFsDetailPage from './_detail';
import EmpresaDEFPage from './def';

export default function EmpresaDEFsPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/defs/:id" component={EmpresaDEFPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaDEFsDetailPage} />
        </Switch>
      </Card>);
}
