import { Button, Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import styles from './_WorkflowButton.less';

export default function WorkflowButton({ danger, disabled, noTitle, loading, onClick, title, ...props }) {
  const handleClick = e => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  const button = (
    <Button
      className={styles['workflow-button']}
      danger={danger}
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      type="link"
      children={noTitle ? undefined : title}
      {...props}
    />
  );

  if (noTitle)
    return (
      <Tooltip title={title}>
        {button}
      </Tooltip>);

  return button;
}

WorkflowButton.defaultProps = {
  danger: false,
  disabled: false,
  loading: false,
  noTitle: false,
};

WorkflowButton.propTypes = {
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};