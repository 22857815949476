import { CheckCircleFilled, LoadingOutlined, MinusCircleFilled, StopFilled, WarningFilled } from '@ant-design/icons';
import { Space, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import CardTabs from '../../components/card-tabs';
import { Alerta } from '../../constants';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useLoading from '../../hooks/useLoading';
import AuthenticatedPopupLayout from '../../layouts/authenticated-popup';
import {
  administradoresAlertaNivelSelector,
  beneficiariosEmpresaAlertaNivelSelector,
  beneficiariosSocioAlertaNivelSelector,
  cabecalhoSelector,
  cadastroAlertaNivelSelector,
  cbesAlertaNivelSelector,
  certificadosAlertaNivelSelector,
  contasAlertaNivelSelector,
  contratosAlertaNivelSelector,
  defsAlertaNivelSelector,
  documentosAlertaNivelSelector,
  fetchAdministradores,
  fetchBeneficiariosEmpresa,
  fetchBeneficiariosSocio,
  fetchCabecalho,
  fetchCadastro,
  fetchCBEs,
  fetchCCEs,
  fetchCertificados,
  fetchContas,
  fetchContratos,
  fetchDEFs,
  fetchDocumentos,
  fetchLicencas,
  fetchLivrosDigitais,
  fetchPLs,
  fetchProcuradores,
  fetchRDEIEDs,
  fetchRepresentantes,
  fetchSocios,
  fetchUnidades,
  licencasAlertaNivelSelector,
  livrosDigitaisAlertaNivelSelector,
  plsAlertaNivelSelector,
  procuradoresAlertaNivelSelector,
  rdeiedsAlertaNivelSelector,
  representantesAlertaNivelSelector,
  sociosAlertaNivelSelector,
  unidadesAlertaNivelSelector,
} from '../../store/empresa';
import { formatCnpj } from '../../util/cnpj';
import styles from './_styles.less';
import EmpresaAdministradoresPage from './administradores';
import EmpresaBeneficiariosEmpresaPage from './beneficiarios-empresa';
import EmpresaBeneficiariosSocioPage from './beneficiarios-socio';
import EmpresaCabecalho from './cabecalho';
import EmpresaCadastroPage from './cadastro';
import EmpresaCBEsPage from './cbes';
import EmpresaContasPage from './contas';
import EmpresaContratosPage from './contratos';
import EmpresaDEFsPage from './defs';
import EmpresaPLsPage from './pls';
import EmpresaProcuradoresPage from './procuradores';
import EmpresaRDEIEDsPage from './rde-ieds';
import EmpresaRepresentantesPage from './representantes';
import EmpresaSociosPage from './socios';
import EmpresaUnidadesPage from './unidades';
import { funcoesSelector } from "../../store/autenticacao";
import EmpresaLicencasPage from "./licencas";
import EmpresaLivrosDigitaisPage from "./livros-digitais";
import useModulo from "../../hooks/useModulo";
import EmpresaCertificadosPage from "./certificados";
import EmpresaDocumentosPage from "./documentos";

const iconStyle = { marginRight: '12px' };

function getNivelIcon(nivel) {
  switch (nivel) {
    default:
      return <CheckCircleFilled className="primary" style={iconStyle}/>;
    case Alerta.Nivel.Aviso.id:
      return <WarningFilled className="warning" style={iconStyle}/>;
    case Alerta.Nivel.Erro.id:
      return <MinusCircleFilled className="error" style={iconStyle}/>;
    case Alerta.Nivel.NA.id:
      return <StopFilled className="inactive" style={iconStyle}/>;
  }
}

function TabAlerta({ nome, nivelSelector, thunkAction }) {
  const { t } = useTranslation();
  const nivel = useSelector(nivelSelector);
  const loading = useLoading(thunkAction.typePrefix);

  const icon = loading ? <LoadingOutlined style={iconStyle}/> : getNivelIcon(nivel);

  return (
    <>
      {icon}
      {t(nome)}
    </>
  );
}

export default function EmpresasPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.id;
  const activeTab = match.params.tab;
  const { moduloAtual, moduloBasePath } = useModulo();
  const funcoes = useSelector(funcoesSelector);
  const cabecalho = useSelector(cabecalhoSelector);
  const acesso = cabecalho?.acesso;

  useEffect(() => {
    if (cabecalho.cnpj)
      document.title = 'Legal Panel - ' + (cabecalho.apelido || cabecalho.razaoSocial) + ' (' + formatCnpj(cabecalho.cnpj) + ')';
  }, [cabecalho]);

  useEffect(() => {
    dispatch(fetchCabecalho({ empresaId }));
    dispatch(fetchCadastro({ empresaId }));
    dispatch(fetchUnidades({ empresaId }));
    dispatch(fetchCertificados({ empresaId }));
    if (moduloAtual === 'societario') {
      dispatch(fetchSocios({ empresaId }));
      dispatch(fetchContratos({ empresaId }));
      dispatch(fetchPLs({ empresaId }));
      dispatch(fetchAdministradores({ empresaId }));
      dispatch(fetchProcuradores({ empresaId }));
      dispatch(fetchContas({ empresaId }));
      dispatch(fetchBeneficiariosEmpresa({ empresaId }));
      dispatch(fetchRepresentantes({ empresaId }));
      dispatch(fetchBeneficiariosSocio({ empresaId }));
      dispatch(fetchRDEIEDs({ empresaId }));
      dispatch(fetchDEFs({ empresaId }));
      dispatch(fetchCCEs({ empresaId }));
      dispatch(fetchCBEs({ empresaId }));
      dispatch(fetchLivrosDigitais({ empresaId }));
    }
    if (moduloAtual === 'regulatorio')
      dispatch(fetchLicencas({ empresaId }));
    if (moduloAtual === 'compliance')
      dispatch(fetchDocumentos({ empresaId }));
  }, [empresaId, moduloAtual]);

  const handleTabChange = activeKey => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/${activeKey}`);
  };

  return (
    <AuthenticatedPopupLayout menu={<EmpresaCabecalho/>}>
      <Space direction="vertical" className={styles['space']}>
        <CardTabs activeKey={activeTab} onChange={handleTabChange}>
          {funcoes?.includes('cadastro') && acesso?.funcoes?.includes('cadastro') &&
            <Tabs.TabPane
              key="cadastro"
              tab={<TabAlerta nome="Cadastro" nivelSelector={cadastroAlertaNivelSelector} thunkAction={fetchCadastro}/>}
            >
              <EmpresaCadastroPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('unidades') && acesso?.funcoes?.includes('unidades') &&
            <Tabs.TabPane
              key="unidades"
              tab={<TabAlerta nome="Unidades" nivelSelector={unidadesAlertaNivelSelector} thunkAction={fetchUnidades}/>}
            >
              <EmpresaUnidadesPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('certificados') && acesso?.funcoes?.includes('certificados') &&
            <Tabs.TabPane
              key="certificados"
              tab={<TabAlerta nome="Certificados" nivelSelector={certificadosAlertaNivelSelector} thunkAction={fetchCertificados}/>}
            >
              <EmpresaCertificadosPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('licencas') && acesso?.funcoes?.includes('licencas') && moduloAtual === 'regulatorio' &&
            <Tabs.TabPane
              key="licencas"
              tab={<TabAlerta nome="Licenças" nivelSelector={licencasAlertaNivelSelector} thunkAction={fetchLicencas}/>}
            >
              <EmpresaLicencasPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('documentos') && acesso?.funcoes?.includes('documentos') && moduloAtual === 'compliance' &&
            <Tabs.TabPane
              key="documentos"
              tab={<TabAlerta nome="Documentos" nivelSelector={documentosAlertaNivelSelector} thunkAction={fetchDocumentos}/>}
            >
              <EmpresaDocumentosPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('contratos') && acesso?.funcoes?.includes('contratos') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="contratos"
              tab={<TabAlerta nome="Atos Societários" nivelSelector={contratosAlertaNivelSelector} thunkAction={fetchContratos}/>}
            >
              <EmpresaContratosPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('livros-digitais') && acesso?.funcoes?.includes('livros-digitais') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="livros-digitais"
              tab={<TabAlerta nome="Livros Digitais" nivelSelector={livrosDigitaisAlertaNivelSelector} thunkAction={fetchLivrosDigitais}/>}
            >
              <EmpresaLivrosDigitaisPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('socios') && acesso?.funcoes?.includes('socios') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="socios"
              tab={<TabAlerta nome="Sócios" nivelSelector={sociosAlertaNivelSelector} thunkAction={fetchSocios}/>}
            >
              <EmpresaSociosPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('administradores') && acesso?.funcoes?.includes('administradores') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="administradores"
              tab={<TabAlerta nome="Administradores" nivelSelector={administradoresAlertaNivelSelector} thunkAction={fetchAdministradores}/>}
            >
              <EmpresaAdministradoresPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('procuradores') && acesso?.funcoes?.includes('procuradores') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="procuradores"
              tab={<TabAlerta nome="Procuradores" nivelSelector={procuradoresAlertaNivelSelector} thunkAction={fetchProcuradores}/>}
            >
              <EmpresaProcuradoresPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('representantes') && acesso?.funcoes?.includes('representantes') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="representantes"
              tab={<TabAlerta nome="Representantes Legais" nivelSelector={representantesAlertaNivelSelector} thunkAction={fetchRepresentantes}/>}
            >
              <EmpresaRepresentantesPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('beneficiarios-empresa') && acesso?.funcoes?.includes('beneficiarios-empresa') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="beneficiarios-empresa"
              tab={<TabAlerta nome="Beneficiários Finais da Empresa" nivelSelector={beneficiariosEmpresaAlertaNivelSelector} thunkAction={fetchBeneficiariosEmpresa}/>}
            >
              <EmpresaBeneficiariosEmpresaPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('beneficiarios-socio') && acesso?.funcoes?.includes('beneficiarios-socio') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="beneficiarios-socio"
              tab={<TabAlerta nome="Beneficiários Finais do Sócio" nivelSelector={beneficiariosSocioAlertaNivelSelector} thunkAction={fetchBeneficiariosSocio}/>}
            >
              <EmpresaBeneficiariosSocioPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('pls') && acesso?.funcoes?.includes('pls') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="pls"
              tab={<TabAlerta nome="Demonstrações Financeiras" nivelSelector={plsAlertaNivelSelector} thunkAction={fetchPLs}/>}
            >
              <EmpresaPLsPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('contas') && acesso?.funcoes?.includes('contas') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="contas"
              tab={<TabAlerta nome="Aprovação de Contas Anual" nivelSelector={contasAlertaNivelSelector} thunkAction={fetchContas}/>}
            >
              <EmpresaContasPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('rde-ieds') && acesso?.funcoes?.includes('rde-ieds') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="rde-ieds"
              tab={<TabAlerta nome="Operações de Câmbio para Investimento Estrangeiro Direto" nivelSelector={rdeiedsAlertaNivelSelector} thunkAction={fetchRDEIEDs}/>}
            >
              <EmpresaRDEIEDsPage/>
            </Tabs.TabPane>
          }
          {funcoes?.includes('defs') && acesso?.funcoes?.includes('defs') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="defs"
              tab={<TabAlerta nome="Declarações Bacen" nivelSelector={defsAlertaNivelSelector} thunkAction={fetchDEFs}/>}
            >
              <EmpresaDEFsPage/>
            </Tabs.TabPane>
          }
          {/*{funcoes?.includes('cces') && acesso?.funcoes?.includes('cces') && moduloAtual === 'societario &&*/}
          {/*  <Tabs.TabPane*/}
          {/*    key="cces"*/}
          {/*    tab={<TabAlerta nome="Censo de Capital Estrangeiro no País" nivelSelector={ccesAlertaNivelSelector} thunkAction={fetchCCEs}/>}*/}
          {/*  >*/}
          {/*    <EmpresaCCEsPage/>*/}
          {/*  </Tabs.TabPane>*/}
          {/*}*/}
          {funcoes?.includes('cbes') && acesso?.funcoes?.includes('cbes') && moduloAtual === 'societario' &&
            <Tabs.TabPane
              key="cbes"
              tab={<TabAlerta nome="Capital Brasileiro no Exterior" nivelSelector={cbesAlertaNivelSelector} thunkAction={fetchCBEs}/>}
            >
              <EmpresaCBEsPage/>
            </Tabs.TabPane>
          }
        </CardTabs>
      </Space>
    </AuthenticatedPopupLayout>);
}
