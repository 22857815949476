import React from 'react';
import {useSelector} from 'react-redux';
import {Empresa} from '../../../../../constants';
import {cabecalhoSelector} from '../../../../../store/empresa';
import EmpresaSocioDetailPage1 from './_detail_1';
import EmpresaSocioDetailPage2 from './_detail_2';
import EmpresaSocioDetailPage3 from './_detail_3';
import EmpresaSocioDetailPage4 from './_detail_4';
import EmpresaSocioDetailPage5 from './_detail_5';
import EmpresaSocioDetailPage6 from './_detail_6';
import EmpresaSocioDetailPage7 from './_detail_7';
import EmpresaSocioDetailPage8 from './_detail_8';

export default function EmpresaSocioDetailPage() {
  const cabecalho = useSelector(cabecalhoSelector);

  switch (cabecalho?.tipo) {
    default:
    case Empresa.Tipo.SociedadeLimitada.id:
    case Empresa.Tipo.SociedadeSimples.id:
    case Empresa.Tipo.Cooperativa.id:
      return <EmpresaSocioDetailPage1 />;
    case Empresa.Tipo.SociedadePorAcoes.id:
      return <EmpresaSocioDetailPage2 />;
    case Empresa.Tipo.Eireli.id:
      return <EmpresaSocioDetailPage3 />;
    case Empresa.Tipo.EmpresarioIndividual.id:
      return <EmpresaSocioDetailPage4 />;
    case Empresa.Tipo.Associacao.id:
    case Empresa.Tipo.Fundacao.id:
      return <EmpresaSocioDetailPage5 />;
    case Empresa.Tipo.SociedadeEmContaDeParticipacao.id:
      return <EmpresaSocioDetailPage6 />;
    case Empresa.Tipo.FundoDeInvestimento.id:
      return <EmpresaSocioDetailPage7 />;
    case Empresa.Tipo.EntidadeEstrangeira.id:
      return <EmpresaSocioDetailPage8 />;
  }
}
