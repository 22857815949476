import React from 'react';
import ReactDOM from 'react-dom';
import { createApi } from './api';
import { App } from './App';
import { createHistory } from './history';
import { createI18n } from './i18n';
import { createStore } from './store';

const history = createHistory(false);
const store = createStore(false);
const i18n = createI18n(false, store);
const api = createApi(false, store, i18n);

ReactDOM.render(
  <App
    store={store}
    history={history}
    i18n={i18n}
    api={api}
  />,
  document.getElementById('root'));
