import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AcessoMenu from '../../../components/acesso-menu';
import FlexCard from '../../../components/flex-card';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import AuthenticatedLayout from '../../../layouts/authenticated';
import { acessoSelector } from '../../../store/acessos';
import { isOperadorSelector } from '../../../store/autenticacao';
import { alertasByEmpresaIdSelector, empresasFiltradasSelector, fetchEmpresas } from '../../../store/empresas';
import { fetchTabela } from '../../../store/regulatorioTabela';
import EmpresasTable from './_EmpresasTable';
import PesquisaMenu from "../../../components/pesquisa-menu";
import RegulatorioMenu from "../../../components/regulatorio-menu";

export default function RegulatorioTabelaPage() {
  const isOperador = useSelector(isOperadorSelector);
  const acesso = useSelector(acessoSelector);
  const empresas = useSelector(empresasFiltradasSelector);
  const alertasByEmpresaId = useSelector(alertasByEmpresaIdSelector);
  const dispatch = useDispatchWithResult();
  const acessoId = acesso?.id;

  useEffect(() => {
    dispatch(fetchEmpresas({ acessoId }));
  }, [acessoId]);

  useEffect(() => {
    dispatch(fetchTabela({ empresas, alertasByEmpresaId }));
  }, [empresas, alertasByEmpresaId]);

  return (
    <AuthenticatedLayout
      modulo="regulatorio"
      menu={
        <>
          <AcessoMenu defaultRoute="/regulatorio/tabela" fetchEmpresas/>
          {!!acesso && <PesquisaMenu/>}
          <RegulatorioMenu showCreate={isOperador} showFilter/>
        </>
      }
    >
      {acesso &&
        <FlexCard>
          <EmpresasTable/>
        </FlexCard>
      }
    </AuthenticatedLayout>
  );
}
