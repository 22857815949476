import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import MiniForm from '../../../../components/mini-form';

export default function EmpresaDEFForm({ disabled, disabledDataReferencia, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="dataReferencia" label={t('Data Base')}>
            <InputDate disabled={disabled || disabledDataReferencia}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="dataApresentacao" label={t('Data da Apresentação')}>
            <InputDate disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexo" label={t('Anexo')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="obs" label={t('Observação')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

EmpresaDEFForm.propTypes = {
  disabled: PropTypes.bool,
  disabledDataReferencia: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
