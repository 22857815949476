import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../../hooks/useDispatchWithResult';
import {fetchTipoTrabalho} from '../../../../../store/consultivoArea';
import ConsultivoAreaTipoTrabalhoCreatePage from './_create';
import ConsultivoAreaTipoTrabalhoDetailPage from './_detail';
import ConsultivoAreaTipoTrabalhoEditPage from './_edit';

export default function ConsultivoAreaTipoTrabalhoPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const areaId = match.params.areaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchTipoTrabalho({areaId, id}));
  }, [areaId, id]);

  return (
      <Switch>
        <Route path="/consultivo/areas/:areaId/tipos-trabalho/:id/edit" component={ConsultivoAreaTipoTrabalhoEditPage} />
        <Route path="/consultivo/areas/:areaId/tipos-trabalho/create" component={ConsultivoAreaTipoTrabalhoCreatePage} />
        <Route path="/consultivo/areas/:areaId/tipos-trabalho/:id" component={ConsultivoAreaTipoTrabalhoDetailPage} />
      </Switch>);
}
