import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaBeneficiariosSocioDetailPage from './_detail';
import EmpresaBeneficiarioSocioPage from './beneficiario-socio';

export default function EmpresaBeneficiariosSocioPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/beneficiarios-socio/:id" component={EmpresaBeneficiarioSocioPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaBeneficiariosSocioDetailPage} />
        </Switch>
      </Card>);
}
