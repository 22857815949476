import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmpresaAdministrador} from '../../constants';

const {Tipo} = EmpresaAdministrador;

export default function SelectEmpresaAdministradorTipo(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Tipo.Administrador)}
        {createOption(Tipo.Diretoria)}
        {createOption(Tipo.ConselhoConsultivo)}
        {createOption(Tipo.ConselhoDeAdministracao)}
        {createOption(Tipo.ConselhoFiscal)}
        {createOption(Tipo.Outros)}
      </Select>
  );
}

SelectEmpresaAdministradorTipo.propTypes = {
  value: PropTypes.oneOf([
    Tipo.Administrador.id,
    Tipo.Diretoria.id,
    Tipo.ConselhoConsultivo.id,
    Tipo.ConselhoDeAdministracao.id,
    Tipo.ConselhoFiscal.id,
    Tipo.Outros.id,
  ]),
  onChange: PropTypes.func,
};
