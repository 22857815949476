import React, { useRef } from 'react';
import styles from './_styles.less';
import { Breadcrumb, Col, Row, Spin } from "antd";
import MiniTable from "../../../components/mini-table";
import { useTranslation } from "react-i18next";
import useFormatter from "../../../hooks/useFormatter";
import { colapsarPainelDireito, colapsarPainelEsquerdo, consultasSelector, fetchConsultas, previewConsulta, previewSelector } from "../../../store/consultivoPesquisa";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import useLoading from "../../../hooks/useLoading";
import useConsultivo from "../../../hooks/useConsultivo";
import { useSelector } from "react-redux";
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

export default function ConsultasPanel() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const { formatDate } = useFormatter();
  const loading = useLoading(fetchConsultas.typePrefix);
  const { getAreaCaminho } = useConsultivo();
  const dispatch = useDispatchWithResult();
  const consultas = useSelector(consultasSelector);
  const consulta = useSelector(previewSelector);

  const columns = [
    {
      title: t('Título'),
      dataIndex: 'titulo',
    },
    {
      title: t('Descrição'),
      dataIndex: 'descricao',
      render: (text) => <TableCellEllipsis text={text} maxLength={50} style={{ whiteSpace: 'nowrap' }}/>
    },
    {
      title: t('Resposta'),
      dataIndex: 'resposta',
      render: (text) => <TableCellEllipsis text={text} maxLength={50} style={{ whiteSpace: 'nowrap' }}/>
    },
    {
      title: t('Tags'),
      dataIndex: 'tags',
      render: (text, record) => record.tags.join(', ')
    },
    {
      title: t('Área de Atuação'),
      dataIndex: ['area', 'id'],
      render: text => getAreaCaminho(text)
    },
    {
      title: t('Tipo de Trabalho'),
      dataIndex: ['tipoTrabalho', 'descricao'],
    },
    {
      title: t('Acesso'),
      dataIndex: ['empresa', 'acesso', 'nome'],
    },
    {
      title: t('Conclusão'),
      dataIndex: 'dataConclusao',
      render: (text) => formatDate(text)
    },
  ];

  const handleRowClick = consulta => {
    dispatch(colapsarPainelEsquerdo(true));
    dispatch(colapsarPainelDireito(false));
    dispatch(previewConsulta(consulta));
  };

  const title = () => {
    return (
      <Row gutter={[8, 8]} align="bottom" justify="end">
        <Col flex="1">
          <Breadcrumb>
            <Breadcrumb.Item>{t('Consultas')}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <MiniTable.DownloadButton disabled={loading || consultas.length === 0} miniTableRef={miniTableRef}/>
        </Col>
      </Row>);
  };

  return (
    <div className={styles.ConsultasPanel}>
      <Spin spinning={loading}>
        <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={consultas}
          pagination={false}
          rowClassName={record => record.id === consulta?.id ? styles.selectedRow : styles.row}
          onRow={(record, rowIndex) => ({ onClick: () => handleRowClick(record) })}
          title={title}
        />
      </Spin>
    </div>
  )
}
