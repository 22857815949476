import React from 'react';
import {useSelector} from 'react-redux';
import {Empresa} from '../../../../../constants';
import {cabecalhoSelector} from '../../../../../store/empresa';
import EmpresaContratoDetailPage1 from './_detail_1';
import EmpresaContratoDetailPage2 from './_detail_2';

export default function EmpresaContratoDetailPage() {
  const cabecalho = useSelector(cabecalhoSelector);

  switch (cabecalho?.tipo) {
    default:
    case Empresa.Tipo.SociedadeLimitada.id:
    case Empresa.Tipo.SociedadeSimples.id:
    case Empresa.Tipo.Cooperativa.id:
    case Empresa.Tipo.SociedadePorAcoes.id:
    case Empresa.Tipo.Eireli.id:
    case Empresa.Tipo.EmpresarioIndividual.id:
    case Empresa.Tipo.Associacao.id:
    case Empresa.Tipo.Fundacao.id:
    case Empresa.Tipo.SociedadeEmContaDeParticipacao.id:
    case Empresa.Tipo.FundoDeInvestimento.id:
      return <EmpresaContratoDetailPage1 />;
    case Empresa.Tipo.EntidadeEstrangeira.id:
      return <EmpresaContratoDetailPage2 />;
  }
}
