import { Menu, Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import useLoading from '../../../../hooks/useLoading';
import { cadastroSelector } from '../../../../store/consultivoArea';
import styles from '../_styles.less';

export default function AreaCabecalho() {
  const cadastro = useSelector(cadastroSelector);
  const loading = useLoading(cadastro.typePrefix);

  if (loading) {
    return (
      <Menu className={styles['area-menu']} theme="dark" mode="horizontal" selectable={false}>
        <Menu.Item>
          <Spin/>
        </Menu.Item>
      </Menu>);
  }

  return (
    <Menu className={styles['area-menu']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
      <Menu.Item>
        {cadastro?.caminho}
      </Menu.Item>
    </Menu>
  );
}
