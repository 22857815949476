import {CheckCircleFilled, MinusCircleFilled, StopFilled, WarningFilled} from '@ant-design/icons';
import {Alert, Col, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {Alerta, Alerta as AlertaConst} from '../../constants/index';

function getAlertClassName(nivel) {
  switch (nivel) {
    default:
      return 'ant-alert-success';
    case Alerta.Nivel.Aviso.id:
      return 'ant-alert-warning';
    case Alerta.Nivel.Erro.id:
      return 'ant-alert-error';
    case Alerta.Nivel.NA.id:
      return 'ant-alert-inactive';
  }
}

function getAlertIcon(nivel) {
  switch (nivel) {
    default:
      return <CheckCircleFilled className="primary" />;
    case Alerta.Nivel.Aviso.id:
      return <WarningFilled className="warning" />;
    case Alerta.Nivel.Erro.id:
      return <MinusCircleFilled className="error" />;
    case Alerta.Nivel.NA.id:
      return <StopFilled className="inactive" />;
  }
}

function getAlertType(nivel) {
  switch (nivel) {
    case AlertaConst.Nivel.Aviso.id:
      return 'warning';
    case AlertaConst.Nivel.Erro.id:
      return 'error';
    case AlertaConst.Nivel.NA.id:
      return 'inactive';
  }
}

function renderAlerta({nivel, mensagem}, index) {
  return (
      <Col span={24} key={index}>
        <Alert
            banner
            className={getAlertClassName(nivel)}
            icon={getAlertIcon(nivel)}
            message={mensagem}
            type={getAlertType(nivel)}
        />
      </Col>
  );
}

export default function Alertas({alertas}) {
  return (
      <Row gutter={[0, 8]}>
        {alertas.map(renderAlerta)}
      </Row>
  );
}

Alertas.propTypes = {
  alertas: PropTypes.array,
};
