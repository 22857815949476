import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './_SelectWorkflowUnidade.less';
import { useTranslation } from "react-i18next";
import useCollator from "../../../hooks/useCollator";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";
import axios from "axios";
import ActionBar from "../../../components/action-bar";
import ActionLink from "../../../components/action-link";
import { formatCnpj } from "../../../util/cnpj";
import { ExportOutlined } from "@ant-design/icons";
import { openPopupEmpresa } from "../../../util/window";

export default function SelectWorkflowUnidade(props) {
  const { workflowId, empresaId, unidade, onSubmit, onUnload, readOnly } = props;
  const { t } = useTranslation();
  const { compare, contains } = useCollator();
  const [form] = Form.useForm();
  const [edicao, setEdicao] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const apelido = searchValue.trim();

  const fireSubmit = unidade => {
    let promise = Promise.resolve();
    if (typeof onSubmit === 'function')
      promise = promise.then(() => onSubmit(unidade));
    return promise;
  }

  const handleClick = e => {
    if (readOnly)
      return;
    e.preventDefault();
    form.resetFields();
    setEdicao(true);
    setSearchValue('');
  }

  const handleFinish = values => {
    fireSubmit(values.unidadeId).then(() => setEdicao(false));
  }

  const handleOpenClick = () => {
    openPopupEmpresa({ id: empresaId }, 'unidades/' + unidade?.id, { onUnload })
  }

  useEffect(() => {
    if (!empresaId) {
      setUnidades([]);
      return;
    }
    setLoading(true);
    unwrapAxiosPromise(axios.get(`/workflows/${workflowId}/empresas/${empresaId}/unidades`))
      .then(data => setUnidades(data))
      .finally(() => setLoading(false))
  }, [workflowId, empresaId]);

  const unidadesFiltradas = useMemo(() =>
      unidades
        .filter(unidade => contains(unidade.cnpj, apelido) || contains(unidade.apelido, apelido))
        .sort((a, b) => compare(a.apelido, b.apelido))
        .slice(0, 50),
    [contains, unidades, apelido]);


  if (edicao) {
    return (
      <Form form={form} onFinish={handleFinish} size="middle">
        <Form.Item name="unidadeId" initialValue={unidade?.id} noStyle>
          <Select
            allowClear
            autoFocus
            className={styles['select']}
            filterOption={false}
            loading={loading}
            placeholder={t('Unidade')}
            searchValue={searchValue}
            onSearch={setSearchValue}
            showSearch
          >
            {unidadesFiltradas.map(unidade => (
              <Select.Option key={unidade.id} value={unidade.id}>
                {formatCnpj(unidade.cnpj)} - {unidade.apelido}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ActionBar>
          <ActionLink.Save onClick={form.submit}/>
          <ActionLink.Cancel onClick={() => setEdicao(false)}/>
        </ActionBar>
      </Form>
    );
  }

  let className = styles['workflow-tarefa-unidade'];
  if (!unidade)
    className += ' ' + styles['nao-preenchido'];
  if (readOnly)
    className += ' ' + styles['read-only'];

  return (
    <div className={className} onClick={handleClick}>
      <div className={styles['descricao']}>
        {!!unidade ? formatCnpj(unidade.cnpj) + ' - ' + unidade.apelido : t('(não preenchido)')}
      </div>
      {!!unidade &&
        <div>
          <ActionBar>
            <ActionLink icon={<ExportOutlined/>} title={t('Abrir Unidade')} noTitle onClick={handleOpenClick}/>
          </ActionBar>
        </div>
      }
    </div>
  );
}

SelectWorkflowUnidade.propTypes = {
  workflowId: PropTypes.number,
  empresaId: PropTypes.number,
  unidade: PropTypes.shape({
    id: PropTypes.number,
    tipo: PropTypes.number,
    cnpj: PropTypes.string,
    apelido: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onUnload: PropTypes.func,
  readOnly: PropTypes.bool,
};
