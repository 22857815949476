import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchBeneficiarioSocio} from '../../../../store/empresa';
import EmpresaBeneficiarioSocioCreatePage from './_create';
import EmpresaBeneficiarioSocioDetailPage from './_detail';
import EmpresaBeneficiarioSocioEditPage from './_edit';

export default function EmpresaBeneficiarioSocioPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchBeneficiarioSocio({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/beneficiarios-socio/:id/edit" component={EmpresaBeneficiarioSocioEditPage} />
        <Route path="/*/empresas/:empresaId/beneficiarios-socio/create" component={EmpresaBeneficiarioSocioCreatePage} />
        <Route path="/*/empresas/:empresaId/beneficiarios-socio/:id" component={EmpresaBeneficiarioSocioDetailPage} />
      </Switch>);
}
