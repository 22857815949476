import { message, Space } from 'antd';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import MiniTable from '../../../../components/mini-table';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import styles from '../_styles.less';
import { anexosSelector, fetchAnexos, fetchLogs, hasPermissaoSelector } from "../../../../store/consultivoConsulta";
import ConsultivoConsultaAnexosTable from "./_table";
import { Consulta } from "../../../../constants";

export default function ConsultivoConsultaAnexosDetailPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.ANEXO_ALTERAR.id));
  const anexos = useSelector(anexosSelector);
  const loading = useLoading(fetchAnexos.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCreate = () => {
    history.replace(`/consultivo/consultas/${consultaId}/anexos/create`);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete(`/consultivo/consultas/${consultaId}/anexos`, { params: { ids: selectedIds } }))
      .then(() => {
        message.success(t('Registro(s) removido(s) com sucesso'));
        setSelectedIds([]);
        dispatch(fetchAnexos({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
      })
      .finally(() => setLoadingRemove(false));
  };

  const handleDetail = anexo => {
    history.replace(`/consultivo/consultas/${consultaId}/anexos/${anexo.id}`);
  };

  const actions = [];
  if (isEditable) {
    actions.push(<ActionButton.Create onClick={handleCreate}/>);
    actions.push(<ActionButton.Remove
      disabled={selectedIds.length === 0}
      onClick={handleRemove}
      loading={loadingRemove}
    />);
  }
  actions.push(<MiniTable.DownloadButton disabled={loading || anexos.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar>{actions}</ActionBar>;
  return (
    <Space direction="vertical" className={styles['space']}>
      <ConsultivoConsultaAnexosTable
        dataSource={anexos}
        loading={loading}
        miniTableRef={miniTableRef}
        onClick={handleDetail}
        onSelectedIdsChange={setSelectedIds}
        selectedIds={selectedIds}
        title={title}
      />
    </Space>);
}
