import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './_styles.less';
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { List, Popover } from "antd";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";
import { useSelector } from "react-redux";
import { acessoSelector } from "../../store/acessos";
import { useTranslation } from "react-i18next";
import { openPopupEmpresa } from "../../util/window";
import useFormatter from "../../hooks/useFormatter";
import * as util from "util";
import useModulo from "../../hooks/useModulo";

export default function PesquisaMenu({ onUnloadPopup }) {
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const { moduloAtual } = useModulo();
  const acesso = useSelector(acessoSelector);
  const { formatNumber, parseNumber } = useFormatter();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [resultado, setResultado] = useState([]);

  const pesquisar = () => {
    if (search.trim().length > 0) {
      setLoading(true);
      unwrapAxiosPromise(axios.get('/acessos/' + acesso?.id + '/pesquisar', { params: { search, modulo: moduloAtual } }))
        .then(data => setResultado(data))
        .finally(() => {
          setLoading(false);
          setOpen(true);
        });
    } else {
      setResultado([]);
      setOpen(false);
    }
  }

  const handleClick = ev => {
    ev.preventDefault();
    inputRef.current?.focus();
  }

  const handleChange = ev => {
    setSearch(ev.target.value);
    setOpen(false);
  }

  const handleKeyDown = ev => {
    // if (ev.key === 'Enter') {
    //   ev.preventDefault();
    //   pesquisar();
    // }
  }

  const handleLinkClick = (ev, item) => {
    ev.preventDefault();
    openPopupEmpresa(item.empresa, item.tab, { onUnload: onUnloadPopup });
  }

  useLayoutEffect(() => {
    window.addEventListener('keydown', ev => {
      if (ev.ctrlKey && ev.key === 'k') {
        ev.preventDefault();
        inputRef.current?.focus();
        inputRef.current?.select();
      }
    })
  }, []);

  useDebouncedEffect(500, () => {
    if (search.trim().length > 2)
      pesquisar();
  }, [search]);

  const header = resultado.length > 0 ?
    resultado.length === 1 ?
      util.format(t('exibindo %d item'), resultado.length) :
      util.format(t('exibindo %d itens'), resultado.length) :
    null;

  const content = (
    <List
      className={styles['list']}
      locale={{ emptyText: t('Nenhum resultado encontrado') }}
      itemLayout="vertical"
      size="small"
      dataSource={resultado}
      header={header}
      renderItem={(item, index) =>
        (
          <List.Item key={index}>
            <List.Item.Meta
              className={styles['list-item-meta']}
              title={item.empresa.apelido || item.empresa.razaoSocial}
              description={(
                <a href="#" onClick={ev => handleLinkClick(ev, item)}>
                  {item.caminho.join(' / ')}
                </a>
              )}
            />
            <div className={styles['conteudo']}>{item.conteudo}</div>
          </List.Item>
        )}
    />
  )

  return (
    <div className={styles['pesquisa-menu']} onClick={handleClick}>
      <div>{loading ? <LoadingOutlined/> : <SearchOutlined/>}</div>
      <Popover
        overlayClassName={styles['popover']}
        placement="bottomLeft"
        content={content}
        trigger="click"
        open={search.length > 0 && !loading && open}
        onOpenChange={setOpen}
      >
        <div>
          <input
            ref={inputRef}
            placeholder={t('Pesquisar...')}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </Popover>
    </div>
  );
}
