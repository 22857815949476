import { Divider, Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Brand from '../../components/brand';
import { isAutenticadoSelector } from '../../store/autenticacao';
import { isMDSelector } from '../../store/layout';
import styles from './_styles.less';

export default function AuthenticatedPopupLayout({ children, menu, contentStyle }) {
  const { t } = useTranslation();
  const isMD = useSelector(isMDSelector);
  const isAutenticado = useSelector(isAutenticadoSelector);

  useEffect(() => {
    if (!isAutenticado) {
      alert(t('Acesso negado'));
      window.close();
    }
  }, [isAutenticado]);

  return (
    <Layout className={styles['authenticated-layout']}>
      <Layout.Header theme="dark" className={styles['header']}>
        <div className={styles['brand']}>
          <Brand height="24px" theme="dark" iconOnly={!isMD}/>
        </div>
        <Divider type="vertical" className={styles['divider']}/>
        <div className={styles['menu']}>
          {menu}
        </div>
      </Layout.Header>
      <Layout.Content className={styles['content']} style={contentStyle}>
        {children}
      </Layout.Content>
    </Layout>
  );
}

AuthenticatedPopupLayout.propTypes = {
  children: PropTypes.node,
  menu: PropTypes.node,
};
