import {Spin} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import logoDark from '../../assets/legalpanel-dark.png';
import logoIconOnlyDark from '../../assets/legalpanel-icononly-dark.png';
import logoIconOnlyLight from '../../assets/legalpanel-icononly-light.png';
import logoLight from '../../assets/legalpanel-light.png';
import {Versao} from '../../constants';
import styles from './_styles.less';

export default function Brand({height, width, showVersion, theme, iconOnly}) {
  const loading = false;

  const className = useMemo(() => {
    switch (theme) {
      default:
        return styles['brand'];
      case 'dark':
        return styles['brand'] + ' ' + styles['dark'];
    }
  }, [theme]);

  const logo = useMemo(() => {
    switch (theme) {
      default:
        return iconOnly ? logoIconOnlyLight : logoLight;
      case 'dark':
        return iconOnly ? logoIconOnlyDark : logoDark;
    }
  }, [theme, iconOnly]);

  return (
      <div className={className}>
        <img
            alt="brand"
            src={logo}
            style={{height, width}}
        />
        {showVersion &&
        <div className={styles['version']}>
          <Spin spinning={loading}>{Versao}</Spin>
        </div>
        }
      </div>
  );
}

Brand.defaultProps = {
  dark: false,
  showVersion: false,
  iconOnly: false,
};

Brand.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
  showVersion: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
  iconOnly: PropTypes.bool,
};
