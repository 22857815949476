import {message, Space} from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import ActionBar from '../../../components/action-bar';
import ActionButton from '../../../components/action-button';
import MiniTable from '../../../components/mini-table';
import {Alerta} from '../../../constants';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import {fetchRepresentantes, isEditableSelector, representantesAlertaNivelSelector, representantesAlertasSelector, representantesSelector} from '../../../store/empresa';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import {openPopup} from '../../../util/window';
import Alertas from '../_Alertas';
import styles from '../_styles.less';
import EmpresaRepresentantesTable from './_table';
import useModulo from "../../../hooks/useModulo";

export default function EmpresaRepresentantesDetailPage() {
  const miniTableRef = useRef();
  const {t} = useTranslation();
  const dispatch = useDispatchWithResult();
  const nivel = useSelector(representantesAlertaNivelSelector);
  const isEditable = useSelector(isEditableSelector);
  const representantes = useSelector(representantesSelector);
  const alertas = useSelector(representantesAlertasSelector);
  const loading = useLoading(fetchRepresentantes.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const {moduloBasePath} = useModulo();
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const isNA = nivel === Alerta.Nivel.NA.id;

  const handleCreate = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/representantes/create`);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete(`/empresas/${empresaId}/representantes`, {params: {ids: selectedIds}}))
        .then(() => {
          message.success(t('Registro(s) removido(s) com sucesso'));
          setSelectedIds([]);
          dispatch(fetchRepresentantes({empresaId}));
        })
        .finally(() => setLoadingRemove(false));
  };

  const handleDetail = representante => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/representantes/${representante.id}`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/representantes/`, `logs_/empresas/${empresaId}/representantes/`);
  };

  const actions = [];
  if (isEditable) {
    actions.push(<ActionButton.Create disabled={isNA} onClick={handleCreate} />);
    actions.push(<ActionButton.Remove
        disabled={selectedIds.length === 0 || isNA}
        onClick={handleRemove}
        loading={loadingRemove}
    />);
  }
  actions.push(<ActionButton.Logs noTitle onClick={handleLogs} />);
  actions.push(<MiniTable.DownloadButton disabled={loading || representantes.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar>{actions}</ActionBar>;
  return (
      <Space direction="vertical" className={styles['space']}>
        <EmpresaRepresentantesTable
            dataSource={representantes}
            loading={loading}
            miniTableRef={miniTableRef}
            onClick={handleDetail}
            onSelectedIdsChange={setSelectedIds}
            selectedIds={selectedIds}
            title={title}
        />
        <Alertas alertas={alertas} />
      </Space>);
}

EmpresaRepresentantesDetailPage.propTypes = {
  representantes: PropTypes.array,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
};
