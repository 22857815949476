import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmpresaProcurador} from '../../constants';

const {Tipo} = EmpresaProcurador;

export default function SelectEmpresaProcuradorTipo(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Tipo.Publica)}
        {createOption(Tipo.Particular)}
        {createOption(Tipo.Eletronica)}
      </Select>
  );
}

SelectEmpresaProcuradorTipo.propTypes = {
  value: PropTypes.oneOf([
    Tipo.Publica.id,
    Tipo.Particular.id,
    Tipo.Eletronica.id,
  ]),
  onChange: PropTypes.func,
};
