import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  cadastro: {},

  usuarios: [],
  usuario: {},

  tiposTrabalho: [],
  tipoTrabalho: {},

  assuntos: [],
  assunto: {},

  assuntoFases: [],
  assuntoFase: {},
}

export const cadastroSelector = state => state.consultivoArea.cadastro;

export const usuariosSelector = state => state.consultivoArea.usuarios;
export const usuarioSelector = state => state.consultivoArea.usuario;

export const tiposTrabalhoSelector = state => state.consultivoArea.tiposTrabalho;
export const tipoTrabalhoSelector = state => state.consultivoArea.tipoTrabalho;

export const assuntosSelector = state => state.consultivoArea.assuntos;
export const assuntoSelector = state => state.consultivoArea.assunto;

export const assuntoFasesSelector = state => state.consultivoArea.assuntoFases;
export const assuntoFaseSelector = state => state.consultivoArea.assuntoFase;

export const fetchCadastro = createAsyncThunk(
  'consultivoArea/fetchCadastro',
  async ({ areaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/areas/${areaId}/cadastro`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchUsuarios = createAsyncThunk(
  'consultivoArea/fetchUsuarios',
  async ({ areaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/areas/${areaId}/usuarios`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchUsuario = createAsyncThunk(
  'consultivoArea/fetchUsuario',
  async ({ areaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/consultivo/areas/${areaId}/usuarios/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchTiposTrabalho = createAsyncThunk(
  'consultivoArea/fetchTiposTrabalho',
  async ({ areaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/areas/${areaId}/tipos-trabalho`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchTipoTrabalho = createAsyncThunk(
  'consultivoArea/fetchTipoTrabalho',
  async ({ areaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/consultivo/areas/${areaId}/tipos-trabalho/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchAssuntos = createAsyncThunk(
  'consultivoArea/fetchAssuntos',
  async ({ areaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/areas/${areaId}/assuntos`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchAssunto = createAsyncThunk(
  'consultivoArea/fetchAssunto',
  async ({ areaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/consultivo/areas/${areaId}/assuntos/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchAssuntoFases = createAsyncThunk(
  'consultivoArea/fetchAssuntoFases',
  async ({ areaId, assuntoId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/areas/${areaId}/assuntos/${assuntoId}/fases`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchAssuntoFase = createAsyncThunk(
  'consultivoArea/fetchAssuntoFase',
  async ({ areaId, assuntoId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/consultivo/areas/${areaId}/assuntos/${assuntoId}/fases/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const slice = createSlice({
  name: 'consultivoArea',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCadastro.fulfilled]: (state, action) => {
      state.cadastro = action.payload;
      state.cadastro.areaPaiId = action.payload.areaPai?.id;
      state.cadastro.responsavelId = action.payload.responsavel?.id;
    },
    [fetchCadastro.rejected]: (state) => {
      state.cadastro = [];
    },

    [fetchUsuarios.fulfilled]: (state, action) => {
      state.usuarios = action.payload;
    },
    [fetchUsuarios.rejected]: (state) => {
      state.usuarios = [];
    },
    [fetchUsuario.fulfilled]: (state, action) => {
      state.usuario = action.payload;
    },

    [fetchTiposTrabalho.fulfilled]: (state, action) => {
      state.tiposTrabalho = action.payload;
    },
    [fetchTiposTrabalho.rejected]: (state) => {
      state.tiposTrabalho = [];
    },
    [fetchTipoTrabalho.fulfilled]: (state, action) => {
      state.tipoTrabalho = action.payload;
    },

    [fetchAssuntos.fulfilled]: (state, action) => {
      state.assuntos = action.payload;
    },
    [fetchAssuntos.rejected]: (state) => {
      state.assuntos = [];
    },
    [fetchAssunto.fulfilled]: (state, action) => {
      state.assunto = action.payload;
    },

    [fetchAssuntoFases.fulfilled]: (state, action) => {
      state.assuntoFases = action.payload;
    },
    [fetchAssuntoFases.rejected]: (state) => {
      state.assuntoFases = [];
    },
    [fetchAssuntoFase.fulfilled]: (state, action) => {
      state.assuntoFase = action.payload;
    },
  }
})

export default slice.reducer;
