import {Form, message} from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import {fetchCBEs, fetchCCEs, fetchDEFs, fetchPL, fetchPLs, plSelector} from '../../../../store/empresa';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import EmpresaPLForm from './_form';
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaPLEditPage() {
  const {t} = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const pl = useSelector(plSelector);
  const loading = useLoading(fetchPL.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(pl);
    setImmediate(() => document.querySelector('#valorReal').focus());
  }, [pl]);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/empresas/${empresaId}/pls/${id}`, values), form)
        .then(() => {
          message.success(t('Registro alterado com sucesso'));
          history.replace(`${moduloBasePath}/empresas/${empresaId}/pls/${id}`);
          dispatch(fetchPLs({empresaId}));
          dispatch(fetchDEFs({empresaId}));
          dispatch(fetchCCEs({empresaId}));
          dispatch(fetchCBEs({empresaId}));
          dispatch(fetchPL({empresaId, id}));
        })
        .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/pls/${id}`);
  };

  const title = (
      <ActionBar>
        <ActionButton.Save onClick={form.submit} loading={loadingSave} />
        <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave} />
      </ActionBar>);

  return (
      <EmpresaPLForm
          disabled={loadingSave}
          form={form}
          loading={loading}
          onFinish={handleFinish}
          title={title}
      />);
}
