import {Col, Form, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputDate from '../../../../components/input-date';
import InputNumber from '../../../../components/input-number';
import MiniForm from '../../../../components/mini-form';
import SelectBoolean from '../../../../components/select-boolean';

export default function EmpresaPLForm({disabled, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="150px">
            <Form.Item name="dataReferencia" label={t('Data Base')}>
              <InputDate disabled />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="valorReal" label={t('Patrimônio Líquido (R$)')}>
              <InputNumber disabled={disabled} maximumFractionDigits={2} minimumFractionDigits={2} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="valorDolar" label={t('Patrimônio Líquido (US$)')}>
              <InputNumber disabled={disabled} maximumFractionDigits={2} minimumFractionDigits={2} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="ativoReal" label={t('Ativo (R$)')}>
              <InputNumber disabled={disabled} maximumFractionDigits={2} minimumFractionDigits={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="350px">
            <Form.Item
                name="saldoDevedor"
                label={t('Saldo Devedor Créditos Estrangeiros (US$)')}
                tooltip={t('Indicar saldo devedor total de créditos comerciais de curto prazo ' +
                    '(exigíveis em 30 até 360 dias) concedidos por não residentes. Operações de prazo entre zero e 29 ' +
                    'dias são consideradas à vista, e estão dispensadas de declaração.')}
            >
              <InputNumber disabled={disabled} maximumFractionDigits={2} minimumFractionDigits={2} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                name="ativosExterior"
                label={t('Ativos no Exterior')}
                tooltip={t('São valores de qualquer natureza mantidos fora do país por ' +
                    'residentes no Brasil. Podem ser bens, direitos, instrumentos financeiros, disponibilidades em ' +
                    'moedas estrangeiras, depósitos, imóveis, participações em empresas, ações, títulos, créditos ' +
                    'comerciais e etc.')}
            >
              <SelectBoolean disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.ativosExterior !== nextValues.ativosExterior}
            >
              {({getFieldValue, setFieldsValue}) => {
                const ativosExterior = getFieldValue('ativosExterior');
                if (ativosExterior === true)
                  return (
                      <Form.Item name="valorAtivosExterior" label={t('Valor de Ativos no Exterior (US$)')}>
                        <InputNumber disabled={disabled} maximumFractionDigits={2} minimumFractionDigits={2} />
                      </Form.Item>);
                setFieldsValue({valorAtivosExterior: null});
                return null;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="150px">
            <Form.Item name="dataDeclaracao" label={t('Data da Declaração')}>
              <InputDate disabled />
            </Form.Item>
          </Col>
        </Row>
      </MiniForm>);
}

EmpresaPLForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
