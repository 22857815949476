import {Tooltip} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Flag from '../../components/flag';
import MiniTable from '../../components/mini-table';
import useFormatter from '../../hooks/useFormatter';
import {isSuperUsuarioSelector} from '../../store/autenticacao';
import styles from './_styles.less';

function IpColumn({ip, pais}) {
  return (
      <div className={styles['ip-column']}>
        <span>{ip}</span>
        <Tooltip title={pais?.nome}>
          <span className={styles['pais']}>
              <Flag countryCode={pais?.sigla} />
          </span>
        </Tooltip>
      </div>
  );
}

export default function LogsTable({dataSource, loading, miniTableRef, title}) {
  const {t} = useTranslation();
  const {formatDateTime} = useFormatter();
  const isSuperUsuario = useSelector(isSuperUsuarioSelector);

  const columns = [
    {
      title: t('Texto'),
      dataIndex: 'texto',
      key: 'texto',
    },
    {
      title: t('Por'),
      dataIndex: ['usuario', 'nome'],
      key: 'usuario',
      width: 220,
    },
    {
      title: t('Em'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (text, record) => formatDateTime(record.createdAt),
    },
  ];

  if (isSuperUsuario) {
    columns.push({
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
      width: 100,
      render: (text, {ip, pais}) => <IpColumn ip={ip} pais={pais} />,
    });
    columns.push({
      title: 'User-Agent',
      dataIndex: 'userAgent',
      key: 'userAgent',
      width: 100,
    });
  }

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
      />
  );
}

LogsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  title: PropTypes.func,
};
