import { Select } from "antd";
import styles from "./_WorkflowFilterBar.less";
import { formatCnpj } from "../../util/cnpj";
import React, { useEffect, useMemo, useState } from "react";
import useCollator from "../../hooks/useCollator";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";
import { useSelector } from "react-redux";
import { filtrarWorkflow, filtroSelector, workflowSelector } from "../../store/workflow";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";

export default function WorkflowFilterBarUnidade() {
  const { compare, contains } = useCollator();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector)
  const filtro = useSelector(filtroSelector);
  const [unidades, setUnidades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const workflowId = workflow?.id;

  const apelido = searchValue.trim();

  const handleChange = unidadeIds => {
    dispatch(filtrarWorkflow({ unidadeIds }));
  }

  useEffect(() => {
    if (workflowId) {
      setLoading(true);
      unwrapAxiosPromise(axios.get(`/workflows/${workflowId}/unidades`))
        .then(data => setUnidades(data))
        .finally(() => setLoading(false))
    } else
      setUnidades([]);
  }, [workflowId]);

  const unidadesFiltradas = useMemo(() =>
      unidades
        .filter(unidade => filtro.empresaIds.includes(unidade.empresa))
        .filter(unidade => contains(unidade.cnpj, apelido) || contains(unidade.apelido, apelido))
        .sort((a, b) => compare(a.cnpj, b.cnpj))
        .slice(0, 50),
    [contains, filtro, unidades, apelido]);


  return (
    <Select
      allowClear
      className={styles['select']}
      disabled={filtro.empresaIds.length === 0}
      filterOption={false}
      loading={loading}
      mode="multiple"
      searchValue={searchValue}
      onSearch={setSearchValue}
      showSearch
      value={filtro.unidadeIds}
      onChange={handleChange}
    >
      {unidadesFiltradas.map(unidade => (
        <Select.Option key={unidade.id} value={unidade.id}>
          {formatCnpj(unidade.cnpj)} - {unidade.apelido}
        </Select.Option>
      ))}
    </Select>
  )
}