import {Form, message} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ActionButton from '../../components/action-button';
import InputName from '../../components/input-name';
import MiniFormDrawer from '../../components/mini-form-drawer';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useLoading from '../../hooks/useLoading';
import {unwrapResult} from '../../store';
import {arquivoSelecionadoSelector, closeRenomearArquivoDrawer, renomearArquivo, renomearArquivoDrawerVisibleSelector} from '../../store/drive';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import useDriveQuery from './_useDriveQuery';

export default function RenomearArquivoDrawer() {
  const {t} = useTranslation();
  const {empresaId} = useDriveQuery();
  const loading = useLoading(renomearArquivo.typePrefix);
  const visible = useSelector(renomearArquivoDrawerVisibleSelector);
  const arquivo = useSelector(arquivoSelecionadoSelector);
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();

  const handleDrawerAfterOpen = () => {
    document.querySelector('#nome').focus();
  };

  const handleDrawerAfterClose = () => {
    form.resetFields();
  };

  const handleFinish = values => {
    unwrapAxiosPromise(dispatch(renomearArquivo({empresaId, ...values})).then(unwrapResult), form)
        .then(() => {
          if (arquivo.tipo === 'diretorio')
            message.success(t('Diretório renomeado com sucesso'));
          else
            message.success(t('Arquivo renomeado com sucesso'));
          dispatch(closeRenomearArquivoDrawer());
        });
  };

  const handleClose = () => {
    dispatch(closeRenomearArquivoDrawer());
  };

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loading}
        />
        <ActionButton.Close
            onClick={handleClose}
            disabled={loading}
        />
      </>
  );

  return (
      <MiniFormDrawer
          drawerTitle={t('Renomear arquivo')}
          drawerFooter={footer}
          drawerWidth={400}
          drawerVisible={visible}
          form={form}
          onDrawerClose={handleClose}
          onDrawerAfterOpen={handleDrawerAfterOpen}
          onDrawerAfterClose={handleDrawerAfterClose}
          onFinish={handleFinish}
      >
        <Form.Item label={t('Nome Atual')}>
          <InputName disabled value={arquivo?.nome} />
        </Form.Item>
        <Form.Item name="nome" label={t('Novo Nome')}>
          <InputName disabled={loading} />
        </Form.Item>
      </MiniFormDrawer>
  );
}
