import styles from "./_WorkflowEtapa.less";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  dragEndWorkflowEtapaId,
  draggingEtapaSelector,
  draggingTarefaSelector,
  dragStartWorkflowEtapaId,
  removeWorkflowEtapa,
  tarefasFiltradasSelector,
  updateWorkflowEtapaNome,
  updateWorkflowEtapaPosicao,
  updateWorkflowTarefaEtapa,
  workflowSelector
} from "../../store/workflow";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import WorkflowEtapaNome from "./_WorkflowEtapaNome";
import WorkflowTarefa from "./_WorkflowTarefa";
import WorkflowButton from "./_WorkflowButton";
import WorkflowTarefaCriar from "./_WorkflowTarefaCriar";
import { isClienteGestorSelector, isClienteOperadorSelector } from "../../store/autenticacao";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";

export default function WorkflowEtapa(props) {
  const { id, posicao, nome } = props;
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isClienteOperador = useSelector(isClienteOperadorSelector);
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const workflow = useSelector(workflowSelector);
  const tarefas = useSelector(tarefasFiltradasSelector(id));
  const draggingEtapa = useSelector(draggingEtapaSelector)
  const draggingTarefa = useSelector(draggingTarefaSelector)
  const [nomeEdicao, setNomeEdicao] = useState(false);
  const [tarefaEdicao, setTarefaEdicao] = useState(false);

  const workflowId = workflow.id;

  const handleDragStart = e => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('application/json', JSON.stringify({ event: 'WorkflowEtapa', data: { id } }));
    dispatch(dragStartWorkflowEtapaId(id));
    setNomeEdicao(false);
    setTarefaEdicao(false);
  }

  const handleDragEnd = e => {
    dispatch(dragEndWorkflowEtapaId());
  }

  const handleDragOver = e => {
    const target = e.currentTarget;
    if (draggingEtapa && draggingEtapa.id !== id) {
      if (posicao < draggingEtapa.posicao)
        target.classList.add(styles['dropzone-left'])
      else
        target.classList.add(styles['dropzone-right'])
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    } else if (draggingTarefa && draggingTarefa.etapa.id !== id) {
      target.classList.add(styles['dropzone-bottom'])
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
    }
  }

  const handleDragLeave = e => {
    const target = e.currentTarget;
    target.classList.remove(styles['dropzone-left']);
    target.classList.remove(styles['dropzone-right']);
    target.classList.remove(styles['dropzone-bottom']);
  }

  const handleDrop = e => {
    const target = e.currentTarget;
    target.classList.remove(styles['dropzone-left']);
    target.classList.remove(styles['dropzone-right']);
    target.classList.remove(styles['dropzone-bottom']);
    const { event, data } = JSON.parse(e.dataTransfer.getData('application/json'));
    switch (event) {
      case 'WorkflowEtapa':
        e.preventDefault();
        unwrapAxiosPromise(dispatch(updateWorkflowEtapaPosicao({ workflowId, id: data.id, posicao })));
        break;
      case 'WorkflowTarefa':
        e.preventDefault();
        unwrapAxiosPromise(dispatch(updateWorkflowTarefaEtapa({ workflowId, etapaId: id, id: data.id, posicao: 1 })));
        break;
    }
  }

  const handleNomeSubmit = nome => unwrapAxiosPromise(dispatch(updateWorkflowEtapaNome({ workflowId, id, nome })));

  const handleExcluir = () => {
    const workflowId = workflow.id;
    unwrapAxiosPromise(dispatch(removeWorkflowEtapa({ workflowId, id })));
  }

  return (
    <div className={styles['workflow-etapa']}>
      <div
        className={styles['cabecalho']}

        draggable={isClienteGestor}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}

        onDragEnter={handleDragOver}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className={styles['titulo']}>
          <WorkflowEtapaNome nome={nome} edicao={isClienteGestor && nomeEdicao} onEdicao={setNomeEdicao} onSubmit={handleNomeSubmit}/>
        </div>
        {isClienteOperador &&
          <div className={styles['menu']}>
            <Dropdown
              trigger="click"
              overlay={(
                <Menu>
                  <Menu.Item key="1" onClick={e => setTarefaEdicao(true)}>{t('Criar Tarefa')}</Menu.Item>
                  {isClienteGestor && <Menu.Item key="2" onClick={e => setNomeEdicao(true)}>{t('Renomear Etapa')}</Menu.Item>}
                  {isClienteGestor && <Menu.Item key="3" onClick={handleExcluir}>{t('Excluir Etapa')}</Menu.Item>}
                </Menu>
              )}
            >
              <div>
                <WorkflowButton title="..."/>
              </div>
            </Dropdown>
          </div>
        }
      </div>
      <div className={styles['conteudo']}>
        {tarefas.map(tarefa => <WorkflowTarefa key={tarefa.id} {...tarefa} />)}
      </div>
      {isClienteOperador &&
        <div className={styles['rodape']}>
          <WorkflowTarefaCriar etapaId={id} edicao={tarefaEdicao} onEdicao={setTarefaEdicao}/>
        </div>
      }
    </div>
  )
}

WorkflowEtapa.propTypes = {
  id: PropTypes.number,
  workflow: PropTypes.shape({
    id: PropTypes.number,
  }),
  nome: PropTypes.string,
  posicao: PropTypes.number,
  tarefas: PropTypes.array
}