import {CheckOutlined, CloseOutlined, LineOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import React, {useMemo} from 'react';
import {isValidName, parseName} from '../../util/name';

export default function InputName({onChange, value, onRemove, ...props}) {
  const isValid = useMemo(() => isValidName(value), [value]);

  const fireChange = newValue => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(newValue);
  };

  const handleBlur = e => fireChange(e.target.value.trim());
  const handleChange = e => fireChange(parseName(e.target.value));
  const handlePaste = e => {
    e.preventDefault();
    fireChange(parseName(e.clipboardData.getData('text')));
  };
  const handleKeyDown = e => {
    switch (e.key) {
      case "Backspace":
      case "Delete":
        if (e.target.value === '')
          if (typeof onRemove === 'function')
            onRemove();
        break;
    }
  }

  const suffix = !value ?
      <LineOutlined/> :
      isValid ?
          <CheckOutlined className="success"/> :
          <CloseOutlined className="error"/>;

  return (
      <Input
          maxLength={100}
          onBlur={handleBlur}
          onChange={handleChange}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          suffix={suffix}
          value={value}
          {...props}
      />
  );
}
