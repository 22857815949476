import { Menu, Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import useLoading from '../../../../hooks/useLoading';
import { cabecalhoSelector, fetchCabecalho } from '../../../../store/consultivoConsulta';
import styles from '../_styles.less';

export default function ConsultaCabecalho() {
  const cabecalho = useSelector(cabecalhoSelector);
  const loading = useLoading(fetchCabecalho.typePrefix);

  if (loading) {
    return (
      <Menu className={styles['cabecalho-menu']} theme="dark" mode="horizontal" selectable={false}>
        <Menu.Item>
          <Spin/>
        </Menu.Item>
      </Menu>);
  }

  return (
    <>
      <Menu className={styles['cabecalho-menu']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
        <Menu.Item>
          {cabecalho?.titulo}
        </Menu.Item>
      </Menu>
    </>
  );
}
