import {QuestionCircleOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './_styles.less';

export default function TextItem({children, horizontal, label, labelWidth, tooltip}) {
  const {t} = useTranslation();
  let className = styles['text-item'];
  if (horizontal)
    className += ' ' + styles['horizontal'];
  return (
      <div className={className}>
        <label>
          <span className={styles['label']} style={{width: labelWidth}}>{t(label)}</span>
          {tooltip &&
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined className={styles['icon']} />
          </Tooltip>
          }
          <div className={styles['value']}>{children}</div>
        </label>
      </div>
  );
}

TextItem.propTypes = {
  children: PropTypes.node,
  horizontal: PropTypes.bool,
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  tooltip: PropTypes.string,
};
