import {Button, Table} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import useFormatter from '../../../../hooks/useFormatter';
import {compareNumbers} from '../../../../util/number';
import {compareStrings} from '../../../../util/string';
import TableCellEllipsis from "../../../../components/table-cell-ellipsis";

export default function EmpresaSociosTable2({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatNumber, formatPercentage} = useFormatter();

  const fireClick = socio => typeof onClick === 'function' ? onClick(socio) : undefined;

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Ações ON'),
      align: 'right',
      dataIndex: 'capitalQuantidade',
      key: 'capitalQuantidade',
      sorter: (a, b) => compareNumbers(a.capitalQuantidade, b.capitalQuantidade),
      render: (text, record) => formatNumber(record.capitalQuantidade),
    },
    {
      title: <small>%</small>,
      align: 'right',
      className: 'padding-left-0',
      dataIndex: 'capitalQuantidadePercentual',
      key: 'capitalQuantidadePercentual',
      width: 80,
      render: (text, record) => <small>{formatPercentage(record.capitalQuantidadePercentual, {minimumFractionDigits: 4, maximumFractionDigits: 4})}</small>,
    },
    {
      title: t('Ações PN'),
      align: 'right',
      dataIndex: 'capitalQuantidade2',
      key: 'capitalQuantidade2',
      sorter: (a, b) => compareNumbers(a.capitalQuantidade2, b.capitalQuantidade2),
      render: (text, record) => formatNumber(record.capitalQuantidade2),
    },
    {
      title: <small>%</small>,
      align: 'right',
      className: 'padding-left-0',
      dataIndex: 'capitalQuantidade2Percentual',
      key: 'capitalQuantidade2Percentual',
      width: 80,
      render: (text, record) => <small>{formatPercentage(record.capitalQuantidade2Percentual, {minimumFractionDigits: 4, maximumFractionDigits: 4})}</small>,
    },
    {
      title: t('Ações Total'),
      align: 'right',
      dataIndex: 'capitalQuantidadeTotal',
      key: 'capitalQuantidadeTotal',
      sorter: (a, b) => compareNumbers(a.capitalQuantidadeTotal, b.capitalQuantidadeTotal),
      render: (text, record) => formatNumber(record.capitalQuantidadeTotal),
    },
    {
      title: t('Participação (%)'),
      align: 'right',
      dataIndex: 'capitalQuantidadeTotalPercentual',
      key: 'capitalQuantidadeTotalPercentual',
      width: 140,
      sorter: (a, b) => compareNumbers(a.capitalQuantidadeTotalPercentual, b.capitalQuantidadeTotalPercentual),
      render: (text, record) => formatPercentage(record.capitalQuantidadeTotalPercentual, {minimumFractionDigits: 4, maximumFractionDigits: 4}),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  const summary = pageData => {
    const total = pageData.reduce((pv, cv) => {
      pv.capitalQuantidade = (pv.capitalQuantidade ?? 0) + cv.capitalQuantidade;
      pv.capitalQuantidadePercentual = (pv.capitalQuantidadePercentual ?? 0) + cv.capitalQuantidadePercentual;
      pv.capitalQuantidade2 = (pv.capitalQuantidade2 ?? 0) + cv.capitalQuantidade2;
      pv.capitalQuantidade2Percentual = (pv.capitalQuantidade2Percentual ?? 0) + cv.capitalQuantidade2Percentual;
      pv.capitalQuantidadeTotal = (pv.capitalQuantidadeTotal ?? 0) + cv.capitalQuantidadeTotal;
      pv.capitalQuantidadeTotalPercentual = (pv.capitalQuantidadeTotalPercentual ?? 0) + cv.capitalQuantidadeTotalPercentual;
      return pv;
    }, {});

    return (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right">
            <strong>{formatNumber(total.capitalQuantidade)}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right padding-left-0">
            <strong><small>{formatPercentage(total.capitalQuantidadePercentual, {minimumFractionDigits: 4, maximumFractionDigits: 4})}</small></strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right">
            <strong>{formatNumber(total.capitalQuantidade2)}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right padding-left-0">
            <strong><small>{formatPercentage(total.capitalQuantidade2Percentual, {minimumFractionDigits: 4, maximumFractionDigits: 4})}</small></strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right">
            <strong>{formatNumber(total.capitalQuantidadeTotal)}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right padding-left-0">
            <strong>{formatPercentage(total.capitalQuantidadeTotalPercentual, {minimumFractionDigits: 4, maximumFractionDigits: 4})}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell />
        </Table.Summary.Row>
    );
  };

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
          summary={summary}
      />
  );
}

EmpresaSociosTable2.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
