import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaLivrosDigitaisDetailPage from './_detail';
import EmpresaLivroDigitalPage from './livro-digital';

export default function EmpresaLivrosDigitaisPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/livros-digitais/:id" component={EmpresaLivroDigitalPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaLivrosDigitaisDetailPage} />
        </Switch>
      </Card>);
}
