import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaAdministradoresDetailPage from './_detail';
import EmpresaAdministradorPage from './administrador';

export default function EmpresaAdministradoresPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/administradores/:id" component={EmpresaAdministradorPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaAdministradoresDetailPage} />
        </Switch>
      </Card>);
}
