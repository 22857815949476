import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import SelectBoolean from "../../../../components/select-boolean";
import MiniFormDrawer from "../../../../components/mini-form-drawer";
import ActionButton from "../../../../components/action-button";
import useDispatchWithResult from "../../../../hooks/useDispatchWithResult";
import axios from "axios";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import { fetchLogs, hasPermissaoSelector } from "../../../../store/consultivoConsulta";
import { useSelector } from "react-redux";
import { Consulta } from "../../../../constants";

const defaultValue = {
  texto: '',
  publico: true
};

export default function ConsultivoConsultaLogsDrawer({ consultaId, loading, onAfterClose, onChange, onClose, value = defaultValue, visible }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isIncluirPrivado = useSelector(hasPermissaoSelector(Consulta.Permissoes.LOG_INCLUIR_PRIVADO.id));
  const [loadingSave, setLoadingSave] = useState(false);
  const dispatch = useDispatchWithResult();

  const fireChange = data => typeof onChange === 'function' ? onChange(data) : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#texto').focus();
  };

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.post(`/consultivo/consultas/${consultaId}/logs`, values), form)
      .then(data => {
        message.success(t('Registro incluído com sucesso'));
        fireChange({ ...value, ...data });
        fireClose();
        dispatch(fetchLogs({ consultaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        disabled={loading}
        loading={loadingSave}
        onClick={() => form.submit()}
      />
      <ActionButton.Close
        disabled={loadingSave}
        onClick={fireClose}
      />
    </>
  );

  return (
    <MiniFormDrawer
      drawerTitle={t('Criar Comentário')}
      drawerFooter={footer}
      drawerWidth={440}
      drawerVisible={visible}
      form={form}
      loading={loading}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onDrawerAfterClose={onAfterClose}
      onFinish={handleFinish}
    >
      <Form.Item name="texto" label={t('Texto')}>
        <Input.TextArea autoSize disabled={loadingSave}/>
      </Form.Item>
      {isIncluirPrivado &&
        <Form.Item name="publico" label={t('Público')}>
          <SelectBoolean disabled={loadingSave}/>
        </Form.Item>
      }
    </MiniFormDrawer>
  );
}

ConsultivoConsultaLogsDrawer.propTypes = {
  loading: PropTypes.bool,
  onAfterClose: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.object,
  visible: PropTypes.bool,
};
