import {Layout} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './_styles.less';

export default function BlankLayout({children, centered}) {
  const align = centered ? 'center' : undefined;

  return (
      <Layout
          className={styles['blank-layout']}
          style={{justifyContent: align, alignItems: align}}
      >
        {children}
      </Layout>
  );
}

BlankLayout.propTypes = {
  children: PropTypes.node,
  centered: PropTypes.bool,
};
