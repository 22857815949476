import PropTypes from 'prop-types';
import React from 'react';
import {Empresa} from '../../../../../constants';
import EmpresaSocioForm1 from './_form_1';
import EmpresaSocioForm2 from './_form_2';
import EmpresaSocioForm3 from './_form_3';
import EmpresaSocioForm4 from './_form_4';
import EmpresaSocioForm5 from './_form_5';
import EmpresaSocioForm6 from './_form_6';
import EmpresaSocioForm7 from './_form_7';
import EmpresaSocioForm8 from './_form_8';

export default function EmpresaSocioForm({cabecalho, ...props}) {
  switch (cabecalho?.tipo) {
    default:
    case Empresa.Tipo.SociedadeLimitada.id:
    case Empresa.Tipo.SociedadeSimples.id:
    case Empresa.Tipo.Cooperativa.id:
      return <EmpresaSocioForm1 {...props} />;
    case Empresa.Tipo.SociedadePorAcoes.id:
      return <EmpresaSocioForm2 {...props} />;
    case Empresa.Tipo.Eireli.id:
      return <EmpresaSocioForm3 {...props} />;
    case Empresa.Tipo.EmpresarioIndividual.id:
      return <EmpresaSocioForm4 {...props} />;
    case Empresa.Tipo.Associacao.id:
    case Empresa.Tipo.Fundacao.id:
      return <EmpresaSocioForm5 {...props} />;
    case Empresa.Tipo.SociedadeEmContaDeParticipacao.id:
      return <EmpresaSocioForm6 {...props} />;
    case Empresa.Tipo.FundoDeInvestimento.id:
      return <EmpresaSocioForm7 {...props} />;
    case Empresa.Tipo.EntidadeEstrangeira.id:
      return <EmpresaSocioForm8 {...props} />;
  }
}

EmpresaSocioForm.propTypes = {
  disabled: PropTypes.bool,
  cabecalho: PropTypes.object,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
