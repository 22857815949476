import AuthenticatedPopupLayout from "../../../layouts/authenticated-popup";
import styles from './_styles.less';
import { Space, Tabs } from "antd";
import { useHistory, useRouteMatch } from "react-router";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import React, { useEffect } from "react";
import CardTabs from "../../../components/card-tabs";
import { useTranslation } from "react-i18next";

export default function ConsultivoProjetoPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const projetoId = match.params.id;
  const activeTab = match.params.tab;
  const cadastro = {};

  useEffect(() => {
    if (cadastro.nome)
      document.title = 'Legal Panel - ' + cadastro.nome;
  }, [cadastro]);

  useEffect(() => {
  }, [projetoId]);

  const handleTabChange = activeKey => {
    history.replace(`/consultivo/projetos/${projetoId}/${activeKey}`);
  };

  return (
    <AuthenticatedPopupLayout>
      <Space direction="vertical" className={styles['space']}>
        <CardTabs activeKey={activeTab} onChange={handleTabChange}>
          <Tabs.TabPane key="cadastro" tab={t('Cadastro')}>
          </Tabs.TabPane>
          <Tabs.TabPane key="log" tab={t('Log')}>
          </Tabs.TabPane>
        </CardTabs>
      </Space>
    </AuthenticatedPopupLayout>
  )
}
