import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaContasDetailPage from './_detail';
import EmpresaContaPage from './conta';

export default function EmpresaContasPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/contas/:id" component={EmpresaContaPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaContasDetailPage} />
        </Switch>
      </Card>);
}
