import {Alert, Form, Spin} from 'antd';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styles from './_styles.less';

function MiniForm({children, form, loading, onFinish, ...props}) {
  const [error, setError] = useState(false);

  const handleFinish = values => {
    setError(false);
    if (typeof onFinish === 'function') {
      const result = onFinish(values);
      if (result instanceof Promise)
        result.catch(err => setError(err.message));
    }
  };

  return (
      <Spin spinning={loading}>
        <Form
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            {...props}
        >
          {error &&
          <Alert showIcon message={error} type="error" className={styles['alert']} />
          }
          {children}
        </Form>
      </Spin>
  );
}

MiniForm.defaultProps = {
  loading: false,
};

MiniForm.propTypes = {
  children: PropTypes.node,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
};

export default MiniForm;
