import { Checkbox, Col, Form, message, Row } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../components/action-button';
import InputEmail from '../../components/input-email';
import InputName from '../../components/input-name';
import MiniFormDrawer from '../../components/mini-form-drawer';
import SelectAcesso from '../../components/select-acesso';
import SelectUsuarioNivelAcesso from '../../components/select-usuario-nivel-acesso';
import TextItem from '../../components/text-item';
import useFormatter from '../../hooks/useFormatter';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import { EmpresaTabs, findByIdAndMap, Modulos } from "../../constants";
import styles from "./_styles.less";

const defaultValue = {
  email: '',
  nivelAcesso: 0,
  ativo: true,
  nome: '',
  acessoIds: [],
  recebeAvisos: true,
  funcoes: EmpresaTabs.map(tab => tab.name)
};

export default function UsuarioDrawer({ loading, onAfterClose, onChange, onClose, value = defaultValue, visible }) {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const modulos = Form.useWatch('modulos', form) ?? value?.modulos ?? [];
  const funcoes = Form.useWatch('funcoes', form) ?? value?.funcoes ?? [];
  const hasCadastro = funcoes && funcoes.includes('cadastro');

  const fireChange = () => typeof onChange === 'function' ? onChange() : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  const modulosOptions = Modulos.map(tab => ({ label: t(tab.label), value: tab.id }))
  const funcoesOptions = EmpresaTabs.map(tab => {
    let prefix = 'Geral';
    if (tab.modulos.length === 1)
      prefix = findByIdAndMap(Modulos, tab.modulos[0], m => m.label)
    let disabled = true;
    for (const modulo of tab.modulos)
      if (modulos.includes(modulo)) {
        disabled = false;
        break;
      }
    if (tab.name !== 'cadastro' && !hasCadastro)
      disabled = true;

    return ({
      label: t(prefix) + ': ' + t(tab.label),
      value: tab.name,
      disabled
    });
  });

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#email').focus();
  };

  const handleFinish = values => {
    setLoadingSave(true);
    const promise = value.id ?
      axios.put(`/usuarios/${value.id}`, values) :
      axios.post('/usuarios', values);

    unwrapAxiosPromise(promise, form)
      .then(() => {
        message.success(t(value.id ?
          'Usuário alterado com sucesso' :
          'Usuário incluído com sucesso'));
        fireChange();
        fireClose();
      })
      .finally(() => setLoadingSave(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        onClick={() => form.submit()}
        loading={loadingSave}
      />
      <ActionButton.Close
        onClick={fireClose}
        disabled={loadingSave}
      />
    </>
  );

  const drawerTitle = value.id ? (value.id + ' - ' + value.email) : t('Criar Usuário');

  return (
    <MiniFormDrawer
      drawerTitle={drawerTitle}
      drawerFooter={footer}
      drawerWidth={500}
      drawerVisible={visible}
      form={form}
      loading={loading}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onDrawerAfterClose={onAfterClose}
      onFinish={handleFinish}
    >
      <Form.Item name="email" label={t('E-mail')}>
        <InputEmail disabled={loadingSave}/>
      </Form.Item>
      <Form.Item name="nome" label={t('Nome')}>
        <InputName disabled={loadingSave}/>
      </Form.Item>
      <Row gutter={[16, 0]}>
        <Col flex="auto">
          <Form.Item name="nivelAcesso" label={t('Nível de Acesso')} initialValue={1}>
            <SelectUsuarioNivelAcesso disabled={loadingSave}/>
          </Form.Item>
        </Col>
        <Col flex="100px">
          <Form.Item name="ativo" valuePropName="checked">
            <Checkbox disabled={loadingSave}>{t('Ativo')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, nextValues) => prevValues.nivelAcesso !== nextValues.nivelAcesso}
      >
        {({ getFieldValue }) =>
          getFieldValue('nivelAcesso') <= 2 &&
          <Form.Item name="acessoIds" label={t('Acessos')}>
            <SelectAcesso disabled={loadingSave} mode="multiple"/>
          </Form.Item>
        }
      </Form.Item>
      <Row gutter={[16, 0]}>
        <Col flex="auto">
          <Form.Item name="recebeAvisos" valuePropName="checked">
            <Checkbox disabled={loadingSave}>{t('Recebe Avisos')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="modulos" label={t('Módulos')}>
        <Checkbox.Group className={styles['checkbox-vertical']} disabled={loadingSave} options={modulosOptions}/>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, nextValues) => prevValues.nivelAcesso !== nextValues.nivelAcesso}
      >
        <Form.Item name="funcoes" label={t('Funções')}>
          <Checkbox.Group className={styles['checkbox-vertical']} disabled={loadingSave} options={funcoesOptions}/>
        </Form.Item>
      </Form.Item>
      <TextItem label="Últ. Login em">{formatDateTime(value?.ultimoLoginEm)}</TextItem>
      <TextItem label="Criado em">{formatDateTime(value?.createdAt)}</TextItem>
      <TextItem label="Alterado em">{formatDateTime(value?.updatedAt)}</TextItem>
    </MiniFormDrawer>
  );
}

UsuarioDrawer.propTypes = {
  loading: PropTypes.bool,
  onAfterClose: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.object,
  visible: PropTypes.bool,
};
