// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X7sgOZEzeZVE9EWys7Urmw\\=\\= {\n  border-radius: 2px;\n  padding: 1px 8px;\n  color: rgba(255, 255, 255, 0.9);\n  font-weight: bold;\n  font-size: 10px;\n  line-height: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/_WorkflowTipoTarefaTag.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,+BAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":[".workflow-tipoTarefa-tag {\n  border-radius: 2px;\n  padding: 1px 8px;\n  color: rgba(255, 255, 255, 90%);\n  font-weight: bold;\n  font-size: 10px;\n  line-height: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tipoTarefa-tag": "X7sgOZEzeZVE9EWys7Urmw=="
};
export default ___CSS_LOADER_EXPORT___;
