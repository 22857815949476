// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I9Dg3Rd8j0WHAW4Q7xRd5A\\=\\= {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n.I9Dg3Rd8j0WHAW4Q7xRd5A\\=\\= > .dYJpW6y4iKIijQMIUgGu3g\\=\\= {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.I9Dg3Rd8j0WHAW4Q7xRd5A\\=\\= > .dYJpW6y4iKIijQMIUgGu3g\\=\\= * {\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://src/layouts/splash/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AAJA;EAMI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAVA;EAYM,YAAA;AACN","sourcesContent":[".splash-layout {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  > .content {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    * {\n      color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splash-layout": "I9Dg3Rd8j0WHAW4Q7xRd5A==",
	"content": "dYJpW6y4iKIijQMIUgGu3g=="
};
export default ___CSS_LOADER_EXPORT___;
