import {Button, Table} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import useFormatter from '../../../../hooks/useFormatter';
import {formatDocumento} from '../../../../util/documento';
import {compareStrings} from '../../../../util/string';
import TableCellEllipsis from "../../../../components/table-cell-ellipsis";

export default function EmpresaSociosTable5({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatNumber} = useFormatter();

  const fireClick = socio => typeof onClick === 'function' ? onClick(socio) : undefined;

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('CPF/CNPJ'),
      dataIndex: 'documento',
      key: 'documento',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
      render: (text, record) => formatDocumento(text),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  const summary = pageData => {
    const total = pageData.length;

    return (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={3}>Total de Associados: <strong>{formatNumber(total)}</strong></Table.Summary.Cell>
        </Table.Summary.Row>
    );
  };

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
          summary={summary}
      />
  );
}

EmpresaSociosTable5.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
