import { Select } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import styles from './_styles.less';

export default function SelectConsultivoAssunto({ areaId, filter, onChange, params, value, ...props }) {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchSearch = search => {
    if (areaId) {
      setLoadingSearch(true);
      unwrapAxiosPromise(axios.get(`/consultivo/areas/${areaId}/assuntos`, { params: { search, ...params } }))
        .then(data => {
          if (typeof filter === 'function')
            data = data.filter(filter);
          setOptions(data);
        })
        .finally(() => setLoadingSearch(false));
    } else
      setOptions([]);
  };

  const handleChange = id => {
    onChange(id);
  };

  const handleSearch = useDebouncedCallback(500, search => {
    fetchSearch(search);
  }, [areaId]);

  useEffect(() => {
    fetchSearch();
  }, [areaId]);

  return (
    <Select
      filterOption={false}
      loading={loadingSearch}
      onChange={handleChange}
      onSearch={handleSearch}
      showSearch
      value={value}
      {...props}
    >
      {options.map(option => (
        <Select.Option key={option.id} value={option.id}>
          <div className={styles['option-node']}>
            <div className={styles['icon']} style={{ backgroundColor: '#' + option.cor }}/>
            <div>{option.descricao}</div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

SelectConsultivoAssunto.propTypes = {
  areaId: PropTypes.number,
  filter: PropTypes.func,
  onChange: PropTypes.func,
  params: PropTypes.any,
  value: PropTypes.number,
};
