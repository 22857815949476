import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQuery from '../../../hooks/useQuery';
import AuthenticatedPopupLayout from '../../../layouts/authenticated-popup';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import Organograma from '../../../components/organograma';

export default function OrganogramaPopupPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [organograma, setOrganograma] = useState([]);
  const query = useQuery();
  const acessoId = query.get('acessoId');
  const empresaId = query.get('empresaId');

  const fetch = useCallback(() => {
    setLoading(true);
    const params = {};
    if (acessoId)
      params.acessoId = acessoId;
    if (empresaId)
      params.empresaId = empresaId;
    unwrapAxiosPromise(axios.get('/societario/organograma', { params }))
      .then(data => {
        setOrganograma(data.organograma);
        document.title = 'Legal Panel - ' + t('Organograma') + ' - ' + data.nome;
      })
      .finally(() => setLoading(false));
  }, [acessoId, empresaId]);

  useEffect(() => {
    fetch();
  }, [fetch, acessoId, empresaId]);

  return (
    <AuthenticatedPopupLayout contentStyle={{ maxWidth: 'unset', padding: 0 }}>
      <Organograma loading={loading} organograma={organograma} onUnload={fetch} defaultLayout="rectangular"/>
    </AuthenticatedPopupLayout>);
}
