import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import ConsultivoAreaAssuntosDetailPage from "./_detail";
import ConsultivoAreaAssuntoPage from "./assunto";

export default function ConsultivoAreaAssuntosPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/areas/:areaId/assuntos/:id" component={ConsultivoAreaAssuntoPage}/>
        <Route path="/consultivo/areas/:areaId" component={ConsultivoAreaAssuntosDetailPage}/>
      </Switch>
    </Card>);
}
