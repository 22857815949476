import {Divider, message, Spin} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import FlexCard from '../../components/flex-card';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useLoading from '../../hooks/useLoading';
import DrivePopupLayout from '../../layouts/drive-popup';
import {unwrapResult} from '../../store';
import {diretorioSelecionadoSelector, empresaSelector, fetchDrive, isDriveAutenticadoUsuarioSelector} from '../../store/drive';
import {formatCnpj} from '../../util/cnpj';
import CriarDiretorioDrawer from './_CriarDiretorioDrawer';
import DriveBreadcrumb from './_DriveBreadcrumb';
import DriveCabecalho from './_DriveCabecalho';
import DriveDirectory from './_DriveDirectory';
import DriveSendFile from './_DriveSendFile';
import DriveTree from './_DriveTree';
import RenomearArquivoDrawer from './_RenomearArquivoDrawer';
import styles from './_styles.less';
import useDriveQuery from './_useDriveQuery';

export default function DrivePage() {
  const history = useHistory();
  const {empresaId} = useDriveQuery();
  const loading = useLoading(fetchDrive.typePrefix);
  const empresa = useSelector(empresaSelector);
  const isAutenticadoUsuario = useSelector(isDriveAutenticadoUsuarioSelector);
  const diretorioSelecionado = useSelector(diretorioSelecionadoSelector);
  const dispatch = useDispatchWithResult();

  useEffect(() => {
    dispatch(fetchDrive({empresaId}))
        .then(unwrapResult)
        .catch(err => {
              message.error(err.mensagem);
              history.go(-1);
            },
        );
  }, []);

  useEffect(() => {
    if (empresa.cnpj)
      document.title = 'Legal Panel - Drive - ' + (empresa.apelido || empresa.razaoSocial) + ' (' + formatCnpj(empresa.cnpj) + ')';
  }, [empresa]);

  return (
      <DrivePopupLayout menu={<DriveCabecalho />}>
        <Spin spinning={loading} wrapperClassName={styles['spin-wrapper']} className={styles['spin']}>
          <FlexCard bordered={false}>
            <div className={styles['drive']}>
              <div className={styles['panel-wrapper']}>
                <div className={styles['panel']}>
                  <DriveTree />
                </div>
              </div>
              {diretorioSelecionado &&
              <div className={styles['content']}>
                <div className={styles['content-header']}>
                  <DriveBreadcrumb />
                </div>
                <Divider className={styles['divider']} />
                <div className={styles['directory-wrapper']}>
                  <div className={styles['directory']}>
                    <DriveDirectory />
                  </div>
                </div>
                {isAutenticadoUsuario && <Divider className={styles['divider']} />}
                {isAutenticadoUsuario &&
                <div className={styles['content-footer']}>
                  <DriveSendFile />
                </div>
                }
              </div>
              }
            </div>
          </FlexCard>
        </Spin>
        <CriarDiretorioDrawer />
        <RenomearArquivoDrawer />
      </DrivePopupLayout>
  );
}
