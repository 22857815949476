import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import styles from './_WorkflowTipoTarefaItemDescricao.less';
import PropTypes from "prop-types";

export default function WorkflowTipoTarefaItemDescricao(props) {
  const { descricao, edicao, onEdicao, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const fireEdicao = (edicao) => {
    if (typeof onEdicao === 'function')
      onEdicao(edicao);
  }

  const fireSubmit = descricao => {
    let promise = Promise.resolve();
    if (typeof onSubmit === 'function')
      promise = promise.then(() => onSubmit(descricao));
    return promise;
  }

  const handleClick = e => {
    e.preventDefault();
    form.resetFields();
    fireEdicao(true);
  }

  const handleBlur = e => {
    fireEdicao(false);
  }

  const handleFinish = values => {
    fireSubmit(values.descricao).then(() => fireEdicao(false));
  }

  if (edicao) {
    return (
      <Form form={form} onFinish={handleFinish} size="middle">
        <Form.Item name="descricao" initialValue={descricao} noStyle>
          <Input autoFocus placeholder={t('Descrição')} onBlur={handleBlur}/>
        </Form.Item>
      </Form>
    );
  }

  if (descricao)
    return (
      <div className={styles['workflow-tipo-tarefa-item-descricao']} onClick={handleClick}>
        {descricao}
      </div>
    );

  return (
    <div className={styles['nao-preenchido']} onClick={handleClick}>
      (não preenchido)
    </div>
  )
}

WorkflowTipoTarefaItemDescricao.propTypes = {
  descricao: PropTypes.string,
  edicao: PropTypes.bool,
  onEdicao: PropTypes.func,
  onSubmit: PropTypes.func
}