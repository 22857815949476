export function parseName(name) {
  return name.replace(/[^\w\dÀ-ÖØ-öø-ÿ'. ]/gi, '').replace(/\s+/g, ' ').trimStart();
}

export function isValidName(name) {
  if (!name || name.length < 2 || name.length > 100)
    return false;
  return /^[\w\dÀ-ÖØ-öø-ÿ'. ]+$/gi.test(name);
}

export function parseNames(names) {
  if (typeof names === 'string')
    names = names.split(',');
  return names.map(name => parseName(name));
}