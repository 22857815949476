import {Form, message} from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import {administradoreSelector, fetchAdministrador, fetchAdministradores} from '../../../../store/empresa';
import {valuesToFormData} from '../../../../util/formData';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import EmpresaAdministradorForm from './_form';
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaAdministradorEditPage() {
  const {t} = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const administrador = useSelector(administradoreSelector);
  const loading = useLoading(fetchAdministrador.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(administrador);
    setImmediate(() => document.querySelector('#tipo').focus());
  }, [administrador]);

  const handleFinish = values => {
    setLoadingSave(true);
    const formData = valuesToFormData(values);
    unwrapAxiosPromise(axios.put(`/empresas/${empresaId}/administradores/${id}`, formData), form)
        .then(() => {
          message.success(t('Registro alterado com sucesso'));
          history.replace(`${moduloBasePath}/empresas/${empresaId}/administradores/${id}`);
          dispatch(fetchAdministradores({empresaId}));
          dispatch(fetchAdministrador({empresaId, id}));
        })
        .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/administradores/${id}`);
  };

  const title = (
      <ActionBar>
        <ActionButton.Save onClick={form.submit} loading={loadingSave} />
        <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave} />
      </ActionBar>);

  return (
      <EmpresaAdministradorForm
          disabled={loadingSave}
          form={form}
          loading={loading}
          onFinish={handleFinish}
          title={title}
      />);
}
