// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rBY5itdNeNlSEmztue-KUA\\=\\= .uIMLCMnSsCQ1zqZb75k\\+qA\\=\\= {\n  min-width: 384px;\n}\n.rBY5itdNeNlSEmztue-KUA\\=\\= .pT\\+CTemFIcpMS0PuiL1FAQ\\=\\= {\n  min-width: 292px;\n}\n.rBY5itdNeNlSEmztue-KUA\\=\\= .pT\\+CTemFIcpMS0PuiL1FAQ\\=\\= p {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.rBY5itdNeNlSEmztue-KUA\\=\\= td + td {\n  padding-left: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/empresa/livros-digitais/livro-digital/_styles.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAFA;EAKI,gBAAA;AAAJ;AALA;EAQM,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAN;AAVA;EAeI,kBAAA;AAFJ","sourcesContent":[".conteudo {\n  .nome {\n    min-width: 384px;\n  }\n  .anexo {\n    min-width: 292px;\n\n    p {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n    }\n  }\n\n  td + td {\n    padding-left: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conteudo": "rBY5itdNeNlSEmztue-KUA==",
	"nome": "uIMLCMnSsCQ1zqZb75k+qA==",
	"anexo": "pT+CTemFIcpMS0PuiL1FAQ=="
};
export default ___CSS_LOADER_EXPORT___;
