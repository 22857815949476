import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../hooks/useFormatter';
import styles from './_styles.less';

export default function InputAnexo({ compact, onChange, value, ...props }) {
  const { t } = useTranslation();
  const { formatBytes } = useFormatter();

  const fileList = useMemo(() => {
    if (value instanceof Object)
      return [{ ...value }];
    return [];
  }, [value]);

  const beforeUpload = (file) => {
    onChange(file);
    return false;
  };

  const itemRender = () => {
    return null;
  };

  const handleRemove = () => {
    onChange(null);
  };

  if (value) {
    return (
      <div className="ant-upload ant-upload-drag disabled">
          <span className="ant-upload ant-upload-btn">
            <div className={compact ? styles['compact'] : undefined}>
              <p className={styles['icon'] + ' ant-upload-drag-icon'}>
              {!(value instanceof File) &&
                <a download={value?.name} href={value?.url}>
                  <DownloadOutlined
                    style={{
                      marginRight: '8px',
                    }}
                  />
                </a>
              }
                {!props.disabled &&
                  <DeleteOutlined
                    onClick={handleRemove}
                    style={{ color: 'rgb(255, 77, 79)' }}
                  />
                }
              </p>
              <p className={styles['text'] + +' ant-upload-text'}>{value.name}</p>
              <p className={styles['text'] + +' ant-upload-text'}>{formatBytes(value.size)}</p>
            </div>
          </span>
      </div>
    );
  }

  return (
    <Upload.Dragger
      beforeUpload={beforeUpload}
      fileList={fileList}
      itemRender={itemRender}
      onRemove={handleRemove}
      {...props}
    >
      <div className={compact ? styles['compact'] : undefined}>
        <p className={styles['icon'] + ' ant-upload-drag-icon'}>
          <UploadOutlined/>
        </p>
        <p className={styles['text'] + +' ant-upload-text'}>{t('Clique ou arraste um arquivo aqui')}</p>
        <p className={styles['text'] + +' ant-upload-text'}>&nbsp;</p>
      </div>
    </Upload.Dragger>
  );
}

InputAnexo.propTypes = {
  accept: PropTypes.string,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};
