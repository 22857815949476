import styles from "../_styles.less";
import { Consulta } from "../../../../constants";
import ActionLink from "../../../../components/action-link";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextItem from "../../../../components/text-item";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import axios from "axios";
import { message } from "antd";
import { cadastroSelector, fetchCadastro, fetchLogs, fetchPermissoes, hasPermissaoSelector } from "../../../../store/consultivoConsulta";
import useDispatchWithResult from "../../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";

export default function UsuarioResponsavel() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.USUARIO_RESPONSAVEL_ALTERAR.id));
  const cadastro = useSelector(cadastroSelector);
  const [loadingSave, setLoadingSave] = useState(false);

  const consultaId = cadastro?.id;

  const handleClick = () => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/consultivo/consultas/${consultaId}/cadastro/usuario-responsavel`))
      .then(() => {
        message.success(t('Responsável alterado com sucesso'));
        dispatch(fetchPermissoes({ consultaId }));
        dispatch(fetchCadastro({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  return (
    <TextItem label={t('Responsável')}>
      <div className={styles['text-item-editable']}>
        {cadastro?.usuarioResponsavel && <div>{cadastro?.usuarioResponsavel?.nome}</div>}
        {isEditable &&
          <ActionLink
            className={styles['button']}
            icon={<PlusOutlined/>}
            title={t('Atribuir a mim')}
            loading={loadingSave}
            onClick={handleClick}
          />
        }
      </div>
    </TextItem>
  );
}
