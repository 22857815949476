import React from 'react';
import styles from './_ConsultasPorStatusCollectionItem.less';
import { Consulta, findById } from "../../../constants";
import { useTranslation } from "react-i18next";
import ConsultaCollection from "./_ConsultaCollection";

export default function ConsultasPorStatusCollectionItem({ status, consultas }) {
  const { t } = useTranslation();

  return (
    <div className={styles.ConsultasPorStatusCollectionItem}>
      <div className={styles.header}>
        {t(findById(Consulta.Status, status)?.label)}
      </div>
      <div className={styles.body}>
        <ConsultaCollection consultas={consultas}/>
      </div>
    </div>
  )
}
