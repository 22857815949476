import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmpresaLicenca } from '../../constants';

const { Aviso } = EmpresaLicenca;

export default function SelectEmpresaLicencaAviso(props) {
  const { t } = useTranslation();

  const createOption = ({ id, label }) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(Aviso.Sim180Dias)}
      {createOption(Aviso.Sim150Dias)}
      {createOption(Aviso.Sim120Dias)}
      {createOption(Aviso.Sim90Dias)}
      {createOption(Aviso.Sim60Dias)}
      {createOption(Aviso.Sim30Dias)}
      {createOption(Aviso.Nao)}
    </Select>
  );
}

SelectEmpresaLicencaAviso.propTypes = {
  value: PropTypes.oneOf([
    Aviso.Sim180Dias.id,
    Aviso.Sim150Dias.id,
    Aviso.Sim120Dias.id,
    Aviso.Sim90Dias.id,
    Aviso.Sim60Dias.id,
    Aviso.Sim30Dias.id,
    Aviso.Nao.id]),
  onChange: PropTypes.func,
};
