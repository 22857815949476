// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lkdyTq\\+QxiOZ2w8swU4wzg\\=\\= {\n  text-align: right;\n}\n.lkdyTq\\+QxiOZ2w8swU4wzg\\=\\= > * + * {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://src/components/mini-form-drawer/_styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAFA;EAII,gBAAA;AACJ","sourcesContent":[".footer {\n  text-align: right;\n\n  > * + * {\n    margin-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "lkdyTq+QxiOZ2w8swU4wzg=="
};
export default ___CSS_LOADER_EXPORT___;
