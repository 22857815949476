import { Checkbox, Col, Form, message, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import styles from '../_styles.less';
import { fetchLogs, hasPermissaoSelector, logsSelector } from "../../../../store/consultivoConsulta";
import ConsultivoConsultaLogsTable from "./_table";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import axios from "axios";
import ActionButton from "../../../../components/action-button";
import ConsultivoConsultaLogsDrawer from "./_drawer";
import { Consulta } from "../../../../constants";

export default function ConsultivoConsultaLogsDetailPage() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatchWithResult();
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.LOG_INCLUIR.id));
  const isDetalharPrivado = useSelector(hasPermissaoSelector(Consulta.Permissoes.LOG_DETALHAR_PRIVADO.id));
  const logs = useSelector(logsSelector);
  const loading = useLoading(fetchLogs.typePrefix);
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;
  const [filtro, setFiltro] = useState({ exibirPrivado: true, exibirSistema: true });
  // DRAWER
  const [drawerValue, setDrawerValue] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const filteredLogs = logs.filter(log => {
    if (!filtro.exibirPrivado && !log.publico)
      return false;
    if (!filtro.exibirSistema && !log.pessoal)
      return false;
    return true;
  })

  const handleValuesChange = values => {
    setFiltro(filtro => ({ ...filtro, ...values }));
  };

  const handleCreateClick = () => {
    setDrawerValue({});
    setDrawerVisible(true);
  };

  const handleChange = (values) => {
    return unwrapAxiosPromise(axios.put(`/consultivo/consultas/${consultaId}/logs/${id}`, values), form)
      .then(() => {
        form.resetFields();
        message.success(t('Registro alterado com sucesso'));
        dispatch(fetchLogs({ consultaId }));
      })
  }

  // DRAWER

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  const handleDrawerAfterClose = () => {
    setDrawerValue(undefined);
  };

  //

  return (
    <Space direction="vertical" className={styles['space']}>
      <Form noValidate onValuesChange={handleValuesChange}>
        <Row gutter={[8, 8]}>
          <Col>
            <Form.Item name="exibirSistema" valuePropName="checked" initialValue={filtro.exibirSistema} noStyle>
              <Checkbox>{t('Exibir Sistema')}</Checkbox>
            </Form.Item>
          </Col>
          {isDetalharPrivado &&
            <Col>
              <Form.Item name="exibirPrivado" valuePropName="checked" initialValue={filtro.exibirPrivado} noStyle>
                <Checkbox>{t('Exibir Privado')}</Checkbox>
              </Form.Item>
            </Col>
          }
          <Col flex="1"/>
          {isEditable && (
            <Col>
              <ActionButton.Create onClick={handleCreateClick}/>
            </Col>
          )}
        </Row>
      </Form>
      <ConsultivoConsultaLogsTable
        dataSource={filteredLogs}
        loading={loading}
      />
      <ConsultivoConsultaLogsDrawer
        consultaId={consultaId}
        onAfterClose={handleDrawerAfterClose}
        onClose={handleDrawerClose}
        value={drawerValue}
        visible={drawerVisible}
      />
    </Space>);
}
