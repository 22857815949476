import {CheckOutlined, CloseOutlined, LineOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import useFormatter from '../../hooks/useFormatter';

function isValidNumber(n, parseNumber) {
  if (typeof n === 'string')
    n = parseNumber(n);
  return isFinite(n);
}

function parseNumberEditing(n) {
  return n.replace(/[^\d.,]+/g, '');
}

export default function InputNumber({maximumFractionDigits, minimumFractionDigits, onChange, value, ...props}) {
  const options = {maximumFractionDigits, minimumFractionDigits};
  const editingOptions = {useGrouping: false};
  const {formatNumber, parseNumber} = useFormatter();
  const [text, setText] = useState(null);
  const isValid = useMemo(() => isValidNumber(text, parseNumber), [text]);
  const isNull = useMemo(() => text === null, [text]);

  useEffect(() => {
    setText(formatNumber(value, options));
  }, [value]);

  const fireChange = newValue => {
    if (isNaN(newValue))
      newValue = null;
    if (newValue === value) {
      setText(formatNumber(value, options));
      return;
    }
    if (typeof onChange === 'function')
      onChange(newValue);
  };

  const handleBlur = e => {
    fireChange(parseNumber(e.target.value));
  };
  const handleChange = e => setText(parseNumberEditing(e.target.value));
  const handleFocus = e => {
    setText(formatNumber(value, editingOptions));
    const target = e.target;
    setImmediate(() => target.setSelectionRange(0, target.value.length));
  };
  const handlePaste = e => {
    e.preventDefault();
    fireChange(parseNumber(e.clipboardData.getData('text')));
  };

  const suffix = isNull ?
      <LineOutlined /> :
      isValid ?
          <CheckOutlined className="success" /> :
          <CloseOutlined className="error" />;

  return (
      <Input
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onPaste={handlePaste}
          suffix={suffix}
          value={text}
          {...props}
      />);
}

InputNumber.defaultProps = {
  maximumFractionDigits: 8,
  minimumFractionDigits: 0,
};

InputNumber.propTypes = {
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
};
