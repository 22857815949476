// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4sxbZXbv09D1j\\+L\\+\\+Eqscw\\=\\= {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://src/components/mini-form/_styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".alert {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "_4sxbZXbv09D1j+L++Eqscw=="
};
export default ___CSS_LOADER_EXPORT___;
