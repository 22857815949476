import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import MiniForm from '../../../../components/mini-form';
import SelectBoolean from '../../../../components/select-boolean';
import InputName from "../../../../components/input-name";
import SelectEmpresaDocumentoAviso from "../../../../components/select-empresa-documento-aviso";
import SelectEmpresaUnidade from "../../../../components/select-empresa-unidade";
import { EmpresaDocumento } from "../../../../constants";

export default function EmpresaDocumentoForm({ disabled, empresaId, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="600px">
          <Form.Item name="unidadeId" label={t('Unidade')}>
            <SelectEmpresaUnidade disabled={disabled} empresaId={empresaId}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="nome" label={t('Nome')}>
            <InputName disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="areaResponsavel" label={t('Área Responsável')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="dataPublicacao" label={t('Data da Publicação')}>
            <InputDate disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="revisao" label={t('Revisão')}>
            <SelectBoolean disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.revisao !== nextValues.revisao}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const revisao = getFieldValue('revisao');
              if (revisao === true)
                return (
                  <Form.Item name="dataLimiteRevisao" label={t('Data Limite para Revisão')}>
                    <InputDate disabled={disabled}/>
                  </Form.Item>);
              setFieldsValue({ 'dataLimiteRevisao': null });
              return null;
            }}
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.revisao !== nextValues.revisao}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const revisao = getFieldValue('revisao');
              if (revisao === true)
                return (
                  <Form.Item name="aviso" label={t('Controle de Prazo')}>
                    <SelectEmpresaDocumentoAviso disabled={disabled}/>
                  </Form.Item>);
              setFieldsValue({ 'aviso': EmpresaDocumento.Aviso.Nao.id });
              return null;
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="site" label={t('Site')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="conteudo" label={t('Conteúdo')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexo" label={t('Anexo')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="obs" label={t('Observação')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

EmpresaDocumentoForm.propTypes = {
  disabled: PropTypes.bool,
  empresaId: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
