import React from 'react';
import { Modal } from "antd";
import styles from './_styles.less';
import { useTranslation } from "react-i18next";

export default function ConhecaModal({ modulo, onClose }) {
  const { t } = useTranslation();

  const description = modulo ? t(modulo.id + ".descricao") : null;

  return (
    <Modal
      className={styles['conheca-modal']}
      open={!!modulo}
      destroyOnClose
      footer={false}
      onCancel={onClose}
      width={480}
    >
      <div className={styles['body']}>
        <div className={styles['title']}>{modulo?.label}</div>
        <div className={styles['horizontal-line']}/>
        <div className={styles['description']}>{description}</div>
        <div>
          <a href="mailto:contato@legalpanel.com.br" className={styles['button']}>Saiba Mais</a>
        </div>
      </div>
      <div className={styles['footer']}>
        <div className={styles['rectangle']}/>
        <div className={styles['image']}>
          <img alt="conheca" src={modulo?.imageConheca}/>
        </div>
      </div>
    </Modal>
  )
}
