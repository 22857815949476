import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import ConsultivoAreaTiposTrabalhoDetailPage from "./_detail";
import ConsultivoAreaTipoTrabalhoPage from "./tipo-trabalho";

export default function ConsultivoAreaTiposTrabalhoPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/areas/:areaId/tipos-trabalho/:id" component={ConsultivoAreaTipoTrabalhoPage}/>
        <Route path="/consultivo/areas/:areaId" component={ConsultivoAreaTiposTrabalhoDetailPage}/>
      </Switch>
    </Card>);
}
