// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M5u\\+A8c\\+3MFDeHeOroE-HQ\\=\\= {\n  white-space: nowrap;\n}\n.M5u\\+A8c\\+3MFDeHeOroE-HQ\\=\\= > label > .IPz8mVDmFcOzHW-SMUOomQ\\=\\= {\n  font-weight: bold;\n  white-space: pre-wrap;\n  min-height: 24px;\n  padding-bottom: 4px;\n}\n.M5u\\+A8c\\+3MFDeHeOroE-HQ\\=\\= .GkCf-1rfq\\+Xicbr7DJszkg\\=\\= {\n  writing-mode: horizontal-tb;\n  margin-inline-start: 4px;\n  color: rgba(0, 0, 0, 0.45);\n}\n.M5u\\+A8c\\+3MFDeHeOroE-HQ\\=\\=._4k9V4uJIsiOYujjDjQIn2A\\=\\= > label {\n  display: flex;\n  flex-direction: row;\n}\n.M5u\\+A8c\\+3MFDeHeOroE-HQ\\=\\=._4k9V4uJIsiOYujjDjQIn2A\\=\\= > label .LZ-D-\\+7sRxfTxUO7P4ETeA\\=\\= {\n  text-align: end;\n}\n.M5u\\+A8c\\+3MFDeHeOroE-HQ\\=\\=._4k9V4uJIsiOYujjDjQIn2A\\=\\= > label .LZ-D-\\+7sRxfTxUO7P4ETeA\\=\\=::after {\n  content: ':';\n  position: relative;\n  top: -0.5px;\n  margin: 0 8px 0 2px;\n}\n", "",{"version":3,"sources":["webpack://src/components/text-item/_styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAFA;EAII,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AARA;EAWI,2BAAA;EACA,wBAAA;EACA,0BAAA;AAAJ;AAGE;EAEI,aAAA;EACA,mBAAA;AAFN;AADE;EAMM,eAAA;AAFR;AAIQ;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,mBAAA;AAFV","sourcesContent":[".text-item {\n  white-space: nowrap;\n\n  > label > .value {\n    font-weight: bold;\n    white-space: pre-wrap;\n    min-height: 24px;\n    padding-bottom: 4px;\n  }\n\n  .icon {\n    writing-mode: horizontal-tb;\n    margin-inline-start: 4px;\n    color: rgba(0, 0, 0, 0.45);\n  }\n\n  &.horizontal {\n    > label {\n      display: flex;\n      flex-direction: row;\n\n      .label {\n        text-align: end;\n\n        &::after {\n          content: ':';\n          position: relative;\n          top: -0.5px;\n          margin: 0 8px 0 2px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-item": "M5u+A8c+3MFDeHeOroE-HQ==",
	"value": "IPz8mVDmFcOzHW-SMUOomQ==",
	"icon": "GkCf-1rfq+Xicbr7DJszkg==",
	"horizontal": "_4k9V4uJIsiOYujjDjQIn2A==",
	"label": "LZ-D-+7sRxfTxUO7P4ETeA=="
};
export default ___CSS_LOADER_EXPORT___;
