import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaBeneficiariosEmpresaDetailPage from './_detail';
import EmpresaBeneficiarioEmpresaPage from './beneficiario-empresa';

export default function EmpresaBeneficiariosEmpresaPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/beneficiarios-empresa/:id" component={EmpresaBeneficiarioEmpresaPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaBeneficiariosEmpresaDetailPage} />
        </Switch>
      </Card>);
}
