import {FolderOpenOutlined, FolderOutlined, HomeOutlined} from '@ant-design/icons';
import {Tree} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import {diretorioCaminhoSelecionadoSelector, diretorioSelecionadoSelector, diretorioSelector, selecionarDiretorioCaminho} from '../../store/drive';
import DriveFileDropdown from './_DriveFileDropdown';
import styles from './_styles.less';

const mapDataToTreeData = diretorio => ({
  children: diretorio.arquivos
      ?.filter(arquivo => arquivo.tipo === 'diretorio')
      ?.map(mapDataToTreeData),
  data: diretorio,
  key: diretorio.caminho,
  title: diretorio.nome,
});

export default function DriveTree() {
  const dispatch = useDispatchWithResult();
  const diretorio = useSelector(diretorioSelector);
  const diretorioSelecionado = useSelector(diretorioSelecionadoSelector);
  const diretorioCaminhoSelecionado = useSelector(diretorioCaminhoSelecionadoSelector);
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const caminhoPai = diretorioSelecionado?.caminhoPai;

  useEffect(() => {
    if (caminhoPai)
      setExpandedKeys([...new Set([caminhoPai, ...expandedKeys])]);
  }, [caminhoPai]);

  useEffect(() => {
    if (diretorio) {
      setExpandedKeys([...new Set([diretorio.caminho, ...expandedKeys])]);
      setTreeData([mapDataToTreeData(diretorio)]);
    } else
      setTreeData([]);
  }, [diretorio]);

  const icon = node => {
    const isSelecionado = diretorioCaminhoSelecionado === node.data.data.caminho;
    const isRoot = node.data.data.caminho === '/';
    const hasLink = !!node.data.data.link;
    const className = isSelecionado ? 'primary' : undefined;
    return isRoot ? <HomeOutlined className={className} /> :
        hasLink ? <FolderOpenOutlined className={className} /> :
            <FolderOutlined className={className} />;
  };

  const handleClick = (e, node) => {
    dispatch(selecionarDiretorioCaminho(node.data.caminho));
  };

  const handleExpand = expandedKeys => {
    setExpandedKeys(expandedKeys);
  };

  const titleRender = nodeData => {
    const isRoot = nodeData.data.caminho === '/';
    if (isRoot)
      return null;
    const isSelecionado = diretorioCaminhoSelecionado === nodeData.data.caminho;
    const className = isSelecionado ? 'primary' : undefined;
    return (
        <DriveFileDropdown arquivo={nodeData.data}>
          <span className={className}>{nodeData.title}</span>
        </DriveFileDropdown>
    );
  };

  return (
      <Tree
          autoExpandParent
          className={styles['tree']}
          expandedKeys={expandedKeys}
          icon={icon}
          showIcon
          showLine={{showLeafIcon: false}}
          onClick={handleClick}
          onExpand={handleExpand}
          selectable={false}
          titleRender={titleRender}
          treeData={treeData} />
  );
}
