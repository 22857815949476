// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".L2lxpk4MfTh0is3PujqThQ\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 4px;\n  align-items: center;\n}\n.L2lxpk4MfTh0is3PujqThQ\\=\\=:not(.VZbrCt1v0ds\\+9fJH7ZmdqQ\\=\\=) {\n  cursor: pointer;\n}\n.beCpPSlZT4dwwaWEaXzIHg\\=\\= {\n  width: 22px;\n  height: 22px;\n  margin: 0;\n}\n.U-JCjdBsAWFi\\+AXBw-NLkA\\=\\= {\n  font-weight: bold;\n}\n._9s7QN3HZNsMmUT47l8Occg\\=\\= {\n  padding: 0;\n}\n._9s7QN3HZNsMmUT47l8Occg\\=\\= > .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content {\n  padding: 0;\n  background: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/input-color/_styles.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,qBAAA;EACA,QAAA;EACA,mBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAIA;EACE,WAAA;EACA,YAAA;EACA,SAAA;AAFF;AAKA;EACE,iBAAA;AAHF;AAMA;EACE,UAAA;AAJF;AAGA;EAMQ,UAAA;EACA,gBAAA;AANR","sourcesContent":["\n.input-color {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 4px;\n  align-items: center;\n\n  &:not(.disabled) {\n    cursor: pointer;\n  }\n}\n\n.tag {\n  width: 22px;\n  height: 22px;\n  margin: 0;\n}\n\n.text {\n  font-weight: bold;\n}\n\n.overlay {\n  padding: 0;\n\n  > :global(.ant-popover-content) {\n    > :global(.ant-popover-inner) {\n      > :global(.ant-popover-inner-content) {\n        padding: 0;\n        background: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-color": "L2lxpk4MfTh0is3PujqThQ==",
	"disabled": "VZbrCt1v0ds+9fJH7ZmdqQ==",
	"tag": "beCpPSlZT4dwwaWEaXzIHg==",
	"text": "U-JCjdBsAWFi+AXBw-NLkA==",
	"overlay": "_9s7QN3HZNsMmUT47l8Occg=="
};
export default ___CSS_LOADER_EXPORT___;
