import { useCallback, useState } from "react";
import unwrapAxiosPromise from "../util/unwrapAxiosPromise";
import axios from "axios";
import { isValidCnpj } from "../util/cnpj";

export function useInformacoes(deps) {
  const [loading, setLoading] = useState({});
  const [informacoes, setInformacoes] = useState({});

  const isLoading = useCallback(cnpj => loading[cnpj] === true, [loading]);

  const fetch = useCallback((cnpj, onFetch) => {
    if (!isValidCnpj(cnpj))
      return {};
    if (loading[cnpj])
      return {};
    if (informacoes[cnpj]) {
      onFetch(informacoes[cnpj]);
      return informacoes[cnpj];
    }
    setLoading(loading => ({ ...loading, [cnpj]: true }));
    unwrapAxiosPromise(axios.get(`/informacoes/${cnpj}`))
      .then(data => {
        setInformacoes(informacoes => ({ ...informacoes, [cnpj]: data }));
        onFetch(data);
      })
      .catch(err => {
        setInformacoes({});
      })
      .finally(() => {
        setLoading(loading => ({ ...loading, [cnpj]: false }));
      })
    return {};
  }, [loading, informacoes, ...deps]);

  const get = useCallback(cnpj => informacoes[cnpj], [informacoes])

  return { isLoading, fetch, get }
}