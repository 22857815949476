import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Usuario} from '../../constants';

const {NivelAcesso} = Usuario;

export default function SelectUsuarioNivelAcesso(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {id} - {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(NivelAcesso.Cliente)}
        {createOption(NivelAcesso.ClienteOperador)}
        {createOption(NivelAcesso.ClienteGestor)}
        {createOption(NivelAcesso.Operador)}
        {createOption(NivelAcesso.Gestor)}
        {createOption(NivelAcesso.Diretor)}
        {createOption(NivelAcesso.SuperUsuario)}
      </Select>
  );
}

SelectUsuarioNivelAcesso.defaultProps = {
  max: NivelAcesso.SuperUsuario,
};

SelectUsuarioNivelAcesso.propTypes = {
  value: PropTypes.oneOf([
    NivelAcesso.Cliente.id,
    NivelAcesso.ClienteOperador.id,
    NivelAcesso.ClienteGestor.id,
    NivelAcesso.Operador.id,
    NivelAcesso.Gestor.id,
    NivelAcesso.Diretor.id,
    NivelAcesso.SuperUsuario.id]),
  onChange: PropTypes.func,
  max: PropTypes.number,
};
