import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AuthenticatedLayout from '../layouts/authenticated';
import { isAutenticadoSelector } from '../store/autenticacao';
import AcessoMenu from "../components/acesso-menu";

export default function IndexPage() {
  const history = useHistory();
  const isAutenticado = useSelector(isAutenticadoSelector);

  if (isAutenticado) {
    history.push('/home');
  } else
    history.push('/autenticar');

  return (<AuthenticatedLayout menu={<AcessoMenu defaultRoute="/"/>}/>);
}
