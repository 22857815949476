import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaCadastroDetailPage from './_detail';
import EmpresaCadastroEditPage from './_edit';

export default function EmpresaCadastroPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/cadastro/edit" component={EmpresaCadastroEditPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaCadastroDetailPage} />
        </Switch>
      </Card>);
}
