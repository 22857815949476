import axios from 'axios';
import qs from 'qs';
import {renovar} from '../store/autenticacao';

let dispatch;
let accessToken = false;
let i18n;

let renovando;

function renovar0() {
    if (renovando)
        return renovando;
    renovando = new Promise((resolve, reject) => {
        dispatch(renovar())
            .then(({payload, error}) => {
                if (error)
                    reject(payload);
                else
                    resolve();
            })
            .finally(() => renovando = false);
    });
    return renovando;
}

function getAccessToken() {
    return accessToken;
}

export function setAccessToken(newAccessToken) {
    accessToken = newAccessToken;
}

axios.defaults.paramsSerializer = qs.stringify;

axios.interceptors.request.use(config => {
    if (accessToken)
        config.headers['authorization'] = `Bearer ${accessToken}`;
    if (i18n.language)
        config.headers['accept-language'] = i18n.language;
    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error?.response?.data) {
            if (error.response.data.codigo === 'TokenExpiredError' && !error.config._retry) {
                error.config._retry = true;
                return renovar0()
                    .then(() => axios.request(error.config));
            }
            return Promise.reject(error.response.data);
        }
        console.error(error);
        return Promise.reject({mensagem: 'Erro desconhecido'});
    });

export function createApi(isDev, store, i18n_) {
    dispatch = store.dispatch;
    i18n = i18n_
    axios.defaults.headers['accept-language'] = '';
    axios.defaults.baseURL = '/api';
    return axios;
}
