import {CalendarOutlined, CheckOutlined, CloseOutlined, LineOutlined} from '@ant-design/icons';
import {Calendar, Col, Input, Popover, Row, Select} from 'antd';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import useFormatter from '../../hooks/useFormatter';
import {capitalize} from '../../util/string';
import ActionButton from '../action-button';
import styles from './_styles.less';

function isValidDate(n, parseDate) {
  if (typeof n === 'string')
    n = parseDate(n);
  return n instanceof Date;
}

function parseDateEditing(n) {
  return n.replace(/[^\d\/]+/g, '');
}

export default function InputDate({maximumFractionDigits, minimumFractionDigits, onChange, value, ...props}) {
  const {formatDate, parseDate} = useFormatter();
  const [text, setText] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const isValid = useMemo(() => isValidDate(text, parseDate), [text]);
  const isNull = useMemo(() => text === null, [text]);

  useEffect(() => {
    setText(formatDate(value));
  }, [value]);

  const fireChange = newValue => {
    if (!(newValue instanceof Date))
      newValue = null;
    if (newValue === value) {
      setText(formatDate(value));
      return;
    }
    if (typeof onChange === 'function')
      onChange(newValue);
  };

  const handleBlur = e => {
    fireChange(parseDate(e.target.value));
  };
  const handleChange = e => {
    setText(parseDateEditing(e.target.value));
  };
  const handleFocus = e => {
    setText(formatDate(value));
    const target = e.target;
    setImmediate(() => target.setSelectionRange(0, target.value.length));
  };
  const handlePaste = e => {
    e.preventDefault();
    fireChange(parseDate(e.clipboardData.getData('text')));
  };
  const handleCalendarClick = date => {
    setCalendarVisible(false);
    fireChange(date.toDate());
  };

  const suffix = isNull ?
      <LineOutlined /> :
      isValid ?
          <CheckOutlined className="success" /> :
          <CloseOutlined className="error" />;

  let value2 = value;
  if (typeof value2 === 'string')
    value2 = parseDate(value2);
  if (value2 instanceof Date)
    value2 = moment(value2);
  if (!moment.isMoment(value2))
    value2 = moment();

  return (
      <div className={styles['wrapper']}>
        <Input
            maxLength={10}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onPaste={handlePaste}
            suffix={suffix}
            value={text}
            {...props}
        />
        <Popover
            content={(
                <div className={styles['calendar-wrapper']}>
                  <Calendar
                      dateFullCellRender={date => {
                        const value = date.toISOString().substring(8, 10);
                        return (
                            <div
                                className="ant-picker-cell-inner ant-picker-calendar-date"
                                onClick={e => handleCalendarClick(date)}
                            >
                              <div className="ant-picker-calendar-date-value">{value}</div>
                              <div className="ant-picker-calendar-date-content" />
                            </div>);
                      }}
                      headerRender={({value, type, onChange, onTypeChange}) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];

                        const current = value.clone();
                        const localeData = value.localeData();
                        const months = [];
                        for (let i = 0; i < 12; i++) {
                          current.month(i);
                          months.push(capitalize(localeData.months(current)));
                        }

                        for (let index = start; index < end; index++) {
                          monthOptions.push(
                              <Select.Option className="month-item" key={`${index}`}>
                                {months[index]}
                              </Select.Option>,
                          );
                        }
                        const month = value.month();

                        const year = value.year();
                        const options = [];
                        for (let i = year - 10; i < year + 10; i += 1) {
                          options.push(
                              <Select.Option key={i} value={i} className="year-item">
                                {i}
                              </Select.Option>,
                          );
                        }
                        return (
                            <div style={{padding: 8}}>
                              <Row gutter={8}>
                                <Col>
                                  <Select
                                      size="small"
                                      dropdownMatchSelectWidth={false}
                                      onChange={newYear => {
                                        const now = value.clone().year(newYear);
                                        onChange(now);
                                      }}
                                      value={String(year)}
                                  >
                                    {options}
                                  </Select>
                                </Col>
                                <Col>
                                  <Select
                                      size="small"
                                      dropdownMatchSelectWidth={false}
                                      value={String(month)}
                                      onChange={selectedMonth => {
                                        const newValue = value.clone();
                                        newValue.month(parseInt(selectedMonth, 10));
                                        onChange(newValue);
                                      }}
                                  >
                                    {monthOptions}
                                  </Select>
                                </Col>
                              </Row>
                            </div>
                        );
                      }}
                      fullscreen={false}
                      defaultValue={value2}
                  />
                </div>)}
            destroyTooltipOnHide
            placement="topLeft"
            trigger="click"
            visible={calendarVisible}
            onVisibleChange={setCalendarVisible}
        >
          <ActionButton disabled={props.disabled} icon={<CalendarOutlined />} />
        </Popover>
      </div>);
}
