import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import useFormatter from '../../../hooks/useFormatter';
import {compareNumbers} from '../../../util/number';
import {compareStrings} from '../../../util/string';

export default function EmpresaPLsTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatDate, formatCurrency} = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Data Base'),
      key: 'dataReferencia',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataReferencia, b.dataReferencia),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {formatDate(record.dataReferencia) ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Patrimônio Líq. (R$)'),
      align: 'right',
      key: 'valorReal',
      sorter: (a, b) => compareNumbers(a.valorReal, b.valorReal),
      render: (text, record) => formatCurrency(record?.valorReal),
    },
    {
      title: t('Saldo Devedor (US$)'),
      align: 'right',
      key: 'saldoDevedor',
      sorter: (a, b) => compareNumbers(a.saldoDevedor, b.saldoDevedor),
      render: (text, record) => formatCurrency(record?.saldoDevedor),
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaPLsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
