import {Col, Form, Input, Row} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import MiniForm from '../../../../components/mini-form';
import SelectBoolean from '../../../../components/select-boolean';
import SelectEmpresaBeneficiarioSocioAceite from '../../../../components/select-empresa-beneficiario-socio-aceite';
import SelectEmpresaBeneficiarioSocioApresentacao from '../../../../components/select-empresa-beneficiario-socio-apresentacao';
import SelectEmpresaSocio from '../../../../components/select-empresa-socio';
import {EmpresaBeneficiarioSocio} from '../../../../constants';

const {Apresentacao, Aceite} = EmpresaBeneficiarioSocio;

export default function EmpresaBeneficiarioSocioForm({disabled, empresaId, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="600px">
            <Form.Item name="socioId" label={t('Sócio')}>
              <SelectEmpresaSocio disabled={disabled} empresaId={empresaId} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="aplicavel" label={t('Aplicável')}>
              <SelectBoolean disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.aplicavel !== nextValues.aplicavel}
            >
              {({getFieldValue, setFieldsValue}) => {
                const aplicavel = getFieldValue('aplicavel');
                if (aplicavel) {
                  setFieldsValue({'dataAplicavel': moment()});
                  return (
                      <Form.Item name="dataAplicavel" label={t('Informado em')}>
                        <InputDate disabled />
                      </Form.Item>);
                }
                setFieldsValue({'dataAplicavel': null});
                return null;
              }}
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.aplicavel !== nextValues.aplicavel}
            >
              {({getFieldValue, setFieldsValue}) => {
                const aplicavel = getFieldValue('aplicavel');
                if (aplicavel)
                  return (
                      <Form.Item name="dataLimiteApresentacao" label={t('Data Limite da Apresentação')}>
                        <InputDate disabled={disabled} />
                      </Form.Item>);
                setFieldsValue({'dataLimiteApresentacao': null});
                return null;
              }}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.aplicavel !== nextValues.aplicavel}
        >
          {({getFieldValue, setFieldsValue}) => {
            const aplicavel = getFieldValue('aplicavel');
            if (aplicavel)
              return (
                  <>
                    <Row gutter={[16, 8]}>
                      <Col flex="200px">
                        <Form.Item name="apresentacao" label={t('Apresentação')}>
                          <SelectEmpresaBeneficiarioSocioApresentacao disabled={disabled} />
                        </Form.Item>
                      </Col>
                      <Col flex="200px">
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, nextValues) => prevValues.apresentacao !== nextValues.apresentacao}
                        >
                          {({getFieldValue, setFieldsValue}) => {
                            const apresentacao = getFieldValue('apresentacao');
                            if (apresentacao === Apresentacao.Sim.id)
                              return (
                                  <Form.Item name="dataApresentacao" label={t('Data de Apresentação')}>
                                    <InputDate disabled={disabled} />
                                  </Form.Item>);
                            setFieldsValue({'dataApresentacao': null});
                            return null;
                          }}
                        </Form.Item>
                      </Col>
                      <Col flex="200px">
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, nextValues) => prevValues.apresentacao !== nextValues.apresentacao}
                        >
                          {({getFieldValue, setFieldsValue}) => {
                            const apresentacao = getFieldValue('apresentacao');
                            if (apresentacao === Apresentacao.Sim.id)
                              return (
                                  <Form.Item name="aceite" label={t('Aceite')}>
                                    <SelectEmpresaBeneficiarioSocioAceite disabled={disabled} />
                                  </Form.Item>);
                            setFieldsValue({'aceite': Aceite.Pendente.id});
                            return null;
                          }}
                        </Form.Item>
                      </Col>
                      <Col flex="200px">
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, nextValues) => prevValues.aceite !== nextValues.aceite}
                        >
                          {({getFieldValue, setFieldsValue}) => {
                            const aceite = getFieldValue('aceite');
                            if (aceite === Aceite.Sim.id)
                              return (
                                  <Form.Item name="dataAceite" label={t('Data de Aceite')}>
                                    <InputDate disabled={disabled} />
                                  </Form.Item>);
                            setFieldsValue({'dataAceite': null});
                            return null;
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                      <Col flex="800px">
                        <Form.Item name="anexo" label={t('Anexo')}>
                          <InputAnexo disabled={loading} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                      <Col flex="800px">
                        <Form.Item name="obs" label={t('Observação')}>
                          <Input.TextArea autoSize disabled={disabled} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>);
            setFieldsValue({'apresentacao': Apresentacao.Pendente.id});
            setFieldsValue({'anexo': null});
            setFieldsValue({'obs': null});
            return null;
          }}
        </Form.Item>
      </MiniForm>);
}

EmpresaBeneficiarioSocioForm.propTypes = {
  disabled: PropTypes.bool,
  empresaId: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
