import React, { useEffect, useState } from 'react';
import { Modal, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styles from './_WorkflowTarefaModal.less';
import {
  etapaByIdSelector,
  fetchWorkflowTarefa,
  logsByTarefaIdSelector,
  removeWorkflowTarefaMembro,
  selectedTarefaSelector,
  selectWorkflowTarefaId,
  tarefasAnexosSelector,
  tarefasItensSelector,
  tarefasMembrosSelector,
  tipoTarefaByIdSelector,
  updateWorkflowTarefaDataEntrega,
  updateWorkflowTarefaDataPrazo,
  updateWorkflowTarefaDescricao,
  updateWorkflowTarefaEmpresa,
  updateWorkflowTarefaNome,
  updateWorkflowTarefaUnidade,
  workflowSelector
} from "../../../store/workflow";
import { useSelector } from "react-redux";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import WorkflowTipoTarefaTag from "../_WorkflowTipoTarefaTag";
import { UserOutlined } from "@ant-design/icons";
import ActionLink from "../../../components/action-link";
import ActionBar from "../../../components/action-bar";
import WorkflowTarefaDescricao from "./_WorkflowTarefaDescricao";
import WorkflowTarefaDataPrazo from "./_WorkflowTarefaDataPrazo";
import WorkflowTarefaNome from "./_WorkflowTarefaNome";
import WorkflowTarefaDataEntrega from "./_WorkflowTarefaDataEntrega";
import useFormatter from "../../../hooks/useFormatter";
import moment from "moment";
import WorkflowTarefaItemCriar from "./_WorkflowTarefaItemCriar";
import WorkflowTarefaItem from "./_WorkflowTarefaItem";
import WorkflowTarefaMembroAdicionar from "./_WorkflowTarefaMembroAdicionar";
import useLoading from "../../../hooks/useLoading";
import WorkflowTarefaAnexoCriar from "./_WorkflowTarefaAnexoCriar";
import WorkflowTarefaAnexo from "./_WorkflowTarefaAnexo";
import { isClienteGestorSelector, isClienteOperadorSelector, usuarioIdSelector } from "../../../store/autenticacao";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";
import SelectWorkflowEmpresa from "./_SelectWorkflowEmpresa";
import SelectWorkflowUnidade from "./_SelectWorkflowUnidade";

export default function WorkflowTarefaModal() {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();
  const dispatch = useDispatchWithResult();
  const isClienteOperador = useSelector(isClienteOperadorSelector);
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const usuarioId = useSelector(usuarioIdSelector);
  const loading = useLoading(fetchWorkflowTarefa.typePrefix);
  const { id, nome, descricao, dataPrazo, dataEntrega, empresa, unidade, ...tarefa } = useSelector(selectedTarefaSelector);
  const workflow = useSelector(workflowSelector);
  const membros = useSelector(tarefasMembrosSelector(id));
  const anexos = useSelector(tarefasAnexosSelector(id));
  const itens = useSelector(tarefasItensSelector(id));
  const logs = useSelector(logsByTarefaIdSelector(id));
  const etapa = useSelector(etapaByIdSelector(tarefa.etapa));
  const tipoTarefa = useSelector(tipoTarefaByIdSelector(tarefa.tipoTarefa));
  const [now, setNow] = useState(Date.now());

  const workflowId = workflow.id;

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 60000);
    return () => clearInterval(interval);
  }, []);

  const handleNomeSubmit = nome => unwrapAxiosPromise(dispatch(updateWorkflowTarefaNome({ workflowId, id, nome })));
  const handleDescricaoSubmit = descricao => unwrapAxiosPromise(dispatch(updateWorkflowTarefaDescricao({ workflowId, id, descricao })));
  const handleDataPrazoSubmit = dataPrazo => unwrapAxiosPromise(dispatch(updateWorkflowTarefaDataPrazo({ workflowId, id, dataPrazo })));
  const handleDataEntregaSubmit = dataEntrega => unwrapAxiosPromise(dispatch(updateWorkflowTarefaDataEntrega({ workflowId, id, dataEntrega })));
  const handleEmpresaSubmit = empresaId => unwrapAxiosPromise(dispatch(updateWorkflowTarefaEmpresa({ workflowId, id, empresaId })));
  const handleEmpresaUnload = () => unwrapAxiosPromise(dispatch(fetchWorkflowTarefa({ workflowId, id })));
  const handleUnidadeSubmit = unidadeId => unwrapAxiosPromise(dispatch(updateWorkflowTarefaUnidade({ workflowId, id, unidadeId })));
  const handleUnidadeUnload = () => unwrapAxiosPromise(dispatch(fetchWorkflowTarefa({ workflowId, id })));
  const handleMembroRemoveClick = membroId => unwrapAxiosPromise(dispatch(removeWorkflowTarefaMembro({ workflowId, id, membroId })));
  const handleCancel = e => dispatch(selectWorkflowTarefaId(null));

  return (
    <Modal
      title={
        <div className={styles['titulo']}>
          <div>
            <WorkflowTarefaNome nome={nome} onSubmit={handleNomeSubmit} readOnly={!isClienteOperador}/>
            <div>({etapa.nome})</div>
          </div>
          <div><WorkflowTipoTarefaTag {...tipoTarefa} /></div>
        </div>
      }
      open={id > 0}
      destroyOnClose
      footer={false}
      onCancel={handleCancel}
    >
      <Spin spinning={loading}>
        <div className={styles['workflow-tarefa-modal']}>
          <div className={styles['cabecalho']}>{t('Descrição')}</div>
          <div>
            <WorkflowTarefaDescricao descricao={descricao} onSubmit={handleDescricaoSubmit} readOnly={!isClienteOperador}/>
          </div>
          <div className={styles['datas']}>
            <div>
              <div className={styles['cabecalho']}>{t('Prazo')}</div>
              <div>
                <WorkflowTarefaDataPrazo dataPrazo={dataPrazo} onSubmit={handleDataPrazoSubmit} readOnly={!isClienteOperador}/>
              </div>
            </div>
            <div>
              <div className={styles['cabecalho']}>{t('Entrega')}</div>
              <div>
                <WorkflowTarefaDataEntrega dataEntrega={dataEntrega} onSubmit={handleDataEntregaSubmit} readOnly={!isClienteOperador}/>
              </div>
            </div>
          </div>
          <div className={styles['cabecalho']}>{t('Empresa')}</div>
          <div>
            <SelectWorkflowEmpresa workflowId={workflowId} empresa={empresa} onSubmit={handleEmpresaSubmit} onUnload={handleEmpresaUnload} readOnly={!isClienteOperador}/>
          </div>
          {!!empresa && <div className={styles['cabecalho']}>{t('Unidade')}</div>}
          {!!empresa &&
            <div>
              <SelectWorkflowUnidade workflowId={workflowId} empresaId={empresa?.id} unidade={unidade} onSubmit={handleUnidadeSubmit} onUnload={handleUnidadeUnload} readOnly={!isClienteOperador}/>
            </div>}
          <div className={styles['cabecalho']}>{t('Membros')}</div>
          <div className={styles['conteudo']}>
            {membros?.map(membro => (
              <div key={membro.id} className={styles['item']}>
                <div><UserOutlined/></div>
                <div>{membro.nome}</div>
                {(isClienteGestor || isClienteOperador && membro.id !== usuarioId) &&
                  <div>
                    <ActionBar>
                      <ActionLink.Remove noTitle onClick={() => handleMembroRemoveClick(membro.id)}/>
                    </ActionBar>
                  </div>
                }
              </div>
            ))}
            {isClienteOperador &&
              <div>
                <WorkflowTarefaMembroAdicionar tarefaId={id}/>
              </div>
            }
          </div>
          <div className={styles['cabecalho']}>{t('Anexos')}</div>
          <div className={styles['conteudo']}>
            {anexos?.map(anexo => (
              <WorkflowTarefaAnexo key={anexo.id} {...anexo} />
            ))}
            {isClienteOperador &&
              <div>
                <WorkflowTarefaAnexoCriar tarefaId={id}/>
              </div>
            }
          </div>
          <div className={styles['cabecalho']}>{t('Itens')}</div>
          <div className={styles['conteudo']}>
            {itens?.map(item => (
              <WorkflowTarefaItem key={item.id} {...item} />
            ))}
            {isClienteOperador &&
              <div>
                <WorkflowTarefaItemCriar tarefaId={id}/>
              </div>
            }
          </div>
          <div className={styles['cabecalho']}>{t('Log')}</div>
          <div className={styles['logs']}>
            {logs?.map(log => (
              <div key={log.id}>
                <div>{log.texto}</div>
                <div className={styles['log-autor']}>
                  <div>
                    <Tooltip title={formatDateTime(log.createdAt)}>
                      {moment(log.createdAt).fromNow()}
                    </Tooltip>
                  </div>
                  <div>{t('por')} <strong>{log.usuario.nome}</strong></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Spin>
    </Modal>
  )
}