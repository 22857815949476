import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import TableCellColor from "../../../components/table-cell-color";

export default function AreasTable({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();

  const fireClick = area => typeof onClick === 'function' ? onClick(area) : undefined;

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('Cor'),
      dataIndex: 'cor',
      key: 'cor',
      width: 50,
      render: (text) => <TableCellColor text={text}/>
    },
    {
      title: t('Responsável'),
      dataIndex: ['responsavel', 'nome'],
      key: 'responsavel',
    },
    {
      title: t('Id Externo'),
      dataIndex: 'idExterno',
      key: 'idExterno',
    },
  ];

  if (dataSource.length > 0)
    return (
      <MiniTable
        ref={miniTableRef}
        columns={columns}
        dataSource={dataSource}
        expandable={{
          childrenColumnName: 'areasFilhas',
          defaultExpandAllRows: true
        }}
        loading={loading}
        title={title}
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: onSelectedIdsChange,
        }}
      />
    );
  return title();
}

AreasTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
