import React, { useState } from "react";
import styles from './_WorkflowTipoTarefaCor.less';
import PropTypes from "prop-types";
import { CirclePicker } from "react-color";

const colors = [
  '#009EDB',
  '#00E8E8',
  '#00E8A9',
  '#81BE1C',
  '#E8E800',
  '#E8A900',
  '#543B23',
  '#E80000',
  '#E800E8',
  '#9E00E8',
];

export default function WorkflowTipoTarefaCor(props) {
  const { cor, onSubmit } = props;
  const [edicao, setEdicao] = useState(false);

  const fireSubmit = cor => {
    let promise = Promise.resolve();
    if (typeof onSubmit === 'function')
      promise = promise.then(() => onSubmit(cor));
    return promise;
  }

  const handleClick = e => {
    e.preventDefault();
    setEdicao(true);
  }

  const handleBlur = e => {
    setEdicao(false);
  }

  const handleChange = e => {
    fireSubmit(e.hex.slice(1)).then(() => setEdicao(false));
  }

  if (edicao) {
    return (
      <div className={styles['workflow-tipo-tarefa-cor-edicao']} onBlur={handleBlur}>
        <CirclePicker color={'#' + cor} colors={colors} onChangeComplete={handleChange} width="unset"/>
      </div>
    );
  }

  return (
    <div className={styles['workflow-tipo-tarefa-cor']} onClick={handleClick}>
      <div style={{ backgroundColor: '#' + cor }}/>
    </div>
  );
}

WorkflowTipoTarefaCor.propTypes = {
  cor: PropTypes.string,
  onSubmit: PropTypes.func
}