import { TreeSelect } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import styles from './_styles.less';

export default function SelectConsultivoArea({ filter, filterChildren, onChange, params, value, ...props }) {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData] = useState([]);

  const fetchSearch = useCallback(search => {
    setLoadingSearch(true);
    unwrapAxiosPromise(axios.get(`/consultivo/areas`, { params: { search, ...params } }))
      .then(data => setData(data))
      .finally(() => setLoadingSearch(false));
  }, []);

  const treeData = useMemo(() => {
    return data.map(area => mapTreeData(area, filter, false, filterChildren));
  }, [data, filter, filterChildren]);

  const handleChange = id => {
    onChange(id);
  };

  const handleSearch = useDebouncedCallback(500, search => {
    fetchSearch(search);
  }, []);

  useEffect(() => {
    fetchSearch();
  }, []);

  return (
    <TreeSelect
      fieldNames={{
        label: 'nome',
        value: 'id',
        children: 'areasFilhas'
      }}
      filterTreeNode={false}
      loading={loadingSearch}
      onChange={handleChange}
      onSearch={handleSearch}
      showSearch
      treeData={treeData}
      treeDefaultExpandAll
      treeLine
      treeNodeLabelProp="caminho"
      value={value}
      {...props}
    />
  );
}

SelectConsultivoArea.propTypes = {
  filter: PropTypes.func,
  filterChildren: PropTypes.bool,
  onChange: PropTypes.func,
  params: PropTypes.any,
  value: PropTypes.number,
};

function mapTreeData(area, filter, disabled = false, filterChildren = false) {
  let disabledChildren = disabled;
  if (!disabled) {
    if (typeof filter === 'function' && !filter(area)) {
      disabled = true;
      if (filterChildren)
        disabledChildren = true;
    }
  }
  return {
    ...area,
    areasFilhas: area.areasFilhas?.map(af => mapTreeData(af, filter, disabledChildren, filterChildren)),
    disabled,
    key: area.id,
    nome: (
      <div className={styles['tree-node']}>
        <div className={styles['icon']} style={{ backgroundColor: '#' + area.cor }}/>
        <div>{area.nome}</div>
      </div>
    ),
    caminho: (
      <div className={styles['tree-node']}>
        <div className={styles['icon']} style={{ backgroundColor: '#' + area.cor }}/>
        <div>{area.caminho}</div>
      </div>
    )
  }
}
