// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".koCyccDh-IrHcFK44HlbvA\\=\\= {\n  line-height: 32px;\n  padding: 0 8px;\n}\n.koCyccDh-IrHcFK44HlbvA\\=\\=:not(.dqdIZyVkv-V2TLqnjcTwTw\\=\\=) {\n  cursor: pointer;\n}\n.koCyccDh-IrHcFK44HlbvA\\=\\=:not(.dqdIZyVkv-V2TLqnjcTwTw\\=\\=):hover {\n  background-color: #f0f2f5;\n}\n.sfcPPDcqZI0IyhQkf5lrkg\\=\\= {\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_WorkflowTarefaDescricao.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;AACF;AAEA;EACE,eAAA;AAAF;AAEE;EACE,yBAAA;AAAJ;AAIA;EACE,cAAA;AAFF","sourcesContent":[".workflow-tarefa-descricao {\n  line-height: 32px;\n  padding: 0 8px;\n}\n\n.workflow-tarefa-descricao:not(.read-only) {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.nao-preenchido {\n  color: #999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-descricao": "koCyccDh-IrHcFK44HlbvA==",
	"read-only": "dqdIZyVkv-V2TLqnjcTwTw==",
	"nao-preenchido": "sfcPPDcqZI0IyhQkf5lrkg=="
};
export default ___CSS_LOADER_EXPORT___;
