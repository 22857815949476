import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import * as d3 from 'd3';
import * as d3dag from 'd3-dag';
import styles from './_styles.less';
import useFormatter from "../../hooks/useFormatter";
import { formatDocumento } from "../../util/documento";
import OrganogramaTooltip from "./_OrganogramaTooltip";
import ActionLink from "../action-link";
import PropTypes from "prop-types";
import { openPopupEmpresa } from "../../util/window";

export default function Organograma({ organograma, onUnload, defaultLayout }) {
  const { formatPercentage } = useFormatter();
  const [empresa, setEmpresa] = useState(null);
  const [hoverTarget, setHoverTarget] = useState(null);
  const [tooltipTarget, setTooltipTarget] = useState(null);
  const [currentLayout, setCurrentLayout] = useState(defaultLayout);
  const id = useMemo(() => "organograma-" + new Date().getTime(), []);

  const isRelated = useCallback((data1, data2) => {
    if (data1.documento === data2.documento)
      return true;
    for (let socio of data1.socios)
      if (socio.documento === data2.documento)
        return true;
    for (let socio of data2.socios)
      if (socio.documento === data1.documento)
        return true;
    return false;
  }, []);

  const isSocio = useCallback((socios, socio) => {
    for (let s of socios)
      if (socio.documento === s.documento)
        return true;
    return false;
  }, [])

  const handleLayout = useCallback(() => {
    setCurrentLayout(currentLayout => {
      switch (currentLayout) {
        case "curved":
          return "rectangular";
        case "rectangular":
          return "curved";
      }
    })
  }, []);

  const handleTooltipClick = useCallback((empresa, tab) => {
    openPopupEmpresa(empresa, tab, { onUnload });
  }, [onUnload]);

  const handleSave = useCallback(() => {
    const svg = document.querySelector("#" + id + " > #svg");
    const width = svg.getAttribute("width");
    const height = svg.getAttribute("height");
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    const DOMURL = window.URL || window.webkitURL || window;
    const imageSrc = DOMURL.createObjectURL(new Blob([svgString], { type: 'image/svg+xml' }));
    const image = new Image(width, height);

    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', width);
      canvas.setAttribute('height', height);
      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, width, height);
      const dataURL = canvas.toDataURL();
      const a = document.createElement('a');
      a.setAttribute('download', window.document.title + '.png');
      a.setAttribute('href', dataURL);
      a.click();
      DOMURL.revokeObjectURL(imageSrc);
    });

    image.setAttribute("src", imageSrc);
  }, [id]);

  useLayoutEffect(() => {
    function arrowTransform({ points }) {
      const [[x1, y1], [x2, y2]] = points.slice(-2);
      const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 90;
      return `translate(${x2}, ${y2}) rotate(${angle})`;
    }

// create our builder and turn the raw data into a graph
    const builder = d3dag.graphStratify()
      .id(d => d.documento)
      .parentIds((d, i) => d.socios.map(s => s.documento))
      .parentData((d, i) => d.socios.map(socio => ([socio.documento, socio])));
    const graph = builder(organograma);

    const getLinks = () => {
      if (currentLayout === 'curved')
        return graph.links();
      const sourcesCount = {};
      const links = [];
      for (let link of graph.links()) {
        const p0 = [link.source.x, link.source.y + nodeSize[1] / 2];
        const nchildlinks = link.source.nchildLinks();
        let gap = (nodeSize[0] - 8) / nchildlinks;
        const sourceCount = sourcesCount[link.source.data.documento] ?? 0;
        if (sourceCount % 2 === 0)
          p0[0] += gap * (sourceCount / 2)
        else
          p0[0] -= gap * ((sourceCount + 1) / 2)
        if (nchildlinks % 2 === 0)
          p0[0] += gap / 2;

        const p2 = [link.target.x, link.target.y - nodeSize[1] / 2];

        const p1 = [
          (p0[0] + p2[0]) / 2,
          p2[1] - 64,
        ]
        link.points = [
          p0,
          [p0[0], p1[1]],
          [p1[0], p1[1]],
          [p2[0], p1[1]],
          p2,
        ]
        links.push(link);

        sourcesCount[link.source.data.documento] = sourceCount + 1;
      }
      return links;
    };

// -------------- //
// Compute Layout //
// -------------- //

// set the layout functions
    const nodeSize = [160, 48]
// this truncates the edges so we can render arrows nicely
    const shape = d3dag.tweakShape(nodeSize, d3dag.shapeRect);
// use this to render our edges
    const line = d3.line();
// here's the layout operator, uncomment some of the settings
    const layout = d3dag
      .sugiyama()
      .nodeSize(nodeSize)
      .gap([32, 128])
      .tweaks([shape]);

// actually perform the layout and get the final size
    const { width, height } = layout(graph);

// --------- //
// Rendering //
// --------- //;

// global
    const svg = d3
      .select("#" + id + " > #svg")
      // pad a little for link thickness
      .attr("width", width + 32)
      .attr("height", height + 32)
      .style('background-color', '#f0f2f5');
    const trans = svg.transition().duration(250);
    svg
      .select("g")
      .attr("transform", (d) => `translate(16, 16)`)

// nodes
    const handleClick = (e, d) => {
      e.stopPropagation();
      setTooltipTarget(target => {
        if (hoverTarget === target)
          return null;
        return hoverTarget;
      })
    }
    const handleMouseEnter = (e, d) => {
      e.stopPropagation();
      e.target.classList.add(styles['hover']);
      setEmpresa(d.data);
      setHoverTarget(e.target);
      setTooltipTarget(target => target ? e.target : null);
    }

    const handleMouseLeave = (e, d) => {
      e.stopPropagation();
      e.target.classList.remove(styles['hover']);
      setHoverTarget(null);
    }

    svg
      .select("#nodes")
      .selectAll("g")
      .data(graph.nodes())
      .join(
        (enter) =>
          enter
            .append("g")
            .attr("transform", (d) => `translate(${d.x}, ${d.y})`)
            .attr("opacity", 0)
            .attr("class", d => d.data.tipo === 'empresa' ? styles['node-empresa'] : styles['node'])
            .call((enter) => {
              enter
                .append("rect")
                .attr("x", -nodeSize[0] / 2)
                .attr("y", -nodeSize[1] / 2)
                .attr("rx", "4")
                .attr("width", nodeSize[0])
                .attr("height", nodeSize[1])
                .attr("fill", d => d.data.tipo === 'empresa' ? '#009edb' : d.data.documento.length === 14 ? '#262626' : '#565659');
              enter
                .append("text")
                .attr("class", styles['text'])
                .style("fill", 'rgba(240, 242, 245, 0.8)')
                .style("text-anchor", 'middle')
                .style('font-weight', 'normal')
                .style('font-family', 'Verdana')
                .style('font-size', '11px')
                .call((enter) => {
                  enter
                    .append("tspan")
                    .text(d => {
                      if (d.data.nome.length > 20)
                        return d.data.nome.slice(0, 17).trim() + "...";
                      else
                        return d.data.nome;
                    })
                    .attr("x", 0)
                    .attr("y", -8)
                    .style("alignment-baseline", 'central');
                  enter
                    .append("tspan")
                    .text(d => formatDocumento(d.data.documento))
                    .attr("x", 0)
                    .attr("dy", 16)
                    .style('font-weight', 'bold')
                    .style("alignment-baseline", 'central');
                });
              enter
                .on('mouseenter', handleMouseEnter)
                .on('mouseleave', handleMouseLeave);
            }),
        update => {
          update.transition(trans).attr("opacity", d => (!hoverTarget || isRelated(d.data, empresa)) ? 1 : 0);
          update.on('click', handleClick);
        }
      );

// link paths
    svg
      .select("#links")
      .selectAll("path")
      .data(getLinks())
      .join((enter) => {
          enter
            .append("path")
            .attr("fill", "none")
            .attr("stroke-width", 2)
            .attr("stroke", d => d.source.data.tipo === 'empresa' ? '#009edb' : '#262626')
            .attr("opacity", 0);
        },
        update => {
          update
            .attr("d", ({ points }) => line(points))
            .transition(trans).attr("opacity", d => {
            if (!hoverTarget)
              return 1;
            if (empresa.documento === d.target.data.documento)
              return 1;
            if (empresa.documento === d.source.data.documento)
              return 1;
            return 0;
          });
        }
      );

// Arrows
    const arrowSize = 64;
    const arrowLen = Math.sqrt((4 * arrowSize) / Math.sqrt(3));
    const arrow = d3.symbol().type(d3.symbolTriangle).size(arrowSize);
    svg
      .select("#arrows")
      .selectAll("path")
      .data(getLinks())
      .join((enter) =>
          enter
            .append("path")
            .attr("d", arrow)
            .attr("fill", d => d.source.data.tipo === 'empresa' ? '#009edb' : '#262626')
            .attr("opacity", 0)
            .attr("stroke", "#f0f2f5")
            .attr("stroke-width", 2)
            // use this to put a white boundary on the tip of the arrow
            .attr("stroke-dasharray", `${arrowLen},${arrowLen}`),
        update => {
          update
            .attr("transform", arrowTransform)
            .transition(trans).attr("opacity", d => {
            if (!hoverTarget)
              return 1;
            if (empresa.documento === d.target.data.documento)
              return 1;
            if (empresa.documento === d.source.data.documento)
              return 1;
            return 0;
          });
        }
      );

// links labels paths
    svg
      .select("#links")
      .selectAll("g")
      .data(getLinks())
      .join((enter) => {
          enter
            .append("g")
            .filter(d => d.data.capitalPercentual > 0)
            .attr("opacity", 0)
            .call(enter =>
              enter
                .append("text")
                .text(d => {
                  if (d.data.capitalPercentual < 0.01)
                    return "<" + formatPercentage(0.01) + "%";
                  if (d.data.capitalPercentual > 0.99 && d.data.capitalPercentual < 1)
                    return ">" + formatPercentage(0.99) + "%";
                  return formatPercentage(d.data.capitalPercentual, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%';
                })
                .attr('font-family', 'Consolas')
                .attr('stroke', '#f0f2f5')
                .attr('stroke-width', '4px')
                .attr('stroke-linecap', 'butt')
                .attr('stroke-linejoin', 'miter')
                .attr('paint-order', 'stroke')
                .attr('transition', 'all 250ms ease-in-out')
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .attr('font-size', '12px')
                .attr("fill", d => d.source.data.tipo === 'empresa' ? '#009edb' : '#262626'))
        },
        update => {
          update
            .attr("transform", ({ points, ...d }) => {
              let x1, y1, x2, y2;
              if (currentLayout === 'rectangular')
                [[x1, y1], [x2, y2]] = points;
              else
                [[x2, y2], [x1, y1]] = points.slice(-2);
              const dx = x2 - x1;
              const dy = y2 - y1;
              const len = Math.sqrt(dx * dx + dy * dy);
              const x = x1 + 32 * (dx / len);
              const y = y1 + 32 * (dy / len);
              return `translate(${x}, ${y})`
            })
            .transition(trans).attr("opacity", d => {
            if (!hoverTarget)
              return 1;
            if (empresa.documento === d.target.data.documento)
              return 1;
            if (empresa.documento === d.source.data.documento)
              return 1;
            return 0;
          });
        }
      );
  }, [id, empresa, isRelated, isSocio, hoverTarget, currentLayout, organograma, formatPercentage]);

  return (
    <div className={styles['organograma-wrapper']}>
      <div id={id} className={styles['organograma']}>
        <div className="grow"/>
        <svg id="svg">
          <g>
            <g id="links"/>
            <g id="nodes"/>
            <g id="arrows"/>
            <g id="tooltips"/>
          </g>
        </svg>
        <div className="grow"/>
      </div>
      <div className={styles["buttons"]}>
        <ActionLink.Layout noTitle onClick={handleLayout}/>
        <ActionLink.Download noTitle onClick={handleSave}/>
      </div>
      <OrganogramaTooltip empresa={empresa} onClick={handleTooltipClick} target={tooltipTarget}/>
    </div>
  );
}

Organograma.defaultProps = {
  defaultLayout: 'curved'
}

Organograma.propTypes = {
  organograma: PropTypes.object,
  onUnload: PropTypes.func,
  defaultLayout: PropTypes.oneOf(['curved', 'rectangular']),
}
