import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaCBEsDetailPage from './_detail';
import EmpresaCBEPage from './cbe';

export default function EmpresaCBEsPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/cbes/:id" component={EmpresaCBEPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaCBEsDetailPage} />
        </Switch>
      </Card>);
}
