// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bQki78wN1Up8D8O2C63-eA\\=\\= {\n  cursor: pointer;\n  line-height: 32px;\n  font-weight: bold;\n  padding: 0 8px;\n  margin-right: 16px;\n}\n.bQki78wN1Up8D8O2C63-eA\\=\\=:hover {\n  background-color: #f0f2f5;\n}\n.\\--Gopt8bDUIYDjAejIOhOw\\=\\= {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tipoTarefa/_WorkflowTipoTarefaNome.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,yBAAA;AACJ;AAGA;EACE,kBAAA;AADF","sourcesContent":[".workflow-tipo-tarefa-nome {\n  cursor: pointer;\n  line-height: 32px;\n  font-weight: bold;\n  padding: 0 8px;\n  margin-right: 16px;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.form {\n  margin-right: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tipo-tarefa-nome": "bQki78wN1Up8D8O2C63-eA==",
	"form": "--Gopt8bDUIYDjAejIOhOw=="
};
export default ___CSS_LOADER_EXPORT___;
