import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Endereco} from '../../constants';

const {Estado} = Endereco;

export default function SelectEnderecoEstado({max, ...props}) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select showSearch {...props} >
        {createOption(Estado.AC)}
        {createOption(Estado.AL)}
        {createOption(Estado.AP)}
        {createOption(Estado.AM)}
        {createOption(Estado.BA)}
        {createOption(Estado.CE)}
        {createOption(Estado.ES)}
        {createOption(Estado.GO)}
        {createOption(Estado.MA)}
        {createOption(Estado.MT)}
        {createOption(Estado.MS)}
        {createOption(Estado.MG)}
        {createOption(Estado.PA)}
        {createOption(Estado.PB)}
        {createOption(Estado.PR)}
        {createOption(Estado.PE)}
        {createOption(Estado.PI)}
        {createOption(Estado.RJ)}
        {createOption(Estado.RN)}
        {createOption(Estado.RS)}
        {createOption(Estado.RO)}
        {createOption(Estado.RR)}
        {createOption(Estado.SC)}
        {createOption(Estado.SP)}
        {createOption(Estado.SE)}
        {createOption(Estado.TO)}
        {createOption(Estado.DF)}
      </Select>
  );
}

SelectEnderecoEstado.propTypes = {
  value: PropTypes.oneOf([
    Endereco.Estado.AC.id,
    Endereco.Estado.AL.id,
    Endereco.Estado.AP.id,
    Endereco.Estado.AM.id,
    Endereco.Estado.BA.id,
    Endereco.Estado.CE.id,
    Endereco.Estado.ES.id,
    Endereco.Estado.GO.id,
    Endereco.Estado.MA.id,
    Endereco.Estado.MT.id,
    Endereco.Estado.MS.id,
    Endereco.Estado.MG.id,
    Endereco.Estado.PA.id,
    Endereco.Estado.PB.id,
    Endereco.Estado.PR.id,
    Endereco.Estado.PE.id,
    Endereco.Estado.PI.id,
    Endereco.Estado.RJ.id,
    Endereco.Estado.RN.id,
    Endereco.Estado.RS.id,
    Endereco.Estado.RO.id,
    Endereco.Estado.RR.id,
    Endereco.Estado.SC.id,
    Endereco.Estado.SP.id,
    Endereco.Estado.SE.id,
    Endereco.Estado.TO.id,
    Endereco.Estado.DF.id]),
  onChange: PropTypes.func,
};
