import { createSlice } from '@reduxjs/toolkit';
import { EmpresaTabByName } from "../constants";

const initialState = {
  empresas: [],
};

export const empresasSelector = state => state.complianceTabela.empresas;

export const slice = createSlice({
  name: 'complianceTabela',
  initialState,
  reducers: {
    fetchTabela(state, action) {
      state.empresas = [];
      action.payload.empresas.forEach(empresa => {
        const abas = action.payload.alertasByEmpresaId[empresa.id]
          ?.filter(aba => EmpresaTabByName[aba.nome]?.modulos?.includes('compliance') === true)
          ?.reduce((pv, cv) => {
            pv[cv.nome] = cv;
            return pv;
          }, {}) ?? {};
        state.empresas.push({ ...empresa, abas });
      });
    },
  },
  extraReducers: {},
});

export default slice.reducer;

export const { fetchTabela } = slice.actions;
