import { useTranslation } from 'react-i18next';
import { useMemo } from "react";
import unorm from "unorm";

export default function useCollator() {
  const { t, i18n } = useTranslation();

  const collator = useMemo(() =>
      new Intl.Collator(i18n.language, { usage: 'search', sensitivity: 'base' }),
    [i18n.language]);

  const hook = {
    collator,
    /**
     * @param a {string}
     * @param b {string}
     * @return {number}
     */
    compare(a, b) {
      if (a === b)
        return 0;
      if (a === undefined || a === null)
        return 1;
      if (b === undefined || b === null)
        return -1;
      return collator.compare(a, b);
    },
    /**
     * @param str {string}
     * @return {string}
     */
    normalize(str) {
      function match(letter) {
        const normalizedLetter = unorm.nfkd(letter).replace(/[\u0300-\u036f]/gi, '');
        const compara = hook.compare(letter, normalizedLetter);
        if (compara === 0)
          return normalizedLetter;
        return letter;
      }

      if (str === undefined || str === null)
        return '';

      return str.replace(/[^\u0000-\u007E]/g, match);
    },
    /**
     * @param str {string}
     * @param searchString {string}
     * @param position {number | undefined}
     * @return {boolean}
     */
    contains(str, searchString, position = undefined) {
      if (str === searchString)
        return true;
      if (searchString?.length > str?.length)
        return false;
      const a = hook.normalize(str).toLocaleLowerCase(i18n.language);
      const b = hook.normalize(searchString).toLocaleLowerCase(i18n.language);
      return a.indexOf(b, position) >= 0;
    },
  };
  return hook;
};
