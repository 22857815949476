import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { defSelector, fetchDEF, isEditableSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaDEFDetailPage() {
  const {t} = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const def = useSelector(defSelector);
  const {formatDateTime, formatDate, formatBoolean} = useFormatter();
  const loading = useLoading(fetchDEF.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/defs/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/defs`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/defs/${id}/`, `logs_/empresas/${empresaId}/defs/${id}/`);
  };

  return (
      <Spin spinning={loading}>
        <ActionBar>
          {isEditable && <ActionButton.Edit onClick={handleEdit} />}
          <ActionButton.Back onClick={handleBack} />
          <ActionButton.Logs noTitle onClick={handleLogs} />
        </ActionBar>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Data Base')}>{formatDate(def?.dataReferencia)}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Data da Apresentação')}>{formatDate(def?.dataApresentacao)}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextAnexo label={t('Anexo')} value={def?.anexo} /></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextItem label={t('Observação')}>{def?.obs}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label="Criado em">{formatDateTime(def?.createdAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(def?.updatedAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Id">{def?.id}</TextItem></Col>
        </Row>
      </Spin>
  );
}
