import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'loading',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder
        .addMatcher(
            action => action.type.endsWith('/pending'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 8);
              state[typePrefix] = true;
            })
        .addMatcher(
            action => action.type.endsWith('/fulfilled'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 10);
              state[typePrefix] = false;
            },
        )
        .addMatcher(
            action => action.type.endsWith('/rejected'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 9);
              state[typePrefix] = false;
            },
        );
  },
});

export default slice.reducer;
