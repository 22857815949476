import { setTwoToneColor } from '@ant-design/icons';
import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.less';
import enUS from 'antd/lib/locale/en_US';
import ptBR from 'antd/lib/locale/pt_BR';
import 'chart.js/auto';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { Suspense, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider, useSelector } from 'react-redux';
import './assets/fonts/effra/Effra.less';
import './global.less';
import BlankLayout from './layouts/blank';
import Router from './router';
import { unwrapResult } from './store';
import { fetchAcessos } from './store/acessos';
import { renovar } from './store/autenticacao';
import { languageSelector } from './store/i18n';
import { resize } from './store/layout';

setTwoToneColor('#009edb');

function LoadingPage() {
  return (
    <BlankLayout centered>
      <Spin size="large"/>
    </BlankLayout>
  );
}

function WithStore({ history }) {
  const [t, i18n] = useTranslation();
  const language = useSelector(languageSelector);

  useEffect(() => {
    switch (language) {
      default:
        moment.locale('en');
        break;
      case 'pt-BR':
      case 'pt':
        moment.locale('pt-br');
        break;
    }
  }, [language]);

  const locale = useMemo(() => {
    switch (language) {
      default:
        return enUS;
      case 'pt-BR':
      case 'pt':
        return ptBR;
    }
  }, [language]);

  return (
    <ConfigProvider
      locale={locale}
      componentSize="small"
      renderEmpty={componentName => <div>{t('Não há dados')}</div>}
    >
      <Router key={locale.locale} history={history}/>
    </ConfigProvider>
  );
}

export function App({ store, history }) {
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    function onResize() {
      store.dispatch(resize(window));
    }

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    store.dispatch(renovar()).then(unwrapResult)
      .then(() => {
        if (
          history.location.pathname.startsWith('/home') ||
          history.location.pathname.startsWith('/logs') ||
          history.location.pathname.startsWith('/empresas') ||
          history.location.pathname.startsWith('/consultivo/areas/') ||
          history.location.pathname.startsWith('/consultivo/consultas/') ||
          history.location.pathname.startsWith('/consultivo/projetos/') ||
          history.location.pathname.startsWith('/empresas') ||
          history.location.pathname.startsWith('/drive') ||
          history.location.pathname.startsWith('/drive-id'))
          return Promise.resolve();
        return store.dispatch(fetchAcessos()).then(unwrapResult);
      })
      .finally(() => setLoading(false));
  }, [history]);

  if (loading)
    return <LoadingPage/>;

  return (
    <Suspense fallback={<LoadingPage/>}>
      <Provider store={store}>
        <WithStore history={history}/>
      </Provider>
    </Suspense>
  );
}
