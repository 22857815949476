// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kuhe5FV3by6ln1d77BrJ9g\\=\\= {\n  display: flex;\n  flex-direction: row;\n}\n.kuhe5FV3by6ln1d77BrJ9g\\=\\= > span.ant-input-affix-wrapper {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n  z-index: 1;\n}\n.kuhe5FV3by6ln1d77BrJ9g\\=\\= > span.ant-input-affix-wrapper:hover,\n.kuhe5FV3by6ln1d77BrJ9g\\=\\= > span.ant-input-affix-wrapper:focus {\n  z-index: 3;\n}\n.kuhe5FV3by6ln1d77BrJ9g\\=\\= > button.ant-btn-icon-only.ant-btn-sm {\n  min-width: 24px;\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n  margin-left: -1px;\n}\n.kuhe5FV3by6ln1d77BrJ9g\\=\\= > button.ant-btn-icon-only.ant-btn-sm:hover,\n.kuhe5FV3by6ln1d77BrJ9g\\=\\= > button.ant-btn-icon-only.ant-btn-sm:focus {\n  z-index: 2;\n}\n", "",{"version":3,"sources":["webpack://src/components/input-names/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAHA;EAKI,0BAAA;EACA,6BAAA;EACA,UAAA;AACJ;AACI;;EACE,UAAA;AAEN;AAZA;EAeI,eAAA;EACA,yBAAA;EACA,4BAAA;EACA,iBAAA;AAAJ;AAEI;;EACE,UAAA;AACN","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n\n  > span:global(.ant-input-affix-wrapper) {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n    z-index: 1;\n\n    &:hover, &:focus {\n      z-index: 3;\n    }\n  }\n\n  > button:global(.ant-btn-icon-only.ant-btn-sm) {\n    min-width: 24px;\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n    margin-left: -1px;\n\n    &:hover, &:focus {\n      z-index: 2;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "kuhe5FV3by6ln1d77BrJ9g=="
};
export default ___CSS_LOADER_EXPORT___;
