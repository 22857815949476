import {Form, message} from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import ActionButton from '../../../components/action-button';
import InputAnexo from '../../../components/input-anexo';
import InputCnpj from '../../../components/input-cnpj';
import InputRazaoSocial from '../../../components/input-razao-social';
import MiniFormDrawer from '../../../components/mini-form-drawer';
import SelectAcesso from '../../../components/select-acesso';
import SelectEmpresaTipo from '../../../components/select-empresa-tipo';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import {isSuperUsuarioSelector} from '../../../store/autenticacao';
import {cabecalhoSelector, fetchCabecalho, fetchCadastro, fetchContratos, fetchSocios} from '../../../store/empresa';
import {valuesToFormData} from '../../../util/formData';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';

export default function EmpresaDrawer({onClose, visible}) {
  const isSuperUsuario = useSelector(isSuperUsuarioSelector);
  const {t} = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const cabecalho = useSelector(cabecalhoSelector);
  const loading = useLoading(fetchCabecalho.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const match = useRouteMatch();
  const empresaId = match.params.id;

  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  useEffect(() => {
    form.setFieldsValue(cabecalho);
  }, [cabecalho]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#cnpj').focus();
  };

  const handleFinish = values => {
    setLoadingSave(true);
    const formData = valuesToFormData(values);
    unwrapAxiosPromise(axios.put(`/empresas/${empresaId}/cabecalho`, formData), form)
        .then(() => {
          message.success(t('Registro alterado com sucesso'));
          dispatch(fetchCabecalho({empresaId}));
          dispatch(fetchCadastro({empresaId}));
          dispatch(fetchSocios({empresaId}));
          dispatch(fetchContratos({empresaId}));
          fireClose();
        })
        .finally(() => setLoadingSave(false));
  };

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loadingSave}
        />
        <ActionButton.Close
            onClick={fireClose}
            disabled={loadingSave}
        />
      </>
  );

  const drawerTitle = t('Alterar Empresa');

  return (
      <MiniFormDrawer
          drawerTitle={drawerTitle}
          drawerFooter={footer}
          drawerWidth={400}
          drawerVisible={visible}
          form={form}
          loading={loading}
          onDrawerClose={fireClose}
          onDrawerAfterOpen={handleDrawerAfterOpen}
          onFinish={handleFinish}
      >
        <Form.Item id="cnpj" name="cnpj" label={t('CNPJ')}>
          <InputCnpj disabled={loadingSave} />
        </Form.Item>
        <Form.Item name="razaoSocial" label={t('Razão Social')}>
          <InputRazaoSocial disabled={loadingSave} />
        </Form.Item>
        <Form.Item name="apelido" label={t('Nome de Exibição')}>
          <InputRazaoSocial disabled={loadingSave} />
        </Form.Item>
        <Form.Item name="acessoId" label={t('Acesso')}>
          <SelectAcesso disabled={loadingSave || !isSuperUsuario} />
        </Form.Item>
        <Form.Item name="tipo" label={t('Tipo')}>
          <SelectEmpresaTipo disabled={loadingSave} />
        </Form.Item>
        <Form.Item name="logo" label={t('Logo')}>
          <InputAnexo accept="image/*" disabled={loading} />
        </Form.Item>
      </MiniFormDrawer>
  );
}

EmpresaDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};
