import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import {EmpresaConta, findByIdAndMap} from '../../../constants';
import useFormatter from '../../../hooks/useFormatter';
import {compareNumbers} from '../../../util/number';
import {compareStrings} from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

const {Aprovacao, Registro, Publicacao, Status} = EmpresaConta;

export default function EmpresaContasTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatDate} = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Ano de Ref.'),
      key: 'anoReferencia',
      sorter: (a, b) => compareNumbers(a.anoReferencia, b.anoReferencia),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {record.anoReferencia ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Status'),
      key: 'status',
      width: 100,
      sorter: (a, b) => compareNumbers(a.status, b.status),
      render: (text, record) => findByIdAndMap(Status, record.status, status => t(status.label)),
    },
    {
      title: t('Publicação'),
      key: 'publicacao',
      width: 100,
      sorter: (a, b) => {
        let n = compareNumbers(a.publicacao, b.publicacao);
        if (n !== 0) return n;
        return compareStrings(a.dataPublicacao, b.dataPublicacao);
      },
      render: (text, record) => record.publicacao === Publicacao.Sim.id ?
          formatDate(record.dataPublicacao) :
          findByIdAndMap(Publicacao, record.publicacao, publicacao => t(publicacao.label)),
    },
    {
      title: t('Aprovação'),
      key: 'aprovacao',
      width: 100,
      sorter: (a, b) => {
        let n = compareNumbers(a.aprovacao, b.aprovacao);
        if (n !== 0) return n;
        return compareStrings(a.dataAprovacao, b.dataAprovacao);
      },
      render: (text, record) => record.aprovacao === Aprovacao.Sim.id ?
          formatDate(record.dataAprovacao) :
          findByIdAndMap(Aprovacao, record.aprovacao, aprovacao => t(aprovacao.label)),
    },
    {
      title: t('Registro'),
      key: 'registro',
      width: 100,
      sorter: (a, b) => {
        let n = compareNumbers(a.registro, b.registro);
        if (n !== 0) return n;
        return compareStrings(a.dataRegistro, b.dataRegistro);
      },
      render: (text, record) => record.registro === Registro.Sim.id ?
          formatDate(record.dataRegistro) :
          findByIdAndMap(Registro, record.registro, registro => t(registro.label)),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaContasTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
