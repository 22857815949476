import {CheckOutlined, CloseOutlined, LineOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import React, {useMemo, useState} from 'react';
import {formatDocumento, isValidDocumento, parseDocumento} from '../../util/documento';

export default function InputDocumento({onChange, value, ...props}) {
  const [editing, setEditing] = useState(false);
  const formattedValue = useMemo(() => formatDocumento(value), [value]);
  const isValid = useMemo(() => isValidDocumento(value), [value]);

  const fireChange = newValue => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(newValue);
  };
  const handleBlur = e => setEditing(false);
  const handleChange = e => fireChange(parseDocumento(e.target.value));
  const handleFocus = e => {
    setEditing(true);
    const target = e.target;
    setImmediate(() => target.setSelectionRange(0, target.value.length));
  };
  const handlePaste = e => {
    e.preventDefault();
    fireChange(parseDocumento(e.clipboardData.getData('text')));
  };

  const suffix = !value ?
      <LineOutlined /> :
      isValid ?
          <CheckOutlined className="success" /> :
          <CloseOutlined className="error" />;

  return (
      <Input
          maxLength={editing ? 14 : undefined}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onPaste={handlePaste}
          suffix={suffix}
          value={editing ? value : formattedValue}
          {...props}
      />
  );
}
