// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kp1r10VglRMLCRLhNIe1iA\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  line-height: 32px;\n  border-top: 4px solid transparent;\n  border-bottom: 4px solid transparent;\n  margin-top: -4px;\n  margin-bottom: -4px;\n}\n.kp1r10VglRMLCRLhNIe1iA\\=\\= > div:nth-child(2) p {\n  margin: 0;\n  padding: 0;\n}\n.kp1r10VglRMLCRLhNIe1iA\\=\\= > div:nth-child(3) {\n  flex-grow: 1;\n  line-height: 12px;\n}\n.b6542KoMuT2LRix1nIGNrw\\=\\= {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-top-color: #009edb;\n}\n.leUc3MAjkgCueRfoZ\\+8log\\=\\= {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-bottom-color: #009edb;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_WorkflowTarefaAnexo.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,UAAA;EACA,mBAAA;EACA,iBAAA;EACA,iCAAA;EACA,oCAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAVA;EAaM,SAAA;EACA,UAAA;AAAN;AAdA;EAmBI,YAAA;EACA,iBAAA;AAFJ;AAMA;EACE,0BAAA;EACA,2BAAA;EACA,yBAAA;AAJF;AAOA;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;AALF","sourcesContent":[".workflow-tarefa-anexo {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  line-height: 32px;\n  border-top: 4px solid transparent;\n  border-bottom: 4px solid transparent;\n  margin-top: -4px;\n  margin-bottom: -4px;\n\n  > div:nth-child(2) {\n    p {\n      margin: 0;\n      padding: 0;\n    }\n  }\n\n  > div:nth-child(3) {\n    flex-grow: 1;\n    line-height: 12px;\n  }\n}\n\n.dropzone-top {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-top-color: #009edb;\n}\n\n.dropzone-bottom {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-bottom-color: #009edb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-anexo": "kp1r10VglRMLCRLhNIe1iA==",
	"dropzone-top": "b6542KoMuT2LRix1nIGNrw==",
	"dropzone-bottom": "leUc3MAjkgCueRfoZ+8log=="
};
export default ___CSS_LOADER_EXPORT___;
