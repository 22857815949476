import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchEmpresas } from './empresas';

const initialState = {
  acessos: [],
  acesso: null
};

export const acessosSelector = state => state.acessos.acessos;
export const acessoSelector = state => state.acessos.acesso;

export const fetchAcessos = createAsyncThunk(
  'acessos/fetchAcessos',
  async (payload, thunkAPI) => {
    try {
      const acessos = (await axios.get('/acessos')).data;
      if (acessos.length === 1) {
        thunkAPI.dispatch(setAcesso({ acesso: acessos[0], fetchEmpresas: payload?.fetchEmpresas }));
        if (payload?.fetchEmpresas)
          thunkAPI.dispatch(fetchEmpresas({ acessoId: acessos[0].id }));
      }
      return acessos;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const setAcesso = createAsyncThunk(
  'acessos/setAcesso',
  async (payload, thunkAPI) => {
    try {
      if (payload?.fetchEmpresas)
        thunkAPI.dispatch(fetchEmpresas({ acessoId: payload?.acesso?.id }));
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const slice = createSlice({
  name: 'acessos',
  initialState,
  reducers: {
    setAcesso(state, action) {
      state.acesso = action.payload;
    },
  },
  extraReducers: {
    [fetchAcessos.fulfilled]: (state, action) => {
      state.acessos = action.payload;
      const selected = state.acessos.find(acesso => acesso.id === state.acesso?.id);
      if (selected) {
        state.acesso = selected;
      } else if (state.acessos.length === 1) {
        state.acesso = state.acessos[0];
      } else {
        state.acesso = null;
      }
    },
    [setAcesso.fulfilled]: (state, action) => {
      state.acesso = action.meta.arg.acesso;
    },
  },
});

export default slice.reducer;
