import { Form, Input, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import ActionButton from '../action-button';
import MiniFormDrawer from '../mini-form-drawer';
import SelectConsultivoEmpresa from "../select-consultivo-empresa";
import { useSelector } from "react-redux";
import { acessoSelector } from "../../store/acessos";
import SelectConsultivoUnidade from "../select-consultivo-unidade";
import SelectConsultivoArea from "../select-consultivo-area";
import SelectConsultivoConsultaPrioridade from "../select-consultivo-consulta-prioridade";
import SelectConsultivoTipoTrabalho from "../select-consultivo-tipo-trabalho";
import SelectConsultivoAssunto from "../select-consultivo-assunto";
import SelectConsultivoUsuario from "../select-consultivo-usuario";
import SelectBoolean from "../select-boolean";
import InputEmails from "../input-emails";
import { Consulta } from "../../constants";

const defaultValue = {
  empresaId: null,
  unidadeId: null,
  areaId: null,
  prioridade: Consulta.Prioridade.Baixa,
  tipoTrabalhoId: null,
  assuntoId: null,
  titulo: null,
  descricao: null,
  usuarioSolicitanteId: null,
  usuarioControleId: null,
  usuarioResponsavelId: null,
  emailsExtras: [],
  sigilo: false,
  permissoes: null,
  idExterno: null,
};

export default function ConsultaDrawer({ value = defaultValue, onChange, visible, onClose, onAfterClose }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const acessoId = useSelector(acessoSelector)?.id ?? 0;
  const [loading, setLoading] = useState(false);

  const fireChange = () => typeof onChange === 'function' ? onChange() : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#empresaId').focus();
  };

  const handleFinish = values => {
    setLoading(true);
    unwrapAxiosPromise(axios.post('/consultivo/consultas', values, { params: { acessoId } }), form)
      .then(() => {
        message.success(t('Consulta incluída com sucesso'));
        fireChange();
        fireClose();
      })
      .finally(() => setLoading(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        onClick={() => form.submit()}
        loading={loading}
      />
      <ActionButton.Close
        onClick={fireClose}
        disabled={loading}
      />
    </>
  );

  return (
    <MiniFormDrawer
      destroyOnClose
      drawerTitle={t('Criar Consulta')}
      drawerFooter={footer}
      drawerWidth={400}
      drawerVisible={visible}
      form={form}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onDrawerAfterClose={onAfterClose}
      onFinish={handleFinish}
    >
      <Form.Item name="empresaId" label={t('Empresa')}>
        <SelectConsultivoEmpresa disabled={loading} acessoId={acessoId}/>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, nextValues) => prevValues.empresaId !== nextValues.empresaId}
      >
        {({ getFieldValue }) =>
          <Form.Item name="unidadeId" label={t('Unidade')}>
            <SelectConsultivoUnidade
              allowClear
              disabled={loading}
              acessoId={acessoId}
              empresaId={getFieldValue('empresaId')}
            />
          </Form.Item>
        }
      </Form.Item>
      <Form.Item name="areaId" label={t('Área de Atuação')}>
        <SelectConsultivoArea disabled={loading}/>
      </Form.Item>
      <Form.Item name="prioridade" label={t('Prioridade')}>
        <SelectConsultivoConsultaPrioridade disabled={loading}/>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
      >
        {({ getFieldValue }) =>
          <Form.Item name="tipoTrabalhoId" label={t('Tipo de Trabalho')}>
            <SelectConsultivoTipoTrabalho areaId={getFieldValue('areaId')} disabled={loading}/>
          </Form.Item>
        }
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
      >
        {({ getFieldValue }) =>
          <Form.Item name="assuntoId" label={t('Assunto')}>
            <SelectConsultivoAssunto areaId={getFieldValue('areaId')} disabled={loading}/>
          </Form.Item>
        }
      </Form.Item>
      <Form.Item name="titulo" label={t('Título')}>
        <Input disabled={loading}/>
      </Form.Item>
      <Form.Item name="descricao" label={t('Descrição')}>
        <Input.TextArea autoSize disabled={loading}/>
      </Form.Item>
      <Form.Item name="usuarioSolicitanteId" label={t('Solicitante')}>
        <SelectConsultivoUsuario disabled={loading}/>
      </Form.Item>
      <Form.Item name="usuarioControleId" label={t('Controle')}>
        <SelectConsultivoUsuario disabled={loading}/>
      </Form.Item>
      <Form.Item name="usuarioResponsavelId" label={t('Responsável')}>
        <SelectConsultivoUsuario disabled={loading}/>
      </Form.Item>
      <Form.Item name="emailsExtras" label={t('E-mails Extras')}>
        <InputEmails disabled={loading}/>
      </Form.Item>
      <Form.Item name="sigilo" label={t('Sigilo')}>
        <SelectBoolean disabled={loading}/>
      </Form.Item>
      <Form.Item name="idExterno" label={t('Id Externo')}>
        <Input disabled={loading}/>
      </Form.Item>
    </MiniFormDrawer>
  );
}

ConsultaDrawer.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onAfterClose: PropTypes.func,
};
