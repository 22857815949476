import PropTypes from 'prop-types';
import React from 'react';
import {Empresa} from '../../../../constants';
import EmpresaSociosTable1 from './_table_1';
import EmpresaSociosTable2 from './_table_2';
import EmpresaSociosTable3 from './_table_3';
import EmpresaSociosTable4 from './_table_4';
import EmpresaSociosTable5 from './_table_5';
import EmpresaSociosTable6 from './_table_6';
import EmpresaSociosTable7 from './_table_7';
import EmpresaSociosTable8 from './_table_8';

export default function EmpresaSociosTable({cabecalho, ...props}) {
  switch (cabecalho?.tipo) {
    default:
    case Empresa.Tipo.SociedadeLimitada.id:
    case Empresa.Tipo.SociedadeSimples.id:
    case Empresa.Tipo.Cooperativa.id:
      return <EmpresaSociosTable1 {...props} />;
    case Empresa.Tipo.SociedadePorAcoes.id:
      return <EmpresaSociosTable2 {...props} />;
    case Empresa.Tipo.Eireli.id:
      return <EmpresaSociosTable3 {...props} />;
    case Empresa.Tipo.EmpresarioIndividual.id:
      return <EmpresaSociosTable4 {...props} />;
    case Empresa.Tipo.Associacao.id:
    case Empresa.Tipo.Fundacao.id:
      return <EmpresaSociosTable5 {...props} />;
    case Empresa.Tipo.SociedadeEmContaDeParticipacao.id:
      return <EmpresaSociosTable6 {...props} />;
    case Empresa.Tipo.FundoDeInvestimento.id:
      return <EmpresaSociosTable7 {...props} />;
    case Empresa.Tipo.EntidadeEstrangeira.id:
      return <EmpresaSociosTable8 {...props} />;
  }
}

EmpresaSociosTable.propTypes = {
  dataSource: PropTypes.array,
  cabecalho: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
