import {DownOutlined, FolderAddOutlined} from '@ant-design/icons';
import {Divider, Menu, Spin} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ActionLink from '../../components/action-link';
import Flag from '../../components/flag';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useLoading from '../../hooks/useLoading';
import {baixarArquivo, diretorioSelecionadoSelector, driveNomeSelector, empresaSelector, fetchDrive, isDriveAutenticadoUsuarioSelector, openCriarDiretorioDrawer} from '../../store/drive';
import {isXSSelector} from '../../store/layout';
import {formatCnpj} from '../../util/cnpj';
import {openPopup} from '../../util/window';
import styles from './_styles.less';
import useDriveQuery from './_useDriveQuery';

export default function DriveCabecalho() {
  const {t, i18n} = useTranslation();
  const {empresaId} = useDriveQuery();
  const dispatch = useDispatchWithResult();
  const isXS = useSelector(isXSSelector);
  const empresa = useSelector(empresaSelector);
  const diretorioSelecionado = useSelector(diretorioSelecionadoSelector);
  const isAutenticadoUsuario = useSelector(isDriveAutenticadoUsuarioSelector);
  const nome = useSelector(driveNomeSelector);
  const loading = useLoading(fetchDrive.typePrefix);

  const handleCriarDiretorioClick = () => {
    dispatch(openCriarDiretorioDrawer());
  };

  const handleBaixarDiretorioClick = () => {
    dispatch(baixarArquivo({empresaId, arquivo: diretorioSelecionado}));
  };

  const handleLogs = () => {
    const categoria = `/empresas/${empresaId}/drive/`;
    openPopup(`/logs?categoria=${categoria}`, `logs_${categoria}`);
  };

  const handleLanguageClick = ({key}) => {
    i18n.changeLanguage(key);
  };

  const empresaNome = `${empresa?.apelido || empresa?.razaoSocial} (${formatCnpj(empresa?.cnpj)})`;

  const buttons = [];
  if (isAutenticadoUsuario)
    buttons.push(
        <Menu.Item key="create-directory">
          <ActionLink
              title={t('Criar diretório')}
              noTitle
              icon={<FolderAddOutlined />}
              onClick={handleCriarDiretorioClick}
          />
        </Menu.Item>,
    );
  buttons.push(
      <Menu.Item key="download">
        <ActionLink.Download
            title={t('Baixar diretório')}
            noTitle
            disabled={!diretorioSelecionado}
            onClick={handleBaixarDiretorioClick}
        />
      </Menu.Item>,
  );
  if (isAutenticadoUsuario)
    buttons.push(
        <Menu.Item key="logs">
          <ActionLink.Logs noTitle onClick={handleLogs} />
        </Menu.Item>,
    );

  if (loading) {
    return (
        <Menu className={styles['cabecalho-menu']} theme="dark" mode="horizontal" selectable={false}>
          <Menu.Item key="cabecalho-loading">
            <Spin />
          </Menu.Item>
        </Menu>);
  }

  return (
      <>
        <Menu className={styles['cabecalho-menu']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
          <Menu.Item key="cabecalho-empresa">{empresaNome}</Menu.Item>
        </Menu>
        {!isXS &&
        <Menu className={styles['cabecalho-menu-buttons']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
          {buttons}
        </Menu>
        }
        <Divider type="vertical" className={styles['cabecalho-divider']} />
        <Menu theme="dark" mode="horizontal" disabledOverflow>
          <Menu.SubMenu title={isXS ? undefined : nome} icon={<DownOutlined />}>
            {isXS && <Menu.Item key="/" disabled>{nome}</Menu.Item>}
            {isXS && isAutenticadoUsuario && <Menu.Item key="/create-directory" onClick={handleCriarDiretorioClick}>{t('Criar diretório')}</Menu.Item>}
            {isXS && <Menu.Item key="/download" onClick={handleBaixarDiretorioClick}>{t('Baixar diretório')}</Menu.Item>}
            <Menu.SubMenu
                icon={<Flag countryCode={i18n.language.substr(-2)} />}
                title={' ' + i18n.language.substr(-2)}
            >
              <Menu.Item key="pt-BR" onClick={handleLanguageClick}><Flag countryCode="BR" /> BR</Menu.Item>
              <Menu.Item key="en-US" onClick={handleLanguageClick}><Flag countryCode="US" /> US</Menu.Item>
            </Menu.SubMenu>
            {isXS && isAutenticadoUsuario && <Menu.Item key="/logs" onClick={handleLogs}>{t('Histórico')}</Menu.Item>}
          </Menu.SubMenu>
        </Menu>
      </>
  );
}
