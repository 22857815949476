import {Form, message} from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import ActionButton from '../action-button';
import InputCnpj from '../input-cnpj';
import InputRazaoSocial from '../input-razao-social';
import MiniFormDrawer from '../mini-form-drawer';
import SelectAcesso from '../select-acesso';
import SelectEmpresaTipo from '../select-empresa-tipo';

const defaultValue = {
  cnpj: '',
  razaoSocial: '',
  apelido: '',
  acessoId: null,
  tipo: null,
};

export default function EmpresaDrawer({value = defaultValue, onChange, visible, onClose, onAfterClose}) {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fireChange = () => typeof onChange === 'function' ? onChange() : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#cnpj').focus();
  };

  const handleFinish = values => {
    setLoading(true);
    unwrapAxiosPromise(axios.post('/empresas', values), form)
        .then(() => {
          message.success(t('Empresa incluída com sucesso'));
          fireChange();
          fireClose();
        })
        .finally(() => setLoading(false));
  };

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loading}
        />
        <ActionButton.Close
            onClick={fireClose}
            disabled={loading}
        />
      </>
  );

  return (
      <MiniFormDrawer
          drawerTitle={t('Criar Empresa')}
          drawerFooter={footer}
          drawerWidth={400}
          drawerVisible={visible}
          form={form}
          onDrawerClose={fireClose}
          onDrawerAfterOpen={handleDrawerAfterOpen}
          onDrawerAfterClose={onAfterClose}
          onFinish={handleFinish}
      >
        <Form.Item name="cnpj" label={t('CNPJ')}>
          <InputCnpj disabled={loading} />
        </Form.Item>
        <Form.Item name="razaoSocial" label={t('Razão Social')}>
          <InputRazaoSocial disabled={loading} />
        </Form.Item>
        <Form.Item name="apelido" label={t('Nome de Exibição')}>
          <InputRazaoSocial disabled={loading} />
        </Form.Item>
        <Form.Item name="acessoId" label={t('Acesso')}>
          <SelectAcesso disabled={loading} />
        </Form.Item>
        <Form.Item name="tipo" label={t('Tipo')}>
          <SelectEmpresaTipo disabled={loading} />
        </Form.Item>
      </MiniFormDrawer>
  );
}

EmpresaDrawer.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onAfterClose: PropTypes.func,
};
