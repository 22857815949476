import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaRDEIEDsDetailPage from './_detail';
import EmpresaRDEIEDPage from './rde-ied';

export default function EmpresaRDEIEDsPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/rde-ieds/:id" component={EmpresaRDEIEDPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaRDEIEDsDetailPage} />
        </Switch>
      </Card>);
}
