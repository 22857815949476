import { Form, Input, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import ActionButton from '../action-button';
import MiniFormDrawer from '../mini-form-drawer';

const defaultValue = {
  empresa: null,
};

export default function ProjetoDrawer({ value = defaultValue, onChange, visible, onClose, onAfterClose }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fireChange = () => typeof onChange === 'function' ? onChange() : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#empresa').focus();
  };

  const handleFinish = values => {
    setLoading(true);
    unwrapAxiosPromise(axios.post('/consultivo/projetos', values), form)
      .then(() => {
        message.success(t('Projeto incluída com sucesso'));
        fireChange();
        fireClose();
      })
      .finally(() => setLoading(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        onClick={() => form.submit()}
        loading={loading}
      />
      <ActionButton.Close
        onClick={fireClose}
        disabled={loading}
      />
    </>
  );

  return (
    <MiniFormDrawer
      drawerTitle={t('Criar Projeto')}
      drawerFooter={footer}
      drawerWidth={400}
      drawerVisible={visible}
      form={form}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onDrawerAfterClose={onAfterClose}
      onFinish={handleFinish}
    >
      <Form.Item name="empresa" label={t('Empresa')}>
        <Input disabled={loading}/>
      </Form.Item>
    </MiniFormDrawer>
  );
}

ProjetoDrawer.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onAfterClose: PropTypes.func,
};
