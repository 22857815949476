import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import TextItem from '../../../../../components/text-item';
import useFormatter from '../../../../../hooks/useFormatter';
import useLoading from '../../../../../hooks/useLoading';
import { openPopup } from '../../../../../util/window';
import { fetchTipoTrabalho, tipoTrabalhoSelector } from "../../../../../store/consultivoArea";
import { isGestorSelector } from "../../../../../store/autenticacao";

export default function ConsultivoAreaTipoTrabalhoDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isGestorSelector);
  const tipoTrabalho = useSelector(tipoTrabalhoSelector);
  const { formatNumber } = useFormatter();
  const loading = useLoading(fetchTipoTrabalho.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;
  const id = match.params.id;

  const handleEdit = () => {
    history.replace(`/consultivo/areas/${areaId}/tipos-trabalho/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`/consultivo/areas/${areaId}/tipos-trabalho`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/consultivo/areas/${areaId}/tipos-trabalho/${id}/`, `logs_/consultivo/areas/${areaId}/tipos-trabalho/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Descrição')}>{tipoTrabalho?.descricao}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <TextItem label={t('SLA p/ Alta Prioridade')}>
            {tipoTrabalho?.sla1 ? formatNumber(tipoTrabalho.sla1) + ' ' + t('dia(s)') : ''}
          </TextItem>
        </Col>
        <Col flex="200px">
          <TextItem label={t('SLA p/ Média Prioridade')}>
            {tipoTrabalho?.sla2 ? formatNumber(tipoTrabalho.sla2) + ' ' + t('dia(s)') : ''}
          </TextItem>
        </Col>
        <Col flex="200px">
          <TextItem label={t('SLA p/ Baixa Prioridade')}>
            {tipoTrabalho?.sla3 ? formatNumber(tipoTrabalho.sla3) + ' ' + t('dia(s)') : ''}
          </TextItem>
        </Col>
      </Row>
    </Spin>
  );
}
