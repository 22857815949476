import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import ConsultivoConsultaCadastroDetailPage from './_detail';
import ConsultivoConsultaCadastroEditPage from "./_edit";

export default function ConsultivoConsultaCadastroPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/consultas/:consultaId/cadastro/edit" component={ConsultivoConsultaCadastroEditPage}/>
        <Route path="/consultivo/consultas/:consultaId" component={ConsultivoConsultaCadastroDetailPage}/>
      </Switch>
    </Card>);
}
