import React, { useMemo, useState } from 'react';
import InputName from "../input-name";
import { Form } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, ClearOutlined, DeleteOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons";
import ActionButton from "../action-button";
import InputAnexo from "../input-anexo";
import styles from './_styles.less';
import ActionBar from "../action-bar";
import { removeFileNameExtension } from "../../util/string";
import { useTranslation } from "react-i18next";
import SelectEmpresaContrato from "../select-empresa-contrato";
import TextAnexo from "../text-anexo";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";

export default function InputEmpresaLivroDigitalConteudos({ empresaId, name, onChange, value: values = [], ...props }) {
  const { t } = useTranslation();
  const [contratoById, setContratoById] = useState({});

  const id = useMemo(() => "input-livro-digital-conteudos-" + new Date().getTime(), []);
  const fireChange = (newValue, value, index) => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(values
        .map((v, i) => i === index ? newValue : v)
        .filter(v => v !== null));
  };

  const getAnexoByContratoId = contratoId => {
    if (!contratoId)
      return null;
    let contrato = contratoById[contratoId];
    if (!contrato) {
      setContratoById(contratoById => ({ ...contratoById, [contratoId]: {} }))
      unwrapAxiosPromise(axios.get(`/empresas/${empresaId}/contratos/${contratoId}`))
        .then(data => {
          setContratoById(contratoById => ({ ...contratoById, [contratoId]: data }))
        });
    }
    return contrato?.anexo;
  }

  const handleChange = (newValue, value, index) => fireChange(newValue, value, index);

  const handleAdd = (index, defaultValue = {}) => {
    if (typeof onChange === 'function') {
      const before = values.slice(0, index + 1);
      const after = values.slice(index + 1);
      onChange([...before, defaultValue, ...after]);
      setImmediate(() => document.querySelector(`#${id} tbody tr:nth-child(${index + 1}) input`).focus());
    }
  }

  const handleSwap = (index1, index2) => {
    onChange(values.map((value, index) => {
      if (index === index1)
        return values[index2];
      else if (index === index2)
        return values[index1];
      return value;
    }));
  }

  const handleRemove = index => {
    const before = values.slice(0, index);
    const after = values.slice(index + 1);
    onChange([...before, ...after])
    setImmediate(() => document.querySelector(`#${id} tbody tr:nth-child(${Math.max(0, index)}) input`).focus());
  }

  return (
    <table id={id} className={styles['input-empresa-livro-digital-conteudos']}>
      <tbody>
      {values.map((value, index) => {
        const hasContratoId = typeof value.contratoId === 'number' || value.tipo === 'contrato';
        return (
          <tr key={index}>
            <td className={styles['nome']}>
              {!hasContratoId &&
                <Form.Item name={[name, index, "nome"]}>
                  <InputName
                    placeholder={t('Nome')}
                    value={value.nome}
                    onChange={nome => handleChange({ ...value, nome }, value, index)}
                    {...props}
                  />
                </Form.Item>
              }
              {hasContratoId &&
                <Form.Item name={[name, index, "contratoId"]}>
                  <SelectEmpresaContrato
                    placeholder={t('Ato Societário')}
                    empresaId={empresaId}
                    value={value.contratoId}
                    onChange={contratoId => handleChange({ ...value, contratoId }, value, index)}
                    {...props}
                  />
                </Form.Item>
              }
            </td>
            <td className={styles['contrato']}>
              {!hasContratoId &&
                <ActionButton
                  disabled={props.disabled}
                  title={t('Vincular Ato Societário')}
                  noTitle
                  icon={<LinkOutlined/>}
                  onClick={() => handleChange({ ...value, tipo: 'contrato', nome: '', anexo: null }, value, index)}/>
              }
              {hasContratoId &&
                <ActionButton
                  disabled={props.disabled}
                  title={t('Desvincular Ato Societário')}
                  noTitle
                  icon={<ClearOutlined/>}
                  onClick={() => handleChange({ ...value, tipo: null, contratoId: null }, value, index)}/>
              }
            </td>
            <td className={styles['anexo']}>
              {!hasContratoId &&
                <Form.Item name={[name, index, "anexo"]}>
                  <InputAnexo
                    accept="application/pdf"
                    compact
                    value={value.anexo}
                    onChange={anexo => {
                      let nome = value.nome;
                      if (!value.nome)
                        nome = removeFileNameExtension(anexo.name);
                      handleChange({ ...value, nome, anexo }, value, index);
                    }}
                    {...props}
                  />
                </Form.Item>
              }
              {hasContratoId &&
                <TextAnexo value={getAnexoByContratoId(value.contratoId)}/>
              }
            </td>
            <td>
              <ActionBar gutter={[0, 0]}>
                <ActionButton disabled={props.disabled || index <= 0} icon={<ArrowUpOutlined/>} onClick={() => handleSwap(index, index - 1)}/>
                <ActionButton disabled={props.disabled || index + 1 >= values.length} icon={<ArrowDownOutlined/>} onClick={() => handleSwap(index, index + 1)}/>
                <ActionButton disabled={props.disabled} icon={<DeleteOutlined/>} onClick={() => handleRemove(index)}/>
              </ActionBar>
            </td>
          </tr>
        );
      })}
      </tbody>
      <tfoot>
      <tr>
        <td colSpan={3}>
          <Form.Item name={[name, -1]}>
            <ActionBar justify="start">
              <ActionButton disabled={props.disabled} title={t('Adicionar Conteúdo')} noTitle icon={<PlusOutlined/>} onClick={() => handleAdd(values.length)}/>
            </ActionBar>
          </Form.Item>
        </td>
      </tr>
      </tfoot>
    </table>
  );
}
