import {WarningFilled} from '@ant-design/icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useLoading from '../../../hooks/useLoading';
import {proximoAvisoSelector} from '../../../store/complianceDashboard';
import {fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas} from '../../../store/empresas';
import ProximoAlertaCard from './_ProximoAlertaCard';

export default function ProximoAlertaAvisoCard() {
  const {t} = useTranslation();
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const proximoAviso = useSelector(proximoAvisoSelector);

  return (
      <ProximoAlertaCard
          alerta={proximoAviso}
          className="background-warning"
          icon={<WarningFilled />}
          title={t('Próximo alerta')}
          loading={loading}
      />
  );
}
