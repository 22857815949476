import {useMemo} from 'react';
import {useSelector} from 'react-redux';

export default function useLoading() {
  const types = useMemo(() => Array.from(arguments), [arguments]);
  return useSelector(
      state => Object.entries(state.loading)
          .filter(([_, loading]) => loading === true)
          .some(([typePrefix]) => types.includes(typePrefix)));
}
