import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmpresaConta} from '../../constants';

const {Publicacao} = EmpresaConta;

export default function SelectEmpresaContaPublicacao(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Publicacao.Pendente)}
        {createOption(Publicacao.Sim)}
        {createOption(Publicacao.Nao)}
        {createOption(Publicacao.NaoSeAplica)}
      </Select>
  );
}

SelectEmpresaContaPublicacao.propTypes = {
  value: PropTypes.oneOf([
    Publicacao.Pendente.id,
    Publicacao.Sim.id,
    Publicacao.Nao.id,
    Publicacao.NaoSeAplica.id]),
  onChange: PropTypes.func,
};
