import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../components/mini-table';
import useFormatter from '../../hooks/useFormatter';
import Logo from "../../components/logo";
import styles from './_styles.less';
import { compareNumbers } from "../../util/number";

export default function AmbientesTable({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();
  const { formatNumber } = useFormatter();

  const fireClick = ambiente => typeof onClick === 'function' ? onClick(ambiente) : undefined;

  const columns = [
    {
      title: t('Logo'),
      dataIndex: 'logo',
      key: 'logo',
      align: 'center',
      width: 80,
      render: (text, record) => (
        <div className={styles['logo']}>
          <Logo src={record.logo?.url} background="#262626"/>
        </div>
      )
    },
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('Usuários'),
      align: 'right',
      dataIndex: 'numeroUsuarios',
      key: 'numeroUsuarios',
      width: 160,
      sorter: (a, b) => compareNumbers(a.numeroUsuarios, b.numeroUsuarios),
      render: (text, record) => formatNumber(record.numeroUsuarios),
    },
    {
      title: t('Empresas'),
      align: 'right',
      key: 'numeroEmpresas',
      width: 160,
      sorter: (a, b) => compareNumbers(a.numeroEmpresas, b.numeroEmpresas),
      render: (text, record) => formatNumber(record.numeroEmpresas),
    },
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

AmbientesTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
