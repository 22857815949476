// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kR1wQiFgFtYQLXHG1bs8FA\\=\\=,\n.NnnKQjm1wjOLGusIYFDrlw\\=\\= {\n  cursor: pointer;\n  line-height: 32px;\n  padding: 0 8px;\n}\n.kR1wQiFgFtYQLXHG1bs8FA\\=\\=:hover,\n.NnnKQjm1wjOLGusIYFDrlw\\=\\=:hover {\n  background-color: #f0f2f5;\n}\n.NnnKQjm1wjOLGusIYFDrlw\\=\\= {\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tipoTarefa/_WorkflowTipoTarefaItemDescricao.less"],"names":[],"mappings":"AAAA;;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAEF;AAAE;;EACE,yBAAA;AAGJ;AACA;EAEE,cAAA;AAAF","sourcesContent":[".workflow-tipo-tarefa-item-descricao {\n  cursor: pointer;\n  line-height: 32px;\n  padding: 0 8px;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.nao-preenchido {\n  &:extend(.workflow-tipo-tarefa-item-descricao all);\n  color: #999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tipo-tarefa-item-descricao": "kR1wQiFgFtYQLXHG1bs8FA==",
	"nao-preenchido": "NnnKQjm1wjOLGusIYFDrlw=="
};
export default ___CSS_LOADER_EXPORT___;
