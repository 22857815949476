// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v6ny-jsQX18kozFkrrqw0g\\=\\= {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/layouts/blank/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF","sourcesContent":[".blank-layout {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blank-layout": "v6ny-jsQX18kozFkrrqw0g=="
};
export default ___CSS_LOADER_EXPORT___;
