import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from './_WorkflowTarefa.less';
import useFormatter from "../../hooks/useFormatter";
import { CheckSquareOutlined, ClockCircleOutlined, PaperClipOutlined, UserOutlined } from "@ant-design/icons";
import WorkflowTarefaInfoTag from "./_WorkflowTarefaInfoTag";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import {
  dragEndWorkflowTarefaId,
  draggingTarefaSelector,
  dragStartWorkflowTarefaId,
  fetchWorkflowTarefa,
  removeWorkflowTarefa,
  selectWorkflowTarefaId,
  tarefasAnexosSelector,
  tarefasItensSelector,
  tarefasMembrosSelector,
  tipoTarefaByIdSelector,
  updateWorkflowTarefaEtapa,
  updateWorkflowTarefaPosicao,
  workflowSelector
} from "../../store/workflow";
import WorkflowTipoTarefaTag from "./_WorkflowTipoTarefaTag";
import { isClienteOperadorSelector } from "../../store/autenticacao";
import { Dropdown, Menu } from "antd";
import WorkflowButton from "./_WorkflowButton";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";

export default function WorkflowTarefa(props) {
  const { id, etapa: etapaId, nome, descricao, posicao, dataPrazo, dataEntrega } = props;
  const { formatDate } = useFormatter();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isClienteOperador = useSelector(isClienteOperadorSelector);
  const workflow = useSelector(workflowSelector);
  const membros = useSelector(tarefasMembrosSelector(id));
  const anexos = useSelector(tarefasAnexosSelector(id));
  const itens = useSelector(tarefasItensSelector(id));
  const tipoTarefa = useSelector(tipoTarefaByIdSelector(props.tipoTarefa))
  const draggingTarefa = useSelector(draggingTarefaSelector)
  const now = moment();

  const workflowId = workflow.id;

  const handleClick = e => {
    e.preventDefault();
    unwrapAxiosPromise(dispatch(fetchWorkflowTarefa({ workflowId, id })));
    dispatch(selectWorkflowTarefaId(id));
  };

  const handleExcluir = () => {
    const workflowId = workflow.id;
    unwrapAxiosPromise(dispatch(removeWorkflowTarefa({ workflowId, id })));
  };

  const handleDragStart = e => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('application/json', JSON.stringify({ event: 'WorkflowTarefa', data: { id, etapaId } }));
    dispatch(dragStartWorkflowTarefaId(id));
  };

  const handleDragEnd = e => {
    dispatch(dragEndWorkflowTarefaId());
  };

  const handleDragOver = e => {
    const target = e.currentTarget;
    if (!draggingTarefa)
      return;
    if (draggingTarefa.id === id)
      return;
    if (etapaId === draggingTarefa.etapa && posicao < draggingTarefa.posicao)
      target.classList.add(styles['dropzone-top'])
    else
      target.classList.add(styles['dropzone-bottom'])
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  const handleDragLeave = e => {
    const target = e.currentTarget;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
  }

  const handleDrop = e => {
    const target = e.currentTarget;
    if (!target)
      return;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
    const { event, data } = JSON.parse(e.dataTransfer.getData('application/json'));
    if (event !== 'WorkflowTarefa')
      return;
    e.preventDefault();
    if (data.etapaId === etapaId)
      unwrapAxiosPromise(dispatch(updateWorkflowTarefaPosicao({ workflowId, id: data.id, posicao })));
    else
      unwrapAxiosPromise(dispatch(updateWorkflowTarefaEtapa({ workflowId, etapaId, id: data.id, posicao: posicao + 1 })));
  }

  const itensMarcados = itens.filter(item => item.marcado);

  const statusPrazo = useMemo(() => {
    if (dataPrazo && now.isSame(dataPrazo.slice(0, 10), 'date'))
      return 'warning';
    if (dataPrazo && now.isAfter(dataPrazo.slice(0, 10), 'date'))
      return 'error';
  }, [now, dataPrazo]);

  const statusItens = useMemo(() => {
    if (itensMarcados.length === itens.length)
      return 'success';
  }, [itensMarcados, itens])

  return (
    <div
      className={styles['workflow-tarefa']}
      onClick={handleClick}

      draggable={isClienteOperador}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}

      onDragEnter={handleDragOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className={styles['conteudo']}>
        <div className={styles['cabecalho']}>
          <WorkflowTipoTarefaTag {...tipoTarefa} />
          {isClienteOperador &&
            <div className={styles['menu']} onClick={e => e.stopPropagation()}>
              <Dropdown
                trigger="click"
                overlay={(
                  <Menu>
                    <Menu.Item key="1" onClick={e => dispatch(selectWorkflowTarefaId(id))}>{t('Abrir Tarefa')}</Menu.Item>
                    <Menu.Item key="2" onClick={handleExcluir}>{t('Excluir Tarefa')}</Menu.Item>
                  </Menu>
                )}
              >
                <div>
                  <WorkflowButton title="..."/>
                </div>
              </Dropdown>
            </div>
          }
        </div>
        <div>
          {nome}
        </div>
        <div className={styles['informacoes']}>
          {!!dataPrazo && <WorkflowTarefaInfoTag icon={<ClockCircleOutlined/>} status={statusPrazo} text={formatDate(dataPrazo)}/>}
          {anexos.length > 0 && <WorkflowTarefaInfoTag icon={<PaperClipOutlined/>} text={anexos.length}/>}
          {itens.length > 0 && <WorkflowTarefaInfoTag icon={<CheckSquareOutlined/>} status={statusItens} text={itensMarcados.length + '/' + itens.length}/>}
        </div>
        <div className={styles['membros']}>
          {membros.map(membro => <WorkflowTarefaInfoTag key={membro.id} icon={<UserOutlined/>} text={membro.nome}/>)}
        </div>
      </div>
    </div>
  )
}

WorkflowTarefa.propTypes = {
  id: PropTypes.number,
  etapa: PropTypes.shape({
    id: PropTypes.number,
  }),
  nome: PropTypes.string,
  descricao: PropTypes.string,
  posicao: PropTypes.number,
  dataPrazo: PropTypes.string,
  dataEntrega: PropTypes.string,
  tipoTarefa: PropTypes.shape({
    id: PropTypes.number,
  }),
  membros: PropTypes.array,
  anexos: PropTypes.array,
  itens: PropTypes.array
}