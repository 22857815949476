import PropTypes from 'prop-types';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';

export default function Flag({countryCode}) {
  return (
      <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            fontSize: '2em',
            lineHeight: '1em',
          }}
      />
  );
}

Flag.propTypes = {
  countryCode: PropTypes.string,
};
