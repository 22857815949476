import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaRepresentantesDetailPage from './_detail';
import EmpresaRepresentantePage from './representante';

export default function EmpresaRepresentantesPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/representantes/:id" component={EmpresaRepresentantePage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaRepresentantesDetailPage} />
        </Switch>
      </Card>);
}
