import { Col, Form, Input, message, Row, Select } from "antd";
import React, { useEffect, useState } from 'react';
import { fetchLogs, fetchResposta, respostaSelector } from "../../../../store/consultivoConsulta";
import { useTranslation } from "react-i18next";
import useDispatchWithResult from "../../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import useLoading from "../../../../hooks/useLoading";
import { useHistory, useRouteMatch } from "react-router";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import axios from "axios";
import ActionBar from "../../../../components/action-bar";
import ActionButton from "../../../../components/action-button";
import MiniForm from "../../../../components/mini-form";
import TextItem from "../../../../components/text-item";

export default function ConsultivoConsultaRespostaEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const resposta = useSelector(respostaSelector);
  const loading = useLoading(fetchResposta.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;

  useEffect(() => {
    if (resposta?.id)
      document.querySelector('#resposta')?.focus();
  }, [resposta]);

  useEffect(() => {
    form.setFieldsValue(resposta);
  }, [resposta]);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/consultivo/consultas/${consultaId}/resposta`, values), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        history.replace(`/consultivo/consultas/${consultaId}/resposta`);
        dispatch(fetchResposta({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/consultas/${consultaId}/resposta`);
  };

  return (
    <MiniForm form={form} onFinish={handleFinish} loading={loading}>
      <ActionBar>
        <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
        <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="resposta" label={t('Resposta')}>
            <Input.TextArea autoSize disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="tags" label={t('Tags')}>
            <Select mode="tags" disabled={loading} dropdownStyle={{ display: 'none' }}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="permissoes" label={t('Permissões')}>
            <Input.TextArea autoSize disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Projeto')}>{resposta?.projeto?.titulo}</TextItem></Col>
        <Col flex="400px"><TextItem label={t('Consulta Origem')}>{resposta?.consultaOrigem?.titulo}</TextItem></Col>
      </Row>
    </MiniForm>
  )
}
