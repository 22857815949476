import { useCallback, useRef, useState } from 'react';
import { useDebouncedEffect } from './useDebouncedEffect';

export function useDebouncedCallback(ms, callback, deps) {
  const ref = useRef(false);
  const [params, setParams] = useState();

  useDebouncedEffect(ms, () => {
    if (ref.current)
      callback.apply(null, params);
    else
      ref.current = true;
  }, [params]);

  return useCallback(function () {
    setParams(arguments);
  }, deps);
}
