import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import AreaUsuariosDetailPage from './_detail';
import ConsultivoAreaUsuarioAddPage from "./_add";

export default function ConsultivoAreaUsuariosPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/areas/:areaId/usuarios/add" component={ConsultivoAreaUsuarioAddPage}/>
        <Route path="/consultivo/areas/:areaId" component={AreaUsuariosDetailPage}/>
      </Switch>
    </Card>);
}
