// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KrGdCu8VU2bG9fHNqxNm3A\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  line-height: 32px;\n  border-top: 4px solid transparent;\n  border-bottom: 4px solid transparent;\n  margin-top: -4px;\n  margin-bottom: -4px;\n}\n.KrGdCu8VU2bG9fHNqxNm3A\\=\\= > :nth-child(1) {\n  flex-grow: 1;\n}\n.vivD2Uc36rLK4TC1v\\+5IBw\\=\\= {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-top-color: #009edb;\n}\n._0FlIXYXJzu7qH8a0D5Uwxg\\=\\= {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-bottom-color: #009edb;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tipoTarefa/_WorkflowTipoTarefaItem.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,UAAA;EACA,mBAAA;EACA,iBAAA;EACA,iCAAA;EACA,oCAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAVA;EAYI,YAAA;AACJ;AAGA;EACE,0BAAA;EACA,2BAAA;EACA,yBAAA;AADF;AAIA;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;AAFF","sourcesContent":[".workflow-tipo-tarefa-item {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  line-height: 32px;\n  border-top: 4px solid transparent;\n  border-bottom: 4px solid transparent;\n  margin-top: -4px;\n  margin-bottom: -4px;\n\n  > :nth-child(1) {\n    flex-grow: 1;\n  }\n}\n\n.dropzone-top {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-top-color: #009edb;\n}\n\n.dropzone-bottom {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-bottom-color: #009edb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tipo-tarefa-item": "KrGdCu8VU2bG9fHNqxNm3A==",
	"dropzone-top": "vivD2Uc36rLK4TC1v+5IBw==",
	"dropzone-bottom": "_0FlIXYXJzu7qH8a0D5Uwxg=="
};
export default ___CSS_LOADER_EXPORT___;
