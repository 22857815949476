import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import { compareStrings } from '../../../../util/string';
import { Button } from "antd";
import { compareNumbers } from "../../../../util/number";
import { formatNumber } from "chart.js/helpers";

export default function ConsultivoAreaTiposTrabalhoTable({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();

  const fireClick = tipoTrabalho => typeof onClick === 'function' ? onClick(tipoTrabalho) : undefined;

  const columns = [
    {
      title: t('Descricao'),
      dataIndex: 'descricao',
      key: 'descricao',
      sorter: (a, b) => compareStrings(a.descricao, b.descricao),
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('SLA p/ Alta Prioridade'),
      align: 'right',
      dataIndex: 'sla1',
      key: 'sla1',
      width: 200,
      sorter: (a, b) => compareNumbers(a.sla1, b.sla1),
      render: (text, record) => record.sla1 ? formatNumber(record.sla1) + ' ' + t('dia(s)') : '',
    },
    {
      title: t('SLA p/ Média Prioridade'),
      align: 'right',
      dataIndex: 'sla2',
      key: 'sla2',
      width: 200,
      sorter: (a, b) => compareNumbers(a.sla2, b.sla2),
      render: (text, record) => record.sla2 ? formatNumber(record.sla2) + ' ' + t('dia(s)') : '',
    },
    {
      title: t('SLA p/ Baixa Prioridade'),
      align: 'right',
      dataIndex: 'sla3',
      key: 'sla3',
      width: 200,
      sorter: (a, b) => compareNumbers(a.sla3, b.sla3),
      render: (text, record) => record.sla3 ? formatNumber(record.sla3) + ' ' + t('dia(s)') : '',
    },
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

ConsultivoAreaTiposTrabalhoTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
