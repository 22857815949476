import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Empresa} from '../../constants';

const {Tipo} = Empresa;

export default function SelectEmpresaTipo(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Tipo.SociedadeLimitada)}
        {createOption(Tipo.SociedadePorAcoes)}
        {createOption(Tipo.Eireli)}
        {createOption(Tipo.SociedadeSimples)}
        {createOption(Tipo.EmpresarioIndividual)}
        {createOption(Tipo.Associacao)}
        {createOption(Tipo.Fundacao)}
        {createOption(Tipo.SociedadeEmContaDeParticipacao)}
        {createOption(Tipo.Cooperativa)}
        {createOption(Tipo.FundoDeInvestimento)}
        {createOption(Tipo.EntidadeEstrangeira)}
      </Select>
  );
}

SelectEmpresaTipo.propTypes = {
  value: PropTypes.oneOf([
    Tipo.SociedadeLimitada.id,
    Tipo.SociedadePorAcoes.id,
    Tipo.Eireli.id,
    Tipo.SociedadeSimples.id,
    Tipo.EmpresarioIndividual.id,
    Tipo.Associacao.id,
    Tipo.Fundacao.id,
    Tipo.SociedadeEmContaDeParticipacao.id,
    Tipo.Cooperativa.id,
    Tipo.FundoDeInvestimento.id,
    Tipo.EntidadeEstrangeira.id]),
  onChange: PropTypes.func,
};
