import React, { useState } from "react";
import styles from "./_WorkflowTipoTarefaItem.less";
import WorkflowTipoTarefaItemDescricao from "./_WorkflowTipoTarefaItemDescricao";
import ActionBar from "../../../components/action-bar";
import ActionLink from "../../../components/action-link";
import {
  draggingTipoTarefaItemSelector,
  dragEndWorkflowTipoTarefaItemId,
  dragStartWorkflowTipoTarefaItemId,
  removeWorkflowTipoTarefaItem,
  updateWorkflowTipoTarefaItemDescricao,
  updateWorkflowTipoTarefaItemPosicao,
  workflowSelector
} from "../../../store/workflow";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTipoTarefaItem(props) {
  const { id, tipoTarefa: tipoTarefaId, descricao, posicao } = props;
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector);
  const draggingTipoTarefaItem = useSelector(draggingTipoTarefaItemSelector)
  const [descricaoEdicao, setDescricaoEdicao] = useState(false);

  const workflowId = workflow.id;

  const handleDragStart = e => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('application/json', JSON.stringify({ event: 'WorkflowTipoTarefaItem', data: { id } }));
    dispatch(dragStartWorkflowTipoTarefaItemId(id));
    setDescricaoEdicao(false);
  }

  const handleDragEnd = e => {
    dispatch(dragEndWorkflowTipoTarefaItemId());
  }

  const handleDragOver = e => {
    const target = e.currentTarget;
    if (!draggingTipoTarefaItem)
      return;
    if (draggingTipoTarefaItem.id === id)
      return;
    if (posicao < draggingTipoTarefaItem.posicao)
      target.classList.add(styles['dropzone-top'])
    else
      target.classList.add(styles['dropzone-bottom'])
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  const handleDragLeave = e => {
    const target = e.currentTarget;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
  }

  const handleDrop = e => {
    const target = e.currentTarget;
    if (!target)
      return;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
    const { event, data } = JSON.parse(e.dataTransfer.getData('application/json'));
    if (event !== 'WorkflowTipoTarefaItem')
      return;
    e.preventDefault();
    unwrapAxiosPromise(dispatch(updateWorkflowTipoTarefaItemPosicao({ workflowId, tipoTarefaId, id: data.id, posicao })));
  }

  const handleItemDescricaoSubmit = (descricao) => unwrapAxiosPromise(dispatch(updateWorkflowTipoTarefaItemDescricao({ workflowId, tipoTarefaId, id, descricao })));
  const handleItemRemoveClick = () => unwrapAxiosPromise(dispatch(removeWorkflowTipoTarefaItem({ workflowId, tipoTarefaId, id })));

  return (
    <div
      className={styles['workflow-tipo-tarefa-item']}

      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}

      onDragEnter={handleDragOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div><WorkflowTipoTarefaItemDescricao descricao={descricao} edicao={descricaoEdicao} onEdicao={setDescricaoEdicao} onSubmit={handleItemDescricaoSubmit}/></div>
      <div>
        <ActionBar>
          <ActionLink.Remove noTitle onClick={handleItemRemoveClick}/>
        </ActionBar>
      </div>
    </div>
  )
}