// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BUzMWvJrBHZB0su53H1t7Q\\=\\=.ant-btn.ant-btn-link {\n  color: #eef0f3;\n  padding: 0 8px;\n}\n.BUzMWvJrBHZB0su53H1t7Q\\=\\=.ant-btn.ant-btn-link:hover {\n  color: #eef0f3;\n  background-color: #eef0f333;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/_WorkflowButton.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;AACF;AACE;EACE,cAAA;EACA,2BAAA;AACJ","sourcesContent":[".workflow-button:global(.ant-btn.ant-btn-link) {\n  color: #eef0f3;\n  padding: 0 8px;\n\n  &:hover {\n    color: #eef0f3;\n    background-color: #eef0f333;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-button": "BUzMWvJrBHZB0su53H1t7Q=="
};
export default ___CSS_LOADER_EXPORT___;
