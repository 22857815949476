import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  permissoes: [],

  cabecalho: {},

  cadastro: {},

  resposta: {},

  anexos: [],
  anexo: {},

  fases: [],

  logs: [],
}

export const permissoesSelector = state => state.consultivoConsulta.permissoes;

export const hasPermissaoSelector = (...permissoes) => state => {
  for (const permissao of permissoes)
    if (!state.consultivoConsulta.permissoes.includes(permissao))
      return false;
  return true;
}

export const cabecalhoSelector = state => state.consultivoConsulta.cabecalho;

export const cadastroSelector = state => state.consultivoConsulta.cadastro;

export const respostaSelector = state => state.consultivoConsulta.resposta;

export const anexosSelector = state => state.consultivoConsulta.anexos;
export const anexoSelector = state => state.consultivoConsulta.anexo;

export const fasesSelector = state => state.consultivoConsulta.fases;

export const logsSelector = state => state.consultivoConsulta.logs;

export const fetchPermissoes = createAsyncThunk(
  'consultivoConsulta/fetchPermissoes',
  async ({ consultaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/consultas/${consultaId}/permissoes`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchCabecalho = createAsyncThunk(
  'consultivoConsulta/fetchCabecalho',
  async ({ consultaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/consultas/${consultaId}/cabecalho`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchCadastro = createAsyncThunk(
  'consultivoConsulta/fetchCadastro',
  async ({ consultaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/consultas/${consultaId}/cadastro`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchResposta = createAsyncThunk(
  'consultivoConsulta/fetchResposta',
  async ({ consultaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/consultas/${consultaId}/resposta`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchAnexos = createAsyncThunk(
  'consultivoConsulta/fetchAnexos',
  async ({ consultaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/consultas/${consultaId}/anexos`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchAnexo = createAsyncThunk(
  'consultivoConsulta/fetchAnexo',
  async ({ consultaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/consultivo/consultas/${consultaId}/anexos/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchFases = createAsyncThunk(
  'consultivoConsulta/fetchFases',
  async ({ consultaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/consultas/${consultaId}/fases`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const fetchLogs = createAsyncThunk(
  'consultivoConsulta/fetchLogs',
  async ({ consultaId }, thunkAPI) => {
    try {
      return (await axios.get(`/consultivo/consultas/${consultaId}/logs`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const slice = createSlice({
  name: 'consultivoConsulta',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPermissoes.fulfilled]: (state, action) => {
      state.permissoes = action.payload;
    },
    [fetchPermissoes.rejected]: (state) => {
      state.permissoes = [];
    },

    [fetchCabecalho.fulfilled]: (state, action) => {
      state.cabecalho = action.payload;
    },
    [fetchCabecalho.rejected]: (state) => {
      state.cabecalho = [];
    },

    [fetchCadastro.fulfilled]: (state, action) => {
      state.cadastro = action.payload;
      state.cadastro.empresaId = action.payload.empresa?.id;
      state.cadastro.unidadeId = action.payload.unidade?.id;
      state.cadastro.areaId = action.payload.area?.id;
      state.cadastro.tipoTrabalhoId = action.payload.tipoTrabalho?.id;
      state.cadastro.assuntoId = action.payload.assunto?.id;
      state.cadastro.usuarioSolicitanteId = action.payload.usuarioSolicitante?.id;
      state.cadastro.usuarioCriadorId = action.payload.usuarioCriador?.id;
      state.cadastro.usuarioControleId = action.payload.usuarioControle?.id;
      state.cadastro.usuarioResponsavelId = action.payload.usuarioResponsavel?.id;
      state.cadastro.projetoId = action.payload.projeto?.id;
      state.cadastro.consultaOrigemId = action.payload.consultaOrigem?.id;
    },
    [fetchCadastro.rejected]: (state) => {
      state.cadastro = [];
    },

    [fetchResposta.fulfilled]: (state, action) => {
      state.resposta = action.payload;
      state.resposta.projetoId = action.payload.projeto?.id;
      state.resposta.consultaOrigemId = action.payload.consultaOrigem?.id;
    },
    [fetchResposta.rejected]: (state) => {
      state.resposta = [];
    },

    [fetchAnexos.fulfilled]: (state, action) => {
      state.anexos = action.payload;
    },
    [fetchAnexos.rejected]: (state) => {
      state.anexos = [];
    },
    [fetchAnexo.fulfilled]: (state, action) => {
      state.anexo = action.payload;
    },

    [fetchFases.fulfilled]: (state, action) => {
      state.fases = action.payload;
    },
    [fetchFases.rejected]: (state) => {
      state.fases = [];
    },

    [fetchLogs.fulfilled]: (state, action) => {
      state.logs = action.payload;
    },
    [fetchLogs.rejected]: (state) => {
      state.logs = [];
    },
  }
})

export default slice.reducer;
