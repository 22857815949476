import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaPLsDetailPage from './_detail';
import EmpresaPLPage from './pl';

export default function EmpresaPLsPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/pls/:id" component={EmpresaPLPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaPLsDetailPage} />
        </Switch>
      </Card>);
}
