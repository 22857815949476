import { Select } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';

export default function SelectCnae({ onChange, params, value, ...props }) {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchSearch = search => {
    setLoadingSearch(true);
    unwrapAxiosPromise(axios.get(`/tabelas/cnae`, { params: { search, ...params } }))
      .then(data => {
        setOptions(data);
      })
      .finally(() => setLoadingSearch(false));
  };

  const handleChange = codigo => {
    onChange(codigo);
  };

  const handleSearch = useDebouncedCallback(500, search => {
    fetchSearch(search);
  }, []);

  useEffect(() => {
    fetchSearch();
  }, []);

  return (
    <Select
      filterOption={false}
      loading={loadingSearch}
      onChange={handleChange}
      onSearch={handleSearch}
      showSearch
      value={value}
      {...props}
    >
      {options.map(option => (
        <Select.Option key={option.codigo} value={option.codigo}>
          {option.codigo} - {option.descricao}
        </Select.Option>
      ))}
    </Select>
  );
}

SelectCnae.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.any,
  value: PropTypes.number,
};
