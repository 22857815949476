import React from 'react';
import { SketchPicker } from "react-color";
import { Popover, Tag } from "antd";
import styles from './_styles.less'

const colors = [
  'hsl(197,100%,43%)',
  'hsl(175,100%,43%)',
  'hsl(152,100%,43%)',
  'hsl(130,100%,43%)',
  'hsl(107,100%,43%)',
  'hsl( 85,100%,43%)',
  'hsl( 62,100%,43%)',
  'hsl( 40,100%,43%)',
  'hsl( 17,100%,43%)',
  'hsl(355,100%,43%)',
  'hsl(332,100%,43%)',
  'hsl(310,100%,43%)',
  'hsl(287,100%,43%)',
  'hsl(265,100%,43%)',
  'hsl(242,100%,43%)',
  'hsl(220,100%,43%)',
];

export default function InputColor({ disabled, onChange, onRemove, value, ...props }) {
  const fireChange = newValue => {
    if (disabled)
      return;
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(newValue);
  };

  const handleChange = e => fireChange(e.hex.slice(1).toUpperCase());

  if (disabled)
    return (
      <div className={`${styles['input-color']} ${styles['disabled']}`}>
        <Tag className={styles['tag']} color={'#' + value}>&nbsp;</Tag>
        <span className={styles['text']}>{value}</span>
      </div>
    );

  return (
    <Popover
      content={
        <SketchPicker
          color={value ? '#' + value : ''}
          presetColors={colors}
          disableAlpha
          onChange={handleChange}
          {...props}
        />
      }
      placement="topLeft"
      overlayClassName={styles['overlay']}
      showArrow={false}
      trigger="click"
    >
      <div className={styles['input-color']}>
        <Tag className={styles['tag']} color={'#' + value}>&nbsp;</Tag>
        <span className={styles['text']}>{value}</span>
      </div>
    </Popover>
  )
}