import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function SelectMonth({max, ...props}) {
  const {t} = useTranslation();

  const createOption = (id, label) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(1, 'Janeiro')}
        {createOption(2, 'Fevereiro')}
        {createOption(3, 'Março')}
        {createOption(4, 'Abril')}
        {createOption(5, 'Maio')}
        {createOption(6, 'Junho')}
        {createOption(7, 'Julho')}
        {createOption(8, 'Agosto')}
        {createOption(9, 'Setembro')}
        {createOption(10, 'Outubro')}
        {createOption(11, 'Novembro')}
        {createOption(12, 'Dezembro')}
      </Select>
  );
}

SelectMonth.propTypes = {
  value: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  onChange: PropTypes.func,
};
