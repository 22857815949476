import {FileExcelTwoTone, FileImageTwoTone, FilePdfTwoTone, FilePptTwoTone, FileTextTwoTone, FileTwoTone, FileUnknownTwoTone, FileWordTwoTone, FileZipTwoTone, FolderOpenTwoTone, FolderTwoTone, RollbackOutlined} from '@ant-design/icons';
import {Col, Row, Spin} from 'antd';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import {baixandoByCaminhoSelector, baixarArquivo, diretorioSelecionadoSelector, selecionarDiretorioCaminho} from '../../store/drive';
import {abbreviateFileName} from '../../util/string';
import DriveFileDropdown from './_DriveFileDropdown';
import styles from './_styles.less';
import useDriveQuery from './_useDriveQuery';

function getIconByArquivo(arquivo) {
  const className = styles['directory-file-icon'];

  if (arquivo.tipo) {
    switch (arquivo.tipo) {
      case 'diretorio':
        return !arquivo.link ? <FolderTwoTone twoToneColor="#faad14" className={className} /> : <FolderOpenTwoTone twoToneColor="#faad14" className={className} />;
      case 'application/xml':
        return <FileTextTwoTone className={className} />;
      case 'application/pdf':
        return <FilePdfTwoTone className={className} />;
      case 'application/zip':
      case 'application/x-7z-compressed':
      case 'application/vnd.rar':
        return <FileZipTwoTone className={className} />;
      case 'application/msword':
        return <FileWordTwoTone className={className} />;
      case 'application/vnd.ms-excel':
        return <FileExcelTwoTone className={className} />;
      case 'application/vnd.ms-powerpoint':
        return <FilePptTwoTone className={className} />;
    }

    if (arquivo.tipo.startsWith('text/'))
      return arquivo.tamanho === 0 ? <FileTwoTone className={className} /> : <FileTextTwoTone className={className} />;
    if (arquivo.tipo.startsWith('image/'))
      return <FileImageTwoTone className={className} />;
    if (arquivo.tipo.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.'))
      return <FileWordTwoTone className={className} />;
    if (arquivo.tipo.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.'))
      return <FileExcelTwoTone className={className} />;
    if (arquivo.tipo.startsWith('application/vnd.openxmlformats-officedocument.presentationml.'))
      return <FilePptTwoTone className={className} />;
  }

  return <FileUnknownTwoTone className={styles['directory-file-icon']} />;
}

export default function DriveDirectory() {
  const {empresaId} = useDriveQuery();
  const dispatch = useDispatchWithResult();
  const diretorioSelecionado = useSelector(diretorioSelecionadoSelector);
  const baixandoByCaminho = useSelector(baixandoByCaminhoSelector);

  const handleDiretorioPaiClick = e => {
    e.preventDefault();
    if (diretorioSelecionado.caminhoPai)
      dispatch(selecionarDiretorioCaminho(diretorioSelecionado.caminhoPai));
  };

  const handleClick = (e, arquivo) => {
    e.preventDefault();
    if (arquivo.tipo === 'diretorio')
      dispatch(selecionarDiretorioCaminho(arquivo.caminho));
    else
      dispatch(baixarArquivo({empresaId, arquivo}));
  };

  const cols = useMemo(() => {
    return diretorioSelecionado.arquivos.map(arquivo => {
      const baixando = baixandoByCaminho[arquivo.caminho] === true;
      const nomeReduzido = abbreviateFileName(arquivo.nome, 36);
      const icon = getIconByArquivo(arquivo);
      return (
          <Col flex="none" key={arquivo.caminho}>
            <DriveFileDropdown arquivo={arquivo}>
              <Spin spinning={baixando}>
                <div className={styles['directory-file']} onClick={e => handleClick(e, arquivo)}>
                  <div>{icon}</div>
                  <div className={styles['directory-file-name']}>{nomeReduzido}</div>
                </div>
              </Spin>
            </DriveFileDropdown>
          </Col>
      );
    });
  }, [diretorioSelecionado, baixandoByCaminho]);

  return (
      <Row gutter={[4, 4]}>
        {!!diretorioSelecionado.caminhoPai && (
            <Col flex="none">
              <div className={styles['directory-file']} onClick={handleDiretorioPaiClick}>
                <div><RollbackOutlined className={styles['directory-file-icon'] + ' warning'} /></div>
              </div>
            </Col>
        )}
        {cols}
      </Row>
  );
}
