import React from 'react';
import { useSelector } from "react-redux";
import { etapasSelector, fetchWorkflow } from "../../store/workflow";
import styles from './_Workflow.less';
import WorkflowEtapa from "./_WorkflowEtapa";
import WorkflowEtapaCriar from "./_WorkflowEtapaCriar";
import WorkflowTarefaModal from "./tarefa/_WorkflowTarefaModal";
import useLoading from "../../hooks/useLoading";
import { Spin } from "antd";
import WorkflowTipoTarefaModal from "./tipoTarefa/_WorkflowTipoTarefaModal";
import { isClienteGestorSelector } from "../../store/autenticacao";
import WorkflowFilterBar from "./_WorkflowFilterBar";

export default function Workflow() {
  const etapas = useSelector(etapasSelector)
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const loading = useLoading(fetchWorkflow.typePrefix);

  if (loading)
    return <Spin className={styles['loading']} spinning/>;

  return (
    <div className={styles['workflow']}>
      <WorkflowFilterBar/>
      <div className={styles['etapas']}>
        {etapas.map(etapa => <WorkflowEtapa key={etapa.id} {...etapa}/>)}
        {isClienteGestor && <WorkflowEtapaCriar/>}
      </div>
      {isClienteGestor && <WorkflowTipoTarefaModal/>}
      <WorkflowTarefaModal/>
    </div>
  )
}