import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styles from "../input-names/_styles.less";
import ActionButton from "../action-button";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import SelectCnae from "../select-cnae";

export default function SelectCnaes({ name, onChange, params, value: values = [], ...props }) {
  const id = useMemo(() => "select-cnaes-" + new Date().getTime(), []);
  const fireChange = (newValue, value, index) => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(values
        .map((v, i) => i === index ? newValue : v)
        .filter(v => v !== null));
  };

  const handleChange = (newValue, value, index) => fireChange(newValue, value, index);

  const handleAdd = (index) => {
    if (typeof onChange === 'function') {
      const before = values.slice(0, index + 1);
      const after = values.slice(index + 1);
      onChange([...before, '', ...after]);
      setImmediate(() => document.querySelector(`#${id} :nth-child(${index + 2}) div.ant-select`).focus());
    }
  }

  const handleRemove = index => {
    const before = values.slice(0, index);
    const after = values.slice(index + 1);
    onChange([...before, ...after])
    setImmediate(() => document.querySelector(`#${id} :nth-child(${Math.max(0, index)}) div.ant-select`).focus());
  }

  return (
    <div id={id}>
      {values.map((value, index) => (
        <Form.Item key={index} name={[name, index]}>
          <div className={styles.wrapper}>
            <SelectCnae
              value={value}
              onChange={newValue => handleChange(newValue, value, index)}
              {...props}
            />
            <ActionButton disabled={props.disabled} icon={<DeleteOutlined/>} onClick={() => handleRemove(index)}/>
          </div>
        </Form.Item>
      ))}
      <Form.Item name={[name, -1]}>
        <ActionButton disabled={props.disabled} icon={<PlusOutlined/>} onClick={() => handleAdd(values.length)}/>
      </Form.Item>
    </div>
  );
}