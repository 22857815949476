import { createPortal } from "react-dom";
import styles from "./_styles.less";
import { formatDocumento } from "../../util/documento";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function OrganogramaTooltip({ empresa, onClick, target }) {
  const ref = useRef(null);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!empresa || !target)
      return;
    const targetRect = target.getBoundingClientRect();
    const tooltip = ref.current;
    const tooltipRect = tooltip.getBoundingClientRect();
    const margin = 12;
    let x = targetRect.x;
    let y = targetRect.y;

    const dx = window.innerWidth - x;
    const dy = window.innerHeight - y;

    // left
    if (dx < targetRect.width + tooltipRect.width + margin)
      x -= tooltipRect.width + margin;
    // right
    else
      x += targetRect.width + margin;
    // top
    if (dy < tooltipRect.height)
      y -= tooltipRect.height - targetRect.height;

    tooltip.style.left = `${Math.round(x)}px`;
    tooltip.style.top = `${Math.round(y)}px`;
  }, [empresa, target]);

  const handleClick = useCallback((e, tab) => {
    e.preventDefault();
    onClick(empresa, tab);
  }, [empresa, onClick]);

  return createPortal(
    <div ref={ref} className={target ? styles['tooltip-visible'] : styles['tooltip']}>
      {empresa?.tipo === 'empresa' &&
        <div className={styles['group']}>
          <div>
            <a href="#" onClick={e => handleClick(e, 'cadastro')}>
              {empresa?.nome}
            </a>
          </div>
          <div>
            <a href="#" onClick={e => handleClick(e, 'cadastro')}>
              <strong>{formatDocumento(empresa?.documento)}</strong>
            </a>
          </div>
        </div>
      }
      {empresa?.tipo !== 'empresa' &&
        <div className={styles['group']}>
          <div>{empresa?.nome}</div>
          <div>
            <strong>{formatDocumento(empresa?.documento)}</strong>
          </div>
        </div>
      }
      {empresa?.administradores?.length > 0 &&
        <div className={styles['group']}>
          <div>
            <a href="#" onClick={e => handleClick(e, 'administradores')}>
              <strong>{t('Administradores')}</strong>
            </a>
          </div>
          {empresa?.administradores?.map(administrador => (
            <div key={administrador.id}>
              <a href="#" onClick={e => handleClick(e, `administradores/${administrador.id}`)}>
                {administrador.nome}
              </a>
            </div>
          ))}
        </div>
      }
      {empresa?.procuradores?.length > 0 &&
        <div className={styles['group']}>
          <div>
            <a href="#" onClick={e => handleClick(e, 'procuradores')}>
              <strong>{t('Procuradores')}</strong>
            </a>
          </div>
          {empresa?.procuradores?.map(procurador => (
            <div key={procurador.id} className="ws-pre-line">
              <a href="#" onClick={e => handleClick(e, `procuradores/${procurador.id}`)}>
                {procurador.nomes.join('\n')}
              </a>
            </div>
          ))}
        </div>
      }
    </div>, document.body);
}