import {MinusCircleFilled} from '@ant-design/icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useLoading from '../../../hooks/useLoading';
import {proximoErroSelector} from '../../../store/societarioDashboard';
import {fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas} from '../../../store/empresas';
import ProximoAlertaCard from './_ProximoAlertaCard';

export default function ProximoAlertaErroCard() {
  const {t} = useTranslation();
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const proximoErro = useSelector(proximoErroSelector);

  return (
      <ProximoAlertaCard
          alerta={proximoErro}
          className="background-error"
          icon={<MinusCircleFilled />}
          title={t('Pendência mais antiga')}
          loading={loading}
      />
  );
}
