import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Consulta} from '../../constants';

const {Status} = Consulta;

export default function SelectConsultivoConsultaStatus(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(Status.Cadastro)}
      {createOption(Status.Aberto)}
      {createOption(Status.EmTrabalho)}
      {createOption(Status.AguardandoDocumentos)}
      {createOption(Status.Pausado)}
      {createOption(Status.Concluido)}
      {createOption(Status.Cancelado)}
    </Select>
  );
}

SelectConsultivoConsultaStatus.propTypes = {
  value: PropTypes.oneOf([
    Status.Cadastro.id,
    Status.Aberto.id,
    Status.EmTrabalho.id,
    Status.AguardandoDocumentos.id,
    Status.Pausado.id,
    Status.Concluido.id,
    Status.Cancelado.id,
  ]),
  onChange: PropTypes.func,
};
