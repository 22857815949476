// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bAadfgwHWSxuvDRPeT6s3g\\=\\= {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n.bAadfgwHWSxuvDRPeT6s3g\\=\\= > .ant-space-item:last-child {\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://src/pages/consultivo/projeto/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AAJA;EAMI,YAAA;AACJ","sourcesContent":[".space {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n\n  > :global(.ant-space-item):last-child {\n    flex-grow: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "bAadfgwHWSxuvDRPeT6s3g=="
};
export default ___CSS_LOADER_EXPORT___;
