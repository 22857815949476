export function formatCep(cep) {
  cep = cep || '';
  if (cep.length === 8) {
    return (
        cep.substring(0, 5) + '-' +
        cep.substring(5, 8));
  }
  return cep;
}

export function parseCep(cep) {
  return cep.replace(/\D+/g, '');
}

export function isValidCep(cep) {
  if (!cep || cep.length !== 8)
    return false;
  return true;
}
