// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W\\+\\+cUHoUxA9eYHpzbQzEOA\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n}\n.W\\+\\+cUHoUxA9eYHpzbQzEOA\\=\\= > .P-FI4kDonpkKrZ6N\\+Mc9\\+A\\=\\= {\n  flex-grow: 1;\n  line-height: 32px;\n  padding: 0 8px;\n}\n.W\\+\\+cUHoUxA9eYHpzbQzEOA\\=\\=:not(.iKuKPhd1JO274ifrbkUEjA\\=\\=) > .P-FI4kDonpkKrZ6N\\+Mc9\\+A\\=\\= {\n  cursor: pointer;\n}\n.W\\+\\+cUHoUxA9eYHpzbQzEOA\\=\\=:not(.iKuKPhd1JO274ifrbkUEjA\\=\\=) > .P-FI4kDonpkKrZ6N\\+Mc9\\+A\\=\\=:hover {\n  background-color: #f0f2f5;\n}\n.DW1\\+GL7PohQ7niFOZpxaxw\\=\\= {\n  width: 100%;\n}\n.iFYawUGnlar6lZ2FSaJNbw\\=\\= {\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_SelectWorkflowUnidade.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,UAAA;EACA,mBAAA;AACF;AAEA;EACE,YAAA;EACA,iBAAA;EACA,cAAA;AAAF;AAGA;EACE,eAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAKA;EACE,WAAA;AAHF;AAMA;EACE,cAAA;AAJF","sourcesContent":[".workflow-tarefa-unidade {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n}\n\n.workflow-tarefa-unidade > .descricao {\n  flex-grow: 1;\n  line-height: 32px;\n  padding: 0 8px;\n}\n\n.workflow-tarefa-unidade:not(.read-only) > .descricao {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.select {\n  width: 100%;\n}\n\n.nao-preenchido {\n  color: #999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-unidade": "W++cUHoUxA9eYHpzbQzEOA==",
	"descricao": "P-FI4kDonpkKrZ6N+Mc9+A==",
	"read-only": "iKuKPhd1JO274ifrbkUEjA==",
	"select": "DW1+GL7PohQ7niFOZpxaxw==",
	"nao-preenchido": "iFYawUGnlar6lZ2FSaJNbw=="
};
export default ___CSS_LOADER_EXPORT___;
