import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchCBE} from '../../../../store/empresa';
import EmpresaCBECreatePage from './_create';
import EmpresaCBEDetailPage from './_detail';
import EmpresaCBEEditPage from './_edit';

export default function EmpresaCBEPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchCBE({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/cbes/:id/edit" component={EmpresaCBEEditPage} />
        <Route path="/*/empresas/:empresaId/cbes/create" component={EmpresaCBECreatePage} />
        <Route path="/*/empresas/:empresaId/cbes/:id" component={EmpresaCBEDetailPage} />
      </Switch>);
}
