import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmpresaBeneficiarioSocio} from '../../constants';

const {Aceite} = EmpresaBeneficiarioSocio;

export default function SelectEmpresaBeneficiarioSocioAceite(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Aceite.Pendente)}
        {createOption(Aceite.Sim)}
      </Select>
  );
}

SelectEmpresaBeneficiarioSocioAceite.propTypes = {
  value: PropTypes.oneOf([
    Aceite.Pendente.id,
    Aceite.Sim.id]),
  onChange: PropTypes.func,
};
