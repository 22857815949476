import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import {compareBooleans} from '../../../util/boolean';
import {formatCep} from '../../../util/cep';
import {formatCnpj} from '../../../util/cnpj';
import {compareStrings} from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

export default function EmpresaUnidadesTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Unidade'),
      dataIndex: 'apelido',
      key: 'apelido',
      sorter: (a, b) => compareStrings(a.apelido, b.apelido),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('CNPJ'),
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 150,
      sorter: (a, b) => compareStrings(a.cnpj, b.cnpj),
      render: (text, record) => formatCnpj(text),
    },
    {
      title: t('Endereço'),
      dataIndex: 'endereco',
      key: 'endereco',
      sorter: (a, b) => compareStrings(a.endereco, b.endereco),
      render: (text, record) => {
        const cidadeEstado = [record.cidade, record.estado].filter(a => !!a).join('/');
        const cep = record.cep ? t('CEP') + ': ' + formatCep(record.cep) : null;
        const endereco = [record.endereco, cidadeEstado, cep].filter(a => !!a).join(', ');
        return <TableCellEllipsis text={endereco}/>;
      },
    },
    {
      title: t('Objeto Social'),
      dataIndex: 'objetoSocial',
      key: 'objetoSocial',
      sorter: (a, b) => compareStrings(a.objetoSocial, b.objetoSocial),
      render: (text) => <TableCellEllipsis text={text}/>,
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>,
    },
    {
      title: t('Ativa'),
      dataIndex: 'ativa',
      key: 'ativa',
      width: 50,
      sorter: (a, b) => compareBooleans(a.ativa, b.ativa),
      render: (text, record) => record.ativa ? t('Sim') : t('Não'),
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowClassName={record => record.ativa ? undefined : 'inactive'}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaUnidadesTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
