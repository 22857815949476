import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import useFormatter from '../../../hooks/useFormatter';
import { compareStrings } from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

export default function EmpresaLicencasTable({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();
  const { formatDate } = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Unidade'),
      dataIndex: ['unidade', 'apelido'],
      key: 'unidade',
      sorter: (a, b) => compareStrings(a.unidade?.apelido, b.unidade?.apelido),
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
    },
    {
      title: t('Órgão Emissor'),
      dataIndex: 'orgaoEmissor',
      key: 'orgaoEmissor',
      sorter: (a, b) => compareStrings(a.orgaoEmissor, b.orgaoEmissor)
    },
    {
      title: t('Data Inicial'),
      dataIndex: 'dataInicial',
      key: 'dataInicial',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataInicial, b.dataInicial),
      render: (text, record) => formatDate(record.dataInicial),
    },
    {
      title: t('Data Final'),
      dataIndex: 'dataFinal',
      key: 'dataFinal',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataFinal, b.dataFinal),
      render: (text, record) => record.indeterminado ? t('Indeterminado') : formatDate(record.dataFinal),
    },
    {
      title: t('Site'),
      dataIndex: 'site',
      key: 'site',
      sorter: (a, b) => compareStrings(a.site, b.site)
    },
    {
      title: t('Conteúdo'),
      dataIndex: 'conteudo',
      key: 'conteudo',
      sorter: (a, b) => compareStrings(a.conteudo, b.conteudo),
      render: (text) => <TableCellEllipsis text={text}/>
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

EmpresaLicencasTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
