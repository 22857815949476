import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../components/mini-table';
import useFormatter from '../../hooks/useFormatter';

export default function UsuariosTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatDateTime} = useFormatter();

  const fireClick = usuario => typeof onClick === 'function' ? onClick(usuario) : undefined;

  const columns = [
    {
      title: t('E-mail'),
      dataIndex: 'email',
      key: 'email',
      width: 250,
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: t('Nível'),
      dataIndex: 'nivelAcesso',
      key: 'nivelAcesso',
      align: 'center',
      width: 50,
    },
    {
      title: t('Últ. Login em'),
      dataIndex: 'ultimoLoginEm',
      key: 'ultimoLoginEm',
      width: 150,
      render: (text, record) => formatDateTime(record.ultimoLoginEm),
    },
    {
      title: t('Ativo'),
      dataIndex: 'ativo',
      key: 'ativo',
      align: 'center',
      width: 50,
      render: (text, record) => record.ativo ? t('Sim') : t('Não'),
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowClassName={record => record.ativo ? undefined : 'inactive'}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

UsuariosTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
