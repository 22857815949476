import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import ConsultivoConsultaRespostaDetailPage from './_detail';
import ConsultivoConsultaRespostaEditPage from "./_edit";

export default function ConsultivoConsultaRespostaPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/consultas/:consultaId/resposta/edit" component={ConsultivoConsultaRespostaEditPage}/>
        <Route path="/consultivo/consultas/:consultaId" component={ConsultivoConsultaRespostaDetailPage}/>
      </Switch>
    </Card>);
}
