import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import {EmpresaBeneficiarioSocio, findByIdAndMap} from '../../../constants';
import useFormatter from '../../../hooks/useFormatter';
import {compareBooleans} from '../../../util/boolean';
import {compareNumbers} from '../../../util/number';
import {compareStrings} from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

const {Apresentacao, Aceite} = EmpresaBeneficiarioSocio;

export default function EmpresaBeneficiariosSocioTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatDate, formatBoolean} = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Sócio'),
      dataIndex: ['socio', 'nome'],
      key: 'socio',
      sorter: (a, b) => compareStrings(a.socio.nome, b.socio.nome),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Aplicável'),
      key: 'aplicavel',
      sorter: (a, b) => {
        let n = compareBooleans(a.aplicavel, b.aplicavel);
        if (n !== 0) return n;
        return compareStrings(a.dataAplicavel, b.dataAplicavel);
      },
      render: (text, record) => record.aplicavel ? formatDate(record.dataAplicavel) : formatBoolean(record.aplicavel),
    },
    {
      title: t('Apresentação'),
      key: 'apresentacao',
      width: 100,
      sorter: (a, b) => {
        let n = compareNumbers(a.apresentacao, b.apresentacao);
        if (n !== 0) return n;
        return compareStrings(a.dataApresentacao, b.dataApresentacao);
      },
      render: (text, record) =>
          record.aplicavel ?
              record.apresentacao === Apresentacao.Sim.id ?
                  formatDate(record.dataApresentacao) :
                  findByIdAndMap(Apresentacao, record.apresentacao, apresentacao => t(apresentacao.label)) :
              '-',
    },
    {
      title: t('Aceite'),
      key: 'aceite',
      width: 100,
      sorter: (a, b) => {
        let n = compareNumbers(a.aceite, b.aceite);
        if (n !== 0) return n;
        return compareStrings(a.dataAceite, b.dataAceite);
      },
      render: (text, record) =>
          record.apresentacao === Apresentacao.Sim.id ?
              record.aceite === Aceite.Sim.id ?
                  formatDate(record.dataAceite) :
                  findByIdAndMap(Aceite, record.aceite, aceite => t(aceite.label)) :
              '-',
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowClassName={record => record.aplicavel === true ? undefined : 'inactive'}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaBeneficiariosSocioTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
