import { ClearOutlined, FilterFilled, FilterOutlined, LayoutOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EmpresaTabs } from '../../constants';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import { acessoSelector } from '../../store/acessos';
import { fetchEmpresas, filtradoSelector, filtrarEmpresas, filtroSelector } from '../../store/empresas';
import ActionLink from '../action-link';
import EmpresaDrawer from './_EmpresaDrawer';
import styles from './_styles.less';
import { funcoesSelector } from "../../store/autenticacao";

export default function ComplianceMenu({ showCreate, showFilter, extraMenu }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const funcoes = useSelector(funcoesSelector);
  const acesso = useSelector(acessoSelector);
  const filtro = useSelector(filtroSelector);
  const filtrado = useSelector(filtradoSelector);

  const [filterVisible, setFilterVisible] = useState(false);
  const [empresa, setEmpresa] = useState();
  const [empresaDrawerVisible, setEmpresaDrawerVisible] = useState(false);

  const hasCadastro = funcoes?.includes('cadastro') && acesso?.funcoes?.includes('cadastro');

  const handleLimparFiltroClick = ({ domEvent }) => {
    dispatch(filtrarEmpresas({ sucessos: false, avisos: false, erros: false, inativas: false, tabs: [] }));
    domEvent.preventDefault();
    return true;
  };

  const handleFilterSucessosChange = ({ domEvent }) => {
    dispatch(filtrarEmpresas({ sucessos: !filtro.sucessos }));
    domEvent.preventDefault();
    return true;
  };

  const handleFilterAvisosChange = ({ domEvent }) => {
    dispatch(filtrarEmpresas({ avisos: !filtro.avisos }));
    domEvent.preventDefault();
    return true;
  };

  const handleFilterErrosChange = ({ domEvent }) => {
    dispatch(filtrarEmpresas({ erros: !filtro.erros }));
    domEvent.preventDefault();
    return true;
  };

  const handleFilterInativasChange = ({ domEvent }) => {
    dispatch(filtrarEmpresas({ inativas: !filtro.inativas }));
    domEvent.preventDefault();
    return true;
  };

  const handleFilterTabChange = ({ key, domEvent }) => {
    const index = filtro.tabs.indexOf(key);
    if (index < 0)
      dispatch(filtrarEmpresas({ tabs: [...filtro.tabs, key] }));
    else {
      const newTabs = [...filtro.tabs];
      newTabs.splice(index, 1);
      dispatch(filtrarEmpresas({ tabs: newTabs }));
    }
    domEvent.preventDefault();
    return true;
  };

  const handleLayoutClick = ({ key }) => {
    dispatch(filtrarEmpresas({ sucessos: false, avisos: false, erros: false, inativas: false, tabs: [] }));
    history.push(key);
  };

  const handleCreateClick = () => {
    setEmpresa({ acessoId: acesso?.id });
    setEmpresaDrawerVisible(true);
  };

  const handleEmpresaChange = () => {
    dispatch(fetchEmpresas({ acessoId: acesso?.id }));
  };

  const handleEmpresaDrawerClose = () => {
    setEmpresaDrawerVisible(false);
  };

  const handleEmpresaDrawerAfterClose = () => {
    setEmpresa(undefined);
  };

  const renderFilterTabMenuItem = tab => {
    return (
      <Menu.Item key={tab.name} onClick={handleFilterTabChange}>
        <Checkbox checked={filtro.tabs.includes(tab.name)}>
          {t(tab.label)}
        </Checkbox>
      </Menu.Item>);
  };

  const buttons = [];
  if (showCreate && hasCadastro) {
    buttons.push(
      <ActionLink
        key="create"
        title={t('Criar Empresa')}
        noTitle
        icon={<PlusOutlined/>}
        onClick={handleCreateClick}
      />);
  }
  if (showFilter && hasCadastro) {
    const tabsFiltered = EmpresaTabs
      .filter(tab => tab.modulos.includes('compliance') === true)
      .filter(tab => funcoes?.includes(tab.name) && acesso?.funcoes?.includes(tab.name));
    buttons.push(
      <Dropdown
        key="filter-dropdown"
        overlay={(
          <Menu theme="dark" mode="horizontal" selectable={false} disabledOverflow>
            <Menu.Item key="limpar" onClick={handleLimparFiltroClick} className={styles['compliance-menu-item']}>
              <ActionLink
                title={t('Limpar Filtro')}
                icon={<ClearOutlined/>}
                disabled={!filtrado}
              />
            </Menu.Item>
            <Menu.ItemGroup title={t('Exibir')} className={styles['compliance-menu-item-group']}>
              <Menu.Item key="sucessos" onClick={handleFilterSucessosChange}>
                <Checkbox checked={filtro.sucessos}>{t('Apenas em dia')}</Checkbox>
              </Menu.Item>
              <Menu.Item key="avisos" onClick={handleFilterAvisosChange}>
                <Checkbox checked={filtro.avisos}>{t('Apenas com alertas')}</Checkbox>
              </Menu.Item>
              <Menu.Item key="erros" onClick={handleFilterErrosChange}>
                <Checkbox checked={filtro.erros}>{t('Apenas com pendências')}</Checkbox>
              </Menu.Item>
              <Menu.Item key="inativas" onClick={handleFilterInativasChange}>
                <Checkbox checked={filtro.inativas}>{t('Apenas inativas')}</Checkbox>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider/>
            <Menu.ItemGroup title={t('Abas')} className={styles['compliance-menu-item-group']}>
              {tabsFiltered.map(renderFilterTabMenuItem)}
            </Menu.ItemGroup>
          </Menu>)}
        visible={filterVisible}
        onVisibleChange={setFilterVisible}
      >
        <ActionLink
          key="filter"
          icon={filtrado ? <FilterFilled/> : <FilterOutlined/>}
          onClick={() => setFilterVisible(true)}
        />
      </Dropdown>);
  }
  if (extraMenu && extraMenu.length > 0)
    buttons.push(extraMenu);
  buttons.push(
    <Dropdown
      key="layout-dropdown"
      overlay={(
        <Menu theme="dark" onClick={handleLayoutClick}>
          {hasCadastro && <Menu.Item key="/compliance/dashboard">{t('Painel')}</Menu.Item>}
          {hasCadastro && <Menu.Item key="/compliance/tabela">{t('Tabela')}</Menu.Item>}
          {hasCadastro && <Menu.Item key="/compliance/detalhado">{t('Detalhado')}</Menu.Item>}
        </Menu>
      )}
    >
      <ActionLink key="layout" icon={<LayoutOutlined/>}/>
    </Dropdown>);

  return (
    <div className={styles['compliance-menu']}>
      {buttons}
      {hasCadastro &&
      <EmpresaDrawer
        value={empresa}
        onChange={handleEmpresaChange}
        visible={empresaDrawerVisible}
        onClose={handleEmpresaDrawerClose}
        onAfterClose={handleEmpresaDrawerAfterClose}
      />
      }
    </div>
  );
}

ComplianceMenu.propTypes = {
  showCreate: PropTypes.bool,
  showFilter: PropTypes.bool,
  extraMenu: PropTypes.array,
};
