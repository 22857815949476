import { Col, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLoading from "../../../hooks/useLoading";
import { consultasPorStatusSelector, filtrar, filtroSelector } from "../../../store/consultivoDashboard";
import { useSelector } from "react-redux";
import StatusCard from "./_StatusCard";
import { Consulta } from "../../../constants";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";

export default function ConsultasPorStatusCardGroup() {
  const { t } = useTranslation();
  const loading = useLoading(consultasPorStatusSelector.typePrefix);
  const dispatch = useDispatchWithResult();
  const filtro = useSelector(filtroSelector);
  const { cadastro, aberto, emTrabalho, aguardandoDocumentos, pausado, emAtraso, total } = useSelector(consultasPorStatusSelector);

  const handleStatusClick = (e, statusId) => {
    e.preventDefault();
    const index = filtro.statusIds.indexOf(statusId);
    if (index < 0)
      dispatch(filtrar({ statusIds: [...filtro.statusIds, statusId] }))
    else
      dispatch(filtrar({ statusIds: filtro.statusIds.filter(id => statusId !== id) }))
  }

  return (
    <>
      <Col span={24} md={8} lg={4}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-primary"
            count={cadastro}
            loading={loading}
            title={t('Cadastro')}
            total={total}
            checked={filtro.statusIds.includes(Consulta.Status.Cadastro.id)}
            onClick={e => handleStatusClick(e, Consulta.Status.Cadastro.id)}
          />
        </Spin>
      </Col>
      <Col span={24} md={8} lg={4}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-warning"
            count={aberto}
            loading={loading}
            title={t('Aberto')}
            total={total}
            checked={filtro.statusIds.includes(Consulta.Status.Aberto.id)}
            onClick={e => handleStatusClick(e, Consulta.Status.Aberto.id)}
          />
        </Spin>
      </Col>
      <Col span={24} md={8} lg={4}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-success"
            count={emTrabalho}
            loading={loading}
            title={t('Em Trabalho')}
            total={total}
            checked={filtro.statusIds.includes(Consulta.Status.EmTrabalho.id)}
            onClick={e => handleStatusClick(e, Consulta.Status.EmTrabalho.id)}
          />
        </Spin>
      </Col>
      <Col span={24} md={8} lg={4}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-error"
            count={emAtraso}
            loading={loading}
            title={t('Em Atraso')}
            total={total}
          />
        </Spin>
      </Col>
      <Col span={24} md={8} lg={4}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-inactive"
            count={aguardandoDocumentos}
            loading={loading}
            title={t('Aguardando Documentos')}
            total={total}
            checked={filtro.statusIds.includes(Consulta.Status.AguardandoDocumentos.id)}
            onClick={e => handleStatusClick(e, Consulta.Status.AguardandoDocumentos.id)}
          />
        </Spin>
      </Col>
      <Col span={24} md={8} lg={4}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-inactive"
            count={pausado}
            loading={loading}
            title={t('Pausado')}
            total={total}
            checked={filtro.statusIds.includes(Consulta.Status.Pausado.id)}
            onClick={e => handleStatusClick(e, Consulta.Status.Pausado.id)}
          />
        </Spin>
      </Col>
    </>);
}
