export function compareStrings(a, b) {
  return (a || '').localeCompare(b || '');
}

export function capitalize(a) {
  a = (a || '');
  const sep = [' ', '.']
  for (let s of sep) {
    a = a.split(s).map(w => w.substring(0, 1).toUpperCase() + w.substring(1)).join(s);
  }
  return a;
}

export function abbreviateStart(a, maxSize, separator = '...') {
  a = a || '';
  if (a.length < maxSize)
    return a;
  if (maxSize <= separator.length)
    return separator;
  return separator + a.substring(a.length - maxSize + separator.length);
}

export function abbreviateEnd(a, maxSize, separator = '...') {
  a = a || '';
  if (a.length < maxSize)
    return a;
  if (maxSize <= separator.length)
    return separator;
  return a.substring(0, maxSize - separator.length) + separator;
}

export function abbreviateMiddle(a, maxSize, separator = '...') {
  a = a || '';
  if (a.length < maxSize)
    return a;
  if (maxSize <= separator.length)
    return separator;
  const halfSize = Math.floor(maxSize / 2);
  const sepSize1 = Math.floor(separator.length / 2);
  const sepSize2 = Math.ceil(separator.length / 2);
  return a.substring(0, halfSize - sepSize1) + separator + a.substring(a.length - halfSize + sepSize2);
}

export function abbreviateFileName(a, maxSize, separator = '...') {
  a = a || '';
  if (a.length < maxSize)
    return a;
  if (maxSize <= separator.length)
    return separator;

  const i = a.lastIndexOf('.');
  if (i === -1)
    return abbreviateEnd(a, maxSize, separator);

  const name = a.substring(0, i);
  const ext = a.substring(i);
  const nameAb = ext.length + separator.length < maxSize ?
    abbreviateEnd(name, maxSize - ext.length - separator.length, separator) :
    abbreviateEnd(name, maxSize - ext.length, '');
  return abbreviateStart(nameAb + ext, maxSize, separator);
}

export function substringAfter(a, searchString, position = undefined) {
  a = a || '';
  const index = a.indexOf(searchString, position);
  if (index < 0)
    return '';
  return a.substring(index + searchString.length);
}

export function removeFileNameExtension(a) {
  if (a) {
    const i = a.lastIndexOf('.');
    if (i >= 0)
      return a.substring(0, i);
  }
  return a;
}