import {Col, Form, Input, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputCpf from '../../../../components/input-cpf';
import InputDate from '../../../../components/input-date';
import InputName from '../../../../components/input-name';
import MiniForm from '../../../../components/mini-form';
import SelectBoolean from '../../../../components/select-boolean';
import SelectEmpresaSocio from '../../../../components/select-empresa-socio';

export default function EmpresaRepresentanteForm({disabled, empresaId, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="600px">
            <Form.Item name="socioId" label={t('Sócio')}>
              <SelectEmpresaSocio disabled={disabled} empresaId={empresaId} params={{exterior: true}} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="400px">
            <Form.Item name="nome" label={t('Nome')}>
              <InputName disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="cpf" label={t('CPF')}>
              <InputCpf disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="receitaFederal" label={t('Registro na Receita Federal')}>
              <SelectBoolean disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="dataInicial" label={t('Data Inicial')}>
              <InputDate disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="indeterminado" label={t('Prazo Indeterminado')}>
              <SelectBoolean disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.indeterminado !== nextValues.indeterminado}
            >
              {({getFieldValue, setFieldsValue}) => {
                const indeterminado = getFieldValue('indeterminado');
                if (indeterminado === false)
                  return (
                      <Form.Item name="dataFinal" label={t('Data Final')}>
                        <InputDate disabled={disabled} />
                      </Form.Item>);
                setFieldsValue({'dataFinal': null});
                return null;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="procuracaoRegistro" label={t('Registro na Junta Comercial')}>
              <SelectBoolean disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.procuracaoRegistro !== nextValues.procuracaoRegistro}
            >
              {({getFieldValue, setFieldsValue}) => {
                const procuracaoRegistro = getFieldValue('procuracaoRegistro');
                if (procuracaoRegistro === true)
                  return (
                      <Form.Item name="procuracaoDataRegistro" label={t('Data de Registro')}>
                        <InputDate disabled={disabled} />
                      </Form.Item>);
                setFieldsValue({'procuracaoDataRegistro': null});
                return null;
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="poderes" label={t('Poderes')}>
              <Input.TextArea autoSize disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="anexo" label={t('Anexo')}>
              <InputAnexo disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="obs" label={t('Observação')}>
              <Input.TextArea autoSize disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </MiniForm>);
}

EmpresaRepresentanteForm.propTypes = {
  disabled: PropTypes.bool,
  empresaId: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
