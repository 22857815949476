// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gbcnSsb0DscBij03UNuVAg\\=\\= {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n.gbcnSsb0DscBij03UNuVAg\\=\\= > .ant-space-item:last-child {\n  flex-grow: 1;\n}\n.\\+moBcNF8QLN\\+bezqa9V7vQ\\=\\= {\n  flex-grow: 1;\n}\n.\\+moBcNF8QLN\\+bezqa9V7vQ\\=\\=.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,\n.\\+moBcNF8QLN\\+bezqa9V7vQ\\=\\=.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {\n  background-color: inherit;\n}\n", "",{"version":3,"sources":["webpack://src/pages/consultivo/area/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AAJA;EAMI,YAAA;AACJ;AAGA;EACE,YAAA;AADF;AAGE;;EAEE,yBAAA;AADJ","sourcesContent":[".space {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n\n  > :global(.ant-space-item):last-child {\n    flex-grow: 1;\n  }\n}\n\n.area-menu {\n  flex-grow: 1;\n\n  &:global(.ant-menu-dark.ant-menu-horizontal) > :global(.ant-menu-item),\n  &:global(.ant-menu-dark.ant-menu-horizontal) > :global(.ant-menu-submenu) {\n    background-color: inherit;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "gbcnSsb0DscBij03UNuVAg==",
	"area-menu": "+moBcNF8QLN+bezqa9V7vQ=="
};
export default ___CSS_LOADER_EXPORT___;
