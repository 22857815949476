import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputCep from '../../../../components/input-cep';
import InputCnpj from '../../../../components/input-cnpj';
import InputDate from '../../../../components/input-date';
import InputRazaoSocial from '../../../../components/input-razao-social';
import MiniForm from '../../../../components/mini-form';
import SelectBoolean from '../../../../components/select-boolean';
import SelectEmpresaUnidadeTipo from '../../../../components/select-empresa-unidade-tipo';
import SelectEnderecoEstado from '../../../../components/select-endereco-estado';
import { useInformacoes } from "../../../../hooks/useInformacoes";
import { LoadingOutlined } from "@ant-design/icons";
import { capitalize } from "../../../../util/string";
import SelectCnae from "../../../../components/select-cnae";
import SelectCnaes from "../../../../components/select-cnaes";

export default function EmpresaUnidadeForm({ autoFillByCnpj, disabled, form, loading, onFinish, title }) {
  const { t } = useTranslation();
  const informacoes = useInformacoes([form]);
  const cnpj = Form.useWatch('cnpj', form);
  const isLoadingCnpj = informacoes.isLoading(cnpj);

  const handleCnpjBlur = () => {
    if (autoFillByCnpj) {
      const isOverwrite = autoFillByCnpj === 'overwrite';
      informacoes.fetch(cnpj, informacoes => {
        if (isOverwrite || !form.getFieldValue('tipo'))
          form.setFieldValue('tipo', informacoes.identificador_matriz_filial);
        if (isOverwrite || !form.getFieldValue('ativa'))
          form.setFieldValue('ativa', informacoes.situacao_cadastral === 2);
        if (isOverwrite || !form.getFieldValue('dataBaixa'))
          if (informacoes.data_situacao_cadastral !== 2)
            form.setFieldValue('dataBaixa', informacoes.data_situacao_cadastral);
        if (isOverwrite || !form.getFieldValue('estado'))
          form.setFieldValue('estado', informacoes.uf);
        if (isOverwrite || !form.getFieldValue('cidade'))
          form.setFieldValue('cidade', capitalize(informacoes.municipio?.toLowerCase()));
        if (isOverwrite || !form.getFieldValue('cep'))
          form.setFieldValue('cep', informacoes.cep);
        const logradouro = [
          capitalize(informacoes.descricao_tipo_de_logradouro?.toLowerCase()),
          capitalize(informacoes.logradouro?.toLowerCase())]
          .filter(e => !!e)
          .join(' ');
        const endereco = [
          logradouro,
          informacoes.numero,
          informacoes.complemento,
          capitalize(informacoes.bairro?.toLowerCase())]
          .filter(e => !!e)
          .join(', ');
        if (isOverwrite || !form.getFieldValue('endereco'))
          form.setFieldValue('endereco', endereco);
        if (isOverwrite || !form.getFieldValue('cnaePrimarioCodigo'))
          form.setFieldValue('cnaePrimarioCodigo', String(informacoes.cnae_fiscal));
        if (isOverwrite || form.getFieldValue('cnaesSecundariosCodigos').length === 0)
          form.setFieldValue('cnaesSecundariosCodigos', informacoes.cnaes_secundarios.filter(it => it.codigo).map(it => String(it.codigo)));
      })
    }
  };

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="250px">
          <Form.Item name="apelido" label={t('Unidade')}>
            <InputRazaoSocial disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="cnpj" label={t('CNPJ')}>
            <InputCnpj disabled={disabled} suffix={isLoadingCnpj ? <LoadingOutlined/> : undefined} onBlur={handleCnpjBlur}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="tipo" label={t('Tipo')}>
            <SelectEmpresaUnidadeTipo disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="150px">
          <Form.Item name="ativa" label={t('Ativa')}>
            <SelectBoolean disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="250px">
          <Form.Item name="estado" label={t('Estado')}>
            <SelectEnderecoEstado disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="cidade" label={t('Cidade')}>
            <Input disabled={disabled} maxLength={50}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="cep" label={t('CEP')}>
            <InputCep disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="150px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.ativa !== nextValues.ativa}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const ativa = getFieldValue('ativa');
              if (ativa === false)
                return (
                  <Form.Item name="dataBaixa" label={t('Baixa em')}>
                    <InputDate disabled={disabled}/>
                  </Form.Item>);
              setFieldsValue({ 'dataBaixa': null });
              return null;
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="endereco" label={t('Endereço')}>
            <Input disabled={disabled} maxLength={250}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="cnaePrimarioCodigo" label={t('CNAE Primário')}>
            <SelectCnae disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="cnaesSecundariosCodigos" label={t('CNAEs Secundários')}>
            <SelectCnaes name="cnaesSecundariosCodigos" disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="objetoSocial" label={t('Objeto Social')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="obs" label={t('Observação')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

EmpresaUnidadeForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  autoFillByCnpj: PropTypes.oneOf(['normal', 'overwrite']),
  title: PropTypes.node,
};
