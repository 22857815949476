import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import useFormatter from '../../../hooks/useFormatter';
import { compareNumbers } from '../../../util/number';
import { compareStrings } from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

export default function EmpresaRDEIEDsTable({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatCurrency, formatDate} = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Sócio'),
      dataIndex: ['socio', 'nome'],
      key: 'socio',
      sorter: (a, b) => compareStrings(a.socio.nome, b.socio.nome),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Op. Cambial'),
      dataIndex: 'dataOperacaoCambial',
      key: 'dataOperacaoCambial',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataOperacaoCambial, b.dataOperacaoCambial),
      render: (text, record) => formatDate(record.dataOperacaoCambial),
    },
    {
      title: t('Valor da Operação (US$)'),
      align: 'right',
      dataIndex: 'valorOperacaoDolar',
      key: 'valorOperacaoDolar',
      sorter: (a, b) => compareNumbers(a.valorOperacaoDolar, b.valorOperacaoDolar),
      render: (text, record) => formatCurrency(record.valorOperacaoDolar, {currency: 'USD'}),
    },
    {
      title: t('Últ. Atual.'),
      dataIndex: 'dataUltimaApresentacao',
      key: 'dataUltimaApresentacao',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataUltimaApresentacao, b.dataUltimaApresentacao),
      render: (text, record) => formatDate(record.dataUltimaApresentacao),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
      />
  );
}

EmpresaRDEIEDsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
