import {createSelector, createSlice} from '@reduxjs/toolkit';

const hasWindow = typeof window === 'object';

const initialState = {
  width: hasWindow ? window.innerWidth : null,
  height: hasWindow ? window.innerHeight : null,
};

export const widthSelector = state => state.layout.width;
export const heightSelector = state => state.layout.height;

export const isXSSelector = createSelector(widthSelector, width => width < 576);
export const isSMSelector = createSelector(widthSelector, width => width >= 576);
export const isMDSelector = createSelector(widthSelector, width => width >= 768);
export const isLGSelector = createSelector(widthSelector, width => width >= 992);
export const isXLSelector = createSelector(widthSelector, width => width >= 1200);
export const isXXLSelector = createSelector(widthSelector, width => width >= 1600);

export const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    resize(state, action) {
      state.width = action.payload.innerWidth;
      state.height = action.payload.innerHeight;
    },
  },
  extraReducers: {},
});

export default slice.reducer;

export const {resize} = slice.actions;
