import React, { useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import styles from './_WorkflowTarefaDataEntrega.less';
import PropTypes from "prop-types";
import ActionBar from "../../../components/action-bar";
import ActionLink from "../../../components/action-link";
import useFormatter from "../../../hooks/useFormatter";
import { ClockCircleOutlined } from "@ant-design/icons";
import InputDate from "../../../components/input-date";
import WorkflowTarefaInfoTag from "../_WorkflowTarefaInfoTag";

export default function WorkflowTarefaDataEntrega(props) {
  const { dataEntrega, onSubmit, readOnly } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { formatDate } = useFormatter();
  const [edicao, setEdicao] = useState(false);

  const fireSubmit = dataEntrega => {
    let promise = Promise.resolve();
    if (typeof onSubmit === 'function')
      promise = promise.then(() => onSubmit(dataEntrega));
    return promise;
  }

  const handleClick = e => {
    if (readOnly)
      return;
    e.preventDefault();
    form.resetFields();
    setEdicao(true);
  }

  const handleFinish = values => {
    fireSubmit(values.dataEntrega).then(() => setEdicao(false));
  }

  if (edicao) {
    return (
      <Form
        form={form}
        onFinish={handleFinish}
        size="middle"
      >
        <Form.Item name="dataEntrega" initialValue={dataEntrega} noStyle>
          <InputDate autoFocus placeholder={t('Entrega')} prefix={<ClockCircleOutlined/>}/>
        </Form.Item>
        <ActionBar>
          <ActionLink.Save onClick={form.submit}/>
          <ActionLink.Cancel onClick={() => setEdicao(false)}/>
        </ActionBar>
      </Form>
    );
  }

  let className = styles['workflow-tarefa-data-entrega'];
  if (!dataEntrega)
    className += ' ' + styles['nao-preenchido'];
  if (readOnly)
    className += ' ' + styles['read-only'];

  if (dataEntrega)
    return (
      <div className={className} onClick={handleClick}>
        <div>
          <WorkflowTarefaInfoTag icon={<ClockCircleOutlined/>} status="success" text={formatDate(dataEntrega)}/>
        </div>
      </div>
    );

  return (
    <div className={className} onClick={handleClick}>
      (não preenchido)
    </div>
  )
}

WorkflowTarefaDataEntrega.propTypes = {
  dataEntrega: PropTypes.string,
  onSubmit: PropTypes.func,
  readOnly: PropTypes.bool
}