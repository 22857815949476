import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmpresaConta} from '../../constants';

const {Registro} = EmpresaConta;

export default function SelectEmpresaContaRegistro(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Registro.Pendente)}
        {createOption(Registro.Sim)}
        {createOption(Registro.NaoSeAplica)}
      </Select>
  );
}

SelectEmpresaContaRegistro.propTypes = {
  value: PropTypes.oneOf([
    Registro.Pendente.id,
    Registro.Sim.id,
    Registro.NaoSeAplica.id]),
  onChange: PropTypes.func,
};
