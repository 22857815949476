import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import useDispatchWithResult from '../../../../../hooks/useDispatchWithResult';
import { fetchAnexo } from '../../../../../store/consultivoConsulta';
import ConsultivoConsultaAnexoCreatePage from './_create';
import ConsultivoConsultaAnexoDetailPage from './_detail';
import ConsultivoConsultaAnexoEditPage from './_edit';

export default function ConsultivoConsultaAnexoPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchAnexo({ consultaId, id }));
  }, [consultaId, id]);

  return (
    <Switch>
      <Route path="/consultivo/consultas/:consultaId/anexos/:id/edit" component={ConsultivoConsultaAnexoEditPage}/>
      <Route path="/consultivo/consultas/:consultaId/anexos/create" component={ConsultivoConsultaAnexoCreatePage}/>
      <Route path="/consultivo/consultas/:consultaId/anexos/:id" component={ConsultivoConsultaAnexoDetailPage}/>
    </Switch>);
}
