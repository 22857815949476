import { Form, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../components/action-button';
import InputPassword from '../../components/input-password';
import MiniFormDrawer from '../../components/mini-form-drawer';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import { useSelector } from "react-redux";
import { usuarioIdSelector } from "../../store/autenticacao";

export default function AlterarSenhaDrawer({visible, onClose}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const usuarioId = useSelector(usuarioIdSelector);

    const confirmarSenhaValidator = (rule, value, cb) => {
        if (form.getFieldValue('novaSenha') === value)
            return cb();
        return cb(t('Senhas são diferentes'));
    };

    const handleDrawerAfterOpen = () => {
        document.querySelector('#senhaAtual').focus();
    };

    const handleDrawerAfterClose = () => {
        form.resetFields();
    };

    const handleFinish = values => {
        const {senhaAtual, novaSenha} = values;
        setLoading(true);
        unwrapAxiosPromise(axios.post(`/usuarios/${usuarioId}/alterar-senha`, { senhaAtual, novaSenha }), form)
            .then(() => {
                message.success(t('Senha alterada com sucesso'));
                onClose();
            })
            .finally(() => setLoading(false));
    };

    const footer = (
        <>
            <ActionButton.Save
                onClick={() => form.submit()}
                loading={loading}
            />
            <ActionButton.Close
                onClick={onClose}
                disabled={loading}
            />
        </>
    );

    return (
        <MiniFormDrawer
            drawerTitle={t('Alterar Senha')}
            drawerFooter={footer}
            drawerVisible={visible}
            form={form}
            onDrawerClose={onClose}
            onDrawerAfterOpen={handleDrawerAfterOpen}
            onDrawerAfterClose={handleDrawerAfterClose}
            onFinish={handleFinish}
        >
            <Form.Item name="senhaAtual" label={t('Senha Atual')}>
                <InputPassword disabled={loading}/>
            </Form.Item>
            <Form.Item name="novaSenha" label={t('Nova Senha')}>
                <InputPassword disabled={loading}/>
            </Form.Item>
            <Form.Item name="confirmarSenha" label={t('Confirmar Senha')} rules={[{validator: confirmarSenhaValidator}]}>
                <InputPassword disabled={loading}/>
            </Form.Item>
        </MiniFormDrawer>
    );
}

AlterarSenhaDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};
