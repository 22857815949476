import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import AuthenticatedLayout from '../layouts/authenticated';
import BlankLayout from '../layouts/blank';
import {isAutenticadoSelector} from '../store/autenticacao';

export function Error404Page() {
  const {t} = useTranslation();
  const history = useHistory();

  const isAutenticado = useSelector(isAutenticadoSelector);
  if (isAutenticado)
    return (
        <AuthenticatedLayout>
          <h1>{t('Página não encontrada')}</h1>
          <a href="#" onClick={e => {
            e.preventDefault();
            history.go(-1);
          }}>{t('voltar')}</a>
        </AuthenticatedLayout>
    );
  else
    return (
        <BlankLayout centered>
          <h1>{t('Página não encontrada')}</h1>
          <a href="#" onClick={e => {
            e.preventDefault();
            history.go(-1);
          }}>{t('voltar')}</a>
        </BlankLayout>
    );
}
