import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    language: ''
}

export const languageSelector = state => state.i18n.language;

export const slice = createSlice({
    name: 'i18n',
    initialState,
    reducers: {
        changeLanguage(state, action) {
            state.language = action.payload
        }
    }
})

export const {changeLanguage} = slice.actions;

export default slice.reducer;