// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7hgUuC1pu\\+44KFarLqeRXQ\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n}\n.SP7PxCy1-DkPt-pDtY1Y3Q\\=\\= {\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: flex-start;\n  height: 32px;\n}\n.SP7PxCy1-DkPt-pDtY1Y3Q\\=\\= > div {\n  border-radius: 2px;\n  padding: 1px 8px;\n  color: rgba(255, 255, 255, 0.9);\n  font-weight: bold;\n  font-size: 10px;\n  line-height: 20px;\n}\n.I8oH4OGfFFXCKGsjcK5i5w\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 8px;\n  color: #009edb;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tipoTarefa/_SelectWorkflowTipoTarefa.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;AACF;AAEA;EACE,aAAA;EACA,wBAAA;EACA,uBAAA;EACA,uBAAA;EACA,YAAA;AAAF;AALA;EAQI,kBAAA;EACA,gBAAA;EACA,+BAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAIA;EACE,aAAA;EACA,qBAAA;EACA,QAAA;EACA,cAAA;AAFF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-flow: row nowrap;\n}\n\n.option {\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: flex-start;\n  height: 32px;\n\n  > div {\n    border-radius: 2px;\n    padding: 1px 8px;\n    color: rgba(255, 255, 255, 90%);\n    font-weight: bold;\n    font-size: 10px;\n    line-height: 20px;\n  }\n}\n\n.create {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 8px;\n  color: #009edb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_7hgUuC1pu+44KFarLqeRXQ==",
	"option": "SP7PxCy1-DkPt-pDtY1Y3Q==",
	"create": "I8oH4OGfFFXCKGsjcK5i5w=="
};
export default ___CSS_LOADER_EXPORT___;
