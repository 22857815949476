// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dmX3dW50e0CXJ16DK2kpjw\\=\\= {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n.dmX3dW50e0CXJ16DK2kpjw\\=\\= > .ant-card-body {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://src/components/flex-card/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AAJA;EAMI,aAAA;EACA,sBAAA;EACA,YAAA;AACJ","sourcesContent":[".card {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n\n  > :global(.ant-card-body) {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "dmX3dW50e0CXJ16DK2kpjw=="
};
export default ___CSS_LOADER_EXPORT___;
