import {Button} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../components/mini-table';
import {Email, findById} from '../../constants';

const {Estado} = Email;

export default function EmailsTable({dataSource, loading, miniTableRef, onClick, title}) {
  const {t} = useTranslation();

  const fireClick = email => typeof onClick === 'function' ? onClick(email) : undefined;

  const columns = [
    {
      title: t('Para'),
      dataIndex: 'para',
      key: 'para',
      width: 250,
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Assunto'),
      dataIndex: 'assunto',
      key: 'assunto',
    },
    {
      title: t('Estado'),
      dataIndex: 'estado',
      key: 'estado',
      align: 'center',
      width: 50,
      render: (text, record) => t(findById(Estado, record.estado)?.label),
    },
    {
      title: t('Enviado em'),
      dataIndex: 'enviadoEm',
      key: 'enviadoEm',
      align: 'center',
      width: 140,
      render: (text, record) => record.enviadoEm ? moment(record.enviadoEm).format('l LT') : '',
    },
    {
      title: t('Criado em'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 140,
      render: (text, record) => record.createdAt ? moment(record.createdAt).format('l LT') : '',
    },
  ];

  const rowClassName = record => {
    switch (record.estado) {
      case 1:
        return 'information';
      case 2:
        return 'success';
      case 3:
        return 'error';
      default:
        return undefined;
    }
  };

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          rowClassName={rowClassName}
          title={title}
      />
  );
}

EmailsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  title: PropTypes.func,
};
