import AuthenticatedPopupLayout from "../../../layouts/authenticated-popup";
import styles from './_styles.less';
import { Space, Tabs } from "antd";
import { useHistory, useRouteMatch } from "react-router";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import React, { useEffect } from "react";
import CardTabs from "../../../components/card-tabs";
import { useTranslation } from "react-i18next";
import { cadastroSelector, fetchAnexos, fetchCabecalho, fetchCadastro, fetchFases, fetchLogs, fetchPermissoes, fetchResposta, permissoesSelector } from "../../../store/consultivoConsulta";
import { useSelector } from "react-redux";
import ConsultivoConsultaCadastroPage from "./cadastro";
import { fetchConsultivo } from "../../../store/consultivo";
import ConsultaCabecalho from "./cabecalho";
import ConsultivoConsultaAnexosPage from "./anexos";
import ConsultivoConsultaLogsPage from "./logs";
import ConsultivoConsultaFasesPage from "./fases";
import ConsultivoConsultaRespostaPage from "./resposta";
import { Consulta } from "../../../constants";

export default function ConsultivoConsultaPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const consultaId = match.params.id;
  const activeTab = match.params.tab;
  const permissoes = useSelector(permissoesSelector);
  const cadastro = useSelector(cadastroSelector);

  useEffect(() => {
    if (cadastro.titulo)
      document.title = 'Legal Panel - ' + cadastro.titulo;
  }, [cadastro]);

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(fetchPermissoes({ consultaId }));
    dispatch(fetchCabecalho({ consultaId }));
    dispatch(fetchCadastro({ consultaId }));
    dispatch(fetchResposta({ consultaId }));
    dispatch(fetchAnexos({ consultaId }));
    dispatch(fetchFases({ consultaId }));
    dispatch(fetchLogs({ consultaId }));
  }, [consultaId]);

  const handleTabChange = activeKey => {
    history.replace(`/consultivo/consultas/${consultaId}/${activeKey}`);
  };

  return (
    <AuthenticatedPopupLayout menu={<ConsultaCabecalho/>}>
      <Space direction="vertical" className={styles['space']}>
        <CardTabs activeKey={activeTab} onChange={handleTabChange}>
          {permissoes.includes(Consulta.Permissoes.CADASTRO_DETALHAR.id) &&
            <Tabs.TabPane key="cadastro" tab={t('Cadastro')}>
              <ConsultivoConsultaCadastroPage/>
            </Tabs.TabPane>
          }
          {permissoes.includes(Consulta.Permissoes.RESPOSTA_DETALHAR.id) &&
            <Tabs.TabPane key="resposta" tab={t('Resposta')}>
              <ConsultivoConsultaRespostaPage/>
            </Tabs.TabPane>
          }
          {permissoes.includes(Consulta.Permissoes.ANEXO_DETALHAR.id) &&
            <Tabs.TabPane key="anexos" tab={t('Anexos')}>
              <ConsultivoConsultaAnexosPage/>
            </Tabs.TabPane>
          }
          {permissoes.includes(Consulta.Permissoes.FASE_DETALHAR.id) &&
            <Tabs.TabPane key="fases" tab={t('Fases')}>
              <ConsultivoConsultaFasesPage/>
            </Tabs.TabPane>
          }
          {permissoes.includes(Consulta.Permissoes.LOG_DETALHAR.id) &&
            <Tabs.TabPane key="logs" tab={t('Logs')}>
              <ConsultivoConsultaLogsPage/>
            </Tabs.TabPane>
          }
        </CardTabs>
      </Space>
    </AuthenticatedPopupLayout>
  )
}
