import React from 'react';
import { useSelector } from "react-redux";
import { consultasPorStatusSelector } from "../../../store/consultivoQuadro";
import styles from './_ConsultasPorStatusCollection.less';
import AssuntoFaseCollectionItem from "./_ConsultasPorStatusCollectionItem";

export default function ConsultasPorStatusCollection() {
  const consultasPorStatus = useSelector(consultasPorStatusSelector);
  return (
    <div className={styles.ConsultasPorStatusCollection}>
      {consultasPorStatus.map(({ status, consultas }) => <AssuntoFaseCollectionItem key={status} status={status} consultas={consultas}/>)}
    </div>
  )
}
