import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Email} from '../../constants';

const {Estado} = Email;

export default function SelectEmailEstado({max, ...props}) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Estado.Pendente)}
        {createOption(Estado.Enviado)}
        {createOption(Estado.Erro)}
      </Select>
  );
}

SelectEmailEstado.propTypes = {
  value: PropTypes.oneOf([
    Estado.Pendente.id,
    Estado.Enviado.id,
    Estado.Erro.id]),
  onChange: PropTypes.func,
};
