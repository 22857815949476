import React from 'react';
import styles from './_ConsultaCollection.less';
import ConsultaCollectionItem from "./_ConsultaCollectionItem";

export default function ConsultaCollection({ consultas }) {
  return (
    <div className={styles.ConsultaCollection}>
      {consultas.map(consulta => <ConsultaCollectionItem key={consulta.id} consulta={consulta}/>)}
    </div>
  );
}
