import {Col, Form, Input, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import MiniForm from '../../../../components/mini-form';
import SelectEmpresaContaAprovacao from '../../../../components/select-empresa-conta-aprovacao';
import SelectEmpresaContaPublicacao from '../../../../components/select-empresa-conta-publicacao';
import SelectEmpresaContaRegistro from '../../../../components/select-empresa-conta-registro';
import SelectEmpresaContaStatus from '../../../../components/select-empresa-conta-status';
import {EmpresaConta} from '../../../../constants';

const {Publicacao, Aprovacao, Registro} = EmpresaConta;

export default function EmpresaContaForm({disabled, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="anoReferencia" label={t('Ano de Referência')}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="status" label={t('Status')}>
              <SelectEmpresaContaStatus disabled />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="publicacao" label={t('Publicação')}>
              <SelectEmpresaContaPublicacao disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.publicacao !== nextValues.publicacao}
            >
              {({getFieldValue, setFieldsValue}) => {
                const publicacao = getFieldValue('publicacao');
                if (publicacao === Publicacao.Sim.id)
                  return (
                      <Form.Item name="dataPublicacao" label={t('Data da Publicação')}>
                        <InputDate disabled={disabled} />
                      </Form.Item>);
                setFieldsValue({'dataPublicacao': null});
                return null;
              }}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.publicacao !== nextValues.publicacao}
        >
          {({getFieldValue, setFieldsValue}) => {
            const publicacao = getFieldValue('publicacao');
            if (publicacao !== Publicacao.Pendente.id)
              return (
                  <Row gutter={[16, 8]}>
                    <Col flex="200px">
                      <Form.Item name="aprovacao" label={t('Aprovação')}>
                        <SelectEmpresaContaAprovacao disabled={disabled} />
                      </Form.Item>
                    </Col>
                    <Col flex="200px">
                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, nextValues) => prevValues.aprovacao !== nextValues.aprovacao}
                      >
                        {({getFieldValue, setFieldsValue}) => {
                          const aprovacao = getFieldValue('aprovacao');
                          if (aprovacao === Aprovacao.Sim.id || aprovacao === Aprovacao.Nao.id)
                            return (
                                <Form.Item name="dataAprovacao" label={t('Data da Reunião')}>
                                  <InputDate disabled={disabled} />
                                </Form.Item>);
                          setFieldsValue({'dataAprovacao': null});
                          return null;
                        }}
                      </Form.Item>
                    </Col>
                  </Row>);
            setFieldsValue({'publicacao': Publicacao.Pendente.id});
            return null;
          }}
        </Form.Item>

        <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.aprovacao !== nextValues.aprovacao}
        >
          {({getFieldValue, setFieldsValue}) => {
            const aprovacao = getFieldValue('aprovacao');
            if (aprovacao !== Aprovacao.Pendente.id)
              return (
                  <Row gutter={[16, 8]}>
                    <Col flex="200px">
                      <Form.Item name="registro" label={t('Registro')}>
                        <SelectEmpresaContaRegistro disabled={disabled} />
                      </Form.Item>
                    </Col>
                    <Col flex="200px">
                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, nextValues) => prevValues.registro !== nextValues.registro}
                      >
                        {({getFieldValue, setFieldsValue}) => {
                          const registro = getFieldValue('registro');
                          if (registro === Registro.Sim.id)
                            return (
                                <Form.Item name="dataRegistro" label={t('Data da Registro')}>
                                  <InputDate disabled={disabled} />
                                </Form.Item>);
                          setFieldsValue({'dataRegistro': null});
                          return null;
                        }}
                      </Form.Item>
                    </Col>
                    <Col flex="400px">
                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, nextValues) => prevValues.registro !== nextValues.registro}
                      >
                        {({getFieldValue, setFieldsValue}) => {
                          const registro = getFieldValue('registro');
                          if (registro === Registro.Sim.id)
                            return (
                                <Form.Item name="registroLocal" label={t('Órgão de Registro')}>
                                  <Input disabled={disabled} maxLength={100} />
                                </Form.Item>);
                          setFieldsValue({'registroLocal': null});
                          return null;
                        }}
                      </Form.Item>
                    </Col>
                  </Row>);
            setFieldsValue({'registro': Registro.Pendente.id});
            return null;
          }}
        </Form.Item>

        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="anexo" label={t('Anexo')}>
              <InputAnexo disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="obs" label={t('Observação')}>
              <Input.TextArea autoSize disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </MiniForm>);
}

EmpresaContaForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
