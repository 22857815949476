import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchLicenca} from '../../../../store/empresa';
import EmpresaLicencaCreatePage from './_create';
import EmpresaLicencaDetailPage from './_detail';
import EmpresaLicencaEditPage from './_edit';

export default function EmpresaLicencaPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchLicenca({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/licencas/:id/edit" component={EmpresaLicencaEditPage} />
        <Route path="/*/empresas/:empresaId/licencas/create" component={EmpresaLicencaCreatePage} />
        <Route path="/*/empresas/:empresaId/licencas/:id" component={EmpresaLicencaDetailPage} />
      </Switch>);
}
