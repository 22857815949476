import React, { useCallback, useEffect, useMemo, useState } from "react";
import AuthenticatedLayout from "../../layouts/authenticated";
import { useSelector } from "react-redux";
import { ambienteModulosSelector, modulosSelector } from "../../store/autenticacao";
import MenuCard from "./_MenuCard";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { acessoSelector, fetchAcessos } from "../../store/acessos";
import { Modulos } from "../../constants";
import AcessoMenu from "../../components/acesso-menu";
import styles from './_styles.less';
import ConhecaModal from "./_ConhecaModal";
import HomeHeader from "./_HomeHeader";
import HomeFooter from "./_HomeFooter";

export default function HomePage() {
  const { t } = useTranslation();
  const ambienteModulos = useSelector(ambienteModulosSelector);
  const usuarioModulos = useSelector(modulosSelector);
  const acesso = useSelector(acessoSelector);
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const [conhecaModulo, setConhecaModulo] = useState(null);

  useEffect(() => {
    dispatch(fetchAcessos());
  }, []);

  const modulos = useMemo(
    () => Modulos.filter(modulo => ambienteModulos.includes(modulo.id)),
    [ambienteModulos]);

  const handleConheca = useCallback(modulo => {
    setConhecaModulo(modulo);
  }, []);

  const handleClick = useCallback((tipo, location) => {
    history.push(location);
  }, [history]);

  return (
    <AuthenticatedLayout
      menu={<AcessoMenu/>}
      contentStyle={{ maxWidth: 'unset', padding: 0, overflow: 'hidden' }}
    >
      <HomeHeader/>
      <div className={styles['space']}/>
      <HomeFooter/>
      {acesso &&
        <div className={styles['menu-wrapper']}>
          {modulos
            .map(modulo => (
              <MenuCard
                imageSrc={modulo.image}
                title={t(modulo.label)}
                onConheca={() => handleConheca(modulo)}
                onClick={() => handleClick(modulo.id, modulo.location)}
                disabled={!usuarioModulos.includes(modulo.id) || !acesso?.modulos?.includes(modulo.id) === true}
                total={modulos.length}
              />
            ))}
        </div>
      }
      <ConhecaModal modulo={conhecaModulo} onClose={() => setConhecaModulo(null)}/>
    </AuthenticatedLayout>
  )
}
