import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchBeneficiarioEmpresa} from '../../../../store/empresa';
import EmpresaBeneficiarioEmpresaCreatePage from './_create';
import EmpresaBeneficiarioEmpresaDetailPage from './_detail';
import EmpresaBeneficiarioEmpresaEditPage from './_edit';

export default function EmpresaBeneficiarioEmpresaPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchBeneficiarioEmpresa({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/beneficiarios-empresa/:id/edit" component={EmpresaBeneficiarioEmpresaEditPage} />
        <Route path="/*/empresas/:empresaId/beneficiarios-empresa/create" component={EmpresaBeneficiarioEmpresaCreatePage} />
        <Route path="/*/empresas/:empresaId/beneficiarios-empresa/:id" component={EmpresaBeneficiarioEmpresaDetailPage} />
      </Switch>);
}
