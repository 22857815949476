// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NI\\+MXCvfichXdm3UGqFK\\+g\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 4px;\n  align-items: center;\n}\n.NI\\+MXCvfichXdm3UGqFK\\+g\\=\\= .h82GH2cl6GOPgStPQnKmow\\=\\= {\n  margin: 0;\n  width: 16px;\n  height: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/components/table-cell-color/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,QAAA;EACA,mBAAA;AACF;AALA;EAOI,SAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[".table-cell-color {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 4px;\n  align-items: center;\n\n  .tag {\n    margin: 0;\n    width: 16px;\n    height: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table-cell-color": "NI+MXCvfichXdm3UGqFK+g==",
	"tag": "h82GH2cl6GOPgStPQnKmow=="
};
export default ___CSS_LOADER_EXPORT___;
