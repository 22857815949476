import { Form, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import useDispatchWithResult from '../../../../../hooks/useDispatchWithResult';
import { fetchAnexos, fetchLogs } from '../../../../../store/consultivoConsulta';
import unwrapAxiosPromise from '../../../../../util/unwrapAxiosPromise';
import ConsultivoConsultaAnexoForm from './_form';
import { valuesToFormData } from "../../../../../util/formData";

export default function ConsultivoConsultaAnexoCreatePage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;

  useEffect(() => {
    form.resetFields();
    setImmediate(() => document.querySelector('#descricao').focus());
  }, []);

  const handleFinish = values => {
    setLoadingSave(true);
    const formData = valuesToFormData(values);
    unwrapAxiosPromise(axios.post(`/consultivo/consultas/${consultaId}/anexos`, formData), form)
      .then(data => {
        message.success(t('Registro incluído com sucesso'));
        history.replace(`/consultivo/consultas/${consultaId}/anexos`);
        dispatch(fetchAnexos({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/consultas/${consultaId}/anexos`);
  };

  const title = (
    <ActionBar>
      <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
      <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
    </ActionBar>);

  return (
    <ConsultivoConsultaAnexoForm
      autoFillByCnpj="overwrite"
      disabled={loadingSave}
      form={form}
      onFinish={handleFinish}
      title={title}
    />);
}
