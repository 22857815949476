// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WKDsG8AiGNftsU2t7n28sA\\=\\= {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  user-select: none;\n}\n.WKDsG8AiGNftsU2t7n28sA\\=\\= .Wjtce1g-K76xVFSkDLbIcg\\=\\= {\n  vertical-align: bottom;\n  height: 24px;\n  font-family: \"Comfortaa\", cursive;\n  font-size: 10pt;\n  font-weight: normal;\n  opacity: 80%;\n  min-width: 32px;\n  margin-left: 12px;\n}\n.WKDsG8AiGNftsU2t7n28sA\\=\\= > img {\n  image-rendering: -webkit-optimize-contrast;\n}\n.WKDsG8AiGNftsU2t7n28sA\\=\\=.qNZT\\+BC0UC\\+Jyy0cfgGYQQ\\=\\= ._8o4CGsfTyZ9K73dpX4wywg\\=\\=,\n.WKDsG8AiGNftsU2t7n28sA\\=\\=.qNZT\\+BC0UC\\+Jyy0cfgGYQQ\\=\\= .cOafKXiPxAc3D\\+lYW\\+mbjg\\=\\=,\n.WKDsG8AiGNftsU2t7n28sA\\=\\=.qNZT\\+BC0UC\\+Jyy0cfgGYQQ\\=\\= .Wjtce1g-K76xVFSkDLbIcg\\=\\= {\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://src/components/brand/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AACF;AANA;EAQI,sBAAA;EACA,YAAA;EACA,iCAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAhBA;EAmBI,0CAAA;AAAJ;AAIA;;;EAEI,YAAA;AADJ","sourcesContent":[".brand {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  user-select: none;\n\n  .version {\n    vertical-align: bottom;\n    height: 24px;\n    font-family: \"Comfortaa\", cursive;\n    font-size: 10pt;\n    font-weight: normal;\n    opacity: 80%;\n    min-width: 32px;\n    margin-left: 12px;\n  }\n\n  > img {\n    image-rendering: -webkit-optimize-contrast;\n  }\n}\n\n.brand.dark {\n  .cloud, .manager, .version {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand": "WKDsG8AiGNftsU2t7n28sA==",
	"version": "Wjtce1g-K76xVFSkDLbIcg==",
	"dark": "qNZT+BC0UC+Jyy0cfgGYQQ==",
	"cloud": "_8o4CGsfTyZ9K73dpX4wywg==",
	"manager": "cOafKXiPxAc3D+lYW+mbjg=="
};
export default ___CSS_LOADER_EXPORT___;
