import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputRazaoSocial from '../../../../../components/input-razao-social';
import MiniForm from '../../../../../components/mini-form';
import InputNumber from "../../../../../components/input-number";

export default function ConsultivoAreaTipoTrabalhoForm({ disabled, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="descricao" label={t('Descrição')}>
            <InputRazaoSocial disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="sla1" label={t('SLA p/ Alta Prioridade')}>
            <InputNumber disabled={disabled} maxLength={5} min={0} max={99999} maximumFractionDigits={0} suffix={t('dia(s)')} />
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="sla2" label={t('SLA p/ Média Prioridade')}>
            <InputNumber disabled={disabled} maxLength={5} min={0} max={99999} maximumFractionDigits={0} suffix={t('dia(s)')}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="sla3" label={t('SLA p/ Baixa Prioridade')}>
            <InputNumber disabled={disabled} maxLength={5} min={0} max={99999} maximumFractionDigits={0} suffix={t('dia(s)')}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

ConsultivoAreaTipoTrabalhoForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
