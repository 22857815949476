export function formatCpf(cpf) {
  cpf = cpf || '';
  if (cpf.length === 11) {
    return (
        cpf.substring(0, 3) + '.' +
        cpf.substring(3, 6) + '.' +
        cpf.substring(6, 9) + '-' +
        cpf.substring(9, 11));
  }
  return cpf;
}

export function parseCpf(cpf) {
  return cpf.replace(/\D+/g, '');
}

export function isValidCpf(cpf) {
  if (!cpf || cpf.length !== 11)
    return false;
  switch (cpf) {
    case '00000000000':
    case '11111111111':
    case '22222222222':
    case '33333333333':
    case '44444444444':
    case '55555555555':
    case '66666666666':
    case '77777777777':
    case '88888888888':
    case '99999999999':
      return false;
  }
  let sum1 = 0;
  let sum2 = 0;
  for (let i = 0; i < 9; i++) {
    const digit = parseInt(cpf[i]);
    sum1 += (10 - i) * digit;
    sum2 += (11 - i) * digit;
  }
  let dv1 = 11 - (sum1 % 11);
  if (dv1 >= 10) dv1 = 0;
  sum2 += 2 * dv1;
  let dv2 = 11 - (sum2 % 11);
  if (dv2 >= 10) dv2 = 0;
  return parseInt(cpf[9]) === dv1 && parseInt(cpf[10]) === dv2;
}
