import moment from 'moment';

export function valuesToFormData(values) {
  const formData = new FormData();
  const keys = Object.keys(values);
  for (const key of keys)
    setFormDataValue(formData, key, values[key])
  return formData;
}

function setFormDataValue(formData, key, value) {
  if (value instanceof Array)
    for (let i = 0; i < value.length; i++)
      setFormDataValue(formData, key, value[i]);
  else if (value instanceof File)
    formData.append(key, value);
  else if (moment.isMoment(value))
    formData.append(key, value.toISOString());
  else if (value instanceof Date)
    formData.append(key, value.toISOString());
  else if (value instanceof Object && value.__is_anexo)
    formData.append(key, new File([], '$$UNMODIFIED$$'));
  else if (value instanceof Object) {
    formData.append(key, new Blob([JSON.stringify(value)], { type: "application/json", encoding: "utf8" }),);
    for (const k of Object.keys(value)) {
      const v = value[k];
      if (v instanceof File)
        formData.append(v.uid, v);
      else if (v instanceof Object && v.__is_anexo)
        formData.append(v.uid, new File([], '$$UNMODIFIED$$'));
    }
  } else if (value !== null && value !== undefined)
    formData.append(key, value);
}