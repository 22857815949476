import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './_SelectWorkflowEmpresa.less';
import { useTranslation } from "react-i18next";
import useCollator from "../../../hooks/useCollator";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";
import axios from "axios";
import ActionBar from "../../../components/action-bar";
import ActionLink from "../../../components/action-link";
import { formatCnpj } from "../../../util/cnpj";
import { openPopupEmpresa } from "../../../util/window";
import { ExportOutlined } from "@ant-design/icons";

export default function SelectWorkflowEmpresa(props) {
  const { workflowId, empresa, onSubmit, onUnload, readOnly } = props;
  const { t } = useTranslation();
  const { compare, contains } = useCollator();
  const [form] = Form.useForm();
  const [edicao, setEdicao] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const nome = searchValue.trim();

  const fireSubmit = empresa => {
    let promise = Promise.resolve();
    if (typeof onSubmit === 'function')
      promise = promise.then(() => onSubmit(empresa));
    return promise;
  }

  const handleClick = e => {
    if (readOnly)
      return;
    e.preventDefault();
    form.resetFields();
    setEdicao(true);
    setSearchValue('');
  }

  const handleFinish = values => {
    fireSubmit(values.empresaId).then(() => setEdicao(false));
  }

  const handleOpenClick = () => {
    openPopupEmpresa(empresa, 'cadastro', { onUnload })
  }

  useEffect(() => {
    setLoading(true);
    unwrapAxiosPromise(axios.get(`/workflows/${workflowId}/empresas`))
      .then(data => setEmpresas(data))
      .finally(() => setLoading(false))
  }, [workflowId]);

  const empresasFiltradas = useMemo(() =>
      empresas
        .filter(empresa => contains(empresa.cnpj, nome) || contains(empresa.nome, nome))
        .sort((a, b) => compare(a.cnpj, b.cnpj))
        .slice(0, 50),
    [contains, empresas, nome]);


  if (edicao) {
    return (
      <Form form={form} onFinish={handleFinish} size="middle">
        <Form.Item name="empresaId" initialValue={empresa?.id} noStyle>
          <Select
            allowClear
            autoFocus
            className={styles['select']}
            filterOption={false}
            loading={loading}
            placeholder={t('Empresa')}
            searchValue={searchValue}
            onSearch={setSearchValue}
            showSearch
          >
            {empresasFiltradas.map(empresa => (
              <Select.Option key={empresa.id} value={empresa.id}>
                {formatCnpj(empresa.cnpj)} - {empresa.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ActionBar>
          <ActionLink.Save onClick={form.submit}/>
          <ActionLink.Cancel onClick={() => setEdicao(false)}/>
        </ActionBar>
      </Form>
    );
  }

  let className = styles['workflow-tarefa-empresa'];
  if (!empresa)
    className += ' ' + styles['nao-preenchido'];
  if (readOnly)
    className += ' ' + styles['read-only'];

  return (
    <div className={className} onClick={handleClick}>
      <div className={styles['descricao']}>
        {!!empresa ? formatCnpj(empresa.cnpj) + ' - ' + empresa.nome : t('(não preenchido)')}
      </div>
      {!!empresa &&
        <div>
          <ActionBar>
            <ActionLink icon={<ExportOutlined/>} title={t('Abrir Cadastro')} noTitle onClick={handleOpenClick}/>
          </ActionBar>
        </div>
      }
    </div>
  );
}

SelectWorkflowEmpresa.propTypes = {
  workflowId: PropTypes.number,
  empresa: PropTypes.shape({
    id: PropTypes.number,
    cnpj: PropTypes.string,
    nome: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onUnload: PropTypes.func,
  readOnly: PropTypes.bool,
};
