import {Spin} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import FlexCard from '../../../components/flex-card';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useFormatter from '../../../hooks/useFormatter';
import {fetchAlertasByEmpresa} from '../../../store/empresas';
import {getTabByName} from '../../../util/tabs';
import {openPopupEmpresa} from '../../../util/window';
import styles from './_styles.less';

export default function ProximoAlertaCard({alerta, className, icon, title, loading}) {
  const {t} = useTranslation();
  const dispatch = useDispatchWithResult();
  const {formatDate} = useFormatter();
  const opacity = alerta ? 1 : 0.2;
  const tab = getTabByName(alerta?.aba);

  const onUnload = () => {
    dispatch(fetchAlertasByEmpresa(alerta));
  };

  const handleClick = () => {
    if (tab)
      openPopupEmpresa(alerta, tab?.name, {onUnload});
  };

  return (
      <Spin spinning={loading}>
        <FlexCard bordered={false} className={className} hoverable={!!tab} style={{opacity}} onClick={handleClick}>
          <div className={styles['proximo-alerta']}>
            <div className={styles['column-icon']}>
              {icon}
            </div>
            <div className={styles['column-description']}>
              <div className={styles['header']}><small>{title}</small></div>
              <div className={styles['description']}>
                {t(tab?.label)} - <small>{alerta?.apelido ?? alerta?.razaoSocial}</small>
              </div>
            </div>
            <div className={styles['column-extra']}>
              <div className={styles['header']}><small>{t('Data de Vencimento')}</small></div>
              <div className={styles['description']}>{formatDate(alerta?.dataVencimento)}</div>
            </div>
          </div>
        </FlexCard>
      </Spin>
  );
}
