// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IrY3oFL-O8VNa3ohI\\+2Z5Q\\=\\= {\n  max-width: 575px;\n}\n", "",{"version":3,"sources":["webpack://src/components/table-cell-ellipsis/_styles.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".tooltip-overlay {\n  max-width: 575px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-overlay": "IrY3oFL-O8VNa3ohI+2Z5Q=="
};
export default ___CSS_LOADER_EXPORT___;
