import React from "react";
import styles from './_WorkflowFilterBar.less';
import WorkflowFilterBarEmpresa from "./_WorkflowFilterBarEmpresa";
import WorkflowFilterBarMembro from "./_WorkflowFilterBarMembro";
import WorkflowFilterBarUnidade from "./_WorkflowFilterBarUnidade";
import { FilterOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function WorkflowFilterBar() {
  const { t } = useTranslation()
  return (
    <div className={styles['workflow-filter-bar']}>
      <div className={styles['membro']}>
        <div className={styles['icone']}>
          <div><FilterOutlined/></div>
          <div>{t('Membro')}</div>
        </div>
        <WorkflowFilterBarMembro/>
      </div>
      <div className={styles['empresa']}>
        <div className={styles['icone']}>
          <div><FilterOutlined/></div>
          <div>{t('Empresa')}</div>
        </div>
        <WorkflowFilterBarEmpresa/>
      </div>
      <div className={styles['unidade']}>
        <div className={styles['icone']}>
          <div><FilterOutlined/></div>
          <div>{t('Unidade')}</div>
        </div>
        <WorkflowFilterBarUnidade/>
      </div>
    </div>
  )
}