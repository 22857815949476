import React, { useState } from "react";
import { Form } from "antd";
import InputName from "../../../components/input-name";
import { useTranslation } from "react-i18next";
import styles from './_WorkflowTipoTarefaNome.less';
import PropTypes from "prop-types";

export default function WorkflowTipoTarefaNome(props) {
  const { nome, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [edicao, setEdicao] = useState(false);

  const fireSubmit = nome => {
    let promise = Promise.resolve();
    if (typeof onSubmit === 'function')
      promise = promise.then(() => onSubmit(nome));
    return promise;
  }

  const handleClick = e => {
    e.preventDefault();
    form.resetFields();
    setEdicao(true);
  }

  const handleBlur = e => {
    setEdicao(false);
  }

  const handleFinish = values => {
    fireSubmit(values.nome).then(() => setEdicao(false));
  }

  if (edicao) {
    return (
      <Form className={styles['form']} form={form} onFinish={handleFinish} size="middle">
        <Form.Item name="nome" initialValue={nome} noStyle>
          <InputName autoFocus placeholder={t('Nome')} onBlur={handleBlur}/>
        </Form.Item>
      </Form>
    );
  }

  return (
    <div className={styles['workflow-tipo-tarefa-nome']} onClick={handleClick}>
      {nome}
    </div>
  );
}

WorkflowTipoTarefaNome.propTypes = {
  nome: PropTypes.string,
  onSubmit: PropTypes.func
}