import React, { useState } from "react";
import styles from "./_WorkflowTarefaAnexo.less";
import ActionBar from "../../../components/action-bar";
import ActionLink from "../../../components/action-link";
import {
  draggingTarefaAnexoSelector,
  dragEndWorkflowTarefaAnexoId,
  dragStartWorkflowTarefaAnexoId,
  removeWorkflowTarefaAnexo,
  updateWorkflowTarefaAnexoNome,
  updateWorkflowTarefaAnexoPosicao,
  workflowSelector
} from "../../../store/workflow";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { PaperClipOutlined } from "@ant-design/icons";
import TextAnexo from "../../../components/text-anexo";
import WorkflowTarefaAnexoNome from "./_WorkflowTarefaAnexoNome";
import { isClienteOperadorSelector, isOperadorSelector } from "../../../store/autenticacao";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTarefaAnexo(props) {
  const { id, tarefa: tarefaId, nome, anexo, posicao } = props;
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector);
  const isClienteOperador = useSelector(isClienteOperadorSelector);
  const draggingTarefaAnexo = useSelector(draggingTarefaAnexoSelector)
  const [nomeEdicao, setNomeEdicao] = useState(false);

  const workflowId = workflow.id;

  const handleDragStart = e => {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('application/json', JSON.stringify({ event: 'WorkflowTarefaAnexo', data: { id } }));
    dispatch(dragStartWorkflowTarefaAnexoId(id));
    setNomeEdicao(false);
  }

  const handleDragEnd = e => {
    dispatch(dragEndWorkflowTarefaAnexoId());
  }

  const handleDragOver = e => {
    const target = e.currentTarget;
    if (!draggingTarefaAnexo)
      return;
    if (draggingTarefaAnexo.id === id)
      return;
    if (posicao < draggingTarefaAnexo.posicao)
      target.classList.add(styles['dropzone-top'])
    else
      target.classList.add(styles['dropzone-bottom'])
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  }

  const handleDragLeave = e => {
    const target = e.currentTarget;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
  }

  const handleDrop = e => {
    const target = e.currentTarget;
    if (!target)
      return;
    target.classList.remove(styles['dropzone-top'])
    target.classList.remove(styles['dropzone-bottom'])
    const { event, data } = JSON.parse(e.dataTransfer.getData('application/json'));
    if (event !== 'WorkflowTarefaAnexo')
      return;
    e.preventDefault();
    unwrapAxiosPromise(dispatch(updateWorkflowTarefaAnexoPosicao({ workflowId, tarefaId, id: data.id, posicao })));
  }

  const handleAnexoNomeSubmit = (nome) => unwrapAxiosPromise(dispatch(updateWorkflowTarefaAnexoNome({ workflowId, tarefaId, id, nome })));
  const handleAnexoEditClick = () => setNomeEdicao(true);
  const handleAnexoRemoveClick = () => unwrapAxiosPromise(dispatch(removeWorkflowTarefaAnexo({ workflowId, tarefaId, id })));

  return (
    <div
      className={styles['workflow-tarefa-anexo']}

      draggable={isClienteOperador}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}

      onDragEnter={handleDragOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div><PaperClipOutlined/></div>
      <div>
        {!nomeEdicao && <TextAnexo name={nome} value={anexo}/>}
        {nomeEdicao && <WorkflowTarefaAnexoNome nome={nome} edicao onEdicao={setNomeEdicao} onSubmit={handleAnexoNomeSubmit}/>}
      </div>
      {isClienteOperador &&
      <div>
        <ActionBar>
          <ActionLink.Edit noTitle onClick={handleAnexoEditClick}/>
          <ActionLink.Remove noTitle onClick={handleAnexoRemoveClick}/>
        </ActionBar>
      </div>
      }
    </div>
  )
}