import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import Organograma from '../../../components/organograma';
import { useSelector } from "react-redux";
import { acessoSelector } from '../../../store/acessos';
import AuthenticatedLayout from '../../../layouts/authenticated';
import AcessoMenu from '../../../components/acesso-menu';
import PesquisaMenu from '../../../components/pesquisa-menu';
import { isClienteGestorSelector } from '../../../store/autenticacao';
import SocietarioMenu from "../../../components/societario-menu";

export default function OrganogramaPage() {
  const { t } = useTranslation();
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const acesso = useSelector(acessoSelector)
  const [loading, setLoading] = useState(false);
  const [organograma, setOrganograma] = useState([]);
  const acessoId = acesso?.id;

  const fetch = useCallback(() => {
    if (acessoId) {
      setLoading(true);
      unwrapAxiosPromise(axios.get('/societario/organograma', { params: { acessoId } }))
        .then(data => {
          setOrganograma(data.organograma);
          document.title = 'Legal Panel - ' + t('Organograma') + ' - ' + data.nome;
        })
        .finally(() => setLoading(false));
    }
  }, [acessoId]);


  useEffect(() => {
    fetch();
  }, [fetch, acessoId]);

  return (
    <AuthenticatedLayout
      menu={
        <>
          <AcessoMenu defaultRoute="/societario/organograma"/>
          {!!acesso && <PesquisaMenu/>}
          <SocietarioMenu showCreate={isClienteGestor}/>
        </>
      }
      contentStyle={{ maxWidth: 'unset', padding: 0 }}
    >
      {acesso &&
        <Organograma loading={loading} organograma={organograma} onUnload={fetch} defaultLayout="curved"/>
      }
    </AuthenticatedLayout>);
}
