// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uiMQkKN5ESZVtyJpy6i96g\\=\\= {\n  padding: 8px;\n}\n.IAMlojaEssR0sIq0t\\+8sbg\\=\\= {\n  cursor: pointer;\n  line-height: 32px;\n  font-weight: bold;\n  padding: 8px;\n}\n.IAMlojaEssR0sIq0t\\+8sbg\\=\\= > div {\n  border-radius: 50%;\n  width: 28px;\n  height: 28px;\n}\n.IAMlojaEssR0sIq0t\\+8sbg\\=\\=:hover {\n  background-color: #f0f2f5;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tipoTarefa/_WorkflowTipoTarefaCor.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAEA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAJA;EAOI,kBAAA;EACA,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,yBAAA;AADJ","sourcesContent":[".workflow-tipo-tarefa-cor-edicao {\n  padding: 8px;\n}\n\n.workflow-tipo-tarefa-cor {\n  cursor: pointer;\n  line-height: 32px;\n  font-weight: bold;\n  padding: 8px;\n\n  > div {\n    border-radius: 50%;\n    width: 28px;\n    height: 28px;\n  }\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tipo-tarefa-cor-edicao": "uiMQkKN5ESZVtyJpy6i96g==",
	"workflow-tipo-tarefa-cor": "IAMlojaEssR0sIq0t+8sbg=="
};
export default ___CSS_LOADER_EXPORT___;
