// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z5xt59YzYKEYG1JGgsvN4g\\=\\= {\n  margin-bottom: 16px;\n}\n.AmSBoot0fUl96gmyOXCjGw\\=\\= {\n  float: right;\n}\n.P3BzZXM-rlgh3frDWKd2Hw\\=\\= {\n  font-size: 20pt;\n}\n.J6T3Wsm1ONTakApwyM1\\+wA\\=\\= {\n  font-size: 10pt;\n  max-width: 320px;\n  text-align: center;\n}\n.k8prXXPgCEyPzHqc5ryMcw\\=\\= {\n  margin-bottom: 24px;\n}\n.ZZ3PFegnfaqQiK2G7bG0HA\\=\\= {\n  display: inline-flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 8px;\n}\n.ZZ3PFegnfaqQiK2G7bG0HA\\=\\= > span {\n  font-size: 10pt;\n  font-weight: bold;\n  color: #262626 !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/_styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,YAAA;AAAF;AAGA;EACE,eAAA;AADF;AAIA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAFF;AAKA;EACE,mBAAA;AAHF;AAMA;EACE,oBAAA;EACA,qBAAA;EACA,mBAAA;EACA,QAAA;AAJF;AAAA;EAOI,eAAA;EACA,iBAAA;EACA,yBAAA;AAJJ","sourcesContent":[".alert {\n  margin-bottom: 16px;\n}\n\n.link {\n  float: right;\n}\n\n.splash-title {\n  font-size: 20pt;\n}\n\n.splash-description {\n  font-size: 10pt;\n  max-width: 320px;\n  text-align: center;\n}\n\n.splash-loading {\n  margin-bottom: 24px;\n}\n\n.ambiente {\n  display: inline-flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 8px;\n\n  > span {\n    font-size: 10pt;\n    font-weight: bold;\n    color: #262626 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "z5xt59YzYKEYG1JGgsvN4g==",
	"link": "AmSBoot0fUl96gmyOXCjGw==",
	"splash-title": "P3BzZXM-rlgh3frDWKd2Hw==",
	"splash-description": "J6T3Wsm1ONTakApwyM1+wA==",
	"splash-loading": "k8prXXPgCEyPzHqc5ryMcw==",
	"ambiente": "ZZ3PFegnfaqQiK2G7bG0HA=="
};
export default ___CSS_LOADER_EXPORT___;
