import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AcessoMenu from '../../../components/acesso-menu';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import AuthenticatedLayout from '../../../layouts/authenticated';
import { acessoSelector } from '../../../store/acessos';
import { isClienteGestorSelector } from '../../../store/autenticacao';
import { alertasByEmpresaIdSelector, empresasFiltradasSelector, fetchEmpresas } from '../../../store/empresas';
import { empresasSelector, fetchTabela } from '../../../store/complianceTabela';
import EmpresaCard from './_EmpresaCard';
import PesquisaMenu from "../../../components/pesquisa-menu";
import ComplianceMenu from "../../../components/compliance-menu";

export default function ComplianceDetalhadoPage() {
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const acesso = useSelector(acessoSelector);
  const empresas = useSelector(empresasSelector);
  const empresasFiltradas = useSelector(empresasFiltradasSelector);
  const alertasByEmpresaId = useSelector(alertasByEmpresaIdSelector);
  const dispatch = useDispatchWithResult();
  const acessoId = acesso?.id;

  useEffect(() => {
    dispatch(fetchEmpresas({ acessoId }));
  }, [acessoId]);

  useEffect(() => {
    dispatch(fetchTabela({ empresas: empresasFiltradas, alertasByEmpresaId }));
  }, [empresasFiltradas, alertasByEmpresaId]);

  return (
    <AuthenticatedLayout
      modulo="compliance"
      menu={
        <>
          <AcessoMenu defaultRoute="/compliance/detalhado" fetchEmpresas/>
          {!!acesso && <PesquisaMenu/>}
          <ComplianceMenu showCreate={isClienteGestor} showFilter/>
        </>
      }
    >
      {acesso &&
        <Row gutter={[16, 16]}>
          {empresas.map(empresa =>
            <Col key={empresa.id} span={24} md={12} lg={8} xl={6}>
              <EmpresaCard empresa={empresa}/>
            </Col>)}
        </Row>
      }
    </AuthenticatedLayout>
  );
}
