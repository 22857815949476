// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z7oYrNde7YeFq4YM8mz1Ng\\=\\=,\n.pPc0hjOPvN-\\+pfn1n87fZg\\=\\= {\n  cursor: pointer;\n  line-height: 32px;\n  padding: 0 8px;\n}\n.z7oYrNde7YeFq4YM8mz1Ng\\=\\=:hover,\n.pPc0hjOPvN-\\+pfn1n87fZg\\=\\=:hover {\n  background-color: #f0f2f5;\n}\n.pPc0hjOPvN-\\+pfn1n87fZg\\=\\= {\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_WorkflowTarefaAnexoNome.less"],"names":[],"mappings":"AAAA;;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAEF;AAAE;;EACE,yBAAA;AAGJ;AACA;EAEE,cAAA;AAAF","sourcesContent":[".workflow-tarefa-anexo-nome {\n  cursor: pointer;\n  line-height: 32px;\n  padding: 0 8px;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.nao-preenchido {\n  &:extend(.workflow-tarefa-anexo-nome all);\n  color: #999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-anexo-nome": "z7oYrNde7YeFq4YM8mz1Ng==",
	"nao-preenchido": "pPc0hjOPvN-+pfn1n87fZg=="
};
export default ___CSS_LOADER_EXPORT___;
