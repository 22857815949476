import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import ConsultivoConsultaAnexosDetailPage from "./_detail";
import ConsultivoConsultaAnexoPage from "./anexo";

export default function ConsultivoConsultaAnexosPage() {
  return (
    <Card bordered={false}>
      <Switch>
        <Route path="/consultivo/consultas/:consultaId/anexos/:id" component={ConsultivoConsultaAnexoPage}/>
        <Route path="/consultivo/consultas/:consultaId" component={ConsultivoConsultaAnexosDetailPage}/>
      </Switch>
    </Card>);
}
