import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchPL} from '../../../../store/empresa';
import EmpresaPLDetailPage from './_detail';
import EmpresaPLEditPage from './_edit';

export default function EmpresaPLPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchPL({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/pls/:id/edit" component={EmpresaPLEditPage} />
        <Route path="/*/empresas/:empresaId/pls/:id" component={EmpresaPLDetailPage} />
      </Switch>);
}
