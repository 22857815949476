import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmpresaBeneficiarioSocio} from '../../constants';

const {Apresentacao} = EmpresaBeneficiarioSocio;

export default function SelectEmpresaBeneficiarioSocioApresentacao(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Apresentacao.Pendente)}
        {createOption(Apresentacao.Sim)}
        {createOption(Apresentacao.Nao)}
      </Select>
  );
}

SelectEmpresaBeneficiarioSocioApresentacao.propTypes = {
  value: PropTypes.oneOf([
    Apresentacao.Pendente.id,
    Apresentacao.Sim.id,
    Apresentacao.Nao.id]),
  onChange: PropTypes.func,
};
