import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../../hooks/useDispatchWithResult';
import {fetchAssunto} from '../../../../../store/consultivoArea';
import ConsultivoAreaAssuntoCreatePage from './_create';
import ConsultivoAreaAssuntoDetailPage from './_detail';
import ConsultivoAreaAssuntoEditPage from './_edit';

export default function ConsultivoAreaAssuntoPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const areaId = match.params.areaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchAssunto({areaId, id}));
  }, [areaId, id]);

  return (
      <Switch>
        <Route path="/consultivo/areas/:areaId/assuntos/:id/edit" component={ConsultivoAreaAssuntoEditPage} />
        <Route path="/consultivo/areas/:areaId/assuntos/create" component={ConsultivoAreaAssuntoCreatePage} />
        <Route path="/consultivo/areas/:areaId/assuntos/:id" component={ConsultivoAreaAssuntoDetailPage} />
      </Switch>);
}
