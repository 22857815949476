// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dl5DIowGxMFT-jfEdJq-FA\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 4px;\n  padding: 0 4px;\n  border-radius: 8px;\n}\n.Dl5DIowGxMFT-jfEdJq-FA\\=\\=.PD-rT4Qc9sDM9sI3fkWC7w\\=\\= {\n  background-color: #1890ff;\n  color: black;\n}\n.Dl5DIowGxMFT-jfEdJq-FA\\=\\=.BJsH72FBqPHAAlOKC\\+Q74w\\=\\= {\n  background-color: #52c41a;\n  color: black;\n}\n.Dl5DIowGxMFT-jfEdJq-FA\\=\\=._9Ijx5ki9KHyBODgpEc-muw\\=\\= {\n  background-color: #faad14;\n  color: black;\n}\n.Dl5DIowGxMFT-jfEdJq-FA\\=\\=.vlrzybgPJwLvdOM0pNu3\\+A\\=\\= {\n  background-color: #ff4d4f;\n  color: black;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/_WorkflowTarefaInfoTag.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,yBAAA;EACA,YAAA;AACJ;AAEE;EACE,yBAAA;EACA,YAAA;AAAJ;AAGE;EACE,yBAAA;EACA,YAAA;AADJ;AAIE;EACE,yBAAA;EACA,YAAA;AAFJ","sourcesContent":[".workflow-tarefa-info-tag {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 4px;\n  padding: 0 4px;\n  border-radius: 8px;\n\n  &.info {\n    background-color: #1890ff;\n    color: black;\n  }\n\n  &.success {\n    background-color: #52c41a;\n    color: black;\n  }\n\n  &.warning {\n    background-color: #faad14;\n    color: black;\n  }\n\n  &.error {\n    background-color: #ff4d4f;\n    color: black;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-info-tag": "Dl5DIowGxMFT-jfEdJq-FA==",
	"info": "PD-rT4Qc9sDM9sI3fkWC7w==",
	"success": "BJsH72FBqPHAAlOKC+Q74w==",
	"warning": "_9Ijx5ki9KHyBODgpEc-muw==",
	"error": "vlrzybgPJwLvdOM0pNu3+A=="
};
export default ___CSS_LOADER_EXPORT___;
