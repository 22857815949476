import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchDEF} from '../../../../store/empresa';
import EmpresaDEFCreatePage from './_create';
import EmpresaDEFDetailPage from './_detail';
import EmpresaDEFEditPage from './_edit';

export default function EmpresaDEFPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchDEF({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/defs/:id/edit" component={EmpresaDEFEditPage} />
        <Route path="/*/empresas/:empresaId/defs/create" component={EmpresaDEFCreatePage} />
        <Route path="/*/empresas/:empresaId/defs/:id" component={EmpresaDEFDetailPage} />
      </Switch>);
}
