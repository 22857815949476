import { Tag } from "antd";
import React from "react";
import styles from './_styles.less';

export default function TableCellColor({ text }) {
  return (
    <div className={styles['table-cell-color']}>
      <Tag className={styles['tag']} color={`#${text}`}>&nbsp;</Tag>
      <span>{text}</span>
    </div>
  )
}