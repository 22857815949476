import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createWorkflowTarefaAnexo, workflowSelector } from "../../../store/workflow";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import InputAnexo from "../../../components/input-anexo";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTarefaAnexoCriar(props) {
  const { tarefaId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector);
  const [value, setValue] = useState(null);

  const workflowId = workflow.id;

  const handleChange = anexo => {
    setValue(anexo);
    unwrapAxiosPromise(dispatch(createWorkflowTarefaAnexo({ workflowId, tarefaId, anexo })))
      .finally(() => setValue(null));
  };

  return (
    <div>
      <InputAnexo compact disabled={!!value} value={value} onChange={handleChange}/>
    </div>
  );
}

WorkflowTarefaAnexoCriar.propTypes = {
  tarefaId: PropTypes.number,
}