// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YPOuLG-2vjGjS5jUzOKwSg\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  height: 32px;\n  padding: 0 8px;\n}\n.YPOuLG-2vjGjS5jUzOKwSg\\=\\= > * {\n  line-height: 18px;\n}\n.YPOuLG-2vjGjS5jUzOKwSg\\=\\=:not(.Sxm3xVkiIhMqJiXky8Y7Wg\\=\\=) {\n  cursor: pointer;\n}\n.YPOuLG-2vjGjS5jUzOKwSg\\=\\=:not(.Sxm3xVkiIhMqJiXky8Y7Wg\\=\\=):hover {\n  background-color: #f0f2f5;\n}\n.YHu7wFGdqA5ibBuosiSpIg\\=\\= {\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_WorkflowTarefaDataEntrega.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,UAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;AACF;AAPA;EASI,iBAAA;AACJ;AAGA;EACE,eAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAKA;EACE,cAAA;AAHF","sourcesContent":[".workflow-tarefa-data-entrega {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  height: 32px;\n  padding: 0 8px;\n\n  > * {\n    line-height: 18px;\n  }\n}\n\n.workflow-tarefa-data-entrega:not(.read-only) {\n  cursor: pointer;\n\n  &:hover {\n    background-color: #f0f2f5;\n  }\n}\n\n.nao-preenchido {\n  color: #999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-data-entrega": "YPOuLG-2vjGjS5jUzOKwSg==",
	"read-only": "Sxm3xVkiIhMqJiXky8Y7Wg==",
	"nao-preenchido": "YHu7wFGdqA5ibBuosiSpIg=="
};
export default ___CSS_LOADER_EXPORT___;
