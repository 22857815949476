import styles from "./_styles.less";
import { FilterOutlined } from "@ant-design/icons";
import { Form } from "antd";
import React from "react";

export default function PesquisaFormItem({ children, label, name, shouldUpdate }) {
  if (shouldUpdate) {
    return (
      <div className={styles.PesquisaFormItem}>
        <div className={styles.icone}>
          <div><FilterOutlined/></div>
          <div>{label}</div>
        </div>
        <Form.Item noStyle shouldUpdate={shouldUpdate}>
          {form =>
            <Form.Item name={name} noStyle>
              {children(form)}
            </Form.Item>
          }
        </Form.Item>
      </div>
    )
  }

  return (
    <div className={styles.PesquisaFormItem}>
      <div className={styles.icone}>
        <div><FilterOutlined/></div>
        <div>{label}</div>
      </div>
      <Form.Item name={name} noStyle>
        {children}
      </Form.Item>
    </div>
  )
}
