import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import {avisosSelector, totalSelector} from '../../../store/complianceDashboard';
import {fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas, filtrarEmpresas} from '../../../store/empresas';
import NivelCard from './_NivelCard';

export default function NivelAvisoCard() {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const avisos = useSelector(avisosSelector);
  const total = useSelector(totalSelector);

  const handleClick = () => {
    dispatch(filtrarEmpresas({sucessos: false, avisos: true, erros: false, inativas: false, tabs: []}));
    history.push('/compliance/detalhado');
  }

  return (
      <NivelCard
          className="background-warning"
          count={avisos}
          loading={loading}
          title={t('Alertas')}
          total={total}
          onClick={handleClick}
      />
  );
}
