import React, { useMemo, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import styles from './_WorkflowTarefaDataPrazo.less';
import PropTypes from "prop-types";
import ActionBar from "../../../components/action-bar";
import ActionLink from "../../../components/action-link";
import useFormatter from "../../../hooks/useFormatter";
import { ClockCircleOutlined } from "@ant-design/icons";
import InputDate from "../../../components/input-date";
import moment from "moment";
import WorkflowTarefaInfoTag from "../_WorkflowTarefaInfoTag";

export default function WorkflowTarefaDataPrazo(props) {
  const { dataPrazo, onSubmit,readOnly } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { formatDate } = useFormatter();
  const [edicao, setEdicao] = useState(false);
  const now = moment();

  const statusPrazo = useMemo(() => {
    if (dataPrazo && now.isSame(dataPrazo.slice(0, 10), 'date'))
      return 'warning';
    if (dataPrazo && now.isAfter(dataPrazo.slice(0, 10), 'date'))
      return 'error';
  }, [now, dataPrazo]);

  const fireSubmit = dataPrazo => {
    let promise = Promise.resolve();
    if (typeof onSubmit === 'function')
      promise = promise.then(() => onSubmit(dataPrazo));
    return promise;
  }

  const handleClick = e => {
    if (readOnly)
      return;
    e.preventDefault();
    form.resetFields();
    setEdicao(true);
  }

  const handleFinish = values => {
    fireSubmit(values.dataPrazo).then(() => setEdicao(false));
  }

  if (edicao) {
    return (
      <Form
        form={form}
        onFinish={handleFinish}
        size="middle"
      >
        <Form.Item name="dataPrazo" initialValue={dataPrazo} noStyle>
          <InputDate autoFocus placeholder={t('Prazo')} prefix={<ClockCircleOutlined/>}/>
        </Form.Item>
        <ActionBar>
          <ActionLink.Save onClick={form.submit}/>
          <ActionLink.Cancel onClick={() => setEdicao(false)}/>
        </ActionBar>
      </Form>
    );
  }

  let className = styles['workflow-tarefa-data-prazo'];
  if (!dataPrazo)
    className += ' ' + styles['nao-preenchido'];
  if (readOnly)
    className += ' ' + styles['read-only'];

  if (dataPrazo)
    return (
      <div className={className} onClick={handleClick}>
        <div>
          <WorkflowTarefaInfoTag icon={<ClockCircleOutlined/>} status={statusPrazo} text={formatDate(dataPrazo)}/>
        </div>
      </div>
    );

  return (
    <div className={className} onClick={handleClick}>
      (não preenchido)
    </div>
  )
}

WorkflowTarefaDataPrazo.propTypes = {
  dataPrazo: PropTypes.string,
  onSubmit: PropTypes.func,
  readOnly: PropTypes.bool
}