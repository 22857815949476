import React, { useMemo, useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { addWorkflowTarefaMembro, membroByIdMapSelector, tarefaByIdSelector, workflowSelector } from "../../../store/workflow";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import ActionLink from "../../../components/action-link";
import PropTypes from "prop-types";
import styles from "./_WorkflowTarefaModal.less";
import useCollator from "../../../hooks/useCollator";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTarefaMembroAdicionar(props) {
  const { tarefaId } = props;
  const { t } = useTranslation();
  const { compare, contains } = useCollator();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector);
  const tarefa = useSelector(tarefaByIdSelector(tarefaId));
  const membroById = useSelector(membroByIdMapSelector);
  const [exibeBotao, setExibeBotao] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const workflowId = workflow.id;

  const membrosFiltrados = useMemo(() =>
      Object.values(membroById)
        .filter(membro => !tarefa.membros.includes(membro.id))
        .filter(membro => contains(membro.nome, searchValue))
        .sort((a, b) => compare(a.nome, b.nome))
        .slice(0, 50),
    [contains, tarefa.membros, membroById, searchValue]);

  const handleClick = () => {
    setExibeBotao(false);
  };

  const handleBlur = () => {
    setExibeBotao(true);
  }

  const handleChange = membroId => {
    unwrapAxiosPromise(dispatch(addWorkflowTarefaMembro({ workflowId, id: tarefaId, membroId })))
      .then(() => setExibeBotao(true));
  };

  if (exibeBotao)
    return (
      <ActionLink.Create title={t('Adicionar Membro')} onClick={handleClick}/>
    );

  return (
    <div className={styles['membro-adicionar']}>
      <Select
        autoFocus
        filterOption={false}
        onBlur={handleBlur}
        onChange={handleChange}
        onSearch={setSearchValue}
        searchValue={searchValue}
        showSearch
        // value={value}
      >
        {membrosFiltrados.map(membro => (
          <Select.Option key={membro.id} value={membro.id}>
            {membro.nome}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

WorkflowTarefaMembroAdicionar.propTypes = {
  tarefaId: PropTypes.number,
}