import {message, Spin} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputAnexo from '../../components/input-anexo';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useLoading from '../../hooks/useLoading';
import {unwrapResult} from '../../store';
import {enviarArquivo} from '../../store/drive';
import useDriveQuery from './_useDriveQuery';

export default function DriveSendFile() {
  const {t} = useTranslation();
  const {empresaId} = useDriveQuery();
  const loading = useLoading(enviarArquivo.typePrefix);
  const dispatch = useDispatchWithResult();

  const handleAnexoChanged = anexo => {
    if (anexo)
      dispatch(enviarArquivo({empresaId, anexo}))
          .then(unwrapResult)
          .then(() => message.success(t('Arquivo enviado com sucesso')))
          .catch(err => message.error(err.mensagem));
  };

  return (
      <Spin spinning={loading}>
        {t('Enviar arquivo')}
        <InputAnexo disabled={loading} onChange={handleAnexoChanged} />
      </Spin>
  );
}
