import { Select } from "antd";
import styles from "./_WorkflowFilterBar.less";
import { formatCnpj } from "../../util/cnpj";
import React, { useEffect, useMemo, useState } from "react";
import useCollator from "../../hooks/useCollator";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";
import { useSelector } from "react-redux";
import { filtrarWorkflow, filtroSelector, workflowSelector } from "../../store/workflow";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";

export default function WorkflowFilterBarEmpresa() {
  const { compare, contains } = useCollator();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector)
  const filtro = useSelector(filtroSelector);
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const workflowId = workflow?.id;

  const nome = searchValue.trim();

  const handleChange = empresaIds => {
    dispatch(filtrarWorkflow({ empresaIds }));
  }

  useEffect(() => {
    if (workflowId) {
      setLoading(true);
      unwrapAxiosPromise(axios.get(`/workflows/${workflowId}/empresas`, { params: { emUso: true } }))
        .then(data => setEmpresas(data))
        .finally(() => setLoading(false))
    } else
      setEmpresas([]);
  }, [workflowId]);

  const empresasFiltradas = useMemo(() =>
      empresas
        .filter(empresa => contains(empresa.cnpj, nome) || contains(empresa.nome, nome))
        .sort((a, b) => compare(a.cnpj, b.cnpj))
        .slice(0, 50),
    [contains, empresas, nome]);


  return (
    <Select
      allowClear
      className={styles['select']}
      filterOption={false}
      loading={loading}
      mode="multiple"
      searchValue={searchValue}
      onSearch={setSearchValue}
      showSearch
      value={filtro.empresaIds}
      onChange={handleChange}
    >
      {empresasFiltradas.map(empresa => (
        <Select.Option key={empresa.id} value={empresa.id}>
          {formatCnpj(empresa.cnpj)} - {empresa.nome}
        </Select.Option>
      ))}
    </Select>
  )
}