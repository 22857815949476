import React, {useMemo} from 'react';
import InputName from "../input-name";
import {Form} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import ActionButton from "../action-button";
import styles from './_styles.less';

export default function InputNames({name, onChange, value: values = [], ...props}) {
  const id = useMemo(() => "input-names-" + new Date().getTime(), []);
  const fireChange = (newValue, value, index) => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(values
          .map((v, i) => i === index ? newValue : v)
          .filter(v => v !== null));
  };

  const handleChange = (newValue, value, index) => fireChange(newValue, value, index);

  const handleAdd = (index) => {
    if (typeof onChange === 'function') {
      const before = values.slice(0, index + 1);
      const after = values.slice(index + 1);
      onChange([...before, '', ...after]);
      setImmediate(() => document.querySelector(`#${id} :nth-child(${index + 2}) input`).focus());
    }
  }

  const handleRemove = index => {
    const before = values.slice(0, index);
    const after = values.slice(index + 1);
    onChange([...before, ...after])
    setImmediate(() => document.querySelector(`#${id} :nth-child(${Math.max(0, index)}) input`).focus());
  }

  return (
      <div id={id}>
        {values.map((value, index) => (
            <Form.Item key={index} name={[name, index]}>
              <div className={styles.wrapper}>
                <InputName
                    value={value}
                    onChange={newValue => handleChange(newValue, value, index)}
                    onPressEnter={() => handleAdd(index)}
                    onRemove={() => handleRemove(index)}
                    {...props}
                />
                <ActionButton disabled={props.disabled} icon={<DeleteOutlined/>} onClick={() => handleRemove(index)}/>
              </div>
            </Form.Item>
        ))}
        <Form.Item name={[name, -1]}>
          <ActionButton disabled={props.disabled} icon={<PlusOutlined/>} onClick={() => handleAdd(values.length)}/>
        </Form.Item>
      </div>
  );
}
