import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createWorkflowTipoTarefaItem, workflowSelector } from "../../../store/workflow";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import ActionLink from "../../../components/action-link";
import PropTypes from "prop-types";
import WorkflowTipoTarefaItemDescricao from "./_WorkflowTipoTarefaItemDescricao";
import styles from "./_WorkflowTipoTarefaModal.less";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function WorkflowTipoTarefaItemCriar(props) {
  const { tipoTarefaId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector);
  const [exibeBotao, setExibeBotao] = useState(true);

  const workflowId = workflow.id;

  const handleClick = () => {
    setExibeBotao(false);
  };

  const handleClose = () => {
    setExibeBotao(true);
  }

  const handleEdicao = edicao => {
    setExibeBotao(!edicao);
  }

  const handleSubmit = descricao => {
    unwrapAxiosPromise(dispatch(createWorkflowTipoTarefaItem({ workflowId, tipoTarefaId, descricao })))
      .then(() => handleClose());
  };

  if (exibeBotao)
    return (
      <div className={styles['checkbox-criar-botao']}>
        <ActionLink.Create title={t('Criar Item')} onClick={handleClick}/>
      </div>
    );

  return (
    <div className={styles['checkbox-criar']}>
      <WorkflowTipoTarefaItemDescricao descricao='' edicao onEdicao={handleEdicao} onSubmit={handleSubmit}/>
    </div>
  );
}

WorkflowTipoTarefaItemCriar.propTypes = {
  tipoTarefaId: PropTypes.number,
}