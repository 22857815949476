import {message} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './_styles.less';

export default function DriveLink({href}) {
  const {t} = useTranslation();

  const handleClick = e => {
    e.preventDefault();
    e.target.select();
    e.target.setSelectionRange(0, e.target.value.length);
    navigator.clipboard.writeText(e.target.value);
    message.info(t('Link copiado para a área de transferência'));
  };

  return (
      <input
          className={styles['link'] + ' primary'}
          readOnly
          onClick={handleClick}
          value={href}
      />
  );
}

DriveLink.propTypes = {
  href: PropTypes.string,
};
