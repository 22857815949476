import {Form, message} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ActionButton from '../../components/action-button';
import InputName from '../../components/input-name';
import MiniFormDrawer from '../../components/mini-form-drawer';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useLoading from '../../hooks/useLoading';
import {unwrapResult} from '../../store';
import {closeCriarDiretorioDrawer, criarDiretorio, criarDiretorioDrawerVisibleSelector} from '../../store/drive';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import useDriveQuery from './_useDriveQuery';

export default function CriarDiretorioDrawer() {
  const {t} = useTranslation();
  const {empresaId} = useDriveQuery();
  const loading = useLoading(criarDiretorio.typePrefix);
  const visible = useSelector(criarDiretorioDrawerVisibleSelector);
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();

  const handleDrawerAfterOpen = () => {
    document.querySelector('#nome').focus();
  };

  const handleDrawerAfterClose = () => {
    form.resetFields();
  };

  const handleFinish = values => {
    unwrapAxiosPromise(dispatch(criarDiretorio({empresaId, ...values})).then(unwrapResult), form)
        .then(() => {
          message.success(t('Diretório criado com sucesso'));
          dispatch(closeCriarDiretorioDrawer());
        });
  };

  const handleClose = () => {
    dispatch(closeCriarDiretorioDrawer());
  };

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loading}
        />
        <ActionButton.Close
            onClick={handleClose}
            disabled={loading}
        />
      </>
  );

  return (
      <MiniFormDrawer
          drawerTitle={t('Criar diretório')}
          drawerFooter={footer}
          drawerWidth={400}
          drawerVisible={visible}
          form={form}
          onDrawerClose={handleClose}
          onDrawerAfterOpen={handleDrawerAfterOpen}
          onDrawerAfterClose={handleDrawerAfterClose}
          onFinish={handleFinish}
      >
        <Form.Item name="nome" label={t('Nome')}>
          <InputName disabled={loading} />
        </Form.Item>
      </MiniFormDrawer>
  );
}
