import { Form, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import useDispatchWithResult from '../../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../../hooks/useLoading';
import { fetchAnexos, fetchAnexo, anexoSelector, fetchLogs } from '../../../../../store/consultivoConsulta';
import unwrapAxiosPromise from '../../../../../util/unwrapAxiosPromise';
import ConsultivoConsultaAnexoForm from './_form';
import { valuesToFormData } from "../../../../../util/formData";

export default function ConsultivoConsultaAnexoEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const anexo = useSelector(anexoSelector);
  const loading = useLoading(fetchAnexo.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;
  const id = match.params.id;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(anexo);
    setImmediate(() => document.querySelector('#nome').focus());
  }, [anexo]);

  const handleFinish = values => {
    setLoadingSave(true);
    const formData = valuesToFormData(values);
    unwrapAxiosPromise(axios.put(`/consultivo/consultas/${consultaId}/anexos/${id}`, formData), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        history.replace(`/consultivo/consultas/${consultaId}/anexos/${id}`);
        dispatch(fetchAnexos({ consultaId }));
        dispatch(fetchAnexo({ consultaId, id }));
        dispatch(fetchLogs({ consultaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/consultas/${consultaId}/anexos/${id}`);
  };

  const title = (
    <ActionBar>
      <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
      <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
    </ActionBar>);

  return (
    <ConsultivoConsultaAnexoForm
      autoFillByCnpj="normal"
      disabled={loadingSave}
      form={form}
      loading={loading}
      onFinish={handleFinish}
      title={title}
    />);
}
