// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CTcWGdtlSUpH8YLb4nt0Yw\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  line-height: 32px;\n  border-top: 4px solid transparent;\n  border-bottom: 4px solid transparent;\n  margin-top: -4px;\n  margin-bottom: -4px;\n}\n.CTcWGdtlSUpH8YLb4nt0Yw\\=\\= > :nth-child(2) {\n  flex-grow: 1;\n}\n.CTcWGdtlSUpH8YLb4nt0Yw\\=\\= .ant-checkbox + .ant-checkbox {\n  margin-left: 0;\n}\n.OU9d\\+RDVrATp-ojgEvqAkQ\\=\\= {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-top-color: #009edb;\n}\n._4J6Swb2jNQ7XeTqcgbWOkw\\=\\= {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-bottom-color: #009edb;\n}\n", "",{"version":3,"sources":["webpack://src/pages/workflows/tarefa/_WorkflowTarefaItem.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,UAAA;EACA,mBAAA;EACA,iBAAA;EACA,iCAAA;EACA,oCAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAVA;EAYI,YAAA;AACJ;AAbA;EAgBI,cAAA;AAAJ;AAIA;EACE,0BAAA;EACA,2BAAA;EACA,yBAAA;AAFF;AAKA;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;AAHF","sourcesContent":[".workflow-tarefa-item {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 0 4px;\n  align-items: center;\n  line-height: 32px;\n  border-top: 4px solid transparent;\n  border-bottom: 4px solid transparent;\n  margin-top: -4px;\n  margin-bottom: -4px;\n\n  > :nth-child(2) {\n    flex-grow: 1;\n  }\n\n  :global(.ant-checkbox + .ant-checkbox) {\n    margin-left: 0;\n  }\n}\n\n.dropzone-top {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-top-color: #009edb;\n}\n\n.dropzone-bottom {\n  border-left-color: #009edb;\n  border-right-color: #009edb;\n  border-bottom-color: #009edb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow-tarefa-item": "CTcWGdtlSUpH8YLb4nt0Yw==",
	"dropzone-top": "OU9d+RDVrATp-ojgEvqAkQ==",
	"dropzone-bottom": "_4J6Swb2jNQ7XeTqcgbWOkw=="
};
export default ___CSS_LOADER_EXPORT___;
