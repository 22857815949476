import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { Children } from 'react';
import Search from '../search';

function ActionBar({ align, children, gutter = [8, 8], justify, onSearch, search, searchLoading }) {
  const margin = gutter.map(n => -Math.floor(n / 2) + 'px').join(' ');

  return (
    <Row align={align} gutter={gutter} justify={justify} style={{ margin }}>
      {onSearch &&
        <Col flex="1">
          <Search search={search} onSearch={onSearch} loading={searchLoading}/>
        </Col>
      }
      {Children.toArray(children).map((node, index) => <Col key={index}>{node}</Col>)}
    </Row>
  );
}

ActionBar.defaultProps = {
  justify: 'end',
};

ActionBar.propTypes = {
  align: PropTypes.oneOf(['start', 'end', 'center', 'space-around', 'space-between']),
  children: PropTypes.node,
  justify: PropTypes.oneOf(['start', 'end', 'center', 'space-around', 'space-between']),
  onSearch: PropTypes.func,
  search: PropTypes.string,
  searchLoading: PropTypes.bool,
};

export default ActionBar;
