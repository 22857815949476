import {Divider, Layout} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import Brand from '../../components/brand';
import {isMDSelector} from '../../store/layout';
import styles from './_styles.less';

export default function DrivePopupLayout({children, menu}) {
  const isMD = useSelector(isMDSelector);

  return (
      <Layout className={styles['drive-layout']}>
        <Layout.Header theme="dark" className={styles['header']}>
          <div className={styles['brand']}>
            <Brand height="24px" theme="dark" iconOnly={!isMD} />
          </div>
          <Divider type="vertical" className={styles['divider']} />
          <div className={styles['menu']}>
            {menu}
          </div>
        </Layout.Header>
        <Layout.Content className={styles['content']}>
          {children}
        </Layout.Content>
      </Layout>
  );
}

DrivePopupLayout.propTypes = {
  children: PropTypes.node,
  menu: PropTypes.node,
};
