export function openPopup(url, target) {
  const width = Math.floor(window.screen.availWidth * 0.9);
  const height = Math.floor(window.screen.availHeight * 0.8);
  const left = Math.floor((window.screen.width - width) / 2);
  const top = Math.floor((window.screen.height - height) / 2);
  const features = 'location=no,menubar=no,status=no,titlebar=no,width=' + width + ',height=' + height + ',left=' + left + ',top=' + top;
  return window.open(url, target, features);
}

export function openPopupEmpresa(empresa, tab, { onUnload }) {
  let prefix = '';
  const { pathname } = document.location;
  if (pathname.startsWith('/societario/'))
    prefix = '/societario';
  if (pathname.startsWith('/regulatorio/'))
    prefix = '/regulatorio';
  if (pathname.startsWith('/compliance/'))
    prefix = '/compliance';
  if (pathname.startsWith('/workflow/') || pathname.startsWith('/workflows/'))
    prefix = '/workflow';
  const popup = openPopup(`${prefix}/empresas/${empresa.id}/${tab}`, `empresa_${empresa.id}`);
  popup.addEventListener('unload', ev => {
    if (ev.target.URL === 'about:blank')
      return;
    if (typeof onUnload === 'function')
      onUnload();
  });
  return popup;
}

export function openPopupConsultivoArea(area, tab, { onUnload }) {
  const popup = openPopup(`/consultivo/areas/${area.id}/${tab}`, `consultivo_area_${area.id}`);
  popup.addEventListener('unload', ev => {
    if (ev.target.URL === 'about:blank')
      return;
    if (typeof onUnload === 'function')
      onUnload();
  });
  return popup;
}

export function openPopupConsultivoConsulta(consulta, tab, { onUnload }) {
  const popup = openPopup(`/consultivo/consultas/${consulta.id}/${tab}`, `consultivo_consulta_${consulta.id}`);
  popup.addEventListener('unload', ev => {
    if (ev.target.URL === 'about:blank')
      return;
    if (typeof onUnload === 'function')
      onUnload();
  });
  return popup;
}

export function openPopupConsultivoProjeto(projeto, tab, { onUnload }) {
  const popup = openPopup(`/consultivo/projetos/${projeto.id}/${tab}`, `consultivo_projeto_${projeto.id}`);
  popup.addEventListener('unload', ev => {
    if (ev.target.URL === 'about:blank')
      return;
    if (typeof onUnload === 'function')
      onUnload();
  });
  return popup;
}
