import styles from "./_WorkflowTarefaInfoTag.less";
import React from "react";
import PropTypes from "prop-types";

export default function WorkflowTarefaInfoTag(props) {
  const { icon, status, text } = props;

  const className = [styles['workflow-tarefa-info-tag']];
  switch (status) {
    case 'info':
    case 'success':
    case 'warning':
    case 'error':
      className.push(styles[status]);
      break;
  }

  return (
    <div className={className.join(' ')}>
      <div>{icon}</div>
      <div>{text}</div>
    </div>
  )
}
WorkflowTarefaInfoTag.propTypes = {
  icon: PropTypes.node,
  status: PropTypes.oneOf(['info', 'success', 'warning', 'error',]),
  text: PropTypes.string
}