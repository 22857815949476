// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-upload.ant-upload-drag .FUY-mBWBcZcgbPCkOwBqWw\\=\\= {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n  padding: 0 8px;\n  height: 22px;\n}\n.ant-upload.ant-upload-drag .FUY-mBWBcZcgbPCkOwBqWw\\=\\= p.dH1RM8L1D9l5qZ9onaHkmg\\=\\=.ant-upload-drag-icon {\n  margin-bottom: 0;\n}\n.ant-upload.ant-upload-drag .FUY-mBWBcZcgbPCkOwBqWw\\=\\= p.dH1RM8L1D9l5qZ9onaHkmg\\=\\=.ant-upload-drag-icon span {\n  font-size: 16px;\n}\n.ant-upload.ant-upload-drag p.dH1RM8L1D9l5qZ9onaHkmg\\=\\=.ant-upload-drag-icon {\n  margin-bottom: 4px;\n}\n.ant-upload.ant-upload-drag p.dH1RM8L1D9l5qZ9onaHkmg\\=\\=.ant-upload-drag-icon span {\n  font-size: 24px;\n}\n.ant-upload.ant-upload-drag p.vHaEaICnjDn2Z--o7QYtsQ\\=\\=.ant-upload-text {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/input-anexo/_styles.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,YAAA;AAAJ;AARA;EAWM,gBAAA;AAAN;AAXA;EAcQ,eAAA;AAAR;AAMA;EACE,kBAAA;AAJF;AAGA;EAII,eAAA;AAJJ;AAQA;EACE,gBAAA;AANF","sourcesContent":[":global(.ant-upload.ant-upload-drag) {\n  .compact {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n    padding: 0 8px;\n    height: 22px;\n\n    p.icon:global(.ant-upload-drag-icon) {\n      margin-bottom: 0;\n\n      span {\n        font-size: 16px;\n      }\n    }\n  }\n}\n\n:global(.ant-upload.ant-upload-drag) p.icon:global(.ant-upload-drag-icon) {\n  margin-bottom: 4px;\n\n  span {\n    font-size: 24px;\n  }\n}\n\n:global(.ant-upload.ant-upload-drag) p.text:global(.ant-upload-text) {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"compact": "FUY-mBWBcZcgbPCkOwBqWw==",
	"icon": "dH1RM8L1D9l5qZ9onaHkmg==",
	"text": "vHaEaICnjDn2Z--o7QYtsQ=="
};
export default ___CSS_LOADER_EXPORT___;
