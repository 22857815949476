export function encodeBase64String(string) {
  if (typeof string === 'string')
    return encodeURIComponent(new Buffer(string, 'utf8').toString('base64'));
  return null;
}

export function decodeBase64String(string) {
  if (typeof string === 'string')
    return new Buffer(decodeURIComponent(string), 'base64').toString('utf8');
  return null;
}
