import {Breadcrumb} from 'antd';
import axios from 'axios';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ActionBar from '../../components/action-bar';
import ActionButton from '../../components/action-button';
import FlexCard from '../../components/flex-card';
import MiniTable from '../../components/mini-table';
import AuthenticatedLayout from '../../layouts/authenticated';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import UsuarioDrawer from './_UsuarioDrawer';
import UsuariosTable from './_UsuariosTable';

export default function UsuariosPage() {
  const miniTableRef = useRef();
  const {t} = useTranslation();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [activeSearch, setActiveSearch] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [usuario, setUsuario] = useState();
  const [usuarioDrawerVisible, setUsuarioDrawerVisible] = useState(false);

  const handleSearch = search => {
    setLoadingSearch(true);
    unwrapAxiosPromise(axios.get('/usuarios', {params: {search}}))
        .then(data => {
          setActiveSearch(search);
          setDataSource(data);
        })
        .finally(() => setLoadingSearch(false));
  };

  const handleCreateClick = () => {
    setUsuario(undefined);
    setUsuarioDrawerVisible(true);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete('/usuarios', {params: {ids: selectedIds}}))
        .then(() => {
          setSelectedIds([]);
          handleSearch(activeSearch);
        })
        .finally(() => setLoadingRemove(false));
  };

  const handleClick = usuario => {
    setLoadingDrawer(true);
    setUsuarioDrawerVisible(true);
    unwrapAxiosPromise(axios.get(`/usuarios/${usuario.id}`))
        .then(data => {
          const {acessos, ...d} = data;
          const acessoIds = acessos.map(acesso => acesso.id);
          setUsuario({...d, acessoIds});
        })
        .finally(() => setLoadingDrawer(false));
  };

  const handleUsuarioChange = () => {
    handleSearch(activeSearch);
  };

  const handleUsuarioDrawerClose = () => {
    setUsuarioDrawerVisible(false);
  };

  const handleUsuarioDrawerAfterClose = () => {
    setUsuario(undefined);
  };

  const title = () => {
    return (
        <ActionBar onSearch={handleSearch}>
          <ActionButton.Create onClick={handleCreateClick} />
          <ActionButton.Remove
              disabled={selectedIds.length === 0}
              onClick={handleRemove}
              loading={loadingRemove}
          />
          <MiniTable.DownloadButton disabled={loadingSearch || loadingRemove || dataSource.length === 0} miniTableRef={miniTableRef}/>
        </ActionBar>);
  };

  return (
      <AuthenticatedLayout breadcrumb={[t('Usuários')]}>
        <Breadcrumb>
          <Breadcrumb.Item>{t('Usuários')}</Breadcrumb.Item>
        </Breadcrumb>
        <FlexCard>
          <UsuariosTable
              dataSource={dataSource}
              loading={loadingSearch || loadingRemove}
              miniTableRef={miniTableRef}
              onClick={handleClick}
              onSelectedIdsChange={setSelectedIds}
              selectedIds={selectedIds}
              title={title}
          />
        </FlexCard>
        <UsuarioDrawer
            loading={loadingDrawer}
            onAfterClose={handleUsuarioDrawerAfterClose}
            onChange={handleUsuarioChange}
            onClose={handleUsuarioDrawerClose}
            value={usuario}
            visible={usuarioDrawerVisible}
        />
      </AuthenticatedLayout>
  );
}
