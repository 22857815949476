import {LockOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import styles from '../../pages/_styles.less';
import React from 'react';

export default function InputPassword(props) {
  return (
      <Input.Password
          prefix={<LockOutlined className={styles['prefix']} />}
          maxLength={32}
          {...props}
      />
  );
}
