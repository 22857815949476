import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './_SelectWorkflowTipoTarefa.less';
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import util from "util";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { createWorkflowTipoTarefa, fetchWorkflowTipoTarefa, selectWorkflowTipoTarefaId, tipoTarefaByIdMapSelector } from "../../../store/workflow";
import { useSelector } from "react-redux";
import ActionLink from "../../../components/action-link";
import useCollator from "../../../hooks/useCollator";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";

export default function SelectWorkflowTipoTarefa({ workflowId, onChange, params, value, ...props }) {
  const { t } = useTranslation();
  const { compare, contains } = useCollator();
  const dispatch = useDispatchWithResult();
  const tipoTarefaById = useSelector(tipoTarefaByIdMapSelector);
  const [searchValue, setSearchValue] = useState('');

  const nome = searchValue.trim();

  const tiposTarefasFiltrados = useMemo(() =>
      Object.values(tipoTarefaById)
        .filter(tipoTarefa => contains(tipoTarefa.nome, nome))
        .sort((a, b) => compare(a.nome, b.nome))
        .slice(0, 50),
    [contains, tipoTarefaById, nome]);

  const handleChange = useCallback(id => {
    if (id === -1) {
      unwrapAxiosPromise(dispatch(createWorkflowTipoTarefa({ workflowId, nome })))
        .then(data => {
          onChange(data.id);
          unwrapAxiosPromise(dispatch(fetchWorkflowTipoTarefa({ workflowId, id: data.id })));
          dispatch(selectWorkflowTipoTarefaId(data.id));
        });
    } else
      onChange(id);
  }, [workflowId, nome]);

  const handleAlterarClick = useCallback(() => {
    unwrapAxiosPromise(dispatch(fetchWorkflowTipoTarefa({ workflowId, id: value })));
    dispatch(selectWorkflowTipoTarefaId(value));
  }, [workflowId, value]);

  return (
    <div className={styles['wrapper']}>
      <Select
        allowClear
        autoFocus
        filterOption={false}
        searchValue={searchValue}
        onChange={handleChange}
        onSearch={setSearchValue}
        showSearch
        value={value ?? null}
        {...props}
      >
        {tiposTarefasFiltrados.map(tipoTarefa => (
          <Select.Option key={tipoTarefa.id} value={tipoTarefa.id}>
            <div className={styles['option']}>
              <div style={{ backgroundColor: '#' + tipoTarefa.cor }}>
                {tipoTarefa.nome}
              </div>
            </div>
          </Select.Option>
        ))}
        {nome.length > 2 &&
          <Select.Option key={-1} value={-1}>
            <div className={styles['create']}>
              <div><PlusOutlined/></div>
              <div>{t(util.format(t('Criar "%s"'), nome))}</div>
            </div>
          </Select.Option>}
      </Select>
      {value > 0 &&
        <ActionLink.Edit noTitle onClick={handleAlterarClick}/>
      }
    </div>
  );
}

SelectWorkflowTipoTarefa.propTypes = {
  workflowId: PropTypes.number,
  onChange: PropTypes.func,
  params: PropTypes.any,
  value: PropTypes.number,
};
