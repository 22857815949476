import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../hooks/useFormatter';
import styles from './_styles.less';

export default function TextAnexo({ label, name, tooltip, value }) {
  const { t } = useTranslation();
  const { formatBytes } = useFormatter();

  const style = {
    marginBottom: label ? undefined : '0'
  }

  const downloadDisabled = useMemo(() => !value, [value]);

  const valueFmt = value ?
    (name ?? value.name) + ' (' + formatBytes(value.size) + ')' :
    null;

  return (
    <div className={styles['text-anexo']}>
      <label>
        {t(label)}
        {tooltip &&
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined className={styles['icon']}/>
          </Tooltip>
        }

        <p className={styles['value']} style={style}>
          {downloadDisabled && valueFmt}
          {!downloadDisabled &&
            <a download={name ?? value?.name} href={value?.url}>
              {valueFmt}
            </a>
          }
        </p>
      </label>
    </div>
  );
}

TextAnexo.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.object,
};
