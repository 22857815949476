import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import InputNumber from '../../../../components/input-number';
import MiniForm from '../../../../components/mini-form';
import SelectEmpresaSocio from '../../../../components/select-empresa-socio';

export default function EmpresaRDEIEDForm({disabled, empresaId, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="600px">
            <Form.Item name="socioId" label={t('Sócio')}>
              <SelectEmpresaSocio disabled={disabled} empresaId={empresaId} params={{exterior: true}} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="dataOperacaoCambial" label={t('Data da Operação Cambial')}>
              <InputDate disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="valorOperacaoDolar" label={t('Valor da Operação (US$)')}>
              <InputNumber disabled={disabled} maximumFractionDigits={2} minimumFractionDigits={2} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="dataUltimaApresentacao" label={t('Data da Última Atualização')}>
              <InputDate disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="anexo" label={t('Anexo')}>
              <InputAnexo disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="obs" label={t('Observação')}>
              <Input.TextArea autoSize disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </MiniForm>);
}

EmpresaRDEIEDForm.propTypes = {
  disabled: PropTypes.bool,
  empresaId: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
