import { useSelector } from "react-redux";
import { areaByIdSelector } from "../store/consultivo";
import { useCallback } from "react";

export default function useConsultivo() {
  const areaById = useSelector(areaByIdSelector);

  /**
   * @param areaId {number}
   * @return {object}
   */
  const getArea = useCallback(areaId => areaById[areaId], [areaById]);

  /**
   * @param areaId {number}
   * @return {string}
   */
  const getAreaCaminho = useCallback(areaId => {
    const area = getArea(areaId);
    if (area?.areaPai?.id > 0)
      return getAreaCaminho(area.areaPai?.id) + ' / ' + area.nome
    if (area?.areaPai > 0)
      return getAreaCaminho(area.areaPai) + ' / ' + area.nome
    return area?.nome ?? '';
  }, [getArea]);

  return { getArea, getAreaCaminho };
}
