import { message, Space } from 'antd';
import axios from 'axios';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import MiniTable from '../../../../components/mini-table';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import styles from '../_styles.less';
import { isGestorSelector } from "../../../../store/autenticacao";
import { fasesSelector, fetchFases, fetchLogs, hasPermissaoSelector } from "../../../../store/consultivoConsulta";
import ConsultivoConsultaFasesTable from "./_table";
import { Consulta } from "../../../../constants";

export default function ConsultivoConsultaFasesDetailPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.FASE_ALTERAR.id));
  const fases = useSelector(fasesSelector);
  const loading = useLoading(fetchFases.typePrefix);
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;

  const handleConcluir = ({ id, dataConclusao }, { form }) => {
    return unwrapAxiosPromise(axios.post(`/consultivo/consultas/${consultaId}/fases/${id}/concluir`, { dataConclusao }), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        dispatch(fetchFases({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
      })
  };

  const actions = [];
  actions.push(<MiniTable.DownloadButton disabled={loading || fases.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar>{actions}</ActionBar>;
  return (
    <Space direction="vertical" className={styles['space']}>
      <ConsultivoConsultaFasesTable
        dataSource={fases}
        disabled={!isEditable}
        loading={loading}
        miniTableRef={miniTableRef}
        onConcluir={handleConcluir}
        title={title}
      />
    </Space>);
}
