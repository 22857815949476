import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaProcuradoresDetailPage from './_detail';
import EmpresaProcuradorPage from './procurador';

export default function EmpresaProcuradoresPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/procuradores/:id" component={EmpresaProcuradorPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaProcuradoresDetailPage} />
        </Switch>
      </Card>);
}
