import React from 'react';
import styles from './_styles.less';
import homeHeaderImage from '../../assets/images/home-header.jpg'
import logoDark from '../../assets/legalpanel-dark-2.svg';

export default function HomeHeader() {
  return (
    <div className={styles['home-header']}>
      <div className={styles['image']}>
        <img alt="header" src={homeHeaderImage}/>
      </div>
      <div className={styles['rectangle']}/>
      <div className={styles['logo']}>
        <img alt="logo" src={logoDark}/>
      </div>
    </div>
  )
}
