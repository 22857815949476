import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../components/action-bar';
import ActionButton from '../../../components/action-button';
import Logo from '../../../components/logo';
import TextItem from '../../../components/text-item';
import { Empresa, findByIdAndMap } from '../../../constants';
import useFormatter from '../../../hooks/useFormatter';
import useLoading from '../../../hooks/useLoading';
import { cadastroAlertasSelector, cadastroSelector, fetchCadastro, isEditableSelector } from '../../../store/empresa';
import { openPopup } from '../../../util/window';
import Alertas from '../_Alertas';
import useModulo from "../../../hooks/useModulo";

const { Tipo } = Empresa;

export default function EmpresaCadastroDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const cadastro = useSelector(cadastroSelector);
  const alertas = useSelector(cadastroAlertasSelector);
  const loading = useLoading(fetchCadastro.typePrefix);
  const { formatBoolean, formatCurrency, formatDate, formatDateTime, formatNumber, formatMonth } = useFormatter();
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const isAlterado = cadastro?.createdAt !== cadastro?.updatedAt;
  const {moduloBasePath} = useModulo();
  
  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/cadastro/edit`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/cadastro/`, `logs_/empresas/${empresaId}/cadastro/`);
  };

  return (
    <Spin spinning={loading}>
      {isEditable &&
        <ActionBar>
          <ActionButton.Edit onClick={handleEdit}/>
          <ActionButton.Logs noTitle onClick={handleLogs}/>
        </ActionBar>
      }
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Ativa">{formatBoolean(cadastro?.ativa)}</TextItem></Col>
        <Col flex="250px"><TextItem label="Acesso">{cadastro?.acesso?.nome}</TextItem></Col>
        <Col flex="250px"><TextItem label="Tipo Corporativo">{findByIdAndMap(Tipo, cadastro?.tipo, tipo => t(tipo.label))}</TextItem></Col>
        <Col flex="200px"><Logo src={cadastro?.logo?.url} width={80} height={40}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Número de Quotas/Ações">{isAlterado ? formatNumber(cadastro?.quotas) : null}</TextItem></Col>
        <Col flex="250px"><TextItem label="Capital Social Subscrito (R$)">{isAlterado ? formatCurrency(cadastro?.capitalSocialSubscrito) : null}</TextItem></Col>
        <Col flex="250px"><TextItem label="Capital Social Integralizado (R$)">{isAlterado ? formatCurrency(cadastro?.capitalSocialIntegralizado) : null}</TextItem></Col>
        <Col flex="200px">
          {cadastro?.capitalSocialSubscrito !== cadastro?.capitalSocialIntegralizado &&
            <TextItem label="Data Limite p/ Integralização">{isAlterado ? formatDate(cadastro?.dataLimite) : null}</TextItem>
          }
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Término do Exercício Social">{isAlterado ? formatMonth(cadastro?.mesEncerramento) : null}</TextItem></Col>
        <Col flex="150px"><TextItem label="EPP ou ME">{isAlterado ? formatBoolean(cadastro?.eppme) : null}</TextItem></Col>
        <Col flex="100px"/>
        <Col flex="150px"><TextItem label="Declarações Bacen">{isAlterado ? formatBoolean(cadastro?.def) : null}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label="Observação">{isAlterado ? cadastro?.obs : null}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Login Sisbacen">{isAlterado ? cadastro?.sisbacenLogin : null}</TextItem></Col>
        <Col flex="200px"><TextItem label="Senha Sisbacen">{isAlterado ? cadastro?.sisbacenSenha : null}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(cadastro?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{isAlterado ? formatDateTime(cadastro?.updatedAt) : null}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
      </Row>
      <Alertas alertas={alertas}/>
    </Spin>);
}
