import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { encodeBase64String } from '../util/base64';
import { valuesToFormData } from '../util/formData';
import { isAutenticadoSelector, nomeSelector as autenticacaoNomeSelector } from './autenticacao';

const initialState = {
  token: null,
  nome: null,
  email: null,
  empresa: {},
  diretorio: null,
  arquivosByCaminho: {},
  diretoriosByCaminho: {},
  diretorioCaminhoSelecionado: null,
  baixandoByCaminho: {},
  arquivoCaminhoSelecionado: null,
  criarDiretorioDrawerVisible: false,
  renomearArquivoDrawerVisible: false,
};

export const tokenSelector = state => state.drive.token;
export const nomeSelector = state => state.drive.nome;
export const emailSelector = state => state.drive.email;
export const empresaSelector = state => state.drive.empresa;
export const diretorioSelector = state => state.drive.diretorio;
export const arquivosByCaminhoSelector = state => state.drive.arquivosByCaminho;
export const diretoriosByCaminhoSelector = state => state.drive.diretoriosByCaminho;
export const diretorioCaminhoSelecionadoSelector = state => state.drive.diretorioCaminhoSelecionado;
export const baixandoByCaminhoSelector = state => state.drive.baixandoByCaminho;
export const arquivoCaminhoSelecionadoSelector = state => state.drive.arquivoCaminhoSelecionado;
export const criarDiretorioDrawerVisibleSelector = state => state.drive.criarDiretorioDrawerVisible;
export const renomearArquivoDrawerVisibleSelector = state => state.drive.renomearArquivoDrawerVisible;

export const isDriveAutenticadoTokenSelector = createSelector(tokenSelector, token => !!token);
export const isDriveAutenticadoUsuarioSelector = createSelector(isDriveAutenticadoTokenSelector, isAutenticadoSelector,
    (isAutenticadoToken, isAutenticado) => !isAutenticadoToken && isAutenticado);
export const driveNomeSelector = createSelector(isDriveAutenticadoTokenSelector, nomeSelector, autenticacaoNomeSelector,
    (isAutenticadoToken, nome, autenticacaoNome) => isAutenticadoToken ? nome : autenticacaoNome);
export const diretorioSelecionadoSelector = createSelector(diretoriosByCaminhoSelector, diretorioCaminhoSelecionadoSelector,
    (diretoriosByCaminho, diretorioCaminhoSelecionado) => diretoriosByCaminho[diretorioCaminhoSelecionado] ?? null);
export const arquivoSelecionadoSelector = createSelector(arquivosByCaminhoSelector, arquivoCaminhoSelecionadoSelector,
    (arquivosByCaminho, arquivoCaminhoSelecionado) => arquivosByCaminho[arquivoCaminhoSelecionado] ?? null);

export const fetchDrive = createAsyncThunk(
    'drive/fetchDrive',
    async ({empresaId}, thunkAPI) => {
      try {
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {data} = await axios.get('/drive', {headers});
        return data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const criarDiretorio = createAsyncThunk(
    'drive/criarDiretorio',
    async ({empresaId, nome}, thunkAPI) => {
      try {
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {diretorioCaminhoSelecionado: caminho} = drive;
        await axios.post('/drive/create-directory', {caminho, nome}, {headers});
        thunkAPI.dispatch(fetchDrive({empresaId}));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const baixarArquivo = createAsyncThunk(
    'drive/baixarArquivo',
    async ({empresaId, arquivo}, thunkAPI) => {
      try {
        const {caminho, nome, tipo} = arquivo;
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {data} = await axios.post('/drive/create-download-token', {caminho}, {headers});
        const href = `/api/drive/download?token=${data.token}`;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.setAttribute('href', href);
        if (caminho === '/') {
          const {drive} = thunkAPI.getState();
          const {empresa} = drive;
          a.setAttribute('download', `${empresa.cnpj}-${empresa.apelido || empresa.razaoSocial}.zip`);
        } else {
          a.setAttribute('download', tipo === 'diretorio' ? `${nome}.zip` : nome);
        }
        a.click();
        await new Promise(resolve => setTimeout(resolve, 1000));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const enviarArquivo = createAsyncThunk(
    'drive/enviarArquivo',
    async ({empresaId, anexo}, thunkAPI) => {
      try {
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {diretorioCaminhoSelecionado: caminho} = drive;
        const formData = valuesToFormData({caminho, anexo});
        await axios.post('/drive', formData, {headers});
        thunkAPI.dispatch(fetchDrive({empresaId}));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const renomearArquivo = createAsyncThunk(
    'drive/renomearArquivo',
    async ({empresaId, nome}, thunkAPI) => {
      try {
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {arquivoCaminhoSelecionado: caminho} = drive;
        await axios.put('/drive', {caminho, nome}, {headers});
        thunkAPI.dispatch(fetchDrive({empresaId}));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const removerArquivo = createAsyncThunk(
    'drive/removerArquivo',
    async ({empresaId, arquivo}, thunkAPI) => {
      try {
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {caminho} = arquivo;
        await axios.delete('/drive', {params: {caminho}, headers});
        thunkAPI.dispatch(fetchDrive({empresaId}));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const criarLink = createAsyncThunk(
    'drive/criarLink',
    async ({empresaId, arquivo}, thunkAPI) => {
      try {
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {caminho} = arquivo;
        await axios.post('/drive/create-link', {caminho}, {headers});
        thunkAPI.dispatch(fetchDrive({empresaId}));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const removerLink = createAsyncThunk(
    'drive/removerLink',
    async ({empresaId, arquivo}, thunkAPI) => {
      try {
        const {drive} = thunkAPI.getState();
        const headers = createHeaders({empresaId}, drive);
        const {caminho} = arquivo;
        await axios.post('/drive/revoke-link', {caminho}, {headers});
        thunkAPI.dispatch(fetchDrive({empresaId}));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const identificarLink = createAsyncThunk(
  'drive/identificarLink',
  async ({ token, nome, email }, thunkAPI) => {
    try {
      const { drive } = thunkAPI.getState();
      const headers = createHeaders({ token, nome, email }, drive);
      await axios.post('/drive/identify-link', null, { headers });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const slice = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    selecionarDiretorioCaminho(state, action) {
      if (state.diretorioCaminhoSelecionado !== action.payload) {
        state.diretorioCaminhoSelecionado = action.payload;
      }
    },
    openCriarDiretorioDrawer(state) {
      state.criarDiretorioDrawerVisible = true;
    },
    closeCriarDiretorioDrawer(state) {
      state.criarDiretorioDrawerVisible = false;
    },
    openRenomearArquivoDrawer(state, action) {
      state.renomearArquivoDrawerVisible = true;
      state.arquivoCaminhoSelecionado = action.payload;
    },
    closeRenomearArquivoDrawer(state) {
      state.renomearArquivoDrawerVisible = false;
      state.arquivoCaminhoSelecionado = null;
    },
  },
  extraReducers: {
    [identificarLink.fulfilled]: (state, action) => {
      const { token, nome, email } = action.meta.arg;
      state.token = token;
      state.nome = nome;
      state.email = email;
    },
    [fetchDrive.fulfilled]: (state, action) => {
      function mapArquivosByCaminho(arquivo, pai, map = {}) {
        arquivo.caminhoPai = pai?.caminho;
        map[arquivo.caminho] = arquivo;
        if (arquivo.arquivos)
          arquivo.arquivos.forEach(a => mapArquivosByCaminho(a, arquivo, map));
        return map;
      }

      const {empresa, diretorio} = action.payload;
      state.empresa = empresa;
      state.diretorio = diretorio;
      state.arquivosByCaminho = mapArquivosByCaminho(state.diretorio);
      state.diretoriosByCaminho = Object.values(state.arquivosByCaminho)
          .filter(arquivo => arquivo.tipo === 'diretorio')
          .reduce((pv, cv) => {
            pv[cv.caminho] = cv;
            return pv;
          }, {});
      if (!state.diretorioCaminhoSelecionado || !state.diretoriosByCaminho.hasOwnProperty(state.diretorioCaminhoSelecionado))
        state.diretorioCaminhoSelecionado = diretorio.caminho;
    },
    [baixarArquivo.pending]: (state, action) => {
      const {caminho} = action.meta.arg.arquivo;
      state.baixandoByCaminho[caminho] = true;
    },
    [baixarArquivo.fulfilled]: (state, action) => {
      const {caminho} = action.meta.arg.arquivo;
      state.baixandoByCaminho[caminho] = false;
    },
    [baixarArquivo.rejected]: (state, action) => {
      const {caminho} = action.meta.arg.arquivo;
      state.baixandoByCaminho[caminho] = false;
    },
    [removerArquivo.pending]: (state, action) => {
      const {caminho} = action.meta.arg.arquivo;
      state.baixandoByCaminho[caminho] = true;
    },
    [removerArquivo.fulfilled]: (state, action) => {
      const {caminho} = action.meta.arg.arquivo;
      state.baixandoByCaminho[caminho] = false;
    },
    [removerArquivo.rejected]: (state, action) => {
      const {caminho} = action.meta.arg.arquivo;
      state.baixandoByCaminho[caminho] = false;
    },
  },
});

export default slice.reducer;

export const {
  selecionarDiretorioCaminho, openCriarDiretorioDrawer, closeCriarDiretorioDrawer,
  openRenomearArquivoDrawer, closeRenomearArquivoDrawer,
} = slice.actions;

function createHeaders(values, defaultValues) {
  const token = defaultValues?.token ?? values?.token;
  const nome = defaultValues?.nome ?? values?.nome;
  const email = defaultValues?.email ?? values?.email;
  const empresaId = defaultValues?.empresaId ?? values?.empresaId;
  return {
    'x-drive-authorization': encodeBase64String(JSON.stringify({token, nome, email, empresaId})),
  };
}
