import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Alerta, EmpresaTabByName, EmpresaTabs } from '../constants';

const initialState = {
  comparativoEmpresas: [],
  sucessos: 0,
  avisos: 0,
  erros: 0,
  total: 0,
  proximoAviso: null,
  proximoErro: null,
};

export const comparativoEmpresasSelector = state => state.regulatorioDashboard.comparativoEmpresas;
export const sucessosSelector = state => state.regulatorioDashboard.sucessos;
export const avisosSelector = state => state.regulatorioDashboard.avisos;
export const errosSelector = state => state.regulatorioDashboard.erros;
export const totalSelector = state => state.regulatorioDashboard.total;
export const proximoAvisoSelector = state => state.regulatorioDashboard.proximoAviso;
export const proximoErroSelector = state => state.regulatorioDashboard.proximoErro;

export const slice = createSlice({
  name: 'regulatorioDashboard',
  initialState,
  reducers: {
    fetchDashboard(state, action) {
      state.comparativoEmpresas = [];
      state.sucessos = 0;
      state.avisos = 0;
      state.erros = 0;
      state.proximoAviso = null;
      state.proximoErro = null;
      action.payload.empresas.forEach(empresa => {
        let sucessos = 0;
        let avisos = 0;
        let erros = 0;
        action.payload.alertasByEmpresaId[empresa.id]
          ?.filter(aba => EmpresaTabByName[aba.nome]?.modulos?.includes('regulatorio') === true)
          ?.forEach(aba => {
            switch (aba.nivel) {
              default:
                sucessos++;
                break;
              case Alerta.Nivel.Aviso.id:
                avisos++;
                break;
              case Alerta.Nivel.Erro.id:
                erros++;
                break;
            }
            aba.alertas.forEach(alerta => {
              switch (alerta.nivel) {
                case Alerta.Nivel.Aviso.id:
                  if (!state.proximoAviso || alerta.dataVencimento.localeCompare(state.proximoAviso.dataVencimento) < 0)
                    state.proximoAviso = { ...empresa, aba: aba.nome, dataVencimento: alerta.dataVencimento };
                  break;
                case Alerta.Nivel.Erro.id:
                  if (!state.proximoErro || alerta.dataVencimento.localeCompare(state.proximoErro.dataVencimento) < 0)
                    state.proximoErro = { ...empresa, aba: aba.nome, dataVencimento: alerta.dataVencimento };
                  break;
              }
            });
          });
        state.comparativoEmpresas.push({ ...empresa, sucessos, avisos, erros });
        state.sucessos += sucessos;
        state.avisos += avisos;
        state.erros += erros;
      });
      state.total = state.sucessos + state.avisos + state.erros;
    },
  },
  extraReducers: {},
});

export default slice.reducer;

export const { fetchDashboard } = slice.actions;
