export function mod(number, modulo) {
  return ((number % modulo) + modulo) % modulo;
}

export function compareNumbers(a, b) {
  if (a === b)
    return 0;
  if (a === null || a === undefined)
    return -1;
  if (b === null || b === undefined)
    return 1;
  return a - b;
}
