import { Select } from "antd";
import styles from "./_WorkflowFilterBar.less";
import React, { useEffect, useMemo, useState } from "react";
import useCollator from "../../hooks/useCollator";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";
import { useSelector } from "react-redux";
import { filtrarWorkflow, filtroSelector, workflowSelector } from "../../store/workflow";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";

export default function WorkflowFilterBarMembro() {
  const { compare, contains } = useCollator();
  const dispatch = useDispatchWithResult();
  const workflow = useSelector(workflowSelector)
  const filtro = useSelector(filtroSelector);
  const [membros, setMembros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const workflowId = workflow?.id;

  const nome = searchValue.trim();

  const handleChange = membroIds => {
    dispatch(filtrarWorkflow({ membroIds }));
  }

  useEffect(() => {
    if (workflowId) {
      setLoading(true);
      unwrapAxiosPromise(axios.get(`/workflows/${workflowId}/membros`, { params: { emUso: true } }))
        .then(data => setMembros(data))
        .finally(() => setLoading(false))
    } else
      setMembros([]);
  }, [workflowId]);

  const membrosFiltradas = useMemo(() =>
      membros
        .filter(membro => contains(membro.nome, nome))
        .sort((a, b) => compare(a.nome, b.nome))
        .slice(0, 50),
    [contains, membros, nome]);


  return (
    <Select
      allowClear
      className={styles['select']}
      filterOption={false}
      loading={loading}
      mode="multiple"
      searchValue={searchValue}
      onSearch={setSearchValue}
      showSearch
      value={filtro.membroIds}
      onChange={handleChange}
    >
      {membrosFiltradas.map(membro => (
        <Select.Option key={membro.id} value={membro.id}>
          {membro.nome}
        </Select.Option>
      ))}
    </Select>
  )
}