import {Form, message} from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useRouteMatch} from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchBeneficiariosEmpresa} from '../../../../store/empresa';
import {valuesToFormData} from '../../../../util/formData';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import EmpresaBeneficiarioEmpresaForm from './_form';
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaBeneficiarioEmpresaCreatePage() {
  const {t} = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const {moduloBasePath} = useModulo();

  useEffect(() => {
    form.resetFields();
    setImmediate(() => document.querySelector('#aplicavel').focus());
  }, []);

  const handleFinish = values => {
    setLoadingSave(true);
    const formData = valuesToFormData(values);
    unwrapAxiosPromise(axios.post(`/empresas/${empresaId}/beneficiarios-empresa`, formData), form)
        .then(data => {
          message.success(t('Registro incluído com sucesso'));
          history.replace(`${moduloBasePath}/empresas/${empresaId}/beneficiarios-empresa`);
          dispatch(fetchBeneficiariosEmpresa({empresaId}));
        })
        .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/beneficiarios-empresa`);
  };

  const title = (
      <ActionBar>
        <ActionButton.Save onClick={form.submit} loading={loadingSave} />
        <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave} />
      </ActionBar>);

  return (
      <EmpresaBeneficiarioEmpresaForm
          disabled={loadingSave}
          form={form}
          onFinish={handleFinish}
          title={title}
      />);
}
