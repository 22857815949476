import {Col, Row, Spin} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import {EmpresaConta, findByIdAndMap} from '../../../../constants';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import {contaSelector, fetchConta, isEditableSelector} from '../../../../store/empresa';
import {openPopup} from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

const {Status, Publicacao, Aprovacao, Registro} = EmpresaConta;

export default function EmpresaContaDetailPage() {
  const {t} = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const conta = useSelector(contaSelector);
  const {formatDate, formatDateTime} = useFormatter();
  const loading = useLoading(fetchConta.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/contas/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/contas`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/contas/${id}/`, `logs_/empresas/${empresaId}/contas/${id}/`);
  };

  return (
      <Spin spinning={loading}>
        <ActionBar>
          {isEditable && <ActionButton.Edit onClick={handleEdit} />}
          <ActionButton.Back onClick={handleBack} />
          <ActionButton.Logs noTitle onClick={handleLogs} />
        </ActionBar>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Ano de Referência')}>{conta?.anoReferencia}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Status')}>{findByIdAndMap(Status, conta?.status, status => t(status.label))}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Publicação')}>{findByIdAndMap(Publicacao, conta?.publicacao, publicacao => t(publicacao.label))}</TextItem></Col>
          <Col flex="200px">
            {conta?.publicacao === Publicacao.Sim.id &&
            <TextItem label={t('Data da Publicação')}>{formatDate(conta?.dataPublicacao)}</TextItem>
            }
          </Col>
        </Row>
        {conta?.publicacao !== Publicacao.Pendente.id &&
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Aprovação')}>{findByIdAndMap(Aprovacao, conta?.aprovacao, aprovacao => t(aprovacao.label))}</TextItem></Col>
          <Col flex="200px">
            {conta?.aprovacao === Aprovacao.Sim.id || conta?.aprovacao === Aprovacao.Nao.id &&
            <TextItem label={t('Data da Reunião')}>{formatDate(conta?.dataAprovacao)}</TextItem>
            }
          </Col>
        </Row>
        }
        {conta?.aprovacao !== Aprovacao.Pendente.id &&
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <TextItem label={t('Registro')}>{findByIdAndMap(Registro, conta?.registro, registro => t(registro.label))}</TextItem>
          </Col>
          <Col flex="200px">
            {conta?.registro === Registro.Sim.id &&
            <TextItem label={t('Data da Registro')}>{formatDate(conta?.dataRegistro)}</TextItem>
            }
          </Col>
          <Col flex="400px">
            {conta?.registro === Registro.Sim.id &&
            <TextItem label={t('Órgão de Registro')}>{conta?.registroLocal}</TextItem>
            }
          </Col>
        </Row>
        }
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextAnexo label={t('Anexo')} value={conta?.anexo} /></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextItem label={t('Observação')}>{conta?.obs}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label="Criado em">{formatDateTime(conta?.createdAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(conta?.updatedAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
        </Row>
      </Spin>
  );
}
