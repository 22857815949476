import {Button, Table} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import useFormatter from '../../../../hooks/useFormatter';
import {compareNumbers} from '../../../../util/number';
import {compareStrings} from '../../../../util/string';
import TableCellEllipsis from "../../../../components/table-cell-ellipsis";

export default function EmpresaSociosTable6({dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title}) {
  const {t} = useTranslation();
  const {formatCurrency, formatNumber, formatPercentage} = useFormatter();

  const fireClick = socio => typeof onClick === 'function' ? onClick(socio) : undefined;

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
      render: (text, record) => (
          <Button type="link" onClick={() => fireClick(record)}>
            {text ?? t('(não preenchido)')}
          </Button>
      ),
    },
    {
      title: t('Patrimônio Especial (R$)'),
      align: 'right',
      dataIndex: 'capitalValorTotal',
      key: 'capitalValorTotal',
      sorter: (a, b) => compareNumbers(a.capitalValorTotal, b.capitalValorTotal),
      render: (text, record) => formatCurrency(record.capitalValorTotal),
    },
    {
      title: t('Participação (%)'),
      align: 'right',
      dataIndex: 'capitalValorTotalPercentual',
      key: 'capitalValorTotalPercentual',
      width: 140,
      sorter: (a, b) => compareNumbers(a.capitalValorTotalPercentual, b.capitalValorTotalPercentual),
      render: (text, record) => formatPercentage(record.capitalValorTotalPercentual, {minimumFractionDigits: 4, maximumFractionDigits: 4}),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  const summary = pageData => {
    const total = pageData.reduce((pv, cv) => {
      pv.capitalValorTotal = (pv.capitalValorTotal ?? 0) + cv.capitalValorTotal;
      pv.capitalValorTotalPercentual = (pv.capitalValorTotalPercentual ?? 0) + cv.capitalValorTotalPercentual;
      return pv;
    }, {});

    return (
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right">
            <strong>{formatCurrency(total.capitalValorTotal)}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell className="text-align-right">
            <strong>{formatPercentage(total.capitalValorTotalPercentual, {minimumFractionDigits: 4, maximumFractionDigits: 4})}</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell />
        </Table.Summary.Row>
    );
  };

  return (
      <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          title={title}
          rowSelection={{
            selectedRowKeys: selectedIds,
            onChange: onSelectedIdsChange,
          }}
          summary={summary}
      />
  );
}

EmpresaSociosTable6.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
