import {Col, Form, Input, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InputDocumento from '../../../../../components/input-documento';
import InputNumber from '../../../../../components/input-number';
import InputRazaoSocial from '../../../../../components/input-razao-social';
import MiniForm from '../../../../../components/mini-form';
import SelectBoolean from '../../../../../components/select-boolean';

export default function EmpresaSocioForm6({disabled, form, loading, onFinish, title}) {
  const {t} = useTranslation();

  return (
      <MiniForm form={form} loading={loading} onFinish={onFinish}>
        {title}
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="documento" label={t('CPF/CNPJ')}>
              <InputDocumento disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="400px">
            <Form.Item name="nome" label={t('Nome')}>
              <InputRazaoSocial disabled={disabled} />
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="exterior" label={t('Domicílio Estrangeiro')}>
              <SelectBoolean disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="capitalValorUnitario" label={t('Patrimônio Especial (R$)')}>
              <InputNumber disabled={disabled} maximumFractionDigits={8} minimumFractionDigits={2} />
            </Form.Item>
          </Col>
          <Col flex="400px">
            <Form.Item name="obs" label={t('Tipo de Sócio')}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="capitalQuantidade" noStyle initialValue={1} />
        <Form.Item name="capitalQuantidade2" noStyle initialValue={0} />
      </MiniForm>);
}

EmpresaSocioForm6.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
