// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qFwcRWBQsq7YQEIuLjNKDA\\=\\= .BrDKIKP6FFT0VbixqzSljA\\=\\= {\n  width: 384px;\n}\n.qFwcRWBQsq7YQEIuLjNKDA\\=\\= .-V6T9KmGEe1KUOUY1kqX0w\\=\\= {\n  padding-left: 0;\n}\n.qFwcRWBQsq7YQEIuLjNKDA\\=\\= ._2ew3TAqnHeBE7z8qpE0bCA\\=\\= {\n  vertical-align: middle;\n}\n.qFwcRWBQsq7YQEIuLjNKDA\\=\\= ._2ew3TAqnHeBE7z8qpE0bCA\\=\\= .ant-upload.ant-upload-btn > div {\n  width: 267px;\n}\n.qFwcRWBQsq7YQEIuLjNKDA\\=\\= ._2ew3TAqnHeBE7z8qpE0bCA\\=\\= .ant-upload.ant-upload-btn > div p {\n  white-space: nowrap;\n}\n.qFwcRWBQsq7YQEIuLjNKDA\\=\\= ._2ew3TAqnHeBE7z8qpE0bCA\\=\\= .ant-upload.ant-upload-btn > div p:nth-child(2) {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.qFwcRWBQsq7YQEIuLjNKDA\\=\\= td {\n  vertical-align: top;\n}\n.qFwcRWBQsq7YQEIuLjNKDA\\=\\= td + td {\n  padding-left: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/components/input-empresa-livro-digital-conteudos/_styles.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;AAAJ;AAFA;EAMI,eAAA;AADJ;AALA;EAUI,sBAAA;AAFJ;AARA;EAaM,YAAA;AAFN;AAXA;EAgBQ,mBAAA;AAFR;AAdA;EAoBQ,uBAAA;EACA,gBAAA;AAHR;AAlBA;EA2BI,mBAAA;AANJ;AArBA;EA+BI,kBAAA;AAPJ","sourcesContent":[".input-empresa-livro-digital-conteudos {\n  .nome {\n    width: 384px;\n  }\n\n  .contrato {\n    padding-left: 0;\n  }\n\n  .anexo {\n    vertical-align: middle;\n\n    :global(.ant-upload.ant-upload-btn) > div {\n      width: 267px;\n\n      p {\n        white-space: nowrap;\n      }\n\n      p:nth-child(2) {\n        text-overflow: ellipsis;\n        overflow: hidden;\n      }\n    }\n  }\n\n  td {\n    vertical-align: top;\n  }\n\n  td + td {\n    padding-left: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-empresa-livro-digital-conteudos": "qFwcRWBQsq7YQEIuLjNKDA==",
	"nome": "BrDKIKP6FFT0VbixqzSljA==",
	"contrato": "-V6T9KmGEe1KUOUY1kqX0w==",
	"anexo": "_2ew3TAqnHeBE7z8qpE0bCA=="
};
export default ___CSS_LOADER_EXPORT___;
