// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fzg2wle2olwR8w8sTatgEg\\=\\= {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n}\n.fzg2wle2olwR8w8sTatgEg\\=\\= .W0SRxQZDo3by5mMs1P64Mg\\=\\= {\n  margin-left: 4px;\n  width: 22px;\n  height: 22px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/logs/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AACF;AALA;EAOI,gBAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[".ip-column {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n\n  .pais {\n    margin-left: 4px;\n    width: 22px;\n    height: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ip-column": "fzg2wle2olwR8w8sTatgEg==",
	"pais": "W0SRxQZDo3by5mMs1P64Mg=="
};
export default ___CSS_LOADER_EXPORT___;
