import {Select} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmpresaConta} from '../../constants';

const {Aprovacao} = EmpresaConta;

export default function SelectEmpresaContaAprovacao(props) {
  const {t} = useTranslation();

  const createOption = ({id, label}) => (
      <Select.Option value={id}>
        {t(label)}
      </Select.Option>);

  return (
      <Select {...props} >
        {createOption(Aprovacao.Pendente)}
        {createOption(Aprovacao.Sim)}
        {createOption(Aprovacao.Nao)}
        {createOption(Aprovacao.NaoSeAplica)}
      </Select>
  );
}

SelectEmpresaContaAprovacao.propTypes = {
  value: PropTypes.oneOf([
    Aprovacao.Pendente.id,
    Aprovacao.Sim.id,
    Aprovacao.Nao.id,
    Aprovacao.NaoSeAplica.id]),
  onChange: PropTypes.func,
};
