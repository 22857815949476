// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pQZjRhjAtNdc0TJnmLWLsw\\=\\= {\n  display: flex;\n  align-items: center;\n  flex-flow: row nowrap;\n  gap: 4px;\n}\n.pQZjRhjAtNdc0TJnmLWLsw\\=\\= > .S4W6vREPiduyvqZcFLM-3g\\=\\= {\n  width: 12px;\n  height: 12px;\n}\n", "",{"version":3,"sources":["webpack://src/components/select-consultivo-assunto/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,QAAA;AACF;AALA;EAOI,WAAA;EACA,YAAA;AACJ","sourcesContent":[".option-node {\n  display: flex;\n  align-items: center;\n  flex-flow: row nowrap;\n  gap: 4px;\n\n  > .icon {\n    width: 12px;\n    height: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option-node": "pQZjRhjAtNdc0TJnmLWLsw==",
	"icon": "S4W6vREPiduyvqZcFLM-3g=="
};
export default ___CSS_LOADER_EXPORT___;
