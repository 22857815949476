import {QuestionCircleTwoTone} from '@ant-design/icons';
import {Dropdown, Menu, message, Popconfirm} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import useFormatter from '../../hooks/useFormatter';
import {unwrapResult} from '../../store';
import {baixandoByCaminhoSelector, baixarArquivo, criarLink, isDriveAutenticadoUsuarioSelector, openRenomearArquivoDrawer, removerArquivo, removerLink} from '../../store/drive';
import DriveLink from './_DriveLink';
import styles from './_styles.less';
import useDriveQuery from './_useDriveQuery';

export default function DriveFileDropdown({arquivo, children}) {
  const {t} = useTranslation();
  const {empresaId} = useDriveQuery();
  const dispatch = useDispatchWithResult();
  const isAutenticadoUsuario = useSelector(isDriveAutenticadoUsuarioSelector);
  const baixandoByCaminho = useSelector(baixandoByCaminhoSelector);
  const {formatBytes, formatDateTime} = useFormatter();

  const handleBaixarClick = () => {
    dispatch(baixarArquivo({empresaId, arquivo}));
  };

  const handleCriarLink = () => {
    dispatch(criarLink({empresaId, arquivo}))
        .then(unwrapResult)
        .then(() => message.success(t('Link criado com sucesso')))
        .catch(err => message.error(err.mensagem));
  };

  const handleRemoverLink = () => {
    dispatch(removerLink({empresaId, arquivo}))
        .then(unwrapResult)
        .then(() => message.success(t('Link excluído com sucesso')))
        .catch(err => message.error(err.mensagem));
  };

  const handleRenomearClick = () => {
    dispatch(openRenomearArquivoDrawer(arquivo.caminho));
  };

  const handleExcluirClick = e => {
    e.stopPropagation();
  };

  const handleExcluirCancel = e => {
    e.stopPropagation();
  };

  const handleExcluirConfirm = () => {
    dispatch(removerArquivo({empresaId, arquivo}))
        .then(unwrapResult)
        .then(() => {
          if (arquivo.tipo === 'diretorio')
            message.success(t('Diretório excluído com sucesso'));
          else
            message.success(t('Arquivo excluído com sucesso'));
        })
        .catch(err => message.error(err.mensagem));
  };

  const baixando = baixandoByCaminho[arquivo.caminho] === true;

  const menu = (
      <Menu>
        <Menu.Item key="baixar" onClick={handleBaixarClick}>{t('Baixar')}</Menu.Item>
        {isAutenticadoUsuario && !arquivo.link && arquivo.tipo === 'diretorio' && <Menu.Item key="criar-link" onClick={handleCriarLink}>{t('Criar link')}</Menu.Item>}
        {isAutenticadoUsuario && !!arquivo.link && <Menu.Item key="remover-link" onClick={handleRemoverLink}>{t('Excluir link')}</Menu.Item>}
        {isAutenticadoUsuario && <Menu.Item key="renomear" onClick={handleRenomearClick}>{t('Renomear')}</Menu.Item>}
        {isAutenticadoUsuario &&
        <Menu.Item key="excluir">
          <Popconfirm
              title={t('Você confirma a exclusão do(s) registro(s) selecionado(s)?')}
              onCancel={handleExcluirCancel}
              onConfirm={handleExcluirConfirm}
              onClick={handleExcluirClick}
              okText={t('Sim, excluir')}
              cancelText={t('Não excluir')}
              okButtonProps={{type: 'danger'}}
              icon={<QuestionCircleTwoTone twoToneColor="#ff4d4f" />}
              zIndex={10000}
          >
            <a href="#">{t('Excluir')}</a>
          </Popconfirm>
        </Menu.Item>
        }
        <Menu.Divider key="divider" />
        <Menu.Item key="arquivo-info" disabled className={styles['directory-file-menu-info']}>
          <div className={styles['directory-file-menu-info-div']}>
            <div><strong>{t('Nome')}:</strong> {arquivo.nome}</div>
            <div><strong>{t('Tamanho')}:</strong> {formatBytes(arquivo.tamanho)}</div>
            <div><strong>{t('Tipo')}:</strong> {getTipoNomeByArquivo(arquivo)}</div>
            {arquivo.link && <div><strong>{t('Link')}:</strong> <DriveLink href={arquivo.link} /></div>}
            <div><strong>{t('Criado em')}:</strong> {formatDateTime(arquivo.criadoEm)}</div>
            <div><strong>{t('Modificado em')}:</strong> {formatDateTime(arquivo.modificadoEm)}</div>
          </div>
        </Menu.Item>
      </Menu>
  );

  return (
      <Dropdown disabled={baixando} overlay={menu} trigger={['contextMenu']}>
        {children}
      </Dropdown>
  );
}

DriveFileDropdown.propTypes = {
  arquivo: PropTypes.object,
  children: PropTypes.node,
};

function getTipoNomeByArquivo(arquivo) {
  if (arquivo.tipo) {
    switch (arquivo.tipo) {
      case 'diretorio':
        return 'Diretório';
      case 'application/xml':
        return 'XML';
      case 'application/pdf':
        return 'Adobe PDF';
      case 'application/zip':
        return 'Arquivo ZIP';
      case 'application/x-7z-compressed':
        return 'Arquivo 7-Zip';
      case 'application/vnd.rar':
        return 'Arquivo RAR';
      case 'application/msword':
        return 'Microsoft Word';
      case 'application/vnd.ms-excel':
        return 'Microsoft Excel';
      case 'application/vnd.ms-powerpoint':
        return 'Microsoft PowerPoint';
      case 'text/csv':
        return 'Valores separados por vírgula';
    }

    if (arquivo.tipo.startsWith('text/'))
      return 'Texto';
    if (arquivo.tipo.startsWith('image/'))
      return 'Imagem';
    if (arquivo.tipo.startsWith('audio/'))
      return 'Áudio';
    if (arquivo.tipo.startsWith('video/'))
      return 'Vídeo';
    if (arquivo.tipo.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.'))
      return 'Microsoft Word XML';
    if (arquivo.tipo.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.'))
      return 'Microsoft Excel XML';
    if (arquivo.tipo.startsWith('application/vnd.openxmlformats-officedocument.presentationml.'))
      return 'Microsoft PowerPoint XML';
  }

  return 'Desconhecido';
}
